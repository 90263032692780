import * as React from 'react'
import { FC } from 'react'
import { useToggle } from 'react-use'
import { EmployeeEducationStat } from '@models/employeeEducationStats'
import { User } from '@models/user'
import StatisticTemplate from '@templates/StatisticTemplate'
import Modal from '@organisms/Modal'
import { BarChartOutlined } from '@ant-design/icons'
import GrayButton from '@organisms/GrayButton'

type Props = {
  user: User
  stats: EmployeeEducationStat[]
}

const StatisticButton: FC<Props> = ({ user, stats = [] }) => {
  const [visible, toggleVisible] = useToggle(false)

  return (
    <>
      <GrayButton leftIcon={<BarChartOutlined />} onClick={toggleVisible}>
        Статистика
      </GrayButton>
      <Modal visible={visible} onModalClose={toggleVisible} width="50vw">
        <StatisticTemplate user={user} stats={stats} />
      </Modal>
    </>
  )
}

export default StatisticButton
