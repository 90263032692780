import { numberField, textField } from '@templates/AddNewItemModal/templates'
import { Fields } from '@templates/AddNewItemModal/Content'

const useChapterFields = (): Fields => {
  return [
    {
      fields: [
        textField({
          name: 'title',
          label: 'Название раздела',
          isRequired: true,
          placeholder: 'Название раздела',
        }),
        numberField({
          name: 'hoursCount',
          label: 'Количество часов',
          isRequired: true,
          placeholder: 'Количество',
        }),
      ],
    },
  ]
}

export default useChapterFields
