import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: flex;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  padding-top: 10px;
  align-items: center;
  gap: 20px;
  padding-right: 30px;
`
