import styled from '@emotion/styled'
import { isMobile } from '@const/device'

const DesktopActiveWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0;
  gap: 10px;
`
const MobileActiveWrapper = styled(DesktopActiveWrapper)``
export const ActiveWrapper = isMobile
  ? MobileActiveWrapper
  : DesktopActiveWrapper

export const LinkWrapper = styled.div``
