import { FilterTypes } from '@templates/ListPageLayout/Filters/Filter'
import { FiltersProps } from '@templates/ListPageLayout/Filters'
import RISK_MAP_STATUSES_EMPLOYEE from './statuses'

const useRiskMapsFilters = (): FiltersProps => {
  return [
    {
      filterType: FilterTypes.HIDDEN,
      fieldName: 'id',
    },
    {
      filterType: FilterTypes.SELECT,
      fieldName: 'status',
      placeholder: 'Статус',
      options: RISK_MAP_STATUSES_EMPLOYEE,
      withoutFetchOptions: true,
    },
    {
      filterType: FilterTypes.CHECKBOX,
      fieldName: 'check',
      fieldNameForSubmit: 'reviewed',
      label: 'Не ознакомлен',
      valueOnChecked: 'false',
    },
  ]
}

export default useRiskMapsFilters
