import { compact } from 'lodash'
import { UseFormReturn } from 'react-hook-form'
import { fileField } from '@templates/AddNewItemModal/templates'
import { Fields } from '@templates/AddNewItemModal/Content'
import useJobFields from '@hooks/useJobFields'

const useUploadManyEmployeesFields = (methods: UseFormReturn): Fields => {
  const { company, filial } = useJobFields(methods)

  return [
    {
      fields: compact([
        company?.({
          isRequired: true,
        }),
        filial?.({
          isRequired: true,
        }),
        fileField({
          name: 'file',
          label: 'Файл',
        }),
      ]),
    },
  ]
}

export default useUploadManyEmployeesFields
