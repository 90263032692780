import { Scorm } from '@models/scorm'
import { FormValues } from './index'

const getDefaultValues = (theme?: Scorm): Partial<FormValues> | void => {
  if (!theme) return

  return {
    title: theme.title,
    file: theme.file,
  }
}

export default getDefaultValues
