import { JoyStep } from '@templates/Onboarding'

export const ONBOARDING_TITLE = 'Расскажем, как добавить или найти карту рисков'

export const ONBOARDING_STEPS: JoyStep[] = [
  {
    target: '#add-button',
    title: 'Как добавить карту рисков',
    content: 'Чтобы добавить карту рисков, нажмите кнопку “Добавить”',
  },
  {
    target: '#secondary-add-button',
    title: 'Как добавить карту рисков',
    content:
      'Чтобы добавить несколько карт рисков сразу, воспользуйтесь загрузкой xlsx файла',
  },
  {
    target: '#reports',
    title: 'Создание отчётов по картам рисков',
    content: 'Чтобы создать отчёт по картам рисков, нажмите на кнопку “Отчёты”',
  },
  {
    target: '#search-block',
    title: 'Как найти карту рисков',
    content: 'Вы можете найти нужную карту рисков по номеру',
  },
  {
    target: '#filter-panel',
    title: 'Как найти карту рисков',
    content: 'Также можно найти карту рисков с помощью фильтров',
  },
]
