import { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react'
import { ReactComponent as InfoIcon } from '@interface-images/info-orange.svg'
import { Counter, RightContent } from './styled'

const InfoCounter: FC<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
> = ({ children, ...rest }) => (
  <Counter {...rest}>
    <InfoIcon />
    <RightContent>{children}</RightContent>
  </Counter>
)

export default InfoCounter
