import { SorterProps } from '@templates/ListPageLayout/Filters/Sorter'

export const ALPHABETICAL_SORTER: SorterProps = {
  title: 'По алфавиту',
  fieldName: 'title',
}

export const CREATED_AT_SORTER: SorterProps = {
  title: 'По дате создания',
  fieldName: 'createdAt',
}

export const REGISTERED_AT_SORTER: SorterProps = {
  title: 'По дате регистрации',
  fieldName: 'registeredAt',
}
