import { CourseChapter } from '@models/courseChapter'
import { FormValues } from './index'

const getDefaultValues = (
  chapter?: CourseChapter
): Partial<FormValues> | void => {
  if (!chapter) return

  let result: Partial<FormValues> = {
    title: chapter?.title,
  }

  if (chapter.hoursCount) {
    result = {
      ...result,
      hoursCount: chapter?.hoursCount?.toString(),
    }
  }

  return result
}

export default getDefaultValues
