import styled from '@emotion/styled'

export const Wrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const LineWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: flex-start;
`

export const MiddleWrapper = styled.div``

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`
