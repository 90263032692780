import { Dispatch, FC, SetStateAction } from 'react'
import TextArea from '@organisms/TextArea'
import StarsGrade from './StarsGrade'
import { Wrapper } from './styled'

type Props = {
  setText: Dispatch<SetStateAction<string | undefined>>
  setRank: Dispatch<SetStateAction<number | undefined>>
}

const OnboardingFeedback: FC<Props> = ({ setText, setRank }) => {
  return (
    <Wrapper>
      <StarsGrade setRank={setRank} />
      <TextArea onChange={(e) => setText(e.target.value)} />
    </Wrapper>
  )
}

export default OnboardingFeedback
