import styled from '@emotion/styled'
import { isMobile } from '@const/device'

const DesktopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0 20px;
`
const MobileWrapper = styled(DesktopWrapper)`
  padding: 0;
`
export const Wrapper = isMobile ? MobileWrapper : DesktopWrapper
