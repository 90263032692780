import { FC } from 'react'
import { CourseChapter } from '@models/courseChapter'
import manyWords from '@utils/manyWords'
import Accordion, { AccordionHeaderProps } from '@organisms/Accordion'
import Text from '@organisms/Text'
import { ListItemProps } from '@templates/List'
import {
  HeaderWrapper,
  RightWrapper,
  Square,
  TagsWrapper,
  ThemeTitle,
} from './styled'
import Body from './Body'

const Chapter: FC<
  ListItemProps<
    CourseChapter & {
      number: number
      courseId: number
      educationId: number
    }
  >
> = ({
  isLoading,
  item: { title, id, number, hoursCount, themesCount, courseId, educationId },
  index,
}) => {
  const courseIri = '/api/courses/' + courseId
  const header: FC<AccordionHeaderProps> = ({ hovered, expanded }) => (
    <HeaderWrapper>
      <Square active={expanded || hovered}>{number}</Square>
      <RightWrapper>
        <ThemeTitle hovered={hovered}>{title}</ThemeTitle>
        <TagsWrapper>
          <Text type="tag">
            {manyWords(themesCount, 'тема', 'темы', 'тем')}
          </Text>
          <Text type="tag">
            {manyWords(hoursCount, 'час', 'часа', 'часов')}
          </Text>
        </TagsWrapper>
      </RightWrapper>
    </HeaderWrapper>
  )

  return (
    <Accordion
      id={`chapter${index}`}
      initLoading={isLoading}
      skeletonHeight={105}
      key={id}
      Header={header}
      Body={({ onChangeSize, toggleLoading }) => (
        <Body
          educationId={educationId}
          courseIri={courseIri}
          toggleLoading={toggleLoading}
          onChangeSize={onChangeSize}
          id={id}
          themeNumber={number}
        />
      )}
    />
  )
}

export default Chapter
