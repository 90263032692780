import styled from '@emotion/styled'
import { isMobile } from '@const/device'
import Title from '@molecules/Title'

const DesktopLabel = styled(Title)`
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.1em;
  font-weight: 400;
`
const MobileLabel = styled(DesktopLabel)``
export const Label = isMobile ? MobileLabel : DesktopLabel

export const ValueWrapper = styled.div<{ isExpired: boolean }>`
  & > * > * {
    ${({ isExpired = false }) => (isExpired ? 'border: 1px solid red;' : '')}
  }
`

const DesktopInfoWrapper = styled.div<{ withoutFixedWidth: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  gap: 15px;

  ${({ withoutFixedWidth }) => (withoutFixedWidth ? '' : 'width: 350px;')}
`
const MobileInfoWrapper = styled(DesktopInfoWrapper)`
  align-items: flex-start;
  width: inherit;
  flex-direction: column;
  margin: 10px 20px 0 -5px;
`
export const InfoWrapper = isMobile ? MobileInfoWrapper : DesktopInfoWrapper
