import { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'
import AddNewItemModal from '@templates/AddNewItemModal'
import Modal from '@organisms/Modal'
import { Fields } from '@templates/AddNewItemModal/Content'

type Props = {
  methods: UseFormReturn
  visible: boolean
  onModalClose: () => void
  fields: Fields
  handleSubmit: (form: any) => void
  isLoading: boolean
  transformFormValues?: (form: any) => any
}

const FiltersModal: FC<Props> = ({
  visible,
  onModalClose,
  fields,
  handleSubmit,
  isLoading,
  methods,
  transformFormValues,
}) => {
  const handleSubmitForm = (form: any) => {
    if (transformFormValues) {
      handleSubmit(transformFormValues(form))
    } else {
      handleSubmit(form)
    }

    methods.reset((formValues) => {
      const resultForm: any = {}
      for (const key in formValues) {
        resultForm[key] = null
      }

      return resultForm
    })

    onModalClose()
  }

  return (
    <Modal visible={visible} onModalClose={onModalClose}>
      <AddNewItemModal
        customFullTitle="Фильтр"
        saveText="Применить"
        methods={methods}
        isLoading={isLoading}
        fields={fields}
        onSubmitForm={handleSubmitForm}
        handleCloseModal={onModalClose}
      />
    </Modal>
  )
}

export default FiltersModal
