import { Dispatch, FC, SetStateAction, useEffect } from 'react'
import { BACKEND_URL } from '@const/env'
import GradeBlock from '@pages/Theme/GradeBlock'
import { ScormVotes } from '@models/scorm'
import BackButton from '@organisms/BackButton'
import { isMobile } from '@const/device'
import { useViewScormMutation } from '@services/scorms'
import NavigationBlock from '../NavigationBlock'
import ExpandButton from './ExpandButton'
import {
  BottomWrapper,
  Frame,
  NavButtonsWrapper,
  Wrapper,
  MobileTopWrapper,
  MobileContentWrapper,
  BottomMobileContentWrapper,
  MobileNavBlockWrapper,
} from './styled'

type Props = {
  scormDirectory: string
  inModal: boolean
  toggleInModal: Dispatch<SetStateAction<boolean>>
  scormType: 'articulate' | 'ispring'
  prevRoute?: string | null
  nextRoute?: string | null
  onChangePage: () => Promise<void>
  scormId: string
  scormIri: string | undefined
  currentVote: ScormVotes | null | undefined
  handleBack: () => void
  title: string | undefined
}

const ScormViewer: FC<Props> = ({
  scormDirectory,
  inModal,
  toggleInModal,
  scormType,
  prevRoute,
  nextRoute,
  onChangePage,
  scormId,
  scormIri,
  currentVote,
  handleBack,
}) => {
  const [viewScorm] = useViewScormMutation()

  useEffect(() => {
    if (scormIri) {
      viewScorm(scormIri)
    }
  }, [viewScorm, scormIri])

  if (!scormDirectory) return null

  const back = <BackButton onClick={handleBack}>Весь курс</BackButton>
  const frame = (
    <Frame
      frameBorder="0"
      name="contentBlock"
      id="scormFrame"
      src={`${BACKEND_URL}/course-themes/${scormDirectory}/${
        scormType === 'articulate' ? 'story' : 'res/index'
      }.html`}
      inModal={inModal}
    />
  )
  const expand = (
    <ExpandButton inModal={inModal} toggleInModal={toggleInModal} />
  )
  const navigation = (
    <NavigationBlock
      onChangePage={onChangePage}
      prevRoute={prevRoute}
      nextRoute={nextRoute}
    />
  )
  const grade = <GradeBlock scormId={scormId} currentVote={currentVote} />

  return (
    <Wrapper inModal={inModal}>
      {isMobile ? (
        <MobileContentWrapper inModal={inModal}>
          {!inModal && <MobileTopWrapper>{back}</MobileTopWrapper>}
          {frame}
          <BottomMobileContentWrapper>
            {grade}
            <MobileNavBlockWrapper>
              {expand}
              {navigation}
            </MobileNavBlockWrapper>
          </BottomMobileContentWrapper>
        </MobileContentWrapper>
      ) : (
        <>
          {frame}
          <BottomWrapper>
            {grade}
            <NavButtonsWrapper>
              {expand}
              {back}
              {navigation}
            </NavButtonsWrapper>
          </BottomWrapper>
        </>
      )}
    </Wrapper>
  )
}

export default ScormViewer
