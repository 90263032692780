import useScorms from '@hooks/useScorms'
import useMe from '@hooks/useMe'
import { Routes } from '@enums/Routes'
import useCourses from './useCourses'

type Params = {
  coursesLength?: number
  themesLength?: number
}

const useThemesCoursesTabs = (params?: Params) => {
  const { coursesLength, themesLength } = params || {}
  const { isAdmin } = useMe()
  const scorms = useScorms()
  const courses = useCourses()

  const titleTabs = [
    {
      index: 1,
      title: 'Витрина курсов',
      counter: coursesLength || courses?.data?.length || 0,
      route: Routes.COURSES,
    },
  ]

  if (isAdmin) {
    titleTabs.push({
      index: 2,
      title: 'Темы',
      counter: themesLength || scorms?.data?.length || 0,
      route: Routes.THEMES,
    })
  }

  return titleTabs
}

export default useThemesCoursesTabs
