import { CompanyGroupDetailed } from '@models/companyGroup'
import { Option } from '@organisms/Select'
import { FormValues } from './index'

type Result = Partial<FormValues & { companyGroup: Option }>

const getDefaultValues = (
  companyGroup?: CompanyGroupDetailed
): Result | void => {
  if (!companyGroup) return

  const result: Result = {
    title: companyGroup.title,
    subscriptionsCount: companyGroup.subscriptionsCount?.toString(),
  }

  if (companyGroup.companies) {
    result.companies = companyGroup.companies.map((item) => ({
      ...item,
      label: item.title,
      value: item['@id'],
    }))
  }

  return result
}

export default getDefaultValues
