import styled from '@emotion/styled'
import { isMobile } from '@const/device'

export const Default = styled.div`
  font-size: ${({ theme }) => theme.text.pNormal.fontSize};
  line-height: ${({ theme }) => theme.text.pNormal.lineHeight};
  font-weight: 500;
  letter-spacing: 0;
  text-align: left;
  color: ${({ theme }) => theme.colors.text.black};
`

const DesktopTag = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.disable};
  line-height: ${({ theme }) => theme.text.h5.lineHeight};
  font-weight: 400;
  letter-spacing: 0.1em;
  text-align: left;
  text-transform: uppercase;
`

const MobileTag = styled(DesktopTag)``

export const Tag = isMobile ? MobileTag : DesktopTag

export const Error = styled.div`
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
  color: ${({ theme }) => theme.colors.error};
`

export const Warning = styled.div`
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
  color: ${({ theme }) => theme.colors.warning};
`

export const Description = styled(Warning)`
  color: #7b7b7b;
`
