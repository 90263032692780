import { FC } from 'react'
import { useDeleteDocumentMutation } from '@services/documents'
import DeleteButton from '@templates/DeleteButton'

type Props = {
  documentId: number
}

const DeleteButtonComponent: FC<Props> = ({ documentId }) => {
  const [deleteDocument, data] = useDeleteDocumentMutation()

  const handleDelete = () => {
    deleteDocument(documentId)
  }

  return (
    <DeleteButton
      {...data}
      onClick={handleDelete}
      deleteDescription="Вы точно хотите удалить документ?"
    />
  )
}

export default DeleteButtonComponent
