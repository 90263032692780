import { FC } from 'react'
import ListPage from '@templates/ListPage'
import useInstructions from '@hooks/useInstructions'
import Instruction from './Instruction'

const Instructions: FC = () => {
  const { data, ...rest } = useInstructions()
  const { items = [], length = 0 } = data || {}

  return (
    <ListPage
      {...rest}
      ItemComponent={Instruction}
      list={items}
      length={length}
    />
  )
}

export default Instructions
