import StoreTags from '@enums/StoreTags'
import { CommonQueryParams, ItemsFromResponse } from '@models/common'
import { DangerHazard, DangerHazardOption } from '@models/dangerHazard'
import emptySplitApi from '../api'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'

const BASE_URL = '/api/risk_map_danger_hazards'
const TAGS = [StoreTags.DANGER_HAZARDS, StoreTags.DANGER_HAZARD]

export const DangerHazardsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDangerHazards: builder.query<
      { items: DangerHazardOption[]; length: number },
      CommonQueryParams
    >({
      providesTags: TAGS,
      query: (params) => ({
        url: BASE_URL,
        params,
      }),
      transformResponse: (response: ItemsFromResponse<DangerHazard>) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          label: item.value,
          value: item['@id'],
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
    }),
  }),
})

export const { useGetDangerHazardsQuery } = DangerHazardsApi
