import { compact } from 'lodash'
import { UseFormReturn } from 'react-hook-form'
import { Fields } from '@templates/AddNewItemModal/Content'
import useJobFields from '@hooks/useJobFields'
import { dateField } from '@templates/AddNewItemModal/templates'
import { EmployeesReports } from '@pages/Employees/enums'

const useEmployeesFiltersModalFields = (
  methods: UseFormReturn,
  selectedReport: EmployeesReports | undefined
): Fields => {
  const { company, filial, division, position } = useJobFields(methods)

  const filters = [
    {
      fields: compact([
        company?.({
          isRequired: false,
        }),
        filial?.({
          isRequired: false,
        }),
        division?.({
          isRequired: false,
        }),
        position?.({
          isRequired: false,
        }),
      ]),
    },
  ]

  if (selectedReport === EmployeesReports.BODY_CHECK_REPORT_NAME) {
    filters.push({
      fields: [
        dateField({
          label: 'Пройти медосмотр до',
          name: 'nextBodyCheckDate',
        }),
      ],
    })
  }

  return filters
}

export default useEmployeesFiltersModalFields
