import * as React from 'react'
import { FC } from 'react'
import { ProgressStatuses } from '@enums/ProgressStatuses'
import { formatServerDate } from '@utils/date'
import AccordionBodySelectedTabs from '@templates/AccordionBodySelectedTabs'
import { EmployeeEducationDestination } from '@models/employeeEducationDestination'
import DestinationsDates from './DestinationsDates'

type Props = {
  employeeEducationDestinations: EmployeeEducationDestination[]
  onChangeSize: () => void
}

const Destinations: FC<Props> = ({
  employeeEducationDestinations,
  onChangeSize,
}) => {
  return (
    <AccordionBodySelectedTabs
      onTabChange={onChangeSize}
      title="Даты назначений"
      items={employeeEducationDestinations.map((item) => {
        const {
          educationDestination,
          currentStatus,
          totalTime,
          progress = 0,
          passedThemesCount = 0,
          totalThemesCount = 0,
        } = item
        return {
          id: item['@id'],
          badgeColor:
            currentStatus === ProgressStatuses.PASSED
              ? 'green'
              : currentStatus === ProgressStatuses.IN_PROCESS
              ? 'orange'
              : 'gray',
          title: formatServerDate(educationDestination.createdAt),
          children: (
            <DestinationsDates
              educationDestination={educationDestination}
              totalThemesCount={totalThemesCount}
              passedThemesCount={passedThemesCount}
              progress={progress}
              totalTime={totalTime}
              employeeEducationDestination={item}
              onChangeSize={onChangeSize}
            />
          ),
        }
      })}
    />
  )
}

export default Destinations
