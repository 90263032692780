import { UseFormReturn } from 'react-hook-form'
import useMe from '@hooks/useMe'

const useSelectedValues = (methods: UseFormReturn) => {
  const {
    isAdmin,
    company: myCompany,
    companyBranch,
    curatorCompanyBranches = [],
  } = useMe()
  const selectedCompany = isAdmin ? methods.watch('company') : [myCompany]
  const selectedFilial =
    isAdmin || curatorCompanyBranches.length > 0
      ? methods.watch('filial')
      : [companyBranch]

  return {
    selectedCompany,
    selectedFilial,
  }
}

export default useSelectedValues
