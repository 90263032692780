import styled from '@emotion/styled'

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const LogoWrapper = styled.div`
  margin-left: -3px;
`
