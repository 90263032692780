import styled from '@emotion/styled'

export const MainWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.sizes.threeBase};
  border-bottom: 1px solid ${({ theme }) => theme.colors.disable};
  padding: 10px 0;
  width: 100%;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const HeaderWrapper = styled(Wrapper)`
  cursor: pointer;
`

export const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`

export const EqualWrapper = styled(ArrowWrapper)`
  margin-right: 10px;
`

export const BodyWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background.surface};
  justify-content: space-between;
  border-radius: 0 0 30px 30px;
`

export const LeftWrapper = styled.div`
  display: flex;
`

export const TitleWrapper = styled.div<{
  expanded: boolean
}>`
  color: ${({
    theme: {
      colors: {
        main,
        text: { black },
      },
    },
    expanded,
  }) => (expanded ? main : black)};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-align: left;
`
