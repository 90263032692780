import { FC } from 'react'
import useScorms from '@hooks/useScorms'
import useThemesCoursesTabs from '@hooks/useThemesCoursesTabs'
import ListPage from '@templates/ListPage'
import { ALPHABETICAL_SORTER } from '@const/sorters'
import { PER_PAGE } from '@const/pagination'
import ThemeModal from '@modals/ScormModal'
import Theme from './Theme'
import useThemesFilters from './useThemesFilters'

const Themes: FC = () => {
  const filters = useThemesFilters()
  const { data, ...rest } = useScorms({
    pagination: true,
    limit: PER_PAGE,
  })
  const { items = [], length = 0 } = data || {}
  const titleTabs = useThemesCoursesTabs({ themesLength: length })

  return (
    <ListPage
      {...rest}
      ModalContent={ThemeModal}
      selectedTab={2}
      titleTabs={titleTabs}
      filters={filters}
      list={items}
      ItemComponent={Theme}
      length={length}
      sorters={[ALPHABETICAL_SORTER]}
      withSearch
    />
  )
}

export default Themes
