import * as React from 'react'
import { FC, useState } from 'react'
import Tabs from '@organisms/Tabs'
import useMe from '@hooks/useMe'
import { Wrapper } from './styled'
import EditCompanyBlock from './EditCompanyBlock'

const CompanySettings: FC = () => {
  const { company } = useMe()

  const [active, setActive] = useState(0)

  return (
    <Wrapper>
      <Tabs
        active={active}
        setActive={setActive}
        tabSize="big"
        items={[
          {
            id: '1',
            title: 'Настройки компании',
            children: <EditCompanyBlock companyData={company} />,
          },
        ]}
      />
    </Wrapper>
  )
}

export default CompanySettings
