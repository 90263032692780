import { FC } from 'react'
import { components, OptionProps } from 'react-select'
import { useToggle } from 'react-use'
import Checkbox from '@atoms/Checkbox'
import Badge from '@atoms/Badge'
import { Option as OptionType } from '../index'
import { BadgeWrapper, StyledLabel } from './styled'

const Option: FC<OptionProps> = (props) => {
  const {
    innerProps,
    selectProps: { isMulti },
    isSelected,
    label,
    data,
  } = props
  const dataOption = data as OptionType

  const [hovered, toggleHovered] = useToggle(false)

  return (
    <div
      onMouseEnter={() => toggleHovered(true)}
      onMouseLeave={() => toggleHovered(false)}
    >
      <components.Option
        {...props}
        innerProps={{
          ...innerProps,
          style: {
            ...innerProps.style,
            display: 'flex',
            flexWrap: 'nowrap',
            gap: 10,
            cursor: 'pointer',
            backgroundColor: 'white',
            color: 'black',
          },
        }}
      >
        {dataOption.badgeColor && (
          <BadgeWrapper>
            <Badge badgeColor={dataOption.badgeColor} />
          </BadgeWrapper>
        )}
        {isMulti && <Checkbox checked={isSelected} value={isSelected} />}
        <StyledLabel hovered={hovered}>{label}</StyledLabel>
      </components.Option>
    </div>
  )
}

export default Option
