import { SelectProps } from '@organisms/Select'
import {
  FilterProps,
  FilterTypes,
} from '@templates/ListPageLayout/Filters/Filter'
import useCourses from './useCourses'

const useCoursesFilter = (
  fieldNameForSubmit?: string
): FilterProps<SelectProps> => {
  const {
    data: coursesData,
    isLoading: coursesIsLoading,
    setFilters,
    setPage,
  } = useCourses()
  const { items = [], length = 0 } = coursesData || {}

  return {
    options: items,
    length,
    isLoading: coursesIsLoading,
    placeholder: 'Курс',
    fieldName: 'course',
    fieldNameForSubmit,
    filterType: FilterTypes.SELECT_SEARCH,
    setFilters,
    setPage,
    dropdownWidth: 500,
  }
}

export default useCoursesFilter
