import { TextAreaProps, TextAreaRef } from 'antd/lib/input/TextArea'
import { FC, forwardRef, useImperativeHandle, useRef } from 'react'
import Loader from '@atoms/Loader'
import { StyledTextArea } from './styled'

type Props = {
  hasError?: boolean
  isLoading?: boolean
} & TextAreaProps

const TextArea: FC<Props> = forwardRef<TextAreaRef, Props>(
  (props, outerRef) => {
    const { isLoading, rows = 4, ...rest } = props
    const innerRef = useRef<TextAreaRef | null>(null)
    useImperativeHandle(outerRef, () => innerRef.current!, [])

    return (
      <Loader isLoading={!!isLoading}>
        <StyledTextArea
          {...rest}
          ref={(ref: TextAreaRef | null) => {
            innerRef.current = ref
          }}
          rows={rows}
          autoSize={{
            minRows: 4,
            maxRows: 6,
          }}
        />
      </Loader>
    )
  }
)

export default TextArea
