import { FC } from 'react'
import ContentBlock from '@organisms/ContentBlock'
import EditPasswordTemplate from '@templates/EditPasswordTemplate'

const EditPasswordBlock: FC = () => {
  return (
    <ContentBlock>
      <EditPasswordTemplate />
    </ContentBlock>
  )
}

export default EditPasswordBlock
