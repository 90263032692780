import { FC } from 'react'
import { useLocation, useMatch, useNavigate } from 'react-router-dom'
import useMouseOverOut from '@hooks/useMouseOverOut'
import useMe from '@hooks/useMe'
import { isMobile } from '@const/device'
import { StyledMenuLink, Wrapper, Xls } from './styled'
import { RouteConfig } from 'src/routes'

const MenuItem: FC<
  RouteConfig & {
    isSmall: boolean
  }
> = ({
  ActiveIcon,
  InactiveIcon,
  text,
  getText,
  route,
  onItemClick,
  routesForMatch,
  isSmall,
  badgeText,
  query,
}) => {
  const me = useMe()
  const [hovered, , handleMouseEnter, handleMouseLeave] = useMouseOverOut()
  const navigate = useNavigate()
  const path = route + (query || '')
  const match = useMatch(path)
  const { pathname } = useLocation()

  const isMatch =
    !!match ||
    routesForMatch?.some((route) => route.includes(pathname.slice(0, 4)))

  const handleClick = () => {
    navigate(path)
    onItemClick?.(path)
  }

  const isActive = isMatch || hovered

  return (
    <Wrapper
      key={route}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      {isActive
        ? ActiveIcon && <ActiveIcon />
        : InactiveIcon && <InactiveIcon />}
      {(!isSmall || isMobile) && (
        <StyledMenuLink isActive={isActive}>
          {getText?.(me) || text}
        </StyledMenuLink>
      )}
      {badgeText && <Xls>{badgeText}</Xls>}
    </Wrapper>
  )
}

export default MenuItem
