import { FC } from 'react'
import { useToggle } from 'react-use'
import { CloseOutlined } from '@ant-design/icons'
import PrimaryButton from '@organisms/PrimaryButton'
import { CloseWrapper, TextWrapper, Wrapper } from './styled'

const InfoBanner: FC = () => {
  const closable = process.env.REACT_APP_BANNER_CLOSABLE === 'true'
  const bannerLink = process.env.REACT_APP_BANNER_LINK
  const text = process.env.REACT_APP_BANNER_TEXT
  const [visible, toggleVisible] = useToggle(!!text)

  const handleLinkClick = () => {
    window.open(bannerLink, '_blank')
  }

  if (!visible || !text) return null

  return (
    <Wrapper>
      <TextWrapper dangerouslySetInnerHTML={{ __html: text }} />
      {!!bannerLink && (
        <PrimaryButton onClick={handleLinkClick}>Смотреть</PrimaryButton>
      )}
      {closable && (
        <CloseWrapper>
          <CloseOutlined onClick={toggleVisible} />
        </CloseWrapper>
      )}
    </Wrapper>
  )
}

export default InfoBanner
