import { IMaskInput } from 'react-imask'
import styled from '@emotion/styled'

export const StyledMaskedInput = styled(IMaskInput)`
  height: 40px;
  border-radius: 30px;
  padding: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  border: 1px solid ${({ hasError }) => (hasError ? '#EF4949' : '#c4c4c4')};

  width: 100%;
  font-style: normal;
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  gap: 6.5px;

  &:focus {
    border: 1px solid #1d4e89;
  }

  &::placeholder {
    color: #7f7f7f;
  }

  &:disabled {
    cursor: not-allowed;
    background: #f2f6fa;
    color: white;
    box-shadow: none;
    border: none;

    &:hover {
      cursor: not-allowed;
      background: #f2f6fa;
      color: white;
      box-shadow: none;
      border: none;
    }
  }
`

export const StyledSmallMaskedInput = styled(StyledMaskedInput)`
  height: 40px;
  border-radius: 30px;
  padding: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`

export const StyledBigMaskedInput = styled(StyledMaskedInput)`
  height: 58px;
  border-radius: 32px;
  padding: 20px 26px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
`
