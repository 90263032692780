import { FC } from 'react'
import * as React from 'react'
import { useToggle } from 'react-use'
import GrayButton from '@organisms/GrayButton'
import { RiskMapDetailed } from '@models/riskMap'
import RiskMapStatuses from '@enums/RiskMapStatuses'
import Modal from '@organisms/Modal'
import Prompt from '@organisms/Prompt'
import ReviseModal from './ReviseModal'

const ENABLE_REVISE_STATUSES = new Set([
  RiskMapStatuses.APPROVED,
  RiskMapStatuses.EXTENDED,
  RiskMapStatuses.CHANGED,
])

type Props = {
  data: RiskMapDetailed
  index: number
}

const ReviseButton: FC<Props> = ({ data, index }) => {
  const { status } = data || {}

  const [visible, toggleVisible] = useToggle(false)

  const isDisabled = !ENABLE_REVISE_STATUSES.has(status)

  return (
    <>
      <GrayButton
        id={`risk-map-revise-${index}`}
        disabled={isDisabled}
        onClick={toggleVisible}
      >
        Пересмотреть
      </GrayButton>
      {isDisabled && (
        <Prompt promptText="Вы не можете пересмотреть карту рисков в текущем статусе" />
      )}
      <Modal visible={visible} onModalClose={toggleVisible}>
        <ReviseModal data={data} />
      </Modal>
    </>
  )
}

export default ReviseButton
