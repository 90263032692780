import { FC } from 'react'
import { Skeleton } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useGetTextSettingsByCodeQuery } from '@services/settings'
import { TextSettingsCodes } from '@models/settings'
import BackButton from '@organisms/BackButton'
import ContentBlock from '@organisms/ContentBlock'
import { BackButtonWrapper, Wrapper } from './styled'

const PrivacyPolicy: FC = () => {
  const navigate = useNavigate()

  const { data: privacyPolicy, isLoading } = useGetTextSettingsByCodeQuery(
    TextSettingsCodes.PRIVACY_POLICY
  )

  const handleBack = () => {
    navigate(-1)
  }

  if (isLoading) {
    return (
      <Skeleton.Button
        active
        block
        style={{
          height: '80vh',
          borderRadius: 30,
          margin: '20px',
        }}
      />
    )
  }

  return (
    <Wrapper>
      <BackButtonWrapper>
        <BackButton onClick={handleBack} />
      </BackButtonWrapper>
      <ContentBlock style={{ whiteSpace: 'pre-line' }}>
        {privacyPolicy?.text}
      </ContentBlock>
    </Wrapper>
  )
}

export default PrivacyPolicy
