import { useMount, useToggle } from 'react-use'

const useVisibleOnboarding = () => {
  const [visibleOnboarding, toggleVisibleOnboarding] = useToggle(false)

  useMount(() => {
    setTimeout(toggleVisibleOnboarding, 1500)
  })

  return visibleOnboarding
}

export default useVisibleOnboarding
