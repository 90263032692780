import StoreTags from '@enums/StoreTags'
import { CommonQueryParams, ItemsFromResponse } from '@models/common'
import {
  CreateDivisionBody,
  CreateDivisionResponse,
  Division,
  DivisionDetailed,
  DivisionOption,
  UpdateDivisionBody,
  UpdateDivisionResponse,
} from '@models/division'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'
import emptySplitApi from '../api'

const BASE_URL = '/api/company_branch_subdivisions'

export const divisionsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDivisions: builder.query<
      { items: DivisionOption[]; length: number },
      CommonQueryParams
    >({
      query: (params) => ({
        url: BASE_URL,
        params,
      }),
      transformResponse: (response: ItemsFromResponse<Division>) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          value: item['@id'],
          label: item.title,
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
      providesTags: [StoreTags.DIVISIONS],
    }),
    createDivision: builder.mutation<
      CreateDivisionResponse,
      CreateDivisionBody
    >({
      invalidatesTags: (result) => (result ? [StoreTags.DIVISIONS] : []),
      query: (body) => ({
        url: BASE_URL,
        method: 'POST',
        body,
      }),
    }),
    updateDivision: builder.mutation<
      UpdateDivisionResponse,
      UpdateDivisionBody
    >({
      invalidatesTags: (result) =>
        result ? [StoreTags.DIVISIONS, StoreTags.DIVISION] : [],
      query: ({ id, ...body }) => ({
        url: `${BASE_URL}/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    getDivision: builder.query<DivisionDetailed, number>({
      providesTags: [StoreTags.DIVISION],
      query: (id) => `${BASE_URL}/${id}`,
    }),
    deleteDivision: builder.mutation<void, number>({
      invalidatesTags: [
        StoreTags.DIVISIONS,
        StoreTags.FILIAL,
        StoreTags.FILIALS,
        StoreTags.COMPANY,
        StoreTags.COMPANIES,
      ],
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetDivisionsQuery,
  useCreateDivisionMutation,
  useUpdateDivisionMutation,
  useGetDivisionQuery,
  useLazyGetDivisionQuery,
  useDeleteDivisionMutation,
} = divisionsApi
