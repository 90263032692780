import { EmployeeDetailed } from '@models/employee'

const setDefaultCommissionMembers = (
  result: any,
  commissionHead: EmployeeDetailed | null,
  commissionMembers: EmployeeDetailed[] | null
) => {
  if (commissionHead) {
    result.commissionHead = {
      ...commissionHead,
      value: commissionHead['@id'],
      label: commissionHead.fullName,
    }
  }

  if (commissionMembers) {
    result.commissionMembers = commissionMembers.map((item) => ({
      ...item,
      label: item.fullName,
      value: item['@id'],
    }))
  }

  return result
}

export default setDefaultCommissionMembers
