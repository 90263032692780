import {
  linkField,
  textAreaField,
  textField,
} from '@templates/AddNewItemModal/templates'
import { Fields } from '@templates/AddNewItemModal/Content'

const TEXT_FIELD = textField({
  name: 'text',
  placeholder: 'Текст баннера',
  label: 'Текст',
  widthMultiplier: 3,
})

const useWelcomeBannersFields = (): Fields => {
  return [
    { fields: [TEXT_FIELD] },
    {
      fields: [
        textField({
          name: 'title1',
          placeholder: 'Название',
          label: 'Название кнопки',
        }),
        linkField({
          name: 'link1',
          label: 'Ссылка',
          widthMultiplier: 2,
        }),
      ],
    },
    {
      fields: [
        textAreaField({
          name: 'text1',
          placeholder: 'Текст при необходимости',
          label: 'Текст (при необходимости)',
          widthMultiplier: 3,
        }),
      ],
    },
    {
      fields: [
        textField({
          name: 'title2',
          placeholder: 'Название',
          label: 'Название кнопки',
        }),
        linkField({
          name: 'link2',
          label: 'Ссылка',
          widthMultiplier: 2,
        }),
      ],
    },
    {
      fields: [
        textAreaField({
          name: 'text2',
          placeholder: 'Текст при необходимости',
          label: 'Текст (при необходимости)',
          widthMultiplier: 3,
        }),
      ],
    },
  ]
}

export default useWelcomeBannersFields
