import { FC, useEffect } from 'react'
import { memoize } from 'lodash'
import { notification } from 'antd'
import ThemeSmiles from '@templates/ThemeSmiles'
import { useVoteScormMutation } from '@services/scorms'
import { ScormVotes } from '@models/scorm'
import { StyledTitle, Wrapper } from './styled'

type Props = {
  scormId: string
  currentVote: ScormVotes | null | undefined
}

const GradeBlock: FC<Props> = ({ scormId, currentVote }) => {
  const [voteScorm, { isSuccess }] = useVoteScormMutation()

  const handleClickOnSmileCreator = memoize((rank: ScormVotes) => () => {
    void voteScorm({
      id: scormId,
      rank,
    })
  })

  useEffect(() => {
    if (isSuccess) {
      notification.success({ message: 'Вы успешно оценили занятие!' })
    }
  }, [isSuccess])

  return (
    <Wrapper id="gradeBlock">
      <StyledTitle mySize="h2">Оцените занятие: </StyledTitle>
      <ThemeSmiles
        activeSmile={currentVote || undefined}
        onClickSad={handleClickOnSmileCreator(ScormVotes.BAD)}
        onClickHappy={handleClickOnSmileCreator(ScormVotes.GOOD)}
        onClickLovely={handleClickOnSmileCreator(ScormVotes.EXCELLENT)}
      />
    </Wrapper>
  )
}

export default GradeBlock
