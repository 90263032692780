import styled from '@emotion/styled'

export const Wrapper = styled.div`
  padding: 15px 0;
`

export const TitleWrapper = styled.div`
  display: flex;
  gap: 15px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const AddButtonWrapper = styled.div`
  display: flex;
`
