import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { FooterProps } from '@templates/AddNewItemModalLayout/Footer'
import { Routes } from '@enums/Routes'
import JobsFooter from '@templates/JobsFooter'
import { FilialDetailed } from '@models/filial'
import { DivisionDetailed } from '@models/division'

const Footer: FC<FooterProps> = (props) => {
  const { state } = useLocation()

  return (
    <JobsFooter
      {...props}
      additionalProps={{
        createOptions: [
          {
            label: 'Компанию',
            value: Routes.COMPANY_MODAL,
          },
        ],
        nextRoute: Routes.DIVISION_MODAL,
        nextRouteDescription: 'Переход к созданию подразделения',
        getNextState: (data: FilialDetailed): Partial<DivisionDetailed> => ({
          company: state?.company,
          companyBranch: data,
        }),
      }}
    />
  )
}

export default Footer
