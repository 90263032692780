import { Routes } from '@enums/Routes'

const useTariffEnrollmentTabs = () => [
  {
    index: 0,
    title: 'Тариф',
    route: Routes.TARIFF,
  },
  {
    index: 1,
    title: 'История зачислений',
    route: Routes.ENROLLMENT_HISTORY,
  },
]

export default useTariffEnrollmentTabs
