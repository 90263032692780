import { FC } from 'react'
import Loader from '@atoms/Loader'
import { Container, Wrapper } from './styled'

type Props = {
  title: string
  onClick?: () => void
  isLoading?: boolean
  width?: number
  id?: string
}

const BlueBorderCard: FC<Props> = ({
  onClick,
  isLoading,
  title,
  width,
  id,
}) => {
  const handleClick = () => {
    if (!isLoading) {
      onClick?.()
    }
  }

  return (
    <Container onClick={handleClick} id={id}>
      <Loader isLoading={!!isLoading}>
        <Wrapper width={width}>{title}</Wrapper>
      </Loader>
    </Container>
  )
}

export default BlueBorderCard
