import { FC } from 'react'
import DeleteButton from '@templates/DeleteButton'
import { RiskMapDetailed } from '@models/riskMap'
import RiskMapStatuses from '@enums/RiskMapStatuses'
import Prompt from '@organisms/Prompt'
import { useDeleteRiskMapMutation } from '@services/riskMaps'

type Props = {
  data: RiskMapDetailed
}

const DeleteButtonComponent: FC<Props> = ({ data }) => {
  const [deleteRiskMap, deleteData] = useDeleteRiskMapMutation()

  const handleDelete = () => {
    void deleteRiskMap(data.id)
  }

  const isDisabled = data.status !== RiskMapStatuses.DRAFT

  return (
    <>
      <DeleteButton
        {...deleteData}
        disabled={isDisabled}
        onClick={handleDelete}
        deleteDescription="Вы уверены, что хотите удалить карту рисков?"
      >
        Удалить
      </DeleteButton>
      {isDisabled && (
        <Prompt
          promptText={'Карту рисков можно удалить только в статусе “Черновик”'}
        />
      )}
    </>
  )
}

export default DeleteButtonComponent
