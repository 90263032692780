import {
  DetailedHTMLProps,
  forwardRef,
  ForwardRefExoticComponent,
  InputHTMLAttributes,
  RefAttributes,
} from 'react'
import { StyledComponent } from '@emotion/styled/dist/emotion-styled.cjs'
import { StyledBigInput, StyledSmallInput } from './styled'

type Props = { big?: boolean; hasError?: boolean } & DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

const Input: ForwardRefExoticComponent<
  Pick<
    Props,
    'key' | 'big' | 'hasError' | keyof InputHTMLAttributes<HTMLInputElement>
  > &
    RefAttributes<HTMLInputElement>
> = forwardRef(({ big = false, ...props }, ref) => {
  const Component: StyledComponent<Props> = big
    ? StyledBigInput
    : StyledSmallInput

  return <Component {...props} ref={ref} />
})

export type { Props as InputProps }
export default Input
