import StoreTags from '@enums/StoreTags'
import { CommonQueryParams, ItemsFromResponse } from '@models/common'
import {
  ReviseRiskMapBody,
  RiskMapVersion,
  RiskMapVersionOption,
} from '@models/riskMapVersions'
import emptySplitApi from '../api'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'

const BASE_URL = '/api/risk_map_revisions'
const TAGS = [StoreTags.RISK_MAP_HISTORIES]

export const RiskMapVersionsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getRiskMapVersions: builder.query<
      { items: RiskMapVersionOption[]; length: number },
      CommonQueryParams
    >({
      query: (params) => ({
        url: BASE_URL,
        params,
      }),
      transformResponse: (response: ItemsFromResponse<RiskMapVersion>) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          label: item['@id'],
          value: item['@id'],
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
      providesTags: TAGS,
    }),
    reviseRiskMap: builder.mutation<void, ReviseRiskMapBody>({
      invalidatesTags: (result) =>
        result ? [...TAGS, StoreTags.RISK_MAPS, StoreTags.RISK_MAP] : [],
      query: (body) => ({
        url: BASE_URL,
        method: 'POST',
        body,
      }),
    }),
    markRiskMapAsDownloaded: builder.mutation<void, string>({
      invalidatesTags: (result) =>
        result ? [...TAGS, StoreTags.RISK_MAPS, StoreTags.RISK_MAP] : [],
      query: (revision) => ({
        url: '/api/risk_map_revision_employee_views',
        method: 'POST',
        body: {
          revision,
        },
      }),
    }),
    markRiskMapAsReviewed: builder.mutation<void, string>({
      invalidatesTags: (result) =>
        result ? [...TAGS, StoreTags.RISK_MAPS, StoreTags.RISK_MAP] : [],
      query: (revision) => ({
        url: '/api/risk_map_revision_employee_reviews',
        method: 'POST',
        body: {
          revision,
        },
      }),
    }),
  }),
})

export const {
  useGetRiskMapVersionsQuery,
  useReviseRiskMapMutation,
  useMarkRiskMapAsDownloadedMutation,
  useMarkRiskMapAsReviewedMutation,
} = RiskMapVersionsApi
