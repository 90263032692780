import { FC } from 'react'
import { components, MultiValueProps } from 'react-select'
import { isEmpty } from 'lodash'

const MultiValue: FC<MultiValueProps> = (props) => {
  const {
    selectProps: {
      value,
      isDisabled,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      withoutFillSelected,
    },
    innerProps,
  } = props
  const notSelected = isEmpty(value)

  let color = 'white'
  if (isDisabled) {
    color = '#C4C4C4'
  } else if (notSelected || withoutFillSelected) {
    color = 'black'
  }

  return (
    <components.MultiValue
      {...props}
      innerProps={{
        ...innerProps,
        style: {
          ...innerProps,
          whiteSpace: 'nowrap',
          color,
          display: 'flex',
        },
      }}
    >
      qqq
    </components.MultiValue>
  )
}

export default MultiValue
