import * as React from 'react'
import { FC } from 'react'
import { RiskMapDetailed } from '@models/riskMap'
import { FooterWrapper } from './styled'
import SendForReview from './SendForReview'
import ChangeStatus from './ChangeStatus'
import ReviseButton from './ReviseButton'
import DeleteButton from './DeleteButton'
import EditButton from './EditButton'

type Props = {
  data: RiskMapDetailed
  index: number
}

const Footer: FC<Props> = ({ data, index }) => {
  return (
    <FooterWrapper>
      <ChangeStatus data={data} index={index} />
      <EditButton data={data} />
      <DeleteButton data={data} />
      <ReviseButton data={data} index={index} />
      <SendForReview data={data} index={index} />
    </FooterWrapper>
  )
}

export default Footer
