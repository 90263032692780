import * as React from 'react'
import { FC } from 'react'
import { useToggle } from 'react-use'
import { useLocation } from 'react-router-dom'
import EmailPasswordForm from './EmailPasswordForm'
import RestorePasswordForm from './RestorePasswordForm'

type Props = {
  onBack: () => void
}

const EmailAuthForm: FC<Props> = ({ onBack }) => {
  const { search } = useLocation()
  const [isRestoreForm, toggleIsRestoreForm] = useToggle(
    search?.includes('loginWithEmailRecovery')
  )

  const handleRestorePassword = () => {
    toggleIsRestoreForm(true)
  }

  const handleBackFromRestore = () => {
    toggleIsRestoreForm(false)
  }

  return (
    <>
      {isRestoreForm ? (
        <RestorePasswordForm handleBack={handleBackFromRestore} />
      ) : (
        <EmailPasswordForm
          handleRestorePassword={handleRestorePassword}
          handleBack={onBack}
        />
      )}
    </>
  )
}

export default EmailAuthForm
