import { FC } from 'react'
import { isMobile } from '@const/device'
import useCertificates from '@hooks/useCertificates'
import ListPage from '@templates/ListPage'
import { CREATED_AT_SORTER } from '@const/sorters'
import CertificateItem from './CertificateItem'

const Certificates: FC = () => {
  const { data, ...rest } = useCertificates()
  const { items = [], length = 0 } = data || {}

  return (
    <ListPage
      {...rest}
      titleTabs={[
        {
          index: 0,
          title: 'Мои сертификаты',
          counter: length,
        },
      ]}
      ItemComponent={CertificateItem}
      list={items}
      length={length}
      perLine={isMobile ? 1 : 3}
      sorters={[CREATED_AT_SORTER]}
      withSearch
    />
  )
}

export default Certificates
