import TextArea, { TextAreaRef } from 'antd/lib/input/TextArea'
import styled from '@emotion/styled'

export const StyledTextArea = styled(TextArea)<{
  hasError?: boolean
  ref?: (ref: TextAreaRef) => void
}>`
  border-radius: 25px;
  transition: none;
  padding: 10px 15px;

  ${({ hasError }) => (hasError ? 'border: 1px solid #EF4949;' : '')}
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.disable};
  }
`
