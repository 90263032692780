import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: flex;
  background-color: #f2f6fa;
  padding: 8px 12px;
  gap: 10px;
  border-radius: 16px;
  align-items: center;
  width: fit-content;
`
