import { UseFormReturn } from 'react-hook-form'
import { fileField, textField } from '@templates/AddNewItemModal/templates'
import { Fields } from '@templates/AddNewItemModal/Content'
import { File } from '@models/file'

const useThemeFields = ({ watch }: UseFormReturn): Fields => {
  const file: File | undefined = watch('file')

  return [
    {
      fields: [
        textField({
          name: 'title',
          label: 'Название темы',
          isRequired: true,
          placeholder: 'Название темы',
        }),
        fileField({
          name: 'file',
          label: 'SCORM',
          isRequired: true,
          value: file,
        }),
      ],
    },
  ]
}

export default useThemeFields
