import styled from '@emotion/styled'
import Title from '@organisms/Title'
import { isMobile } from '@const/device'

export const Wrapper = styled.div`
  border-radius: 24px;
  background-color: ${({ theme }) => theme.colors.main};
  color: ${({ theme }) => theme.colors.background.surface};
  padding: ${isMobile ? 16 : 24}px;
  gap: 25px;
  display: flex;
  flex-direction: column;
  max-width: 90vw;
`

export const TitleWrapper = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`

export const StyledTitle = styled(Title)`
  color: white;
  margin-bottom: 10px;
  white-space: break-spaces;

  ${isMobile ? 'font-size: 16px;' : ''}
`
