import { UseFormReturn } from 'react-hook-form'
import { compact, isEmpty } from 'lodash'
import { Filial } from '@models/filial'
import usePositions from '@hooks/usePositions'
import { FieldForRender } from '@templates/AddNewItemModal'
import { createInitFilter } from '@utils/filters'
import { REQUIRED_VALIDATION_RULE } from '@const/validation'
import useMe from '@hooks/useMe'
import { Division } from '@models/division'
import { selectSearchField } from '@templates/AddNewItemModal/templates'
import useDivisions from '@hooks/useDivisions'
import useJobDataForFields from '@hooks/useJobDataForFields'

const getFieldName = (prefix: string, index: number) =>
  `subdivisionJobPositionRels.${index}.${prefix}`

const usePositionDivisionFields = (
  methods: UseFormReturn,
  addParams?: Record<'item' | 'index', number>
): FieldForRender[] => {
  const { filial } = useJobDataForFields(methods)
  const {
    divisionCuratorWithOneDivision,
    filialCuratorWithOneDivision,
    companyBranch,
  } = useMe()

  let selectedFilial: Filial | undefined = methods.watch('filial')
  if (filialCuratorWithOneDivision && !filial) {
    selectedFilial = filialCuratorWithOneDivision
  }

  let selectedDivision: Division | undefined = methods.watch(
    getFieldName('division', addParams?.index || 0)
  )

  if (divisionCuratorWithOneDivision && !filial) {
    selectedFilial = companyBranch
    selectedDivision = divisionCuratorWithOneDivision
  }

  if (!divisionCuratorWithOneDivision && !selectedFilial && !filial) {
    selectedFilial = companyBranch
  }

  const divisionsResponse = useDivisions({
    skip: !selectedFilial,
    ...(selectedFilial?.id
      ? { initFilters: createInitFilter('companyBranch', selectedFilial?.id) }
      : {}),
  })

  const positionsResponse = usePositions({
    skip: !selectedDivision,
    ...(selectedDivision?.id
      ? {
          initFilters: createInitFilter('subdivision', selectedDivision?.id),
        }
      : {}),
  })

  return compact([
    !divisionCuratorWithOneDivision &&
      selectSearchField({
        label: 'Подразделение',
        name: 'division',
        isDisabled: !selectedFilial,
        options: divisionsResponse.data?.items || [],
        isLoading: divisionsResponse.isLoading,
        setFilters: divisionsResponse.setFilters,
        setPage: divisionsResponse.setPage,
        length: divisionsResponse.data?.length || 0,
        isClearable: false,
      }),
    selectSearchField({
      label: 'Должность',
      name: 'position',
      isDisabled: isEmpty(selectedDivision),
      options: positionsResponse.data?.items || [],
      isLoading: positionsResponse.isLoading,
      setFilters: positionsResponse.setFilters,
      setPage: positionsResponse.setPage,
      length: positionsResponse.data?.length || 0,
      isRequired: !isEmpty(selectedDivision),
      rules: {
        ...(!isEmpty(selectedDivision)
          ? REQUIRED_VALIDATION_RULE
          : { required: undefined }),
      },
      isClearable: false,
    }),
  ])
}

export default usePositionDivisionFields
