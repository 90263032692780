import { FC } from 'react'
import BackButton from '@organisms/BackButton'
import NextButton from '@organisms/NextButton'
import { ButtonsWrapper } from './styled'
import ChangeThemeButton from './ChangeThemeButton'

type Props = {
  prevRoute?: string | null
  nextRoute?: string | null
  onChangePage: () => Promise<void>
}

const NavigationBlock: FC<Props> = ({ prevRoute, nextRoute, onChangePage }) => {
  return (
    <ButtonsWrapper>
      <ChangeThemeButton
        ButtonComponent={BackButton}
        newRoute={prevRoute}
        onChangePage={onChangePage}
        id="prev"
      />
      <ChangeThemeButton
        ButtonComponent={NextButton}
        newRoute={nextRoute}
        onChangePage={onChangePage}
        id="next"
      />
    </ButtonsWrapper>
  )
}

export default NavigationBlock
