import { FC } from 'react'
import { useToggle } from 'react-use'
import OutsideClickHandler from 'react-outside-click-handler'
import { ReactComponent as BellIcon } from '@interface-images/bell.svg'
import { useGetUnreadNotificationsCountQuery } from '@services/notifications'
import { POOLING_NOTIFICATIONS_INTERVAL } from '@const/pooling'
import { Container, Wrapper } from './styled'
import Counter from './Counter'
import List from './List'

const Bell: FC = () => {
  const { data: unreadNotificationsCount = 0 } =
    useGetUnreadNotificationsCountQuery(undefined, {
      pollingInterval: POOLING_NOTIFICATIONS_INTERVAL,
    })

  const [listIsVisible, toggleListIsVisible] = useToggle(false)

  return (
    <OutsideClickHandler onOutsideClick={() => toggleListIsVisible(false)}>
      <Container>
        <Wrapper onClick={toggleListIsVisible}>
          <BellIcon />
          <Counter length={unreadNotificationsCount} />
        </Wrapper>
        {listIsVisible && (
          <List
            listIsVisible={listIsVisible}
            toggleListIsVisible={toggleListIsVisible}
          />
        )}
      </Container>
    </OutsideClickHandler>
  )
}

export default Bell
