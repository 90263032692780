import { FC } from 'react'
import { components, DropdownIndicatorProps } from 'react-select'
import { isEmpty } from 'lodash'

const DropdownIndicator: FC<DropdownIndicatorProps> = (props) => {
  const notSelected = isEmpty(props.selectProps.value)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const withoutFillSelected = !!props.selectProps.withoutFillSelected
  return (
    <components.DropdownIndicator
      {...props}
      innerProps={{
        ...props.innerProps,
        style: {
          ...props.innerProps.style,
          color:
            props.selectProps.isDisabled || notSelected || withoutFillSelected
              ? '#c7c7c7'
              : 'white',
        },
      }}
    />
  )
}

export default DropdownIndicator
