import { UseFormReturn } from 'react-hook-form'
import useCourseCategories from '@hooks/useCourseCategories'
import COURSE_LEARN_TYPES from '@const/courseSubTypes'
import COURSE_FREE_FILTERS from '@const/courseFreeFilters'
import useJobFilters from '@hooks/useJobFilters'
import COURSE_TYPES from '@const/courseTypes'
import { FiltersProps } from '@templates/ListPageLayout/Filters'

const useCoursesFilters = (methods: UseFormReturn): FiltersProps => {
  const { company, filial } = useJobFilters(methods)
  const courseCategoriesResponse = useCourseCategories()
  const { items: courseCategories = [], length = 0 } =
    courseCategoriesResponse.data || {}
  const courseCategoriesIsLoading = courseCategoriesResponse.isLoading

  const filters: FiltersProps = [
    {
      isLoading: courseCategoriesIsLoading,
      options: courseCategories,
      length,
      placeholder: 'Категория курса',
      isMulti: true,
      fieldName: 'courseCategory',
      dropdownWidth: 350,
    },
    {
      options: COURSE_TYPES,
      placeholder: 'Тип курса',
      fieldName: 'type',
      isMulti: true,
      length: 0,
      dropdownWidth: 350,
      withoutFetchOptions: true,
    },
    {
      options: COURSE_LEARN_TYPES,
      placeholder: 'Тип обучения',
      fieldName: 'subType',
      isMulti: true,
      length: 0,
      withoutFetchOptions: true,
    },
    {
      options: COURSE_FREE_FILTERS,
      placeholder: 'Платный',
      fieldName: 'free',
      length: 0,
      withoutFetchOptions: true,
    },
  ]

  if (company) {
    filters.push(company)
  }

  if (filial) {
    filters.push({
      ...filial,
      fieldNameForSubmit: 'companyBranch',
    })
  }

  return filters
}

export default useCoursesFilters
