import useFormProcessor from '@hooks/useFormProcessor'
import useJobFilters from '@hooks/useJobFilters'
import { FiltersProps } from '@templates/ListPageLayout/Filters'
import { FilterTypes } from '@templates/ListPageLayout/Filters/Filter'

const useCompanyGroupsFilters = (): FiltersProps => {
  const methods = useFormProcessor()
  const { company } = useJobFilters(methods, {
    company: {
      initFilters: {
        'exists[companyGroup]': true,
      },
    },
  })

  const filters: FiltersProps = [
    {
      filterType: FilterTypes.HIDDEN,
      fieldName: 'id',
    },
  ]

  if (company) {
    filters.push({
      ...company,
      fieldNameForSubmit: 'companies',
    })
  }

  return filters
}

export default useCompanyGroupsFilters
