import { FC, ReactNode } from 'react'
import { Popover } from 'antd'
import { PopoverWrapper } from './styled'

type Props = {
  children: ReactNode
  tooltipContent: ReactNode
  width?: number | string
}

const Tooltip: FC<Props> = ({ children, tooltipContent, width }) => {
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Popover
        content={
          <PopoverWrapper className="popover" width={width}>
            {tooltipContent}
          </PopoverWrapper>
        }
      >
        {children}
      </Popover>
    </div>
  )
}

export default Tooltip
