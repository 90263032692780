enum RiskMapStatuses {
  DRAFT = 'draft', // Черновик
  APPROVED = 'approved', // Утверждён
  ARCHIVED = 'archived', // Архив
  CANCELED = 'canceled', // Отменён
  CHANGED = 'changed', // Изменён
  EXTENDED = 'extended', // Продлён
}

export default RiskMapStatuses
