import StoreTags from '@enums/StoreTags'
import { CommonQueryParams, ItemsFromResponse } from '@models/common'
import {
  CreateDangerWorkPlaceBody,
  CreateDangerWorkPlaceResponse,
  DangerWorkPlace,
  DangerWorkPlaceOption,
} from '@models/dangerWorkPlace'
import emptySplitApi from '../api'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'

const BASE_URL = '/api/risk_map_danger_work_places'
const JOB_TAGS = [StoreTags.DANGER_WORK_PLACES, StoreTags.DANGER_WORK_PLACE]

export const DangerWorkPlacesApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDangerWorkPlaces: builder.query<
      { items: DangerWorkPlaceOption[]; length: number },
      CommonQueryParams
    >({
      query: (params) => ({
        url: BASE_URL,
        params,
      }),
      transformResponse: (response: ItemsFromResponse<DangerWorkPlace>) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          label: item.value,
          value: item['@id'],
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
      providesTags: [StoreTags.DANGER_WORK_PLACES],
    }),
    createDangerWorkPlace: builder.mutation<
      CreateDangerWorkPlaceResponse,
      CreateDangerWorkPlaceBody
    >({
      invalidatesTags: (result) => (result ? JOB_TAGS : []),
      query: (body) => ({
        url: BASE_URL,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useGetDangerWorkPlacesQuery, useCreateDangerWorkPlaceMutation } =
  DangerWorkPlacesApi
