import { FC } from 'react'
import LineFields from '@templates/AddNewItemModal/Content/LineFields'
import { Field } from '@templates/AddNewItemModal/Content'
import { dateField, textField } from '@templates/AddNewItemModal/templates'
import RiskMapFileField from '@templates/RiskMapFileField'
import { ExternalLineWrapper } from '@templates/AddNewItemModal/Content/LineFields/styled'

const FIRST_LINE: Field[] = [
  textField({
    label: 'Номер карты рисков',
    isRequired: true,
    name: 'number',
    placeholder: 'Номер карты рисков',
  }),
  dateField({
    label: 'Пересмотреть до',
    name: 'reviseBeforeAt',
  }),
]

type Props = {
  fileDisabled: boolean
}

const InfoFields: FC<Props> = ({ fileDisabled }) => {
  return (
    <>
      <ExternalLineWrapper id="risk-map-info">
        <LineFields fields={FIRST_LINE} lineTitle="Информация о карте рисков" />
      </ExternalLineWrapper>
      <RiskMapFileField isDisabled={fileDisabled} />
    </>
  )
}

export default InfoFields
