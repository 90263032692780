import { useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { BannerSettings } from '@models/settings'

const useSetDefaultValuesBanners = (
  { setValue }: UseFormReturn,
  data?: BannerSettings
) => {
  useEffect(() => {
    if (data) {
      setValue('text', data.text)
      setValue('title1', data.buttons[0]?.title)
      setValue('text1', data.buttons[0]?.text)
      setValue('link1', data.buttons[0]?.link)
      setValue('title2', data.buttons[1]?.title)
      setValue('text2', data.buttons[1]?.text)
      setValue('link2', data.buttons[1]?.link)
    }
  }, [data])
}

export default useSetDefaultValuesBanners
