import { FC } from 'react'
import { components, ValueContainerProps } from 'react-select'
import { isEmpty } from 'lodash'

const ValueContainer: FC<ValueContainerProps> = (props) => {
  const {
    selectProps: { value, isDisabled },
    innerProps,
  } = props
  const notSelected = isEmpty(value)

  let color = 'white'

  if (isDisabled) {
    color = '#C4C4C4'
  } else if (notSelected) {
    color = 'black'
  }

  return (
    <components.ValueContainer
      {...props}
      innerProps={{
        ...innerProps,
        style: {
          ...innerProps,
          whiteSpace: 'nowrap',
          color,
        },
      }}
    />
  )
}

export default ValueContainer
