import styled from '@emotion/styled'
import { isMobile } from '@const/device'
import Title from '@organisms/Title'

const DesktopWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  gap: 15px;
  margin-right: 5px;
`
const MobileWrapper = styled(DesktopWrapper)``
export const Wrapper = isMobile ? MobileWrapper : DesktopWrapper

export const StyledTitle = styled(Title)`
  margin: 0;
`
