import { PER_PAGE } from '@const/pagination'
import { useGetNotificationsQuery } from '@services/notifications'
import useItemsFilters, { FilterState } from './useItemsFilters'

type Params = {
  initFilters?: FilterState
  pagination?: boolean
  limit?: number
  skip?: boolean
}

const useNotifications = (params?: Params) => {
  const {
    initFilters = {},
    pagination = true,
    limit = PER_PAGE,
    skip,
  } = params || {}
  const { filters, setFilters, setPage, setOrder } = useItemsFilters(
    initFilters,
    pagination,
    limit
  )

  const state = useGetNotificationsQuery(filters, {
    skip,
  })

  return {
    ...state,
    setPage,
    setFilters,
    setOrder,
  }
}

export default useNotifications
