import { FC } from 'react'
import useFormProcessor from '@hooks/useFormProcessor'
import { useApproveMutation } from '@services/riskMaps'
import AddNewItemModal from '@templates/AddNewItemModal'
import { RiskMapDetailed } from '@models/riskMap'
import useApproveModalFields from './useApproveModalFields'

type FormValues = {
  date: string | undefined
}

type Props = {
  data: RiskMapDetailed
}

const ApproveModal: FC<Props> = ({ data }) => {
  const fields = useApproveModalFields()
  const [approve, postData] = useApproveMutation()

  const methods = useFormProcessor({
    apiErrors: postData.error,
  })

  const onSubmitForm = (form: FormValues) => {
    void approve({
      id: data.id,
      date: form.date,
    })
  }

  return (
    <AddNewItemModal
      {...postData}
      fields={fields}
      customFullTitle="Укажите дату утверждения"
      methods={methods}
      onSubmitForm={onSubmitForm}
    />
  )
}

export default ApproveModal
