import { FC, useEffect } from 'react'
import AddNewItemModal from '@templates/AddNewItemModal'
import useFormProcessor from '@hooks/useFormProcessor'
import { useChangePasswordMutation } from '@services/users'
import { getPasswordValidationSchema } from '@const/validation'
import usePasswordFields from './usePasswordFields'

type FormValues = {
  oldPassword: string
  newPassword: string
  newPasswordRepeat: string
}

type Props = {
  customFullTitle?: string
  onSuccess?: () => void
}

const EditPasswordTemplate: FC<Props> = ({ customFullTitle, onSuccess }) => {
  const fields = usePasswordFields()
  const [changePassword, data] = useChangePasswordMutation()
  const { error, isSuccess } = data

  const methods = useFormProcessor({
    apiErrors: error,
    validationSchema: getPasswordValidationSchema('newPassword'),
  })

  const handleSubmitForm = (form: FormValues) => {
    void changePassword(form)
  }

  useEffect(() => {
    if (onSuccess && isSuccess) {
      onSuccess()
    }
  }, [isSuccess, onSuccess])

  return (
    <AddNewItemModal
      {...data}
      onSubmitForm={handleSubmitForm}
      methods={methods}
      fields={fields}
      isEdit
      customFullTitle={customFullTitle}
      titlePostfix="пароль"
      successContentAddTitle=""
      successContentEditTitle=""
      withNotificationBanner
      minHeightVh={15}
    />
  )
}

export default EditPasswordTemplate
