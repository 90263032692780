import { FC, useEffect } from 'react'
import { useMount } from 'react-use'
import useCourseChapters from '@hooks/useCouseChapters'
import useMe from '@hooks/useMe'
import { useGetCourseByIdQuery } from '@services/courses'
import { createInitFilter } from '@utils/filters'
import { CourseDetailed } from '@models/course'
import EditModal from '@templates/EditModal'
import { AccordionBodyProps } from '@organisms/Accordion'
import ModalContent from '@modals/CourseModal'
import AddChapterButton from './AddChapterButton'
import Chapter from './Chapter'
import DescriptionLink from './DescriptionLink'
import PublishButton from './PublishButton'
import { FooterWrapper, StyledAccordionTitle } from './styled'

type Props = {
  id: number
  expandedChapters: Set<number>
  onExpanded: (index: number) => void
} & AccordionBodyProps

const Body: FC<Props> = ({
  onChangeSize,
  toggleLoading,
  id,
  expandedChapters,
  onExpanded,
}) => {
  const { data: courseData } = useGetCourseByIdQuery(id)
  const course = courseData as CourseDetailed
  const { isAdmin } = useMe()
  const { data } = useCourseChapters(createInitFilter('course', id))
  const { items = [] } = data || {}

  useMount(() => {
    toggleLoading(true)
  })

  useEffect(() => {
    if (data) {
      toggleLoading(false)
      onChangeSize()
    }
  }, [data])

  if (!course) return null

  return (
    <>
      {items.length > 0 && (
        <StyledAccordionTitle mySize="h2">Разделы</StyledAccordionTitle>
      )}
      {items.map((item, index) => (
        <Chapter
          {...item}
          initialExpanded={expandedChapters.has(index)}
          index={index}
          onExpanded={onExpanded}
          onChangeSize={onChangeSize}
          courseIri={courseData?.['@id']!}
          key={item.id}
        />
      ))}
      {isAdmin && (
        <FooterWrapper>
          <EditModal
            ModalContent={ModalContent}
            modalProps={{ course }}
            editText="Редактировать курс"
          />
          <AddChapterButton courseIri={course?.['@id']} />
          <DescriptionLink descriptionLink={course?.descriptionLink} />
          <PublishButton course={course} />
        </FooterWrapper>
      )}
    </>
  )
}

export default Body
