import { FC } from 'react'
import useWorkPlaces from '@hooks/useWorkPlaces'
import useFormProcessor from '@hooks/useFormProcessor'
import ListPageLayout from '@templates/ListPageLayout'
import WorkPlacesTable from '@pages/WorkPlaces/WorkPlacesTable'
import useWorkPlacesFilters from './useWorkPlacesFilters'

const WorkPlaces: FC = () => {
  const methods = useFormProcessor()
  const filters = useWorkPlacesFilters(methods)
  const { data, ...rest } = useWorkPlaces()
  const { items = [], length = 0 } = data || {}

  return (
    <ListPageLayout
      {...rest}
      titleTabs={[
        {
          title: 'Рабочие места',
          counter: length,
          index: 0,
        },
      ]}
      methods={methods}
      filters={filters}
      ListComponent={WorkPlacesTable}
      list={items}
      length={length}
      withSearch
    />
  )
}

export default WorkPlaces
