import * as React from 'react'
import { FC } from 'react'
import { Division } from '@models/division'
import Accordion, { AccordionHeaderProps } from '@organisms/Accordion'
import Title from '@organisms/Title'
import { ListItemProps } from '@templates/List'
import CompanyInfoField from '@templates/CompanyInfoField'
import FilialInfoField from '@templates/FilialInfoField'
import EmployeesCountField from '@templates/EmployeesCountField'
import Body from './Body'
import { BottomWrapper, Wrapper } from './styled'

const DivisionItem: FC<ListItemProps<Division>> = ({
  isLoading,
  handleClickOnItem,
  opened,
  item,
  item: { id, title, countEmployees = 0, company, companyBranch },
}) => {
  const header: FC<AccordionHeaderProps> = () => (
    <Wrapper>
      <Title mySize="h4">{title}</Title>
      <BottomWrapper>
        <CompanyInfoField company={company} />
        <FilialInfoField filial={companyBranch} />
        <EmployeesCountField
          countEmployees={countEmployees}
          company={
            company
              ? {
                  label: company.title,
                  value: company['@id'],
                }
              : undefined
          }
          filial={
            companyBranch
              ? {
                  label: companyBranch.title,
                  value: companyBranch['@id'],
                }
              : undefined
          }
          division={
            item
              ? {
                  label: item.title,
                  value: item['@id'],
                }
              : undefined
          }
        />
      </BottomWrapper>
    </Wrapper>
  )

  return (
    <Accordion
      withAutoRefreshSize
      skeletonHeight={145}
      handleClickOnHeader={handleClickOnItem}
      expanded={opened}
      initLoading={isLoading}
      Header={header}
      Body={({ onChangeSize, toggleLoading }) => (
        <Body
          onChangeSize={onChangeSize}
          toggleLoading={toggleLoading}
          id={id}
        />
      )}
    />
  )
}

export default DivisionItem
