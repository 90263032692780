import { UseFormReturn } from 'react-hook-form'
import { selectSearchField } from '@templates/AddNewItemModal/templates'
import { Field } from '@templates/AddNewItemModal/Content'
import { SelectSearchFieldParams } from '@templates/AddNewItemModal/templates/selectSearchField'
import useJobDataForFields from './useJobDataForFields'

type ReturnType = Record<
  'company' | 'filial' | 'division' | 'position',
  ((params?: Partial<SelectSearchFieldParams>) => Field) | null
>

const DROPDOWN_WIDTH = 400

const useJobFields = (methods: UseFormReturn): ReturnType => {
  const { company, filial, division, position } = useJobDataForFields(methods)

  return {
    company: company
      ? (params) =>
          selectSearchField({
            ...company,
            ...params,
            label: 'Компания',
            name: 'company',
            dropdownWidth: DROPDOWN_WIDTH,
          })
      : null,
    filial: filial
      ? (params) =>
          selectSearchField({
            ...filial,
            ...params,
            label: 'Филиал',
            name: 'filial',
            nameForErrors: 'companyBranch',
            dropdownWidth: DROPDOWN_WIDTH,
          })
      : null,
    division: division
      ? (params) =>
          selectSearchField({
            ...division,
            ...params,
            label: 'Подразделение',
            name: 'division',
            nameForErrors: 'subdivision',
            dropdownWidth: DROPDOWN_WIDTH,
          })
      : null,
    position: position
      ? (params) =>
          selectSearchField({
            ...position,
            ...params,
            label: 'Должность',
            name: 'position',
            nameForErrors: 'jobPosition',
            dropdownWidth: DROPDOWN_WIDTH,
          })
      : null,
  }
}

export default useJobFields
