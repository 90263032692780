import { DivisionDetailed } from '@models/division'
import setDefaultCurators from '@templates/CuratorsMultilineFields/setDefaultCurators'
import setDefaultRiskCommissionMembers from '@templates/RiskMemberMultilineFields/setDefaultRiskCommissionMembers'
import { FormValues } from './index'

const getDefaultValues = (
  division?: DivisionDetailed
): Partial<FormValues> | void => {
  if (!division) return

  const result: Partial<FormValues> = {
    title: division.title,
  }

  if (division.company) {
    result.company = {
      ...division.company,
      label: division.company.title,
      value: division.company['@id'],
    }
  }

  if (division.companyBranch) {
    result.filial = {
      ...division.companyBranch,
      value: division.companyBranch['@id'],
      label: division.companyBranch.title,
    }
  }

  if (division.initialBriefing) {
    result.initialBriefing = {
      ...division.initialBriefing,
      value: division.initialBriefing['@id'],
      label: division.initialBriefing.title,
    }
  }

  if (division.initiatoryBriefing) {
    result.initiatoryBriefing = {
      ...division.initiatoryBriefing,
      value: division.initiatoryBriefing['@id'],
      label: division.initiatoryBriefing.title,
    }
  }

  if (division.safeProgram) {
    result.safeProgram = {
      ...division.safeProgram,
      value: division.safeProgram['@id'],
      label: division.safeProgram.title,
    }
  }

  if (division.responsibleOfProbation) {
    result.responsibleOfProbation = {
      ...division.responsibleOfProbation,
      label: division.responsibleOfProbation.fullName,
      value: division.responsibleOfProbation['@id'],
    }
  }

  if (division.responsibleOfSafeProgram) {
    result.responsibleOfSafeProgram = {
      ...division.responsibleOfSafeProgram,
      label: division.responsibleOfSafeProgram.fullName,
      value: division.responsibleOfSafeProgram['@id'],
    }
  }

  return setDefaultRiskCommissionMembers(
    setDefaultCurators(result, division.curators),
    division.riskMapCommissionMembers
  )
}

export default getDefaultValues
