import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: flex;
  overflow-x: auto;
  margin-bottom: 20px;
  gap: 10px;
`

export const PageWrapper = styled.div<{
  selected: boolean
}>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-align: center;
  font-size: ${({ theme }) => theme.text.pMini.fontSize};
  line-height: ${({ theme }) => theme.text.pMini.lineHeight};
  background: ${({ selected, theme }) =>
    selected ? theme.colors.main : 'none'};
  color: ${({
    selected,
    theme: {
      colors: {
        text: { surface },
      },
    },
  }) => (selected ? surface : 'black')};
  border: 1px solid #c4c4c4;

  &:hover {
    background: ${({ theme }) => theme.colors.main};
    color: ${({ theme }) => theme.colors.text.surface};
  }
`
