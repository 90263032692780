import { UseFormReturn } from 'react-hook-form'
import { compact } from 'lodash'
import useJobFields from '@hooks/useJobFields'
import { Fields } from '@templates/AddNewItemModal/Content'
import { textField } from '@templates/AddNewItemModal/templates'

const useDivisionFields = (methods: UseFormReturn): Fields => {
  const { company, filial } = useJobFields(methods)

  return [
    {
      lineId: 'firstLine',
      fields: compact([
        company?.({
          isRequired: true,
        }),
        filial?.({
          isRequired: true,
        }),
        textField({
          name: 'title',
          label: 'Наименование',
          placeholder: 'Наименование подразделения',
          isRequired: true,
        }),
      ]),
    },
  ]
}

export default useDivisionFields
