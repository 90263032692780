import styled from '@emotion/styled'
import { isMobile } from '@const/device'
import Title from '@organisms/Title'

export const StyledAccordionTitle = styled(Title)`
  margin: 10px 10px;
`

const DesktopFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`
const MobileFooterWrapper = styled(DesktopFooterWrapper)`
  flex-direction: column;
`
export const FooterWrapper = isMobile
  ? MobileFooterWrapper
  : DesktopFooterWrapper
