import * as React from 'react'
import { FC } from 'react'
import useFormProcessor from '@hooks/useFormProcessor'
import { useCreateDocumentTypeMutation } from '@services/documentTypes'
import Title from '@organisms/Title'
import AddNewItemModal from '@templates/AddNewItemModal'
import { textField } from '@templates/AddNewItemModal/templates'
import { Fields } from '@templates/AddNewItemModal/Content'
import { StyledContentBlock } from '../styled'
import TypesElements from './TypesElements'

const FIELDS: Fields = [
  {
    fields: [
      textField({
        name: 'title',
        placeholder: 'Название',
        label: 'Название',
        isRequired: true,
        widthMultiplier: 2,
      }),
    ],
  },
]

type FormValues = {
  title: string
}

const Library: FC = () => {
  const [createDocumentType, createData] = useCreateDocumentTypeMutation()
  const methods = useFormProcessor({ apiErrors: createData.error })

  const handleSubmitForm = (form: FormValues) => {
    createDocumentType(form)
    methods.reset()
  }

  return (
    <StyledContentBlock>
      <Title>Типы документов в библиотеке</Title>
      <TypesElements />
      <AddNewItemModal
        {...createData}
        onSubmitForm={handleSubmitForm}
        methods={methods}
        fields={FIELDS}
        successContentAddTitle="Тип документа успешно добавлен"
        withNotificationBanner
      />
    </StyledContentBlock>
  )
}

export default Library
