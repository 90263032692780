import useMe from './useMe'

const useHasJobs = () => {
  const {
    isAdmin,
    isCompanyGroupCurator,
    isCompanyCurator,
    isFilialCurator,
    curatorCompanyBranches = [],
    curatorCompanyBranchSubdivisions = [],
  } = useMe()

  const hasCompany = isAdmin || isCompanyGroupCurator
  const hasFilial =
    hasCompany || isCompanyCurator || curatorCompanyBranches.length > 1
  const hasDivision =
    hasFilial || isFilialCurator || curatorCompanyBranchSubdivisions?.length > 1

  return {
    hasCompany,
    hasFilial,
    hasDivision,
  }
}

export default useHasJobs
