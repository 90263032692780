import { UseFormReturn } from 'react-hook-form'
import { useEffect } from 'react'
import { SelectProps } from '@organisms/Select'
import {
  FilterProps,
  FilterTypes,
} from '@templates/ListPageLayout/Filters/Filter'
import useDangerKinds from '@hooks/useDangerKinds'

const useDangerKindsFilter = (
  { watch, setValue }: UseFormReturn,
  fieldNameForSubmit?: string
): FilterProps<SelectProps> => {
  const workPlace: string | undefined = watch('workPlace')?.value

  const { data, isLoading, setFilters, setPage } = useDangerKinds({
    skip: !workPlace,
  })
  const { items = [], length = 0 } = data || {}

  useEffect(() => {
    if (workPlace) {
      setFilters({
        relations: {
          workPlace,
        },
      })
    }

    setValue('kind', null)
  }, [workPlace])

  return {
    isDisabled: !workPlace,
    options: items,
    length,
    isLoading,
    placeholder: 'Вид опасности',
    fieldName: 'kind',
    fieldNameForSubmit,
    filterType: FilterTypes.SELECT_SEARCH,
    setFilters,
    setPage,
    dropdownWidth: 500,
  }
}

export default useDangerKindsFilter
