import { FC } from 'react'
import AddButton from '@organisms/AddButton'
import ButtonStyle from '@enums/ButtonStyle'
import {
  useLazyGetEducationProtocolReportQuery,
  useLazyGetMinistryLaborReportXlsxQuery,
  useLazyGetMinistryLaborReportXmlQuery,
} from '@services/employeeEducation'
import Prompt from '@organisms/Prompt'
import Select, { Option } from '@organisms/Select'
import { Wrapper } from './styled'

type Props = {
  educationId: number
}

const ReportButtons: FC<Props> = ({ educationId }) => {
  const [
    getEducationProtocolReport,
    { isLoading: getEducationProtocolReportIsLoading },
  ] = useLazyGetEducationProtocolReportQuery()

  const [
    getMinistryLaborReportXml,
    { isLoading: getMinistryLaborReportXmlIsLoading },
  ] = useLazyGetMinistryLaborReportXmlQuery()

  const [
    getMinistryLaborReportXlsx,
    { isLoading: getMinistryLaborReportXlsxIsLoading },
  ] = useLazyGetMinistryLaborReportXlsxQuery()

  const handleEducationProtocolReportClick = () => {
    void getEducationProtocolReport(educationId)
  }

  const handleChangeSelect = ({ value }: Option) => {
    if (value === 1) {
      void getMinistryLaborReportXlsx(educationId)
    } else {
      void getMinistryLaborReportXml(educationId)
    }
  }

  return (
    <Wrapper
      onClick={(e) => e.stopPropagation()}
      id={`educationReportButtons${educationId}`}
    >
      <AddButton
        buttonStyle={ButtonStyle.SELECT}
        onClick={handleEducationProtocolReportClick}
        isLoading={getEducationProtocolReportIsLoading}
      >
        Создать протокол
      </AddButton>
      <Select
        menuPosition="fixed"
        placeholder="Создать реестр минтруда"
        options={[
          {
            label: 'В формате XLSX',
            value: 1,
          },
          {
            label: 'В формате XML',
            value: 2,
          },
        ]}
        onChange={handleChangeSelect}
        withoutFillSelected
        withoutFetchOptions
        isSearchable={false}
        isClearable={false}
        isLoading={
          getMinistryLaborReportXmlIsLoading ||
          getMinistryLaborReportXlsxIsLoading
        }
      />
      <Prompt promptText="Вы можете изменить содержимое файла, открыв его в текстовом редакторе" />
    </Wrapper>
  )
}

export default ReportButtons
