import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`

export const StyledSaveButtonWrapper = styled.div`
  margin-top: 28px;
`

export const StyledExampleButtonWrapper = styled.div`
  margin-top: 42px;
`
