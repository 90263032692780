import { UseFormReturn } from 'react-hook-form'
import { compact } from 'lodash'
import useJobFields from '@hooks/useJobFields'
import useMe from '@hooks/useMe'
import { dateField, textField } from '@templates/AddNewItemModal/templates'
import { Fields } from '@templates/AddNewItemModal/Content'

const useFilialFields = (methods: UseFormReturn): Fields => {
  const { isAdmin, isCompanyCurator, isCompanyGroupCurator } = useMe()
  const { company } = useJobFields(methods)

  return compact([
    {
      fields: compact([
        company?.({ isRequired: true }),
        textField({
          name: 'title',
          label: 'Наименование',
          placeholder: 'Наименование филиала',
          isRequired: true,
        }),
        textField({
          name: 'fullTitle',
          label: 'Полное наименование',
          placeholder: 'Полное наименование филиала',
          widthMultiplier: 2,
          isRequired: true,
          promptText:
            'Введите полное наименование орагнизации, включая развернутое описание формы собственности, например: Общество с ограниченной ответственностью «Школа экспертов»',
        }),
      ]),
    },
    (isAdmin || isCompanyCurator || isCompanyGroupCurator) && {
      fields: compact([
        isAdmin &&
          dateField({
            name: 'subscriptionStartAt',
            label: 'Дата начала подписки',
            isRequired: true,
          }),
        isAdmin &&
          dateField({
            name: 'subscriptionEndAt',
            label: 'Дата окончания подписки',
            isRequired: true,
          }),
        (isAdmin || isCompanyCurator || isCompanyGroupCurator) &&
          textField({
            name: 'subscriptionsCount',
            label: 'Количество лицензий',
            placeholder: 'Количество лицензий',
            isRequired: true,
          }),
        isAdmin &&
          textField({
            label: 'ИНН',
            placeholder: 'ИНН',
            name: 'inn',
          }),
      ]),
    },
    {
      fields: [
        dateField({
          name: 'protocolOrderDate',
          label: 'Дата приказа для протокола',
        }),
        textField({
          label: 'Номер приказа для протокола',
          placeholder: 'Номер приказа',
          name: 'protocolOrderNumber',
        }),
      ],
    },
  ])
}

export default useFilialFields
