import { JoyStep } from '@templates/Onboarding'

export const ONBOARDING_TITLE = 'Расскажем про разделы в обучении'

const TITLE = 'Прохождение курса'

export const ONBOARDING_STEPS: JoyStep[] = [
  {
    target: '#chapter0',
    title: TITLE,
    content: 'Чтобы посмотреть список тем в разделе, нажмите на него',
  },
]
