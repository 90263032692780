import styled from '@emotion/styled'
import { isMobile } from '@const/device'

export const Wrapper = styled.div<{
  widthMultiplier: number
  withoutFixedHeight: boolean
}>`
  margin-right: 12px;
  margin-bottom: 18px;
  width: ${({
    theme: {
      sizes: { inputWidth },
    },
    widthMultiplier,
  }) =>
    isMobile
      ? '85vw'
      : `${
          widthMultiplier > 1
            ? widthMultiplier * 8 + widthMultiplier * inputWidth
            : widthMultiplier * inputWidth
        }px`};

  ${({ withoutFixedHeight }) => (withoutFixedHeight ? '' : 'height: 80px;')}
`

export const Label = styled.div`
  font-size: ${({ theme }) => theme.text.h6.fontSize};
  line-height: ${({ theme }) => theme.text.h6.lineHeight};
  font-weight: 400;
  letter-spacing: 0.1em;
  text-align: left;
  text-transform: uppercase;
  margin-left: 5px;
  margin-bottom: 12px;
`

export const FieldWrapper = styled.div`
  display: flex;
  align-content: center;
  align-items: flex-start;
`

export const DesktopLabelWrapper = styled.div<{ fixedLabelHeight: boolean }>`
  display: flex;
  align-items: flex-end;
  ${({ fixedLabelHeight }) => (fixedLabelHeight ? 'height: 50px;' : '')}
`

export const MobileLabelWrapper = styled.div<{ fixedLabelHeight: boolean }>`
  display: flex;
`

export const LabelWrapper = isMobile ? MobileLabelWrapper : DesktopLabelWrapper

export const PromptWrapper = styled.div`
  position: relative;
  top: -15px;
`

export const InlineLabelWrapper = styled.div`
  display: flex;
  gap: 15px;
`

export const RightLabel = styled(Label)`
  height: 40px;
  display: flex;
  align-items: center;
`
