import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  flex-wrap: wrap;
`

export const NextButtonWrapper = styled.div`
  display: flex;
`
