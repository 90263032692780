import { FC } from 'react'
import { Skeleton } from 'antd'
import useDocumentTypes from '@hooks/useDocumentTypes'
import DocumentTypeItem from './DocumentTypeItem'
import { Wrapper } from './styled'

const TypesElements: FC = () => {
  const { data: documentTypes, isLoading: documentTypesIsLoading } =
    useDocumentTypes()
  const { items = [] } = documentTypes || {}

  return (
    <Wrapper>
      {documentTypesIsLoading
        ? [1, 2, 3, 4].map((item) => (
            <Skeleton.Button
              key={item}
              active
              style={{
                height: 35,
                width: 100,
                marginRight: 10,
                borderRadius: 25,
              }}
            />
          ))
        : items.map((item) => <DocumentTypeItem item={item} key={item.id} />)}
    </Wrapper>
  )
}

export default TypesElements
