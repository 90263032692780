import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as EqualInactive } from '@interface-images/equal-gray.svg'
import useMe from '@hooks/useMe'
import getThemeRoute from '@utils/getThemeRoute'
import { CourseThemeListItemServerResponse } from '@models/courseTheme'
import { EditButtonsWrapper } from '../styled'
import DeleteThemeLink from './DeleteThemeLink'
import { IconWrapper, Text, Wrapper } from './styled'
import UpdateThemeLink from './UpdateThemeLink'

type Props = {
  courseIri: string
  chapterIri: string
} & CourseThemeListItemServerResponse

const Theme: FC<Props> = ({ courseIri, chapterIri, ...theme }) => {
  const { title, id } = theme
  const { isAdmin } = useMe()

  const navigate = useNavigate()

  const handleOpenTheme = () => {
    navigate(getThemeRoute(id, courseIri, 0))
  }

  return (
    <Wrapper key={id}>
      <IconWrapper>
        <EqualInactive />
      </IconWrapper>
      <Text onClick={handleOpenTheme}>{title}</Text>
      {isAdmin && (
        <EditButtonsWrapper>
          <UpdateThemeLink theme={theme} chapterIri={chapterIri} />
          <DeleteThemeLink themeId={id} />
        </EditButtonsWrapper>
      )}
    </Wrapper>
  )
}

export default Theme
