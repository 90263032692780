import * as React from 'react'
import { FC } from 'react'
import AccordionBodyFieldWithTabs from '@templates/AccordionBodyFieldWithTabs'
import { EmployeeProbationSheetResult } from '@models/employee'
import { useLazyGetProbationSheetEmployeeReportQuery } from '@services/probationSheets'

type Props = {
  employeeProbationSheets: EmployeeProbationSheetResult[] | undefined
}

const ProbationSheets: FC<Props> = ({ employeeProbationSheets }) => {
  const [downloadReport, { isLoading }] =
    useLazyGetProbationSheetEmployeeReportQuery()

  if (!employeeProbationSheets || employeeProbationSheets?.length === 0) {
    return null
  }

  return (
    <AccordionBodyFieldWithTabs
      label="Стажировочные листы"
      items={employeeProbationSheets.map((sheet, index) => ({
        id: String(sheet.id),
        title: sheet.title || 'Лист №' + (index + 1),
        onClick: () => downloadReport(sheet.id),
        isLoading,
      }))}
    />
  )
}

export default ProbationSheets
