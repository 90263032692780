import { FC } from 'react'
import { BaseButtonProps } from '@molecules/BaseButton'
import ButtonStyle from '@enums/ButtonStyle'
import {
  StyledGrayButton,
  StyledPrimaryButton,
  StyledSecondaryButton,
  StyledSecondaryWithWhiteBgButton,
  StyledSelectButton,
  StyledSuccessButton,
  StyledWarningButton,
  StyledWhiteButton,
  StyledWhiteTransparentButton,
} from './styled'

type Props = {
  buttonStyle?: ButtonStyle
  isActive?: boolean
} & BaseButtonProps

const StyledButton: FC<Props> = ({
  buttonStyle = ButtonStyle.PRIMARY,
  ...restProps
}) => {
  let Component = StyledPrimaryButton

  switch (buttonStyle) {
    case ButtonStyle.PRIMARY:
      Component = StyledPrimaryButton
      break
    case ButtonStyle.SECONDARY:
      Component = StyledSecondaryButton
      break
    case ButtonStyle.WARNING:
      Component = StyledWarningButton
      break
    case ButtonStyle.SUCCESS:
      Component = StyledSuccessButton
      break
    case ButtonStyle.GRAY:
      Component = StyledGrayButton
      break
    case ButtonStyle.SECONDARY_WITH_WHITE_BACKGROUND:
      Component = StyledSecondaryWithWhiteBgButton
      break
    case ButtonStyle.SELECT:
      Component = StyledSelectButton
      break
    case ButtonStyle.WHITE:
      Component = StyledWhiteButton
      break
    case ButtonStyle.WHITE_TRANSPARENT:
      Component = StyledWhiteTransparentButton
      break
  }

  return <Component {...restProps} />
}

export type { Props as StyledButtonProps }
export default StyledButton
