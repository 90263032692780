import { TestResult } from '@models/statistic'
import { formatServerDate } from '@utils/date'
import { RowItem } from '.'

const getDuration = (minutes: number, seconds: number, hours: number) => {
  if (minutes < 1) {
    return `${seconds} сек.`
  }

  if (hours) {
    return `${hours} ч. ${minutes - hours * 60} мин.`
  }

  if (seconds) {
    return `${minutes} мин. ${seconds} сек.`
  }

  return `${minutes} мин.`
}

export const getTableData = (testResults: TestResult[]) => {
  const tableData: RowItem[] = []
  for (const {
    date,
    secondsDuration,
    questionsCount,
    questionsPassed,
  } of testResults) {
    const minutes = Math.floor(secondsDuration / 60)
    const hours = Math.floor(minutes / 60)
    const seconds = secondsDuration % 60

    const data: Omit<RowItem, 'key'> = {
      date: formatServerDate(date),
      duration: getDuration(minutes, seconds, hours),
      result: `${Number.parseInt(
        String(questionsPassed ? (questionsPassed / questionsCount) * 100 : 0),
        10
      )}% (${questionsPassed} из ${questionsCount})`,
    }

    tableData.push({
      ...data,
      key: JSON.stringify(data),
    })
  }

  return tableData
}
