import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { notification } from 'antd'
import { useMount } from 'react-use'
import { ObjectSchema } from 'yup'
import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { yupResolver } from '@hookform/resolvers/yup'
import { log } from '@utils/console'

export type ApiErrors = {
  data?: {
    violations: {
      propertyPath: string
      message: string
    }[]
  }
}

type Arguments = {
  apiErrors?: FetchBaseQueryError | SerializedError
  fieldsForToastNotification?: string[]
  defaultValues?: any // correct
  validationSchema?: ObjectSchema<any>
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const useFormProcessor = (params?: Arguments) => {
  const {
    apiErrors,
    defaultValues,
    fieldsForToastNotification = [],
    validationSchema,
  } = params || {}
  const methods = useForm({
    reValidateMode: 'onSubmit',
    ...(validationSchema ? { resolver: yupResolver(validationSchema) } : {}),
  })
  const { setError } = methods

  useMount(() => {
    log('defaultValues:', defaultValues)
    if (defaultValues) {
      for (const key in defaultValues) {
        methods.setValue(key, defaultValues[key])
      }
    }
  })

  useEffect(() => {
    if (apiErrors) {
      const errors = apiErrors as unknown as ApiErrors
      if (errors?.data?.violations) {
        for (const { propertyPath, message } of errors.data.violations) {
          if (fieldsForToastNotification.includes(propertyPath)) {
            notification.error({ message })
          } else {
            setError(propertyPath, { message })
          }
        }
      }
    }
  }, [apiErrors])

  return methods
}

export default useFormProcessor
