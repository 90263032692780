import { FC } from 'react'
import Select, { SelectProps } from '@organisms/Select'
import { FilterComponentProps } from '..'
import useSelectFilterMethods from '../useSelectFilterMethods'

const SearchSelectFilter: FC<FilterComponentProps<SelectProps>> = (props) => {
  const { field, filter } = props
  const { handleSelect } = useSelectFilterMethods(props)

  return (
    <Select
      {...filter}
      {...field}
      onChange={handleSelect}
      menuPosition="fixed"
    />
  )
}

export default SearchSelectFilter
