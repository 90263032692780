import { Option } from '@organisms/Select'
import SeverityDegrees from '@enums/SeverityDegrees'

const SEVERITY_DEGREES: Option[] = [
  {
    label: 'Незначительная',
    value: SeverityDegrees.ONE,
  },
  {
    label: 'Низкая',
    value: SeverityDegrees.TWO,
  },
  {
    label: 'Средняя',
    value: SeverityDegrees.THREE,
  },
  {
    label: 'Значительная',
    value: SeverityDegrees.FOUR,
  },
  {
    label: 'Катастрофическая',
    value: SeverityDegrees.FIVE,
  },
]

export default SEVERITY_DEGREES
