import { JoyStep } from '@templates/Onboarding'

export const ONBOARDING_TITLE = 'Расскажем, как добавить или найти должность'

export const ONBOARDING_STEPS: JoyStep[] = [
  {
    target: '#add-button',
    title: 'Как добавить должность',
    content: 'Чтобы добавить должность, нажмите кнопку “Добавить”',
  },
  {
    target: '#search-block',
    title: 'Как найти должность',
    content: 'Вы можете найти нужную должность по наименованию',
  },
  {
    target: '#filter-panel',
    title: 'Как найти должность',
    content: 'Также можно найти должность с помощью фильтров',
  },
]
