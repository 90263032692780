import { UseFormReturn } from 'react-hook-form'
import { useEffect } from 'react'
import { useMount, useToggle } from 'react-use'
import { FilialOption } from '@models/filial'
import { useGetFilialQuery } from '@services/filials'
import { CompanyOption } from '@models/company'
import { DivisionOption } from '@models/division'
import { useGetDivisionQuery } from '@services/divisions'
import useMe from '@hooks/useMe'

const useSetDefaultComissionMembers = (
  { watch, setValue }: UseFormReturn,
  isEdit: boolean
) => {
  const { divisionCuratorWithOneDivision, filialCuratorWithOneDivision } =
    useMe()
  const [cleanerStarted, toggleCleanerStarted] = useToggle(false)

  useMount(() => {
    setTimeout(() => {
      toggleCleanerStarted(true)
    }, 1000)
  })

  const company: CompanyOption | undefined = watch('company')
  const filial: FilialOption | undefined =
    filialCuratorWithOneDivision || watch('filial')
  const division: DivisionOption | undefined =
    divisionCuratorWithOneDivision || watch('division')

  const { data: filialInfo } = useGetFilialQuery(filial?.id!, {
    skip: !filial,
  })

  const { data: divisionInfo } = useGetDivisionQuery(division?.id!, {
    skip: !division,
  })

  useEffect(() => {
    if (!cleanerStarted || isEdit) return

    setValue('riskMapCommissionMembers', [])
  }, [company])

  useEffect(() => {
    if (
      filialInfo &&
      filialInfo.riskMapCommissionMembers.length > 0 &&
      filial &&
      !isEdit
    ) {
      setValue(
        'riskMapCommissionMembers',
        filialInfo.riskMapCommissionMembers?.map((item) => ({
          ...item,
          label: item.fullName,
          value: item['@id'],
        }))
      )
    }
  }, [filialInfo])

  useEffect(() => {
    if (
      divisionInfo &&
      divisionInfo.riskMapCommissionMembers.length > 0 &&
      division &&
      !isEdit
    ) {
      setValue(
        'riskMapCommissionMembers',
        divisionInfo.riskMapCommissionMembers?.map((item) => ({
          ...item,
          label: item.fullName,
          value: item['@id'],
        }))
      )
    }
  }, [divisionInfo])
}

export default useSetDefaultComissionMembers
