import * as React from 'react'
import { FC } from 'react'
import { User } from '@models/user'
import { formatServerDate } from '@utils/date'
import AccordionBodyField from '@organisms/AccordionBodyField'
import DisplayPhone from '@organisms/DisplayPhone'
import DisplayEmail from '@organisms/DisplayEmail'
import InfoCounter from '@organisms/InfoCounter'
import ProbationSheets from './ProbationSheets'
import ActiveBlock from './ActiveBlock'

type Props = {
  user: User
}

const PersonalInformation: FC<Props> = ({
  user: {
    id,
    active,
    phone,
    email,
    insuranceNumber,
    education,
    nextBodyCheckDate,
    comment,
    employeeProbationSheets,
    inn,
    login,
  },
}) => {
  return (
    <>
      <AccordionBodyField
        label="Статус"
        value={<ActiveBlock id={id} active={active} />}
      />
      <AccordionBodyField label="Логин" value={login} />
      <AccordionBodyField
        label="Телефон"
        value={phone ? <DisplayPhone phone={phone} /> : undefined}
      />
      <AccordionBodyField
        label="EMAIL"
        value={email ? <DisplayEmail email={email} /> : undefined}
      />
      <AccordionBodyField label="СНИЛС" value={insuranceNumber} />
      <AccordionBodyField label="ИНН" value={inn} />
      <AccordionBodyField label="Образование" value={education} />
      <AccordionBodyField
        label="Дата следующего медосмотра"
        value={
          nextBodyCheckDate ? (
            <InfoCounter>{formatServerDate(nextBodyCheckDate)}</InfoCounter>
          ) : undefined
        }
      />
      <AccordionBodyField label="Комментарий" value={comment} />
      <ProbationSheets employeeProbationSheets={employeeProbationSheets} />
    </>
  )
}

export default PersonalInformation
