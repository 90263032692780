import styled from '@emotion/styled'
import { isMobile } from '@const/device'

const DesktopFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-top: 30px;
  gap: 10px;
`
const MobileFooter = styled(DesktopFooter)`
  flex-direction: column;
`
export const Footer = isMobile ? MobileFooter : DesktopFooter
