import { useState } from 'react'
import dataSelectField from '@templates/AddNewItemModal/templates/dataSelectField'
import { FilterState } from '@hooks/useItemsFilters'
import { useGetRiskMapJobPositionsQuery } from '@services/riskMapJobPositions'
import { ReturnType } from '@app-types/riskMapFields'

type Params = {
  name?: string
  label?: string
  isMulti?: boolean
  isDisabled?: boolean
  isRequired?: boolean
  prepareSearch?: (value: string) => string
  withManualEntryText?: boolean
}

const useRiskMapPositionsField = (params?: Params): ReturnType => {
  const {
    name = 'position',
    label = 'Должность',
    isMulti = false,
    isDisabled = false,
    isRequired = false,
    prepareSearch,
    withManualEntryText = true,
  } = params || {}
  const [filters, setFilters] = useState<FilterState>({})

  return {
    setFilters,
    field: () =>
      dataSelectField({
        prepareSearch,
        isDisabled,
        isRequired,
        isMulti,
        query: useGetRiskMapJobPositionsQuery,
        filters,
        setFilters,
        dropdownWidth: 500,
        label,
        name,
        withManualEntryText,
        withShowLabelInDescription: true,
        promptText:
          'Вы можете выбрать должности похожие со своей. Тогда будут добавлены рекомендуемые для них опасности.',
      }),
  }
}

export default useRiskMapPositionsField
