import { FC } from 'react'
import Prompt from '@organisms/Prompt'
import Badge from '@organisms/Badge'
import { Wrapper } from './styled'

const Archive: FC = () => {
  return (
    <Wrapper>
      <Badge badgeColor="#7B7B7B" />
      <span>Карта в архиве</span>
      <Prompt
        promptText="Карта рисков в архиве, с ней нельзя ознакомиться"
        width={300}
      />
    </Wrapper>
  )
}

export default Archive
