import StoreTags from '@enums/StoreTags'
import { CommonQueryParams, ItemsFromResponse } from '@models/common'
import {
  AddExternalPassesBody,
  EmployeeEducationDestination,
  EmployeeEducationDestinationDetailed,
} from '@models/employeeEducationDestination'
import { createDownloadExcelReportQuery } from '@utils/files'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'
import emptySplitApi from '../api'

const BASE_URL = '/api/employee_education_destinations'

export const employeeEducationDestinationApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getEmployeeEducationDestinations: builder.query<
      { items: EmployeeEducationDestination[]; length: number },
      CommonQueryParams
    >({
      query: (params) => ({
        url: BASE_URL,
        params,
      }),
      transformResponse: (
        response: ItemsFromResponse<EmployeeEducationDestination>
      ) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          code: item.id,
          title: item.educationDestination.title,
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
      providesTags: [StoreTags.EMPLOYEE_EDUCATION_DESTINATIONS],
    }),
    getEmployeeEducationDestination: builder.query<
      EmployeeEducationDestinationDetailed,
      number
    >({
      providesTags: [StoreTags.EMPLOYEE_EDUCATION_DESTINATION],
      query: (id) => `${BASE_URL}/${id}`,
    }),
    markEducationAsDone: builder.mutation<void, number>({
      invalidatesTags: [
        StoreTags.EMPLOYEE_EDUCATION_DESTINATIONS,
        StoreTags.EMPLOYEE_EDUCATION_DESTINATION,
        StoreTags.EMPLOYEE_EDUCATION_STATS,
      ],
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
        method: 'PUT',
      }),
    }),
    getDestinationReport: builder.query<void, number>({
      query: (id) =>
        createDownloadExcelReportQuery(
          `${BASE_URL}/${id}/stats_report`,
          'Отчёт.xls'
        )({}),
    }),
    addExternalPasses: builder.mutation<void, AddExternalPassesBody>({
      invalidatesTags: [
        StoreTags.EMPLOYEE_EDUCATION_DESTINATIONS,
        StoreTags.EMPLOYEE_EDUCATION_DESTINATION,
        StoreTags.EMPLOYEE_EDUCATION_STATS,
      ],
      query: (body) => ({
        url: `${BASE_URL}/uploadPassed`,
        method: 'POST',
        body,
      }),
    }),
    deleteEmployeeEducationDestination: builder.mutation<void, number>({
      invalidatesTags: [
        StoreTags.EMPLOYEE_EDUCATION_DESTINATIONS,
        StoreTags.EMPLOYEE_EDUCATION_STATS,
      ],
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetEmployeeEducationDestinationsQuery,
  useGetEmployeeEducationDestinationQuery,
  useMarkEducationAsDoneMutation,
  useLazyGetDestinationReportQuery,
  useAddExternalPassesMutation,
  useDeleteEmployeeEducationDestinationMutation,
} = employeeEducationDestinationApi
