import styled from '@emotion/styled'
import { isMobile } from '@const/device'
import ContentBlock from '@organisms/ContentBlock'

const DesktopStyledContentBlock = styled(ContentBlock)`
  width: 70%;
`
const MobileStyledContentBlock = styled(DesktopStyledContentBlock)`
  width: 100%;
`
export const StyledContentBlock = isMobile
  ? MobileStyledContentBlock
  : DesktopStyledContentBlock

export const CuratorNameWrapper = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`

export const CuratorStatusWrapper = styled.div`
  display: flex;
`
