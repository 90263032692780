import { useState } from 'react'
import { SelectSearchFieldParams } from '@templates/AddNewItemModal/templates/selectSearchField'
import dataSelectField from '@templates/AddNewItemModal/templates/dataSelectField'
import { useGetDangerWorkPlacesQuery } from '@services/dangerWorkPlaces'
import { ReturnType, Setter } from '@app-types/riskMapFields'
import { StateType } from '@modals/RiskMapModal/Dangers/useCommonRiskMapFields'

const useDangerWorkPlacesField = (
  params: Partial<SelectSearchFieldParams>,
  setter: Setter,
  skip?: boolean
): ReturnType<StateType | undefined> => {
  const [filters, setFilters] = useState<StateType | undefined>()

  return {
    setFilters,
    field: () =>
      dataSelectField({
        query: useGetDangerWorkPlacesQuery,
        filters,
        setFilters,
        dropdownWidth: 500,
        label: 'Работы / Место / Авария',
        name: 'work',
        withManualEntryText: true,
        withShowLabelInDescription: true,
        onOneItem: setter,
        skip,
        ...params,
      }),
  }
}

export default useDangerWorkPlacesField
