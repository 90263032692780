import * as React from 'react'
import { FC, useState } from 'react'
import { compact } from 'lodash'
import useFormProcessor from '@hooks/useFormProcessor'
import {
  useGetBannerSettingsByCodeQuery,
  usePutBannerSettingsMutation,
} from '@services/settings'
import { BannerSettingsCodes, UpdateBannerSettingsBody } from '@models/settings'
import Title from '@organisms/Title'
import Tabs from '@organisms/Tabs'
import AddNewItemModal from '@templates/AddNewItemModal'
import { StyledContentBlock } from '../styled'
import useSetDefaultValuesBanners from './useSetDefaultValuesBanners'
import useWelcomeBannersFields from './useWelcomeBannersFields'

const TOP_TABS = [
  {
    title: 'Куратор компании',
    code: 'companyCurator',
  },
  {
    title: 'Куратор филиала',
    code: 'filialCurator',
  },
  {
    title: 'Сотрудник',
    code: 'employee',
  },
]

type FormValues = Record<
  'link1' | 'link2' | 'text' | 'text1' | 'text2' | 'title1' | 'title2',
  string
>

const WelcomeBanners: FC = () => {
  const [activeTop, setActiveTop] = useState(0)
  const [activeBottom, setActiveBottom] = useState(0)

  const bottomTabs = compact([
    {
      title: 'Приветствие',
      code: 'greetings',
    },
    activeTop === 1 && {
      title: 'Подписка заканчивается',
      code: 'subscribeEnd',
    },
    activeTop === 1 && {
      title: 'Количество лицензий заканчивается',
      code: 'licencesCountEnd',
    },
    activeTop === 2 && {
      title: 'Медосмотр',
      code: 'body_check',
    },
    activeTop === 2 && {
      title: 'Повторный инструктаж',
      code: 're_briefing',
    },
    activeTop === 2 && {
      title: 'Опрос',
      code: 'interview',
    },
  ])

  const fields = useWelcomeBannersFields()
  const code =
    `${TOP_TABS[activeTop].code}_${bottomTabs[activeBottom].code}` as BannerSettingsCodes
  const { data } = useGetBannerSettingsByCodeQuery(code)

  const [updateBanners, { isLoading, error, isSuccess, isError, reset }] =
    usePutBannerSettingsMutation()

  const methods = useFormProcessor({
    apiErrors: error,
  })

  useSetDefaultValuesBanners(methods, data)

  const handleSubmitForm = (form: FormValues) => {
    const resultForm: UpdateBannerSettingsBody = {
      code,
      text: form.text || '',
      buttons: [
        {
          text: form.text1,
          link: form.link1,
          title: form.title1,
        },
        {
          text: form.text2,
          link: form.link2,
          title: form.title2,
        },
      ],
    }

    updateBanners(resultForm)
  }

  return (
    <StyledContentBlock>
      <Title>Приветственные баннеры</Title>
      <Tabs
        active={activeTop}
        setActive={setActiveTop}
        tabSize="small"
        items={TOP_TABS.map(({ title }) => ({
          id: title,
          title,
          children: (
            <Tabs
              active={activeBottom}
              setActive={setActiveBottom}
              tabSize="small"
              items={bottomTabs.map(({ title: smallTitle }) => ({
                id: smallTitle,
                title: smallTitle,
                children: (
                  <AddNewItemModal
                    onSubmitForm={handleSubmitForm}
                    methods={methods}
                    isLoading={isLoading}
                    fields={fields}
                    isError={isError}
                    isSuccess={isSuccess}
                    reset={reset}
                    successContentAddTitle="Настройки баннеров успешно обновлены"
                    withNotificationBanner
                  />
                ),
              }))}
            />
          ),
        }))}
      />
    </StyledContentBlock>
  )
}

export default WelcomeBanners
