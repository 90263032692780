import { FC, useEffect } from 'react'
import useCourseThemes from '@hooks/useCourseThemes'
import useMe from '@hooks/useMe'
import { createInitFilter } from '@utils/filters'
import { CourseChapter } from '@models/courseChapter'
import AddThemeButton from './AddThemeButton'
import DeleteChapterLink from './DeleteChapterLink'
import EditChapterLink from './EditChapterLink'
import { EditButtonsWrapper, StyledThemeTitle, ThemeWrapper } from './styled'
import SubAccordion from './SubAccrodion'
import Theme from './Theme'

type Props = {
  onExpanded: (index: number) => void
  onChangeSize: () => void
  courseIri: string
  index: number
  initialExpanded: boolean
} & CourseChapter

const Chapter: FC<Props> = ({
  onChangeSize,
  courseIri,
  onExpanded,
  index,
  initialExpanded,
  ...chapter
}) => {
  const { id, title } = chapter
  const { isAdmin } = useMe()

  const { data } = useCourseThemes(createInitFilter('courseChapter', id))
  const { items = [] } = data || {}

  const chapterIri = chapter['@id']

  useEffect(() => {
    if (items) {
      onChangeSize()
    }
  }, [items.length])

  return (
    <SubAccordion
      initialExpanded={initialExpanded}
      index={index}
      onExpanded={onExpanded}
      onChangeSize={onChangeSize}
      title={title}
      Body={
        <ThemeWrapper>
          {items.length > 0 && (
            <StyledThemeTitle mySize="h2">Темы</StyledThemeTitle>
          )}
          {items.map((theme) => (
            <Theme
              {...theme}
              key={theme.id}
              chapterIri={chapterIri}
              courseIri={courseIri}
            />
          ))}
          {isAdmin && <AddThemeButton chapterIri={chapterIri} />}
        </ThemeWrapper>
      }
      height={150 + items.length * 40}
      rightHeaderContent={
        isAdmin && (
          <EditButtonsWrapper>
            <EditChapterLink courseIri={courseIri} chapter={chapter} />
            <DeleteChapterLink chapter={chapter} />
          </EditButtonsWrapper>
        )
      }
    />
  )
}

export default Chapter
