import styled from '@emotion/styled'
import { isMobile } from '@const/device'

const DesktopIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 120px;
  text-align: center;
`
const MobileIconWrapper = styled(DesktopIconWrapper)`
  flex-direction: row;
  width: auto;
  height: 100%;
`
export const IconWrapper = isMobile ? MobileIconWrapper : DesktopIconWrapper

const DesktopProgress = styled.div<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.success};
  color: ${({ theme }) => theme.colors.success};
  font-size: 9px;
  line-height: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
`
const MobileProgress = styled(DesktopProgress)`
  margin-bottom: 0;
`
export const Progress = isMobile ? MobileProgress : DesktopProgress

export const IconSvgWrapper = styled.div`
  margin-right: 10px;
`

const DesktopListenerIconsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  height: 50px;
`
const MobileListenerIconsWrapper = styled(DesktopListenerIconsWrapper)`
  margin-top: 10px;
  margin-bottom: -10px;
`
export const ListenerIconsWrapper = isMobile
  ? MobileListenerIconsWrapper
  : DesktopListenerIconsWrapper
