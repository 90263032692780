import styled from '@emotion/styled'
import Title from '@organisms/Title'

export const Wrapper = styled.div<{
  selected: boolean
  withCounter: boolean
}>`
  cursor: pointer;
  display: flex;
  margin-top: 7px;
  color: ${({
    selected,
    theme: {
      colors: {
        disable,
        text: { black },
      },
    },
  }) => (selected ? black : disable)};
  align-items: center;
`

export const Counter = styled.div<{
  selected: boolean
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  position: static;
  height: 32px;
  left: 145px;
  top: 0;
  border: 1px solid
    ${({
      selected,
      theme: {
        colors: { disable, main },
      },
    }) => (selected ? main : disable)};
  border-radius: 16px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 12px;
  font-size: ${({ theme }) => theme.text.h5.fontSize};
  line-height: ${({ theme }) => theme.text.h5.lineHeight};
  font-weight: 400;
  letter-spacing: 0.1em;
  text-align: center;
`

export const StyledTitle = styled(Title)`
  margin-bottom: 0;
  white-space: nowrap;
  font-family: 'Gilroy';
`
