import styled from '@emotion/styled'

export const Wrapper = styled.div`
  width: 100%;
`

export const StyledInput = styled.input`
  background-color: white;
  border: none;
  cursor: text;
  width: 100%;
`

export const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 15px 10px;
  width: 100%;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 30px;
  font-size: 14px;
  line-height: 16px;
  color: black;
  height: 58px;
  font-weight: 400;
  letter-spacing: 0;
  text-align: left;
  background-color: white;

  &:hover {
    border: 1px solid #1d4e89;
  }

  &::placeholder {
    color: lightgrey;
  }
`

export const IconWrapper = styled.div`
  margin-top: 2px;
  margin-right: 5px;
  margin-left: 5px;
`
