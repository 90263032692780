import styled from '@emotion/styled'

export const Wrapper = styled.div`
  margin-bottom: 20px;
`

export const BlocksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  //gap: 15px;
`

export const AddButtonWrapper = styled.div`
  display: flex;
  margin-top: 25px;
`
