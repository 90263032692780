import { dateField, textField } from '@templates/AddNewItemModal/templates'
import { Fields } from '@templates/AddNewItemModal/Content'

const useEditFilialFields = (): Fields => {
  return [
    {
      fields: [
        dateField({
          name: 'protocolOrderDate',
          label: 'Дата приказа для протокола',
        }),
        textField({
          label: 'Номер приказа для протокола',
          placeholder: 'Номер приказа',
          name: 'protocolOrderNumber',
        }),
      ],
    },
  ]
}

export default useEditFilialFields
