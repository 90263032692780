import { Dispatch, FC, SetStateAction, useState } from 'react'
import { memoize } from 'lodash'
import { Wrapper } from './styled'
import Star from './Star'

const STARS = [
  {
    id: '1',
    isActive: false,
    clicked: false,
  },
  {
    key: '2',
    isActive: false,
    clicked: false,
  },
  {
    key: '3',
    isActive: false,
    clicked: false,
  },
  {
    key: '4',
    isActive: false,
    clicked: false,
  },
  {
    key: '5',
    isActive: false,
    clicked: false,
  },
]

type Props = {
  setRank: Dispatch<SetStateAction<number | undefined>>
}

const StarsGrade: FC<Props> = ({ setRank }) => {
  const [stars, setStars] = useState(STARS)

  const handleClickCreator = memoize((starIndex: number) => () => {
    setStars((prev) =>
      prev.map((star, index) => ({
        ...star,
        isActive: index <= starIndex,
        clicked: index <= starIndex,
      }))
    )
    setRank(starIndex + 1)
  })

  // const handleMouseEnterCreator = memoize((starIndex: number) => () => {
  //   setStars((prev) =>
  //     prev.map((star, index) =>
  //       index <= starIndex
  //         ? {
  //             ...star,
  //             isActive: true,
  //           }
  //         : star
  //     )
  //   )
  // })
  //
  // const handleMouseLeave = useCallback(() => {
  //   setStars((prev) =>
  //     prev.map((star) => ({
  //       ...star,
  //       isActive: false,
  //     }))
  //   )
  // }, [])

  return (
    <Wrapper>
      {stars.map(({ key, isActive, clicked }, index) => (
        <Star
          key={key}
          isActive={isActive || clicked}
          // onMouseEnter={handleMouseEnterCreator(index)}
          // onMouseLeave={handleMouseLeave}
          onClick={handleClickCreator(index)}
        />
      ))}
    </Wrapper>
  )
}

export default StarsGrade
