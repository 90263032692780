import { FC } from 'react'
import RiskMapModal from '@modals/RiskMapModal'
import EditModal from '@templates/EditModal'
import { RiskMapDetailed } from '@models/riskMap'
import RiskMapStatuses from '@enums/RiskMapStatuses'
import Prompt from '@organs/Prompt'

const NOT_EDITABLE_STATUSES = new Set([
  RiskMapStatuses.ARCHIVED,
  RiskMapStatuses.CANCELED,
])

type Props = {
  data: RiskMapDetailed
}

const EditButtonComponent: FC<Props> = ({ data }) => {
  const isDisabled = NOT_EDITABLE_STATUSES.has(data?.status)

  return (
    <>
      <EditModal
        isDisabled={isDisabled}
        ModalContent={RiskMapModal}
        modalProps={{
          riskMap: data,
        }}
      />
      {isDisabled && (
        <Prompt promptText="Вы не можете редактировать карту рисков в статусах Архив и Отменён" />
      )}
    </>
  )
}

export default EditButtonComponent
