import styled from '@emotion/styled'

export const Description = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 5px;
`

export const Underline = styled.span`
  text-decoration: underline;
`
