import SEVERITY_DEGREES from '@const/severityDegrees'
import SeverityDegrees from '@enums/SeverityDegrees'
import INJURY_POSSIBILITIES from '@const/injuryPossibilities'
import InjuryPossibilities from '@enums/InjuryPossibilities'

export const getSeverityLabel = (injurySeverity: SeverityDegrees) =>
  SEVERITY_DEGREES.find(({ value }) => value === injurySeverity)?.label!

export const getPossibilityLabel = (injuryPossibility: InjuryPossibilities) =>
  INJURY_POSSIBILITIES.find(({ value }) => value === injuryPossibility)?.label!
