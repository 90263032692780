import {
  Dispatch,
  forwardRef,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useToggle } from 'react-use'
import { isEqual } from 'lodash'
import { SelectInstance } from 'react-select'
import Select, { Option, SelectProps } from '@organisms/Select'
import { PER_PAGE } from '@const/pagination'
import { FilterState } from '@hooks/useItemsFilters'
import { memoEqual } from '@utils/memo'

export type QueryType = any

type Props = {
  query: any
  filters: FilterState
  setFilters: Dispatch<SetStateAction<FilterState>>
  onOneItem?: (option: Option) => void
  skip?: boolean
} & Omit<SelectProps, 'options' | 'length' | 'setPage' | 'setFilters'>

const DataSelect = forwardRef<SelectInstance, Props>((allProps, outerRef) => {
  const {
    query,
    filters,
    setFilters,
    onOneItem,
    isDisabled,
    skip: externalSkip,
    ...props
  } = allProps

  const innerRef = useRef<SelectInstance | null>(null)
  useImperativeHandle(outerRef, () => innerRef.current!, [])

  const prevItemsRef = useRef<any[]>([])
  const [page, setPage] = useState(1)
  const [focused, toggleFocused] = useToggle(false)
  const [skip, toggleSkip] = useToggle(
    typeof externalSkip === 'boolean' ? externalSkip : true
  )

  const queryResult = query(
    { ...filters, pagination: true, limit: PER_PAGE, page },
    { skip }
  )
  const { data, isLoading } = queryResult

  useEffect(() => {
    if (
      typeof externalSkip === 'boolean' &&
      skip !== externalSkip &&
      !isLoading
    ) {
      toggleSkip(externalSkip)
    }
  }, [externalSkip])

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      page,
    }))
  }, [page])

  const { items = [], length = 0 } = (data || {}) as {
    items: any[]
    length: number
  }

  useEffect(() => {
    if (focused) return
    if (isEqual(items, prevItemsRef.current)) return

    if (onOneItem && items.length === 1 && !isDisabled) {
      onOneItem(items[0])
    }
    prevItemsRef.current = items
  }, [items, isDisabled])

  const handleOpen = useCallback(() => {
    toggleSkip(false)
  }, [toggleSkip])

  const handleClose = useCallback(() => {
    toggleSkip(true)
  }, [toggleSkip])

  return (
    <Select
      {...props}
      isDisabled={isDisabled}
      ref={(ref) => {
        innerRef.current = ref
      }}
      options={items}
      length={length}
      setPage={setPage}
      setFilters={setFilters}
      onMenuOpen={handleOpen}
      onMenuClose={handleClose}
      isLoading={isLoading}
      onFocus={() => toggleFocused(true)}
      onBlur={() => toggleFocused(false)}
    />
  )
})

export type { Props as DataSelectProps }
export default memo(DataSelect, memoEqual)
