import { CourseThemeDetailed } from '@models/courseTheme'
import { FormValues } from './index'

const getDefaultValues = (
  scorm?: CourseThemeDetailed
): Partial<FormValues> | void => {
  if (!scorm) return

  const result: Partial<FormValues> = {
    title: scorm.title,
    test: scorm.test,
  }

  if (scorm.courseTheme) {
    result.courseTheme = {
      ...scorm.courseTheme,
      value: scorm.courseTheme['@id'],
      label: scorm.courseTheme.title,
    }
  }

  if (scorm.hoursTime) {
    result.hoursTime = scorm.hoursTime.toString()
  }

  return result
}

export default getDefaultValues
