import React, { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as HatIcon } from '@interface-images/hat-gray.svg'
import { Routes } from '@enums/Routes'
import { Course } from '@models/course'
import manyWords from '@utils/manyWords'
import Text from '@organisms/Text'
import { AccordionHeaderProps } from '@organisms/Accordion'
import {
  IconSvgWrapper,
  IconWrapper,
  ListenerIconsWrapper,
  Progress,
} from '@pages/Courses/CourseItem/styled'
import ArrowRight from '@pages/Courses/CourseItem/Header/ArrowRight'
import { LineWrapper, StyledSubtitle } from '@pages/Courses/styled'
import CourseInfo from '@templates/CourseInfo'
import { LeftWrapper, RightContentWrapper, TitleText, Wrapper } from './styled'

const ICON_SIZE = 25

type Props = {
  item: Course
  deadlineAt?: string
  createdAt?: string
  employeeEducationId: number
  withDeadlineBadge?: boolean
  progress: number
  index: number
} & AccordionHeaderProps &
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

const Header: FC<Props> = ({
  hovered,
  employeeEducationId,
  item: { id: courseId, title, subType, hoursCount, type, chaptersCount },
  deadlineAt,
  createdAt,
  withDeadlineBadge = false,
  progress,
  index,
  ...rest
}) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(Routes.COURSES + `/${courseId}/education/${employeeEducationId}`)
  }

  return (
    <Wrapper
      {...rest}
      hovered={hovered}
      onClick={handleClick}
      id={`myEducation${index}`}
    >
      <LeftWrapper>
        <LineWrapper>
          <StyledSubtitle>
            <TitleText>{title}</TitleText>
          </StyledSubtitle>
        </LineWrapper>
        <CourseInfo
          createdAt={createdAt}
          deadlineAt={deadlineAt}
          withDeadlineBadge={withDeadlineBadge}
          type={type}
          subType={subType}
          hoursCount={hoursCount}
          index={index}
        />
      </LeftWrapper>
      <LineWrapper>
        <RightContentWrapper>
          <ListenerIconsWrapper>
            <IconWrapper>
              <IconSvgWrapper>
                <HatIcon height={ICON_SIZE} width={ICON_SIZE} />
              </IconSvgWrapper>
              <Text type="tag">
                {manyWords(chaptersCount, 'раздел', 'раздела', 'разделов')}
              </Text>
            </IconWrapper>
            <IconWrapper>
              <IconSvgWrapper>
                <Progress size={ICON_SIZE}>{progress || 0}%</Progress>
              </IconSvgWrapper>
              <Text type="tag">Прогресс</Text>
            </IconWrapper>
            <ArrowRight hovered={hovered} />
          </ListenerIconsWrapper>
        </RightContentWrapper>
      </LineWrapper>
    </Wrapper>
  )
}

export default Header
