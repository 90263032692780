import { FC, MouseEventHandler } from 'react'
import Tab from '@molecules/Tabs/Tab'
import { InfoWrapper, Label, ValueWrapper } from './styled'

type Props = {
  label?: string
  value: string | number | null | undefined
  onTabClick?: MouseEventHandler
  withStopPropagation?: boolean
  isLoading?: boolean
  withoutFixedWidth?: boolean
  withoutMarginBottom?: boolean
  isWhiteBackground?: boolean
  withoutHover?: boolean
  id?: string
  isExpired?: boolean
}

const InfoField: FC<Props> = ({
  label,
  value,
  onTabClick,
  withStopPropagation = true,
  isLoading = false,
  withoutFixedWidth = false,
  withoutMarginBottom = false,
  isWhiteBackground = false,
  withoutHover = false,
  isExpired = false,
  id,
}) => {
  if (!value && value !== 0) {
    return null
  }

  return (
    <InfoWrapper withoutFixedWidth={withoutFixedWidth} id={id}>
      {label && <Label mySize="h4">{label}</Label>}
      <ValueWrapper isExpired={isExpired}>
        <Tab
          id="id"
          tabSize="small"
          title={value}
          onClick={onTabClick}
          withStopPropagation={withStopPropagation}
          isLoading={isLoading}
          withoutMarginBottom={withoutMarginBottom}
          isWhiteBackground={isWhiteBackground}
          withoutHover={withoutHover}
        />
      </ValueWrapper>
    </InfoWrapper>
  )
}

export default InfoField
