import { Document } from '@models/document'
import { FormValues } from './index'

const getDefaultValues = (document?: Document): Partial<FormValues> | void => {
  if (!document) return

  return {
    ...document,
    file: document.file,
    title: document.title,
    documentType: {
      value: document.documentType['@id'],
      label: document.documentType.title,
    },
  }
}

export default getDefaultValues
