import useScorms from '@hooks/useScorms'
import {
  checkboxField,
  numberField,
  textField,
} from '@templates/AddNewItemModal/templates'
import { Fields } from '@templates/AddNewItemModal/Content'
import selectSearchField from '@templates/AddNewItemModal/templates/selectSearchField'

const useThemeFields = (): Fields => {
  const { data, isLoading, setFilters, setPage } = useScorms()
  const { items = [], length = 0 } = data || {}

  return [
    {
      fields: [
        textField({
          name: 'title',
          label: 'Название темы',
          isRequired: true,
          placeholder: 'Название темы',
        }),
        selectSearchField({
          label: 'Выбрать SCORM',
          name: 'courseTheme',
          isRequired: true,
          options: items,
          isLoading,
          setFilters,
          setPage,
          length,
          isClearable: false,
        }),
        numberField({
          name: 'hoursTime',
          label: 'Количество часов',
          placeholder: 'Количество',
        }),
        checkboxField({
          name: 'test',
          label: 'Содержит тест',
        }),
      ],
    },
  ]
}

export default useThemeFields
