import { SubdivisionJobPositionRel } from '@models/user'
import { Option } from '@organisms/Select'

const setDefaultSubdivisionJobPosionValues = (
  result: Partial<{
    subdivisionJobPositionRels: {
      division: Option
      position: Option
    }[]
  }>,
  subdivisionJobPositionRels: SubdivisionJobPositionRel[]
) => {
  // eslint-disable-next-line unicorn/prefer-ternary
  if (subdivisionJobPositionRels?.length > 0) {
    result.subdivisionJobPositionRels = subdivisionJobPositionRels.map(
      (item) => {
        return {
          division: {
            ...item.subdivision,
            label: item.subdivision?.title,
            value: item.subdivision?.['@id'],
          },
          position: {
            ...item.jobPosition,
            label: item.jobPosition.title,
            value: item.jobPosition['@id'],
          },
        }
      }
    )
  } else {
    result.subdivisionJobPositionRels = [
      {
        division: {} as Option,
        position: {} as Option,
      },
    ]
  }

  return result
}

export default setDefaultSubdivisionJobPosionValues
