import StoreTags from '@enums/StoreTags'
import { CommonQueryParams, ItemsFromResponse } from '@models/common'
import {
  CreatePositionBody,
  CreatePositionResponse,
  Position,
  PositionDetailed,
  PositionOption,
  UpdatePositionBody,
  UpdatePositionResponse,
} from '@models/position'
import emptySplitApi from '../api'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'

const BASE_URL = '/api/company_branch_subdivision_job_positions'
const JOB_TAGS = [
  StoreTags.COMPANIES,
  StoreTags.COMPANY,
  StoreTags.FILIALS,
  StoreTags.FILIAL,
  StoreTags.DIVISIONS,
  StoreTags.DIVISION,
]

export const positionsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getPositions: builder.query<
      { items: PositionOption[]; length: number },
      CommonQueryParams
    >({
      query: (params) => ({
        url: BASE_URL,
        params,
      }),
      transformResponse: (response: ItemsFromResponse<Position>) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          label: item.title,
          value: item['@id'],
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
      providesTags: [StoreTags.POSITIONS],
    }),
    createPosition: builder.mutation<
      CreatePositionResponse,
      CreatePositionBody
    >({
      invalidatesTags: (result) =>
        result ? [...JOB_TAGS, StoreTags.POSITIONS] : [],
      query: (body) => ({
        url: BASE_URL,
        method: 'POST',
        body,
      }),
    }),
    updatePosition: builder.mutation<
      UpdatePositionResponse,
      UpdatePositionBody
    >({
      invalidatesTags: (result) =>
        result ? [...JOB_TAGS, StoreTags.POSITIONS, StoreTags.POSITION] : [],
      query: ({ id, ...body }) => ({
        url: `${BASE_URL}/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    getPosition: builder.query<PositionDetailed, number>({
      providesTags: [StoreTags.POSITION],
      query: (id) => `${BASE_URL}/${id}`,
    }),
    deletePosition: builder.mutation<void, number>({
      invalidatesTags: [
        StoreTags.POSITIONS,
        StoreTags.DIVISIONS,
        StoreTags.DIVISION,
        StoreTags.FILIALS,
        StoreTags.FILIAL,
        StoreTags.COMPANY,
        StoreTags.COMPANIES,
      ],
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetPositionsQuery,
  useCreatePositionMutation,
  useUpdatePositionMutation,
  useLazyGetPositionQuery,
  useGetPositionQuery,
  useDeletePositionMutation,
} = positionsApi
