import styled from '@emotion/styled'
import Title from '@organisms/Title'

export const Wrapper = styled.div`
  margin-top: -15px;
`

export const StyledTitle = styled(Title)`
  margin-bottom: 50px;
`
