import { textAreaField, textField } from '@templates/AddNewItemModal/templates'
import { Fields } from '@templates/AddNewItemModal/Content'
import companyLogoField from '@const/fields/companyLogo'
import DescriptionInfo from './DescriptionInfo'

const useCompanyFields = (): Fields => {
  return [
    {
      fields: [
        textField({
          name: 'title',
          label: 'Наименование',
          placeholder: 'Наименование компании',
          isRequired: true,
        }),
        textField({
          name: 'fullTitle',
          label: 'Полное наименование',
          placeholder: 'Полное наименование компании',
          widthMultiplier: 2,
          promptText:
            'Введите полное наименование орагнизации, включая развернутое описание формы собственности, например: Общество с ограниченной ответственностью «Школа экспертов»',
          isRequired: true,
        }),
      ],
    },
    {
      fields: [companyLogoField],
    },
    {
      fields: [
        textAreaField({
          name: 'description',
          placeholder: 'Информационная и техническая поддержка',
          label: 'Информационная и техническая поддержка',
          widthMultiplier: 2,
        }),
      ],
      contentAfter: DescriptionInfo(),
    },
  ]
}

export default useCompanyFields
