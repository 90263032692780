const A5Set = new Set(['2', '3', '4'])

const manyWords = (
  count: number | undefined,
  a1: string,
  a5: string,
  aOther: string
): string => {
  if (!count && count !== 0) return ''

  const countString = count.toString()
  const lastSymbol = countString[countString.length - 1]

  let postfix
  if (lastSymbol === '1') {
    postfix = a1
  } else if (
    A5Set.has(lastSymbol) &&
    (countString.length === 1 || (count > 20 && count < 100))
  ) {
    postfix = a5
  } else {
    postfix = aOther
  }

  return `${count} ${postfix}`
}

export default manyWords
