import { FC, useState } from 'react'
import CodeForm from './CodeForm'
import PhoneForm from './PhoneForm'

type Props = {
  onBack: () => void
}

const PhoneAuthForm: FC<Props> = ({ onBack }) => {
  const [submittedNumber, setSubmittedNumber] = useState<string | void>()

  const handleSubmitPhoneForm = (phoneNumber: string) => {
    setSubmittedNumber(phoneNumber)
  }

  const handleBack = () => {
    setSubmittedNumber()
  }

  return (
    <>
      {submittedNumber ? (
        <CodeForm submittedNumber={submittedNumber} handleBack={handleBack} />
      ) : (
        <PhoneForm
          handleSubmitPhoneForm={handleSubmitPhoneForm}
          handleBack={onBack}
        />
      )}
    </>
  )
}

export default PhoneAuthForm
