import { FetchArgs } from '@reduxjs/toolkit/query/react'
import {
  CourseThemeDetailed,
  CourseThemeListItemServerResponse,
  CourseThemesQueryParams,
  CreateCourseThemeBody,
  UpdateCourseThemeBody,
} from '@models/courseTheme'
import { ItemsFromResponse } from '@models/common'
import StoreTags from '@enums/StoreTags'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'
import emptySplitApi from '../api'

const BASE_URL = '/api/course_chapter_themes'

export const courseThemesApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCourseThemes: builder.query<
      {
        items: CourseThemeListItemServerResponse[]
        length: number
      },
      CourseThemesQueryParams
    >({
      providesTags: [StoreTags.COURSE_THEMES],
      query: (params) => ({
        url: BASE_URL,
        params,
      }),
      transformResponse: (
        response: ItemsFromResponse<CourseThemeListItemServerResponse>
      ) => ({
        items: response[HYDRA_MEMBER],
        length: response[HYDRA_TOTAL_ITEMS],
      }),
    }),
    getCourseThemeById: builder.query<
      CourseThemeDetailed,
      number | string | undefined
    >({
      providesTags: [StoreTags.COURSE_THEME],
      keepUnusedDataFor: 1,
      query: (id) => `${BASE_URL}/${id}`,
    }),
    postCourseTheme: builder.mutation<
      CourseThemeDetailed,
      CreateCourseThemeBody
    >({
      invalidatesTags: (result) => (result ? [StoreTags.COURSE_THEMES] : []),
      query: (body): FetchArgs => ({
        url: BASE_URL,
        method: 'POST',
        body,
      }),
    }),
    putCourseTheme: builder.mutation<
      CourseThemeDetailed,
      UpdateCourseThemeBody
    >({
      invalidatesTags: (result) =>
        result ? [StoreTags.COURSE_THEMES, StoreTags.COURSE_THEME] : [],
      query: (body): FetchArgs => ({
        url: `${BASE_URL}/${body.id}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteCourseTheme: builder.mutation<void, number>({
      invalidatesTags: [
        StoreTags.COURSE_THEMES,
        StoreTags.COURSE_CHAPTERS,
        StoreTags.EMPLOYEE_EDUCATIONS,
        StoreTags.COURSES,
      ],
      query: (id): FetchArgs => ({
        url: `${BASE_URL}/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetCourseThemesQuery,
  useDeleteCourseThemeMutation,
  usePostCourseThemeMutation,
  usePutCourseThemeMutation,
  useGetCourseThemeByIdQuery,
  useLazyGetCourseThemeByIdQuery,
} = courseThemesApi
