import styled from '@emotion/styled'
import { isMobile } from '@const/device'

export const DateAlertWrapper = styled.div`
  font-weight: normal;
  font-size: 14px;
  margin-top: -5px;
`

export const LineWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: ${() => (isMobile ? 'auto' : '16px')};
  width: 100%;
  gap: 10px;
`
