import { REQUIRED_VALIDATION_RULE } from '@const/validation'
import EmployeeSelect, {
  EmployeeSelectFormValues,
  EmployeeSelectProps,
} from '@templates/EmployeeSelect'
import { FieldForRender, FieldParams } from '..'

type Params = {
  initValues?: EmployeeSelectFormValues
  componentProps?: EmployeeSelectProps
  isClearable?: boolean
} & FieldParams

const employeeField = (params: Params): FieldForRender => {
  const {
    label,
    isRequired,
    isLoading,
    widthMultiplier = 1,
    componentProps: props,
    initValues,
    isDisabled,
    isClearable,
  } = params || {}

  const componentProps: EmployeeSelectProps = {
    ...props,
    placeholder: 'Сотрудник',
    isLoading,
    initValues,
    isDisabled,
    isClearable,
  }

  return {
    ...params,
    label: isRequired ? `${label}*` : label,
    Component: EmployeeSelect,
    rules: {
      ...(isRequired ? REQUIRED_VALIDATION_RULE : {}),
    },
    componentProps,
    widthMultiplier,
  }
}

export default employeeField
