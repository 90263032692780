import { FC, ReactNode } from 'react'
import FlexWrapper from '@atoms/FlexWrapper'
import { Label, Wrapper } from './styled'

type Props = {
  label: string
  value?: ReactNode
  labelWidth?: number
  isBlock?: boolean
  withoutFixedHeight?: boolean
}

const AccordionBodyField: FC<Props> = ({
  label,
  value,
  labelWidth,
  isBlock = false,
  withoutFixedHeight = false,
}) => {
  if (!value && value !== 0) {
    return null
  }

  return (
    <Wrapper isBlock={isBlock} withoutFixedHeight={withoutFixedHeight}>
      <Label labelWidth={labelWidth}>{label}</Label>
      <FlexWrapper>{value}</FlexWrapper>
    </Wrapper>
  )
}

export default AccordionBodyField
