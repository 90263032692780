import { JoyStep } from '@templates/Onboarding'
import { InfoCircleTwoTone } from '@ant-design/icons'

export const ONBOARDING_TITLE = 'Расскажем как загрузить штатное расписание'

export const ONBOARDING_STEPS: JoyStep[] = [
  {
    target: '#download-example',
    // eslint-disable-next-line sonarjs/no-duplicate-string
    title: 'Загрузка штатного расписания',
    content: (
      <div>
        <div>Скачайте и заполните образец штатного расписания.</div>
        <div>
          Порядок столбцов и формат заполнения ячеек должен соответствовать
          образцу.
        </div>
        <div
          style={{
            display: 'flex',
            gap: 10,
            marginTop: 10,
            alignItems: 'flex-start',
          }}
        >
          <InfoCircleTwoTone style={{ color: 'white' }} />
          <div>
            <div>
              Если нужно добавить новых сотрудников, то в файл нужно добавить
              только их.
            </div>
            <div>Уже созданных сотрудников добавлять нельзя.</div>
            <div>
              Если нужно изменить данные о сотруднике, то найдите его в списке,
              разверните и нажмите "Изменить".
            </div>
          </div>
        </div>
      </div>
    ),
  },
  {
    target: '#fieldsLine',
    title: 'Загрузка штатного расписания',
    content: 'Загрузите файл со штатным расписанием',
  },
  {
    target: '#modal-save',
    title: 'Загрузка штатного расписания',
    content: (
      <div>
        <div>Сохраните штатное расписание и дождитесь загрузки.</div>
        <div>
          При появлении ошибок, исправьте их в файле и повторите загрузку.
        </div>
      </div>
    ),
  },
]
