import * as React from 'react'
import { FC } from 'react'
import AddNewItemModal from '@templates/AddNewItemModal'
import { useUpdateFilialMutation } from '@services/filials'
import useFormProcessor from '@hooks/useFormProcessor'
import { Option } from '@organisms/Select'
import { FilialDetailed, UpdateFilialBody } from '@models/filial'
import CommissionMemberMultilineFields from '@templates/CommissionMemberMultilineFields'
import RiskMemberMultilineFields from '@templates/RiskMemberMultilineFields'
import { User } from '@models/user'
import { getMultilineValue } from '@utils/form'
import getDefaultValues from './getDefaultValues'
import useEditFilialFields from './useEditFilialFields'

export type FormValues = {
  commissionHead: Option
  commissionMembers: Option[]
  riskMapCommissionMembers?: User[]
  protocolOrderDate?: string
  protocolOrderNumber?: string
}

type Props = {
  filialInfo: FilialDetailed
}

const EditFilialBlock: FC<Props> = ({ filialInfo }) => {
  const fields = useEditFilialFields()

  const [update, updateData] = useUpdateFilialMutation()

  const methods = useFormProcessor({
    apiErrors: updateData.error,
    defaultValues: getDefaultValues(filialInfo),
  })

  const handleSubmitForm = (form: FormValues) => {
    if (!filialInfo) {
      return
    }

    const formBody: UpdateFilialBody = {
      company: filialInfo.company['@id'],
      title: filialInfo.title,
      fullTitle: filialInfo.fullTitle,
      subscriptionsCount: filialInfo.subscriptionsCount || null,
      subscriptionStartAt: filialInfo.subscriptionStartAt || null,
      subscriptionEndAt: filialInfo.subscriptionEndAt || null,
      curators: getMultilineValue(filialInfo.curators),
      inn: filialInfo.inn || null,
      commissionMembers: getMultilineValue(form.commissionMembers),
      commissionHead: form.commissionHead?.value || null,
      id: filialInfo.id,
      riskMapCommissionMembers: getMultilineValue(
        form.riskMapCommissionMembers
      ),
      protocolOrderDate: form.protocolOrderDate,
      protocolOrderNumber: form.protocolOrderNumber,
    }

    update(formBody)
  }

  return (
    <AddNewItemModal
      {...updateData}
      onSubmitForm={handleSubmitForm}
      methods={methods}
      fields={fields}
      customFullTitle="Члены комиссии и председатель"
      successContentAddTitle="Настройки филиала успешно обновлены"
      withNotificationBanner
      contentAfterFields={
        <>
          <CommissionMemberMultilineFields />
          <RiskMemberMultilineFields />
        </>
      }
      withoutReset
    />
  )
}

export default EditFilialBlock
