import { REQUIRED_VALIDATION_RULE } from '@const/validation'
import FileUploadButton, {
  FileUploadButtonProps,
} from '@organisms/FileUploadButton'
import { File } from '@models/file'
import { FieldForRender, FieldParams } from '..'

type Params = {
  fileName?: string
  formats?: string[]
  maxSizeMegaBytes?: number
  componentProps?: FileUploadButtonProps
  value?: File | null
} & FieldParams

const fileField = (params: Params): FieldForRender => {
  const {
    label,
    isRequired,
    fileName,
    formats,
    componentProps,
    maxSizeMegaBytes,
    isDisabled,
    value,
  } = params || {}

  return {
    ...params,
    label: isRequired ? `${label}*` : label,
    rules: {
      ...(isRequired ? REQUIRED_VALIDATION_RULE : {}),
    },
    Component: FileUploadButton,
    componentProps: {
      ...componentProps,
      fileName,
      formats,
      maxSizeMegaBytes,
      disabled: isDisabled,
      value,
    },
  }
}

export default fileField
