import { FC, SetStateAction, SVGProps, useEffect, useState } from 'react'
import { ReactComponent as EnterIconActive } from '@interface-images/enter-icon-active.svg'
import { ReactComponent as EnterIcon } from '@interface-images/enter-icon.svg'
import { ReactComponent as SearchIcon } from '@interface-images/search.svg'
import useMouseOverOut from '@hooks/useMouseOverOut'
import Input, { InputProps } from './Input'
import { EnterButtonWrapper } from './styled'

const ICON_SIZE = 28

type Props = {
  onSearch?: (value: string) => void
  value?: string
}

const RightIconButton: FC<
  SVGProps<SVGSVGElement> & { title?: string | undefined }
> = () => {
  const [hovered, , handleMouseEnter, handleMouseLeave] = useMouseOverOut()

  return (
    <EnterButtonWrapper
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {hovered ? (
        <EnterIconActive height={ICON_SIZE} width={ICON_SIZE} />
      ) : (
        <EnterIcon height={ICON_SIZE} width={ICON_SIZE} />
      )}
    </EnterButtonWrapper>
  )
}

const SearchInput: FC<Props & InputProps> = ({ onSearch, ...rest }) => {
  const [value, setValue] = useState<string | undefined>()

  const handleSearch = () => {
    onSearch?.(value as string)
  }

  const handleChange = (e: { target: { value: SetStateAction<string> } }) => {
    setValue(e.target.value as string)
  }

  const handleKeyDown = (e: { key: string }) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  useEffect(() => {
    if (value === '') {
      handleSearch()
    }
  }, [value])

  return (
    <Input
      {...rest}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      value={value}
      LeftIcon={SearchIcon}
      RightIcon={RightIconButton}
      rightIconProps={{
        onClick: handleSearch,
      }}
    />
  )
}

export default SearchInput
