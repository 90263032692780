import { ItemsFromResponse } from '@models/common'
import {
  CourseChapter,
  CourseChapterServerResponse,
  CourseChaptersQueryParams,
  CreateCourseChapterBody,
  UpdateCourseChapterBody,
} from '@models/courseChapter'
import StoreTags from '@enums/StoreTags'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'
import emptySplitApi from '../api'

const BASE_URL = '/api/course_chapters'

export const courseChaptersApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCourseChapters: builder.query<
      { items: CourseChapter[]; length: number },
      CourseChaptersQueryParams
    >({
      providesTags: [StoreTags.COURSE_CHAPTERS],
      query: (params) => ({
        url: BASE_URL,
        params,
      }),
      transformResponse: (
        response: ItemsFromResponse<CourseChapterServerResponse>
      ) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          value: item['@id'],
          label: item.title,
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
    }),
    postCourseChapter: builder.mutation<
      CourseChapterServerResponse,
      CreateCourseChapterBody
    >({
      invalidatesTags: (result) => (result ? [StoreTags.COURSE_CHAPTERS] : []),
      query: (body) => ({
        url: BASE_URL,
        method: 'POST',
        body,
      }),
    }),
    putCourseChapter: builder.mutation<
      CourseChapterServerResponse,
      UpdateCourseChapterBody
    >({
      invalidatesTags: (result) =>
        result ? [StoreTags.COURSE_CHAPTERS, StoreTags.COURSE_CHAPTER] : [],
      query: ({ id, ...body }) => ({
        url: `${BASE_URL}/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteCourseChapter: builder.mutation<void, number>({
      invalidatesTags: [
        StoreTags.COURSE_CHAPTERS,
        StoreTags.COURSES,
        StoreTags.EMPLOYEE_EDUCATIONS,
      ],
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetCourseChaptersQuery,
  usePostCourseChapterMutation,
  usePutCourseChapterMutation,
  useDeleteCourseChapterMutation,
} = courseChaptersApi
