import { Dispatch, FC, SetStateAction } from 'react'
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons'
import GrayButton from '@organisms/GrayButton'
import { isMobile } from '@const/device'

type Props = {
  inModal: boolean
  toggleInModal: Dispatch<SetStateAction<boolean>>
}

const ExpandButton: FC<Props> = ({ inModal, toggleInModal }) => {
  const text = isMobile ? '' : inModal ? 'Свернуть' : 'Развернуть'

  return (
    <GrayButton
      id="expandButton"
      onClick={() => toggleInModal(!inModal)}
      leftIcon={inModal ? <ZoomOutOutlined /> : <ZoomInOutlined />}
    >
      {text}
    </GrayButton>
  )
}

export default ExpandButton
