import { FC } from 'react'
import PrimaryButton from '@organisms/PrimaryButton'
import Modal from '@organisms/Modal'
import { useAppSelector } from '@hooks/useAppSelector'
import { useAppDispatch } from '@hooks/useAppDispatch'
import { setSelectDangersModalIsVisible } from '@services/riskMapModal'
import SelectDangersModal from './SelectDangersModal'
import { Wrapper } from './styled'

const SelectDangers: FC = () => {
  const dispatch = useAppDispatch()
  const selectDangersModalIsVisible = useAppSelector(
    (state) => state.riskMapModal.selectDangersModalIsVisible
  )

  const toggleVisible = () => {
    dispatch(setSelectDangersModalIsVisible(!selectDangersModalIsVisible))
  }

  return (
    <div>
      <Wrapper>
        <PrimaryButton big onClick={toggleVisible} id="select-dangers">
          Отфильтровать и выбрать опасности
        </PrimaryButton>
      </Wrapper>
      <Modal visible={selectDangersModalIsVisible} onModalClose={toggleVisible}>
        <SelectDangersModal handleCloseModal={toggleVisible} />
      </Modal>
    </div>
  )
}

export default SelectDangers
