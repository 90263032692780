import styled from '@emotion/styled'
import { isMobile } from '@const/device'

const DesktopWrapper = styled.div`
  padding: 0 20px;
`
const MobileWrapper = styled(DesktopWrapper)`
  padding: 0;
`
export const Wrapper = isMobile ? MobileWrapper : DesktopWrapper

export const ScormWrapper = styled.div<{ inModal: boolean }>`
  ${({ inModal }) =>
    inModal
      ? 'position: fixed; left: 0; top: 0; z-index: 9999; height: 100vh; width: 100vw;'
      : ''}
`
