import { FC, useState } from 'react'
import DownloadButton from '@organisms/DownloadButton'
import { useLazyGetFeedbackReportQuery } from '@services/onboarding/api'
import DatePicker from '@organisms/DatePicker'
import { createInitFilter } from '@utils/filters'
import { StyledContentBlock } from '../styled'
import { ButtonWrapper, PickersWrapper, PickerWrapper } from './styled'

const Onboarding: FC = () => {
  const [getFeedbackReport, { isLoading }] = useLazyGetFeedbackReportQuery()

  const [beforeAt, setBeforeAt] = useState<string | null>(null)
  const [afterAt, setAfterAt] = useState<string | null>(null)

  const handleGetReport = () => {
    const params = {
      ...(beforeAt ? createInitFilter('beforeAt', `${beforeAt} 23:59:59`) : {}),
      ...(afterAt ? createInitFilter('afterAt', `${afterAt} 00:00:00`) : {}),
    } as unknown as Record<'beforeAt' | 'afterAt', Record<string, unknown>>

    void getFeedbackReport(params)
  }

  return (
    <StyledContentBlock>
      <PickersWrapper>
        <PickerWrapper>
          <DatePicker placeholder="Дата от" onChange={setAfterAt} />
        </PickerWrapper>
        <PickerWrapper>
          <DatePicker placeholder="Дата до" onChange={setBeforeAt} />
        </PickerWrapper>
        <ButtonWrapper>
          <DownloadButton isLoading={isLoading} onClick={handleGetReport}>
            Скачать отчет
          </DownloadButton>
        </ButtonWrapper>
      </PickersWrapper>
    </StyledContentBlock>
  )
}

export default Onboarding
