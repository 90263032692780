import * as React from 'react'
import { FC } from 'react'
import { useDeleteScormMutation } from '@services/scorms'
import DeleteButton from '@templates/DeleteButton'

type Props = {
  id: string
}

const DeleteScormButton: FC<Props> = ({ id }) => {
  const [deleteScorm, data] = useDeleteScormMutation()

  const handleDelete = () => {
    deleteScorm(id)
  }

  return <DeleteButton {...data} onClick={handleDelete} />
}

export default DeleteScormButton
