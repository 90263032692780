import { isEmpty, omit } from 'lodash'
import { useGetRiskMapsFilteredDangersQuery } from '@services/riskMaps'
import { PER_PAGE } from '@const/pagination'
import { SortStates } from '@templates/ListPageLayout/Filters/Sorter'
import useItemsFilters, { FilterState } from './useItemsFilters'

type Params = {
  initFilters?: FilterState
  pagination?: boolean
  limit?: number
  skip?: boolean
  initOrder?: { order: Record<string, SortStates> }
}

const useRiskMapsFilteredDangers = (params?: Params) => {
  const {
    initFilters = {},
    pagination = true,
    limit = PER_PAGE,
    skip,
    initOrder,
  } = params || {}
  const { filters, setFilters, setPage, setOrder } = useItemsFilters(
    initFilters,
    pagination,
    limit,
    initOrder
  )

  const emptyFilters = omit(filters, ['pagination', 'page', 'limit'])

  const state = useGetRiskMapsFilteredDangersQuery(filters, {
    skip: skip || isEmpty(emptyFilters),
  })

  return {
    ...state,
    setPage,
    setFilters,
    setOrder,
  }
}

export default useRiskMapsFilteredDangers
