import styled from '@emotion/styled'
import { isMobile } from '@const/device'

const getWidth = ({ labelWidth }: { labelWidth?: number }) =>
  isMobile ? 'auto' : `${labelWidth || 400}px`

const DesktopWrapper = styled.div<{
  isBlock: boolean
  withoutFixedHeight: boolean
}>`
  display: ${({ isBlock }) => (isBlock ? 'block' : 'flex')};
  margin-bottom: 14px;
  height: ${({ isBlock, withoutFixedHeight }) =>
    isBlock || withoutFixedHeight ? 'none' : '30px'};

  align-items: center;
  flex-direction: row;
`

const MobileWrapper = styled(DesktopWrapper)<{
  isBlock: boolean
  withoutFixedHeight: boolean
}>`
  height: inherit;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
`

export const Wrapper = isMobile ? MobileWrapper : DesktopWrapper

const DesktopLabel = styled.div<{
  labelWidth?: number
}>`
  color: #7f7f7f;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-align: left;
  text-transform: uppercase;

  width: ${getWidth};
  min-width: ${getWidth};

  font-size: ${({ theme }) => theme.text.h6.fontSize};
  line-height: ${({ theme }) => theme.text.h6.lineHeight};
  margin-right: ${({ theme }) => theme.sizes.threeBase};

  margin-bottom: 0;
`
const MobileLabel = styled(DesktopLabel)<{
  labelWidth?: number
}>`
  margin-bottom: 10px;
`
export const Label = isMobile ? MobileLabel : DesktopLabel
