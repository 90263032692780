import styled from '@emotion/styled'
import { isMobile } from '@const/device'

export const Wrapper = styled.div<{
  success: boolean
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 30px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: white;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-right: ${() => (isMobile ? '0' : '35px')};
  margin-top: ${() => (isMobile ? '35px' : '0')};
  width: 150px;

  background: ${({ success, theme }) =>
    success ? theme.colors.success : theme.colors.warning};
`
