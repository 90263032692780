import { FC } from 'react'
import useTariffEnrollmentTabs from '@hooks/useTariffEnrollmentTabs'
import ListPage from '@templates/ListPage'
import Item from './Item'

const Tariff: FC = () => {
  const titleTabs = useTariffEnrollmentTabs()

  return (
    <ListPage
      ItemComponent={Item}
      titleTabs={titleTabs}
      list={[{}]}
      length={1}
      addButtonTitle="Подать заявку"
    />
  )
}

export default Tariff
