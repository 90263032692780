import styled from '@emotion/styled'

export const Item = styled.span`
  border-radius: 25px;
  padding: 10px 15px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;

  color: ${({ theme }) => theme.colors.background.surface};
  background: ${({ theme }) => theme.colors.disable};
`

export const TextWrapper = styled.span`
  margin-right: 10px;
`

export const CloseWrapper = styled.div`
  margin-top: 3px;
  cursor: pointer;
`
