import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.sizes.twoBase};
  align-items: flex-end;
  cursor: pointer;
`

export const IconWrapper = styled.div`
  margin: 0 10px;
`

export const Text = styled.div`
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  margin-right: 15px;
`
