import styled from '@emotion/styled'
import { isMobile } from '@const/device'

const WrapperBase = styled.div`
  background: white;
  box-shadow: 5px 5px 20px rgba(29, 78, 137, 0.1);
  border-radius: 25px;
  padding: 32px 32px 10px 32px;
  margin: ${({ theme }) => theme.sizes.base};
  position: fixed;
  z-index: 1100;
  left: 0;
  bottom: 0;
  gap: 10px;
`
const DesktopWrapper = styled(WrapperBase)`
  width: 350px;
`
const MobileWrapper = styled(WrapperBase)`
  width: 95vw;
`
export const Wrapper = isMobile ? MobileWrapper : DesktopWrapper

export const Text = styled.div`
  font-size: ${({ theme }) => theme.text.pMini.fontSize};
  line-height: ${({ theme }) => theme.text.pMini.lineHeight};
  font-weight: 400;
  letter-spacing: 0;
  text-align: left;
  color: ${({ theme }) => theme.colors.text.secondary};
`

export const BackgroundWrapper = styled.div`
  position: fixed;
  z-index: 5000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
`

export const Footer = styled.div`
  display: inline-block;
  margin: 15px 0;
`
