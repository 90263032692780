import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { isEmpty } from 'lodash'
import { tabsField } from '@templates/AddNewItemModal/templates'
import usePersonalFields from './usePersonalFields'
import useStaffingFields, { StaffingReturnType } from './useStaffingFields'

const useEmployeeFields = (
  setSavedFormState: Dispatch<SetStateAction<FieldValues>>,
  intershipList: number[],
  methods: UseFormReturn,
  initActiveList?: number
  // eslint-disable-next-line sonarjs/cognitive-complexity
): StaffingReturnType & { selectedTab: number } => {
  const [active, setActive] = useState(
    initActiveList || initActiveList === 0 ? 1 : 0
  )

  const tabsFieldResult = tabsField({
    label: '',
    name: '',
    tabProps: {
      tabSize: 'small',
      items: [
        {
          title: 'Личные данные',
          id: 'personal-fields',
        },
        {
          title: 'Стажировочные листы',
          id: 'staffing-fields',
        },
      ],
      active,
      setActive,
    },
    widthMultiplier: 2,
  })

  const personalFields = usePersonalFields(methods)
  const {
    fields: staffingFields,
    listActive,
    setListActive,
  } = useStaffingFields(methods, intershipList, initActiveList)

  useEffect(() => {
    setSavedFormState((prev) => {
      const formStateValues = methods.getValues()
      const resultState: any = {} // correct

      for (const key in prev) {
        const value = prev[key]
        if (!isEmpty(value)) {
          resultState[key] = value
        }
      }

      for (const key in formStateValues) {
        const value = formStateValues[key]
        if (!isEmpty(value)) {
          resultState[key] = value
        }
      }

      // Намеренно установка в асинхронном режиме
      setTimeout(() => {
        for (const key in resultState) {
          methods.setValue(key, resultState[key])
        }
      })

      return resultState
    })
  }, [active])

  const fields =
    active === 0
      ? [{ fields: [tabsFieldResult] }, ...personalFields]
      : [{ fields: [tabsFieldResult] }, ...staffingFields]

  return {
    fields,
    selectedTab: active,
    listActive,
    setListActive,
  }
}

export default useEmployeeFields
