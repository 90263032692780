import { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import Modal from '@organisms/Modal'
import { useAppSelector } from '@hooks/useAppSelector'
import EditPasswordTemplate from '@templates/EditPasswordTemplate'
import { useAppDispatch } from '@hooks/useAppDispatch'
import { logout, toggleNeedCheckExpiredPassword } from '@services/auth/slice'
import DeleteButton from '@templates/DeleteButton'
import CloseButton from '@organisms/CloseButton'
import GrayButton from '@organisms/GrayButton'
import { Routes } from '@enums/Routes'
import { ExitButtonWrapper } from './styled'

const ChangeExpiredPasswordModal: FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const needCheckExpiredPassword = useAppSelector(
    (state) => state.auth.needCheckExpiredPassword
  )

  const handleExitClick = useCallback(() => {
    dispatch(logout())
    dispatch(toggleNeedCheckExpiredPassword(false))
  }, [dispatch])

  const handleRestoreClick = useCallback(() => {
    dispatch(logout())
    dispatch(toggleNeedCheckExpiredPassword(false))
    navigate(Routes.AUTH + '?loginWithEmailRecovery=true')
  }, [dispatch, navigate])

  const handleSuccess = useCallback(() => {
    setTimeout(() => {
      window.location.reload()
    }, 500)
  }, [])

  if (!needCheckExpiredPassword) return null

  return (
    <Modal visible={needCheckExpiredPassword} withoutClose>
      <EditPasswordTemplate
        customFullTitle="Для продолжения работы необходима смена пароля"
        onSuccess={handleSuccess}
      />
      <ExitButtonWrapper>
        <GrayButton onClick={handleRestoreClick}>
          Восстановить пароль
        </GrayButton>
        <DeleteButton
          onClick={handleExitClick}
          CustomComponent={({ onClick }) => (
            <CloseButton onClick={onClick}>Выйти</CloseButton>
          )}
        />
      </ExitButtonWrapper>
    </Modal>
  )
}

export default ChangeExpiredPasswordModal
