import styled from '@emotion/styled'
import { isMobile } from '@const/device'

export const TopLine = styled.div`
  display: flex;
`

const DesktopInputWrapper = styled.div`
  width: 300px;
  margin-bottom: 16px;
`
const MobileInputWrapper = styled(DesktopInputWrapper)`
  width: auto;
`
export const InputWrapper = isMobile ? MobileInputWrapper : DesktopInputWrapper

export const AvatarWrapper = styled.div`
  margin-right: ${({ theme }) => theme.sizes.twoBase};
`

const DesktopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px 20px;
`
const MobileWrapper = styled(DesktopWrapper)`
  padding: 0;
`
export const Wrapper = isMobile ? MobileWrapper : DesktopWrapper
