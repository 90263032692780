import {
  RiskMapDangersFieldNames,
  SEQUENCE_ARR,
} from '@modals/RiskMapModal/Dangers/utils/getFilters'

const isEmptyForm = (
  values: Partial<Record<RiskMapDangersFieldNames, boolean>>
): boolean => {
  let isEmpty = true

  for (const fieldName of SEQUENCE_ARR) {
    if (values[fieldName]) {
      isEmpty = false
    }
  }

  return isEmpty
}

export default isEmptyForm
