import { FC } from 'react'
import { Tab } from '@organs/Tabs'
import Prompt from '@organisms/Prompt'
import { RiskSignificanceResponse } from '@models/riskMap'
import { SignificanceWrapper } from './styled'

const getValueTitle = (value: number) => {
  if (value >= 15) {
    return `ВЫСОКИЙ (${value})`
  } else if (value >= 4) {
    return `СРЕДНИЙ (${value})`
  } else {
    return `НИЗКИЙ (${value})`
  }
}

type Props = {
  children: RiskSignificanceResponse
}

const RiskSignificance: FC<Props> = ({ children }) => {
  if (!children) return null

  const { value, color, description } = children

  return (
    <div style={{ display: 'flex' }}>
      <SignificanceWrapper id="risk-map-significance">
        <Tab
          id="id"
          tabSize="small"
          title={getValueTitle(value)}
          badgeColor={color}
          withoutHover
        />
        <Prompt
          promptText={
            <div
              style={{ maxWidth: 500 }}
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          }
        />
      </SignificanceWrapper>
    </div>
  )
}

export default RiskSignificance
