import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import useFormProcessor from '@hooks/useFormProcessor'
import {
  useCreateDivisionMutation,
  useUpdateDivisionMutation,
} from '@services/divisions'
import useMe from '@hooks/useMe'
import { CommonDivisionBody, DivisionDetailed } from '@models/division'
import AddNewItemModal from '@templates/AddNewItemModal'
import { Option } from '@organisms/Select'
import CuratorsMultilineFields from '@templates/CuratorsMultilineFields'
import useSelectedValues from '@modals/DivisionModal/useSelectedValues'
import OnboardingCodes from '@enums/OnboardingCodes'
import RiskMemberMultilineFields from '@templates/RiskMemberMultilineFields'
import { User } from '@models/user'
import { getMultilineValue } from '@utils/form'
import useDivisionFields from './useDivisionFields'
import getDefaultValues from './getDefaultValues'
import Footer from './Footer'
import { ONBOARDING_STEPS, ONBOARDING_TITLE } from './onboarding'

export type FormValues = {
  curators: Option[]
  company: Option
  filial: Option
  title: string
  initialBriefing?: Option
  initiatoryBriefing?: Option
  safeProgram?: Option
  responsibleOfProbation?: Option
  responsibleOfSafeProgram?: Option
  riskMapCommissionMembers?: Array<User & Option>
}

type Props = {
  isEdit?: boolean
  handleCloseModal: () => void
  division?: DivisionDetailed
}

const DivisionModal: FC<Props> = ({ isEdit, handleCloseModal, division }) => {
  const { state } = useLocation()
  const { curatorCompanyBranches } = useMe()

  const [create, createData] = useCreateDivisionMutation()
  const [update, updateData] = useUpdateDivisionMutation()
  const additionalProps = isEdit ? updateData : createData

  const methods = useFormProcessor({
    apiErrors: additionalProps.error,
    defaultValues: getDefaultValues(state || division),
  })

  const fields = useDivisionFields(methods)

  const handleSubmitForm = (form: FormValues) => {
    const commonForm: CommonDivisionBody = {
      companyBranch: form.filial?.value || curatorCompanyBranches?.[0]?.['@id'],
      curators: getMultilineValue(form.curators),
      title: form.title,
      initialBriefing: form.initialBriefing?.value,
      initiatoryBriefing: form.initiatoryBriefing?.value,
      safeProgram: form.safeProgram?.value,
      responsibleOfProbation: form.responsibleOfProbation?.value,
      responsibleOfSafeProgram: form.responsibleOfSafeProgram?.value,
      riskMapCommissionMembers: getMultilineValue(
        form.riskMapCommissionMembers
      ),
    }

    if (isEdit) {
      if (division) {
        update({
          ...commonForm,
          id: division.id,
        })
      }
    } else {
      create(commonForm)
    }
  }

  const { selectedCompany } = useSelectedValues(methods)

  return (
    <AddNewItemModal
      {...additionalProps}
      CustomFooter={isEdit ? undefined : Footer}
      titlePostfix="подразделение"
      fields={fields}
      onSubmitForm={handleSubmitForm}
      methods={methods}
      withSaveAndAddMore
      contentAfterFields={
        <>
          <CuratorsMultilineFields
            curatorFieldLabel="Куратор подразделения"
            initValues={{ company: selectedCompany }}
          />
          <RiskMemberMultilineFields />
        </>
      }
      handleCloseModal={handleCloseModal}
      isEdit={isEdit}
      withoutAddMore
      onboarding={{
        steps: ONBOARDING_STEPS,
        firstStepTitle: ONBOARDING_TITLE,
        code: OnboardingCodes.subdivision_create,
        withScroll: true,
        withFeedback: true,
      }}
    />
  )
}

export default DivisionModal
