import { FC, useEffect } from 'react'
import { useGetPositionQuery } from '@services/positions'
import PositionModal from '@modals/PositionModal'
import Loader from '@organisms/Loader'
import AccordionBodyFieldWithTabs from '@templates/AccordionBodyFieldWithTabs'
import EmployeesCountField from '@templates/EmployeesCountField'

type Props = {
  id: number
  onSuccess?: () => void
}

const PositionListItem: FC<Props> = ({ id, onSuccess }) => {
  const { data, isLoading, isSuccess } = useGetPositionQuery(id)
  const {
    title = '',
    countEmployees = 0,
    company,
    companyBranch,
    subdivision,
  } = data || {}

  useEffect(() => {
    if (isSuccess) {
      onSuccess?.()
    }
  }, [isSuccess])

  return (
    <Loader isLoading={isLoading}>
      <AccordionBodyFieldWithTabs
        label="Должность"
        items={[
          {
            id: '1',
            title,
            editModal: {
              Component: PositionModal,
              props: {
                position: data,
              },
            },
          },
        ]}
      />
      <EmployeesCountField
        countEmployees={countEmployees}
        company={
          company
            ? {
                label: company.title,
                value: company['@id'],
              }
            : undefined
        }
        filial={
          companyBranch
            ? {
                label: companyBranch.title,
                value: companyBranch['@id'],
              }
            : undefined
        }
        division={
          subdivision
            ? {
                label: subdivision.title,
                value: subdivision['@id'],
              }
            : undefined
        }
        position={
          data
            ? {
                label: data.title,
                value: data['@id'],
              }
            : undefined
        }
        isAccordionField
      />
    </Loader>
  )
}

export default PositionListItem
