import { Skeleton } from 'antd'
import { FC } from 'react'
import { NavigateFunction } from 'react-router-dom'
import useMouseOverOut from '@hooks/useMouseOverOut'
import { Routes } from '@enums/Routes'
import { Counter, StyledTitle, Wrapper } from './styled'

type Props = {
  index: number
  title: string
  counter?: number
  selected: number
  isLoading?: boolean
  route?: Routes
  navigate?: NavigateFunction
}

const Title: FC<Props> = ({
  index,
  title,
  counter,
  selected,
  isLoading,
  route,
  navigate,
}) => {
  const [hovered, , handleMouseEnter, handleMouseLeave] = useMouseOverOut()

  const isSelected = selected === index || hovered

  const handleClick = () => {
    if (route && navigate) {
      navigate(route)
    }
  }

  if (isLoading) {
    return (
      <Skeleton.Button
        active
        style={{
          height: 30,
          width: 200,
          marginTop: 7,
          marginRight: 12,
          borderRadius: 50,
        }}
      />
    )
  }

  return (
    <Wrapper
      onClick={handleClick}
      selected={isSelected}
      withCounter={!!counter && counter > 0}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <StyledTitle>{title}</StyledTitle>
      {counter != null && counter >= 0 && (
        <Counter selected={isSelected}>{counter}</Counter>
      )}
    </Wrapper>
  )
}

export type { Props as TitleProps }
export default Title
