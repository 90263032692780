import { FC } from 'react'
import useCourses from '@hooks/useCourses'
import useMe from '@hooks/useMe'
import useThemesCoursesTabs from '@hooks/useThemesCoursesTabs'
import useFormProcessor from '@hooks/useFormProcessor'
import ListPage from '@templates/ListPage'
import { ALPHABETICAL_SORTER } from '@const/sorters'
import CourseModal from '@modals/CourseModal'
import EditableCourseItem from './EditableCourseItem'
import useCoursesFilters from './useCoursesFilters'
import CourseItem from 'src/components/pages/Courses/CourseItem'

const Courses: FC = () => {
  const methods = useFormProcessor()
  const { isAdmin } = useMe()

  const filters = useCoursesFilters(methods)

  const { data: coursesData, ...rest } = useCourses()
  const { items = [], length = 0 } = coursesData || {}
  const titleTabs = useThemesCoursesTabs({ coursesLength: length })

  return (
    <ListPage
      {...rest}
      methods={methods}
      ModalContent={isAdmin ? CourseModal : undefined}
      ItemComponent={isAdmin ? EditableCourseItem : CourseItem}
      selectedTab={1}
      titleTabs={titleTabs}
      filters={filters}
      list={items}
      length={length}
      withSearch
      sorters={[ALPHABETICAL_SORTER]}
    />
  )
}

export default Courses
