import styled from '@emotion/styled'
import { isMobile } from '@const/device'

const DesktopWrapper = styled.div`
  width: 60vw;
  padding: ${({ theme }) => theme.sizes.twoBase};
`
const MobileWrapper = styled(DesktopWrapper)`
  width: 80vw;
`
export const Wrapper = isMobile ? MobileWrapper : DesktopWrapper
