import { FC } from 'react'
import useFormProcessor from '@hooks/useFormProcessor'
import {
  usePostCourseChapterMutation,
  usePutCourseChapterMutation,
} from '@services/courseChapters'
import { CourseChapter, CourseChapterCommonBody } from '@models/courseChapter'
import AddNewItemModal from '@templates/AddNewItemModal'
import getDefaultValues from './getDefaultValues'
import useChapterFields from './useChapterFields'

export type FormValues = {
  title: string
  hoursCount: string
}

type Props = {
  isEdit?: boolean
  chapter?: CourseChapter
  courseIri: string
  handleCloseModal: () => void
}

const ChapterModal: FC<Props> = ({
  isEdit = false,
  chapter,
  courseIri,
  handleCloseModal,
}) => {
  const fields = useChapterFields()

  const [postCourseChapter, postData] = usePostCourseChapterMutation()
  const [putCourseChapter, putData] = usePutCourseChapterMutation()
  const additionalProps = isEdit ? putData : postData

  const methods = useFormProcessor({
    defaultValues: getDefaultValues(chapter),
    apiErrors: additionalProps.error,
  })

  const handleSubmitForm = (form: FormValues) => {
    const resultForm: CourseChapterCommonBody = {
      course: courseIri,
      hoursCount: +form.hoursCount,
      title: form.title,
    }

    if (isEdit) {
      if (chapter) {
        void putCourseChapter({
          ...resultForm,
          id: chapter.id,
        })
      }
    } else {
      void postCourseChapter(resultForm)
    }
  }

  return (
    <AddNewItemModal
      {...additionalProps}
      handleCloseModal={handleCloseModal}
      onSubmitForm={handleSubmitForm}
      methods={methods}
      fields={fields}
      isEdit={isEdit}
      titlePostfix="раздел"
      successContentAddTitle="Вы успешно добавили раздел"
      successContentEditTitle="Вы успешно изменили раздел"
      withSaveAndAddMore
      withStopPropagation
    />
  )
}

export default ChapterModal
