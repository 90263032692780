import * as React from 'react'
import { FC, ReactNode, useEffect, useState } from 'react'
import {
  useGetEmployeeEducationDestinationQuery,
  useLazyGetDestinationReportQuery,
} from '@services/employeeEducationDestination'
import useDownloadCertificate from '@hooks/useDownloadCertificate'
import { formatServerDate } from '@utils/date'
import { EmployeeEducationStat } from '@models/employeeEducationStats'
import { User } from '@models/user'
import { ProgressStatuses } from '@enums/ProgressStatuses'
import { TestResult } from '@models/statistic'
import Table from '@organisms/Table'
import Loader from '@organisms/Loader'
import { Tab } from '@organisms/Tabs'
import DownloadButton from '@organisms/DownloadButton'
import AccordionBodyField from '@organisms/AccordionBodyField'
import Title from '@organisms/Title'
import useMe from '@hooks/useMe'
import { Footer, ProgressWrapper, StyledSubtitle, StyledTitle } from './styled'
import { getTableData } from './utils'

const COLUMNS = [
  {
    title: 'Дата',
    dataIndex: 'date',
    size: 250,
  },
  {
    title: 'Длительность',
    dataIndex: 'duration',
  },
  {
    title: 'Результат',
    dataIndex: 'result',
  },
]

export type RowItem = Record<'date' | 'duration' | 'result', ReactNode> & {
  key: string
}

type Props = {
  user: User
  stats: EmployeeEducationStat[]
}

const StatisticTemplate: FC<Props> = ({ user, stats = [] }) => {
  const { isEmployee } = useMe()
  const [selected, setSelected] = useState(stats[0])
  const [selectedBottom, setSelectedBottom] = useState(
    selected?.employeeEducationDestinations[0]
  )

  useEffect(() => {
    setSelectedBottom(selected?.employeeEducationDestinations[0])
  }, [selected])

  const [getCourseReport, { isLoading: getCourseReportIsLoading }] =
    useLazyGetDestinationReportQuery()

  const { data, isLoading } = useGetEmployeeEducationDestinationQuery(
    selectedBottom?.id,
    {
      skip: !selectedBottom,
    }
  )
  const testResults = data?.testResults || ([] as TestResult[])
  const tableData = getTableData(testResults)

  const { getCert, isLoading: certificateIsLoading } = useDownloadCertificate()

  const handleDownloadCertificate = () => {
    if (data?.educationDestination) {
      getCert({
        educationId: selectedBottom.id,
        courseId: selected.id,
        userId: user.id,
      })
    }
  }

  const handleDownloadReport = () => {
    void getCourseReport(selectedBottom.id)
  }

  if (stats.length === 0) {
    return (
      <>
        <StyledTitle>{user.fullName}</StyledTitle>
        <Title mySize="h2">Обучения не найдены</Title>
      </>
    )
  }

  return (
    <>
      <StyledTitle>{user.fullName}</StyledTitle>
      <ProgressWrapper>
        {stats.map((item) => (
          <Tab
            id="id"
            tabSize="small"
            onClick={() => setSelected(item)}
            isActive={selected?.title === item.title}
            title={item.title}
            children={null}
            key={item.title}
          />
        ))}
      </ProgressWrapper>
      <ProgressWrapper withBottomMargin>
        {selected?.employeeEducationDestinations.map((item) => (
          <Tab
            id="id"
            tabSize="small"
            onClick={() => setSelectedBottom(item)}
            isActive={selectedBottom?.id === item.id}
            title={formatServerDate(item.educationDestination.createdAt)}
            badgeColor={
              item.currentStatus === ProgressStatuses.PASSED
                ? 'green'
                : item.currentStatus === ProgressStatuses.IN_PROCESS
                ? 'orange'
                : 'gray'
            }
            children={null}
            key={item.id}
          />
        ))}
      </ProgressWrapper>
      {selectedBottom && (
        <Loader isLoading={isLoading}>
          <StyledSubtitle>Ведомость</StyledSubtitle>
          <AccordionBodyField
            label="Дата регистрации"
            value={
              user.registeredAt
                ? formatServerDate(user.registeredAt)
                : undefined
            }
          />
          <AccordionBodyField
            label="Курс"
            value={selected.title}
            withoutFixedHeight
          />
          <AccordionBodyField
            label="процент изученного материала"
            value={
              selectedBottom?.progress >= 0
                ? selectedBottom?.progress + ' %'
                : undefined
            }
          />
          <AccordionBodyField
            label="количество изученных тем"
            value={
              selectedBottom?.passedThemesCount >= 0 &&
              selectedBottom?.totalThemesCount >= 0
                ? (selectedBottom?.passedThemesCount || 0) +
                  ' из ' +
                  selectedBottom?.totalThemesCount
                : undefined
            }
          />
          {tableData.length > 0 && (
            <>
              <StyledSubtitle>Итоговое тестирование</StyledSubtitle>
              <Table data={tableData} columns={COLUMNS} />
            </>
          )}
          {selectedBottom?.progress === 100 && (
            <Footer>
              <DownloadButton
                onClick={handleDownloadCertificate}
                isLoading={certificateIsLoading}
              >
                Сохранить сертификат
              </DownloadButton>
              {!isEmployee && (
                <DownloadButton
                  onClick={handleDownloadReport}
                  isLoading={getCourseReportIsLoading}
                >
                  Сохранить отчёт
                </DownloadButton>
              )}
            </Footer>
          )}
        </Loader>
      )}
    </>
  )
}

export default StatisticTemplate
