import { UseFormReturn } from 'react-hook-form'
import { compact } from 'lodash'
import useJobFields from '@hooks/useJobFields'
import { Fields } from '@templates/AddNewItemModal/Content'
import { EmployeeSelectFormValues } from './index'

const useEmployeeSelectFields = (
  methods: UseFormReturn,
  initValues?: EmployeeSelectFormValues
): Fields => {
  const {
    company: initCompany,
    filial: initFilial,
    position: initPosition,
    division: initDivision,
  } = initValues || {}
  const { company, division, filial, position } = useJobFields(methods)

  return [
    {
      fields: compact([
        !initCompany && company?.(),
        !initFilial && filial?.(),
        !initDivision && division?.(),
        !initPosition && position?.(),
      ]),
    },
  ]
}

export default useEmployeeSelectFields
