import { FC, memo, MouseEventHandler } from 'react'
import { ReactComponent as GoldStar } from '@interface-images/gold-star.svg'
import { ReactComponent as GrayStar } from '@interface-images/gray-star.svg'
import { Wrapper } from './styled'

type Props = {
  isActive?: boolean
  onMouseLeave?: MouseEventHandler
  onMouseEnter?: MouseEventHandler
  onClick?: MouseEventHandler
}

const Star: FC<Props> = ({
  isActive = false,
  onMouseLeave,
  onMouseEnter,
  onClick,
}) => {
  return (
    <Wrapper
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      {isActive ? <GoldStar /> : <GrayStar />}
    </Wrapper>
  )
}

export default memo(Star)
