import * as React from 'react'
import { FC } from 'react'
import { notification } from 'antd'
import { ReactComponent as InfoIcon } from '@interface-images/info-orange.svg'
import { isMobile } from '@const/device'
import { NotificationOption } from '@models/notification'
import { formatServerDate } from '@utils/date'
import { CopyOutlined } from '@ant-design/icons'
import {
  CopyLinkWrapper,
  DateTimeWrapper,
  InfoWrapper,
  LeftWrapper,
  MobileWrapper,
  TextContainer,
  TextWrapper,
  Wrapper,
} from './styled'

const ICON_SIZE = 15

type Props = {
  notification: NotificationOption
}

const Notification: FC<Props> = ({
  notification: { text, createdAt, link, notificationCode },
}) => {
  const WrapperComponent = isMobile ? MobileWrapper : Wrapper
  const hasCopyLink =
    notificationCode === 'curator_user_request_password_recovery_link'

  const handleClick = () => {
    if (link && !hasCopyLink) {
      window.open(link)
    }
  }

  const handleCopyLinkClick = () => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        notification.success({
          message:
            'Ссылка скопирована в буфер обмена! Отправьте ее сотруднику удобным способом.',
        })
      })
      .catch(() => {
        notification.error({ message: 'Что-то пошло не так.' })
      })
  }

  return (
    <WrapperComponent onClick={handleClick}>
      <LeftWrapper>
        <InfoWrapper>
          <InfoIcon width={ICON_SIZE} height={ICON_SIZE} />
        </InfoWrapper>
        <TextContainer>
          <TextWrapper>
            <div dangerouslySetInnerHTML={{ __html: text }}></div>
          </TextWrapper>
          {notificationCode ===
            'curator_user_request_password_recovery_link' && (
            <CopyLinkWrapper onClick={handleCopyLinkClick}>
              <CopyOutlined />
              Ссылка на восстановление пароля
            </CopyLinkWrapper>
          )}
          <DateTimeWrapper>{formatServerDate(createdAt, true)}</DateTimeWrapper>
        </TextContainer>
      </LeftWrapper>
    </WrapperComponent>
  )
}

export default Notification
