import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  white-space: pre-line;
`

export const StyledMenuLink = styled.span<{ isActive: boolean }>`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: ${({ isActive }) => (isActive ? '#1D4E89' : '#7b7b7b')};
`

export const Xls = styled.span`
  color: white;
  text-transform: uppercase;
  font-family: Gilroy;
  font-size: 8px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  height: 20px;
  background: #1d4e89;
  border-radius: 8px;
`
