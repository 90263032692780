import { Fields } from '@templates/AddNewItemModal/Content'
import {
  emailField,
  phoneField,
  textField,
} from '@templates/AddNewItemModal/templates'

const useSimpleEmployeeFields = (): Fields => {
  return [
    {
      fields: [
        textField({
          label: 'ФИО',
          placeholder: 'Введите ФИО',
          name: 'fullName',
          isRequired: true,
        }),
        phoneField({}),
        emailField({
          label: 'Электронная почта',
        }),
      ],
    },
  ]
}

export default useSimpleEmployeeFields
