import styled from '@emotion/styled'

export const Wrapper = styled.div`
  position: absolute;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background-color: #1d4e89;
  font-weight: 400;
  font-size: 7px;
  line-height: 7px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: -3px;
`
