import styled from '@emotion/styled'

export const StyledLabel = styled.label<{ hovered: boolean }>`
  margin-top: 10px;
  cursor: pointer;
  color: ${({ hovered, theme }) => (hovered ? theme.colors.main : 'black')};
  font-weight: ${({ hovered }) => (hovered ? 600 : 'normal')};
`

export const BadgeWrapper = styled.div`
  padding-top: 13px;
`
