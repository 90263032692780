import { Option } from '@organisms/Select'
import { CourseTypes } from '@models/course'

const COURSE_TYPES: Option[] = [
  {
    value: CourseTypes.TRAINING,
    label: 'Повышение квалификации',
  },
  {
    value: CourseTypes.PROFESSIONAL_RETRAINING,
    label: 'Профессиональная переподготовка',
  },
  {
    value: CourseTypes.MINI,
    label: 'Мини-курс',
  },
  {
    value: CourseTypes.LABOR_PROTECTION_TRAINING,
    label: 'Обучение по охране труда',
  },
]

export default COURSE_TYPES
