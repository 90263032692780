import { PositionDetailed } from '@models/position'
import { FormValues } from './index'

const getDefaultValues = (
  position?: PositionDetailed
): Partial<FormValues> | void => {
  if (!position) return

  const result: Partial<FormValues> = {
    title: position.title
      ? {
          label: position.title,
          value: position.title,
        }
      : undefined,
  }

  if (position.company) {
    result.company = {
      ...position.company,
      value: position.company['@id'],
      label: position.company.title,
    }
  }

  if (position.companyBranch) {
    result.filial = {
      ...position.companyBranch,
      value: position.companyBranch['@id'],
      label: position.companyBranch.title,
    }
  }

  if (position.subdivision) {
    result.division = {
      ...position.subdivision,
      value: position.subdivision['@id'],
      label: position.subdivision.title,
    }
  }

  return result
}

export default getDefaultValues
