import { FC } from 'react'

const NewPasswordPrompt: FC = () => {
  return (
    <div>
      <h4>Для защиты ваших данных необходимо придумать безопасный пароль.</h4>
      <h4>Он должен содержать:</h4>
      <ul>
        <li>8 или более символов</li>
        <li>Прописные латинские буквы</li>
        <li>Строчные заглавные буквы</li>
        <li>Цифры</li>
        <li>{'Знаки пунктуации ! # $ % & * + , . / : ; < > ? @ { }'}</li>
      </ul>
    </div>
  )
}

export default NewPasswordPrompt
