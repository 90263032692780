import { JoyStep } from '@templates/Onboarding'

export const ONBOARDING_TITLE = 'Расскажем про прохождение темы'

const TITLE = 'Прохождение темы'

export const ONBOARDING_STEPS: JoyStep[] = [
  {
    target: '#scormFrame',
    title: TITLE,
    content:
      'Здесь будет отображаться содержимое темы или итогового тестирования',
  },
  {
    target: '#expandButton',
    title: TITLE,
    content: 'Вы можете развернуть содержимое темы на весь экран',
  },
  {
    target: '#gradeBlock',
    title: TITLE,
    content: 'Вы можете поставить оценку теме',
  },
  {
    target: '#next',
    title: TITLE,
    content: 'Чтобы перейти к следующей кнопке, нажмите “Вперёд”',
  },
  {
    target: '#themeContainer',
    title: TITLE,
    content: 'Желаем вам продуктивного и приятного обучения',
  },
]
