import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import useMe from '@hooks/useMe'
import { Filial } from '@models/filial'
import InfoField from '@organisms/InfoField'
import { Routes } from '@enums/Routes'

type Props = {
  filial: Filial
}

const FilialInfoField: FC<Props> = ({ filial }) => {
  const { isAdmin, isCompanyCurator } = useMe()
  const navigate = useNavigate()

  const handleOpen = async () => {
    navigate(Routes.FILIALS + `?id=${filial.id}`)
  }

  if (isAdmin || isCompanyCurator) {
    return (
      <InfoField
        onTabClick={handleOpen}
        label="Филиал"
        value={filial.title}
        withStopPropagation={false}
      />
    )
  }

  return null
}

export default FilialInfoField
