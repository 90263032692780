import * as React from 'react'
import { FC } from 'react'
import { FormProvider } from 'react-hook-form'
import useJobFields from '@hooks/useJobFields'
import useFormProcessor from '@hooks/useFormProcessor'
import { useGetFilialQuery } from '@services/filials'
import useMe from '@hooks/useMe'
import { formatServerDate } from '@utils/date'
import Field from '@templates/Field'
import Loader from '@organisms/Loader'
import ContentBlock from '@organisms/ContentBlock'
import AccordionBodyField from '@organisms/AccordionBodyField'
import { StyledTitle } from './styled'

const TariffItem: FC = () => {
  const { curatorCompanyBranches } = useMe()
  const methods = useFormProcessor()
  const { filial } = useJobFields(methods)
  const filialValue = methods.watch('filial')
  const { data, isLoading } = useGetFilialQuery(
    filial ? filialValue?.id : curatorCompanyBranches?.[0]?.id,
    {
      skip: filial ? !filialValue : !curatorCompanyBranches,
    }
  )

  const {
    title,
    subscriptionEndAt,
    subscriptionStartAt,
    usedSubscriptionsCount = 0,
    subscriptionsCount = 0,
  } = data || {}

  return (
    <FormProvider {...methods}>
      <ContentBlock height={filial ? 500 : 400}>
        <Loader isLoading={isLoading}>
          <StyledTitle>Тариф</StyledTitle>
          {filial && <Field {...filial({ label: 'Выбрать филиал' })} />}
          {data && (
            <>
              <AccordionBodyField label="Наименование филиала" value={title} />
              <AccordionBodyField
                label="Дата начала"
                value={
                  subscriptionStartAt
                    ? formatServerDate(subscriptionStartAt)
                    : undefined
                }
              />
              <AccordionBodyField
                label="Дата окончания"
                value={
                  subscriptionEndAt
                    ? formatServerDate(subscriptionEndAt)
                    : undefined
                }
              />
              <AccordionBodyField
                label="Количество лицензий"
                value={subscriptionsCount}
              />
              <AccordionBodyField
                label="Количество использованных лицензий"
                value={usedSubscriptionsCount}
              />
              <AccordionBodyField
                label="Количество доступных лицензий"
                value={subscriptionsCount - usedSubscriptionsCount}
              />
            </>
          )}
        </Loader>
      </ContentBlock>
    </FormProvider>
  )
}

export default TariffItem
