import { Option } from '@organisms/Select'
import InjuryPossibilities from '@enums/InjuryPossibilities'

const INJURY_POSSIBILITIES: Option[] = [
  {
    label: 'Очень редко',
    value: InjuryPossibilities.ONE,
  },
  {
    label: 'Маловероятно',
    value: InjuryPossibilities.TWO,
  },
  {
    label: 'Может быть',
    value: InjuryPossibilities.THREE,
  },
  {
    label: 'Вероятно',
    value: InjuryPossibilities.FOUR,
  },
  {
    label: 'Почти наверняка',
    value: InjuryPossibilities.FIVE,
  },
]

export default INJURY_POSSIBILITIES
