import styled from '@emotion/styled'
import { isMobile } from '@const/device'

export const CloseFiltersBlock = styled.div`
  display: flex;
  margin: 3px 10px 10px 5px;
  align-items: center;
`

export const StyledContentBlock = styled.div`
  padding: 10px 15px 5px;
  border-radius: 24px;
  position: relative;

  box-shadow: 5px 5px 20px rgba(29, 78, 137, 0.1);
  width: 100%;

  background: ${({ theme }) => theme.colors.background.surface};
  margin-bottom: ${({ theme }) => theme.sizes.twoBase};
`

export const ArrowWrapper = styled.div`
  margin-right: 5px;
`

const DesktopFiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 10px;
`
const MobileFiltersWrapper = styled(DesktopFiltersWrapper)`
  flex-direction: column;
`
export const FiltersWrapper = isMobile
  ? MobileFiltersWrapper
  : DesktopFiltersWrapper
