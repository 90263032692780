import { FC } from 'react'
import { useLazyGetCourseByIdQuery } from '@services/courses'
import { Course } from '@models/course'
import AccordionBodyFieldWithTabs from '@templates/AccordionBodyFieldWithTabs'
import ModalContent from '@modals/CourseModal'

type Props = {
  course: Course
}

const CourseAccordionField: FC<Props> = ({ course }) => {
  const [getCourse, { data, isLoading }] = useLazyGetCourseByIdQuery()

  const handleOpen = async () => {
    await getCourse(course.id)
  }

  return (
    <AccordionBodyFieldWithTabs
      withoutFixedHeight
      label="курс"
      items={[
        {
          id: '1',
          isLoading,
          title: course?.title,
          editModal: {
            Component: ModalContent,
            props: {
              course: data,
            },
            onOpen: handleOpen,
          },
        },
      ]}
    />
  )
}

export default CourseAccordionField
