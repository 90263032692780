import { FC } from 'react'
import { useToggle } from 'react-use'
import { CourseThemeListItemServerResponse } from '@models/courseTheme'
import Link, { LinkStyle } from '@organisms/Link'
import Modal from '@organisms/Modal'
import ThemeModal from '@modals/ThemeModal'
import { useLazyGetCourseThemeByIdQuery } from '@services/courseThemes'

type Props = {
  theme: CourseThemeListItemServerResponse
  chapterIri: string
}

const UpdateThemeLink: FC<Props> = ({ theme, chapterIri }) => {
  const [visible, toggleVisible] = useToggle(false)

  const [getCourseTheme, { data, isSuccess }] = useLazyGetCourseThemeByIdQuery()

  const handleEditClick = () => {
    toggleVisible(true)
    void getCourseTheme(theme?.id)
  }

  return (
    <>
      <Link
        onClick={handleEditClick}
        text="Изменить"
        linkStyle={LinkStyle.DEFAULT}
        fontSize={10}
      />
      <Modal visible={visible && isSuccess} onModalClose={toggleVisible}>
        <ThemeModal
          chapterIri={chapterIri}
          handleCloseModal={toggleVisible}
          isEdit
          theme={data}
        />
      </Modal>
    </>
  )
}

export default UpdateThemeLink
