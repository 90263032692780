import * as React from 'react'
import { FC, useState } from 'react'
import { EmployeeEducationStat } from '@models/employeeEducationStats'
import Tabs from '@organisms/Tabs'
import Destinations from './Destinations'

type Props = {
  stats: EmployeeEducationStat[]
  onChangeSize: () => void
}

const Study: FC<Props> = ({ stats, onChangeSize }) => {
  const [active, setActive] = useState(0)

  return (
    <Tabs
      onTabChange={onChangeSize}
      active={active}
      setActive={setActive}
      tabSize="small"
      items={stats.map((stat) => ({
        id: stat['@id'],
        title: stat.title,
        children: (
          <Destinations
            employeeEducationDestinations={stat.employeeEducationDestinations}
            onChangeSize={onChangeSize}
          />
        ),
      }))}
    />
  )
}

export default Study
