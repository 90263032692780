import * as React from 'react'
import { FC } from 'react'
import { formatServerDate } from '@utils/date'
import AccordionBodyField from '@organisms/AccordionBodyField'
import MarkEducationAsDoneButton from '@templates/MarkEducationAsDoneButton'
import {
  EducationDestination,
  EmployeeEducationDestination,
} from '@models/employeeEducationDestination'
import DeleteButton from '@templates/DeleteButton'
import {
  useDeleteEmployeeEducationDestinationMutation,
  useGetEmployeeEducationDestinationQuery,
} from '@services/employeeEducationDestination'
import { DeleteWrapper } from './styled'

type Props = {
  educationDestination: EducationDestination
  onChangeSize: () => void
  totalTime: string | number
  progress: number
  passedThemesCount: number
  totalThemesCount: number
  employeeEducationDestination: EmployeeEducationDestination
}

const DestinationsDates: FC<Props> = ({
  educationDestination,
  onChangeSize,
  totalTime,
  progress,
  passedThemesCount,
  totalThemesCount,
  employeeEducationDestination,
}) => {
  const { data } = useGetEmployeeEducationDestinationQuery(
    employeeEducationDestination?.id,
    {
      skip: !employeeEducationDestination?.id,
    }
  )
  const [deleteEmployeeEducationDestination, deleteData] =
    useDeleteEmployeeEducationDestinationMutation()

  return (
    <>
      <AccordionBodyField
        label="Примечание"
        value={data?.externalFilePassed ? 'Пройдено вне платформы' : undefined}
      />
      <AccordionBodyField
        label="Дата окончания"
        value={
          educationDestination.deadlineAt
            ? formatServerDate(educationDestination.deadlineAt)
            : undefined
        }
      />
      <AccordionBodyField label="Общее время в системе" value={totalTime} />
      <AccordionBodyField
        label="Процент изученного материала"
        value={progress + '%'}
      />
      <AccordionBodyField
        label="Количество изученных модулей"
        value={`${passedThemesCount || 0} из ${totalThemesCount}`}
      />
      <MarkEducationAsDoneButton
        onSuccess={onChangeSize}
        employeeEducationDestination={employeeEducationDestination}
      />
      <DeleteWrapper>
        <DeleteButton
          {...deleteData}
          onClick={() =>
            deleteEmployeeEducationDestination(employeeEducationDestination.id)
          }
          deleteDescription="Вы уверены, что хотите удалить обучение у этого сотрудника? Статистика прохождения будет тоже удалена."
        >
          Удалить обучение
        </DeleteButton>
      </DeleteWrapper>
    </>
  )
}

export default DestinationsDates
