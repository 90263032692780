import { FC } from 'react'
import { RiskMap } from '@models/riskMap'
import RiskMapStatuses from '@enums/RiskMapStatuses'
import Archive from './Archive'
import Reviewed from './Reviewed'
import NeedReview from './NeedReview'

type Props = {
  item: RiskMap
}

const ActionButton: FC<Props> = ({ item }) => {
  const inArchive = item.status === RiskMapStatuses.ARCHIVED
  if (inArchive) {
    return <Archive />
  }

  const isReviewed = item.employeeReviewedAt
  if (isReviewed) {
    return <Reviewed />
  }

  if (!item.lastSendForReviewRevision) {
    return null
  }

  return <NeedReview revision={item.lastSendForReviewRevision['@id']} />
}

export default ActionButton
