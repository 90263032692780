import styled from '@emotion/styled'
import { isMobile } from '@const/device'
import Title from '@organisms/Title'

const DesktopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  gap: 25px;
  min-height: 90vh;
`
const MobileWrapper = styled(DesktopWrapper)`
  padding: 0;
`
export const Wrapper = isMobile ? MobileWrapper : DesktopWrapper

export const StyledTitle = styled(Title)`
  margin: 10px 0 20px;
`
