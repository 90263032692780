import { FC } from 'react'
import { components, SingleValueProps } from 'react-select'
import { isEmpty } from 'lodash'
import Badge from '@atoms/Badge'
import { Option } from '../index'
import { BadgeWrapper } from './styled'

const SingleValue: FC<SingleValueProps> = (props) => {
  const {
    selectProps: {
      value,
      isDisabled,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      withoutFillSelected,
    },
    innerProps,
    data,
  } = props
  const dataOption = data as Option
  const notSelected = isEmpty(value)

  let color = 'white'
  if (isDisabled) {
    color = '#C4C4C4'
  } else if (notSelected || withoutFillSelected) {
    color = 'black'
  }

  return (
    <components.SingleValue
      {...props}
      innerProps={{
        ...innerProps,
        style: {
          ...innerProps,
          whiteSpace: 'nowrap',
          color,
          display: 'flex',
        },
      }}
    >
      {dataOption.badgeColor && (
        <BadgeWrapper>
          <Badge badgeColor={dataOption.badgeColor} />
        </BadgeWrapper>
      )}
      {dataOption.label}
    </components.SingleValue>
  )
}

export default SingleValue
