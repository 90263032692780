import styled from '@emotion/styled'
import { isMobile } from '@const/device'
import Title from '@organisms/Title'
import Subtitle from '@organisms/Subtitle'

export const StyledSubtitle = styled(Subtitle)`
  margin-bottom: ${({ theme }) => theme.sizes.twoBase};
`

export const ProgressWrapper = styled.div<{ withBottomMargin?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  gap: 10px;
`

export const Footer = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.sizes.threeBase};
  flex-direction: ${() => (isMobile ? 'column' : 'row')};
  gap: 10px;
`

export const StyledTitle = styled(Title)`
  white-space: pre-wrap;
  margin-bottom: 15px;
`
