import { Dispatch, FC, SetStateAction } from 'react'
import { useToggle } from 'react-use'
import { TabsWrapper } from './styled'
import Tab, { TabProps } from './Tab'

export type TabsItem = Omit<TabProps, 'isActive' | 'onClick' | 'tabSize'>

const lastItem = { title: '...', id: 'last' }

type Props = {
  items: TabsItem[]
  tabSize?: 'big' | 'small'
  onTabChange?: (activeIndex: number) => void
  active: number
  setActive: Dispatch<SetStateAction<number>>
  withNoUnmount?: boolean
  withoutMaxItems?: boolean
}

const Tabs: FC<Props> = ({
  items,
  tabSize = 'big',
  onTabChange,
  active = 0,
  setActive,
  withNoUnmount = false,
  withoutMaxItems = false,
}) => {
  const maxItemsCount = withoutMaxItems ? 10_000 : 5

  const [expandedAll, toggleExpandedAll] = useToggle(
    items.length <= maxItemsCount
  )

  const itemsState =
    tabSize === 'small' && items.length >= maxItemsCount
      ? expandedAll
        ? [...items, lastItem]
        : [...items.slice(0, maxItemsCount - 1), lastItem]
      : items

  let childrens = <div>{items[active]?.children}</div>
  if (withNoUnmount) {
    childrens = (
      <>
        {items.map(({ children, title, id }, index) => (
          <div
            key={id || title || index}
            style={{ display: index === active ? 'block' : 'none' }}
          >
            {children}
          </div>
        ))}
      </>
    )
  }

  return (
    <div>
      <TabsWrapper>
        {itemsState.map((item, index) => (
          <Tab
            {...item}
            key={item.id || item.title || index}
            isActive={active === index}
            onClick={() => {
              if (item.id === lastItem.id) {
                toggleExpandedAll()

                return
              }

              setActive(index)
              onTabChange?.(index)
            }}
            tabSize={tabSize}
          />
        ))}
      </TabsWrapper>
      {childrens}
    </div>
  )
}

export { type Props as TabsProps }
export default Tabs
export { default as Tab } from './Tab'
