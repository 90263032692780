import { FC } from 'react'
import { components, IndicatorSeparatorProps } from 'react-select'
import { isEmpty } from 'lodash'

const IndicatorSeparator: FC<IndicatorSeparatorProps> = (props) => {
  const notSelected = isEmpty(props.selectProps.value)
  return (
    <components.IndicatorSeparator
      {...props}
      innerProps={{
        ...props.innerProps,
        style: {
          color: notSelected ? 'lightgray' : 'white',
        },
      }}
    />
  )
}

export default IndicatorSeparator
