import { isAfter, sub } from 'date-fns'
import { formatServerDate } from '@utils/date'

const getComponentConfig = (date: string, now = new Date()) => {
  const formattedDate = formatServerDate(date)
  const isExpired = isAfter(now, new Date(date))
  const willExpire = isAfter(now, sub(new Date(date), { days: 30 }))

  return {
    formattedDate,
    color: isExpired ? 'red' : willExpire ? 'orange' : 'green',
    promptText: isExpired
      ? 'Срок СИЗ истёк'
      : willExpire
      ? 'Срок СИЗ истекает менее чем через 30 дней'
      : undefined,
  }
}

export default getComponentConfig
