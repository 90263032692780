import {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useEffect,
} from 'react'
import { useFormContext } from 'react-hook-form'
import { isEmpty } from 'lodash'
import { createInitFilter } from '@utils/filters'
import Checkbox, { CheckboxProps } from '@organisms/Checkbox'
import useSearchParam from '@hooks/useSearchParam'
import { FilterComponentProps } from '..'

const CheckboxFilter: ForwardRefExoticComponent<
  FilterComponentProps<CheckboxProps> & RefAttributes<HTMLInputElement>
> = forwardRef(
  (
    {
      filter: { fieldName, fieldNameForSubmit, valueOnChecked = true, ...rest },
      value,
      onFilter,
      field,
    },
    ref
  ) => {
    const { setValue } = useFormContext()
    const param = useSearchParam(fieldName)

    const resultFieldName = fieldNameForSubmit || fieldName

    const handleChange = (checked: boolean) => {
      const newValue = checked ? valueOnChecked : undefined
      field.onChange?.(newValue)
      onFilter?.(createInitFilter(resultFieldName, newValue))
    }

    useEffect(() => {
      if (param) {
        onFilter(createInitFilter(fieldName, param))
      }
    }, [param])

    useEffect(() => {
      if (value) {
        setValue(fieldName, value)
      }
    }, [value])

    return (
      <Checkbox
        {...field}
        {...rest}
        ref={ref}
        value={!isEmpty(field.value)}
        placeholder="Пройти медосмотр до"
        onChange={handleChange}
      />
    )
  }
)

export default CheckboxFilter
