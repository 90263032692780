import { FC } from 'react'
import { StyledA } from './styled'

type Props = {
  email: string
}

const DisplayEmail: FC<Props> = ({ email }) => (
  <StyledA href={`mailto:${email}`}>{email}</StyledA>
)

export default DisplayEmail
