import { Dispatch, FC, ReactNode, SetStateAction } from 'react'
import { useToggle } from 'react-use'
import Title from '@organisms/Title'
import Table from '@organisms/Table'
import EditModal from '@templates/EditModal'
import DeleteButton from '@templates/DeleteButton'
import AddButton from '@organisms/AddButton'
import Modal from '@organisms/Modal'
import SimpleEmployeeModal from '@modals/SimpleEmployeeModal'
import { EmployeeDetailed } from '@models/employee'
import { CompanyGroupDetailed } from '@models/companyGroup'
import { AddWrapper, ContentWrapper, Wrapper } from './styled'

const COLUMNS = [
  {
    title: 'ФИО',
    dataIndex: 'fullName',
  },
  {
    title: 'Телефон',
    dataIndex: 'phone',
  },
  {
    title: 'Электронная почта',
    dataIndex: 'email',
  },
  {
    title: '',
    dataIndex: 'actions',
  },
]

type DataItem = {
  fullName: string
  phone: string
  email: string
  actions: ReactNode
  key: string
}

type Props = {
  isEdit: boolean
  companyGroup: CompanyGroupDetailed | undefined
  selectedCurators: EmployeeDetailed[]
  setSelectedCurators: Dispatch<SetStateAction<EmployeeDetailed[]>>
}

const CuratorsTable: FC<Props> = ({
  isEdit,
  companyGroup,
  setSelectedCurators,
  selectedCurators,
}) => {
  const [visible, toggleVisible] = useToggle(false)

  const tableData: DataItem[] = selectedCurators.map((item) => ({
    key: item['@id'],
    fullName: item.fullName || '',
    phone: item.phone || '',
    email: item.email || '',
    actions: (
      <Wrapper>
        <EditModal
          ModalContent={SimpleEmployeeModal}
          modalProps={{
            employee: item,
            setSelectedCurators,
          }}
        />
        <DeleteButton
          onClick={() =>
            setSelectedCurators((prev) =>
              prev.filter((curator) => curator['@id'] !== item['@id'])
            )
          }
        />
      </Wrapper>
    ),
  }))

  return (
    <ContentWrapper>
      <Title>Кураторы</Title>
      {tableData.length > 0 && <Table columns={COLUMNS} data={tableData} />}
      <AddWrapper>
        <AddButton onClick={toggleVisible} disabled={!isEdit} />
        <Modal visible={visible} onModalClose={toggleVisible}>
          <SimpleEmployeeModal
            companyGroup={companyGroup}
            handleCloseModal={toggleVisible}
            setSelectedCurators={setSelectedCurators}
          />
        </Modal>
      </AddWrapper>
    </ContentWrapper>
  )
}

export default CuratorsTable
