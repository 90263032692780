import { FC } from 'react'
import { Filial } from '@models/filial'
import Accordion, { AccordionHeaderProps } from '@organisms/Accordion'
import Title from '@organisms/Title'
import { ListItemProps } from '@templates/List'
import Body from './Body'

const FilialItem: FC<ListItemProps<Filial>> = ({
  isLoading,
  handleClickOnItem,
  opened,
  item: { title, id },
}) => {
  const header: FC<AccordionHeaderProps> = () => (
    <Title mySize="h4">{title}</Title>
  )

  return (
    <Accordion
      skeletonHeight={60}
      handleClickOnHeader={handleClickOnItem}
      expanded={opened}
      initLoading={isLoading}
      Header={header}
      Body={({ onChangeSize, toggleLoading }) => (
        <Body
          onChangeSize={onChangeSize}
          toggleLoading={toggleLoading}
          id={id}
        />
      )}
    />
  )
}

export default FilialItem
