import { FC } from 'react'
import useFormProcessor from '@hooks/useFormProcessor'
import { usePostScormMutation, usePutScormMutation } from '@services/scorms'
import { CommonScormBody, Scorm } from '@models/scorm'
import { File } from '@models/file'
import AddNewItemModal from '@templates/AddNewItemModal'
import useThemeFields from './useThemeFields'
import getDefaultValues from './getDefaultValues'

export type FormValues = {
  title: string
  file: File
}

type Props = {
  isEdit?: boolean
  theme?: Scorm
  handleCloseModal: () => void
}

const ThemeModal: FC<Props> = ({ isEdit = false, theme, handleCloseModal }) => {
  const [postCourseTheme, postData] = usePostScormMutation()
  const [putCourseTheme, putData] = usePutScormMutation()
  const additionalProps = isEdit ? putData : postData

  const methods = useFormProcessor({
    apiErrors: additionalProps.error,
    defaultValues: getDefaultValues(theme),
  })

  const fields = useThemeFields(methods)

  const handleSubmitForm = (form: FormValues) => {
    const commonForm: CommonScormBody = {
      file: form.file['@id'],
      title: form.title,
    }

    if (isEdit) {
      if (theme) {
        putCourseTheme({
          ...commonForm,
          id: theme.id,
        })
      }
    } else {
      postCourseTheme(commonForm)
    }
  }

  return (
    <AddNewItemModal
      {...additionalProps}
      onSubmitForm={handleSubmitForm}
      methods={methods}
      successContentAddTitle="Вы успешно добавили тему"
      successContentEditTitle="Вы успешно изменили тему"
      fields={fields}
      titlePostfix="тему"
      isEdit={isEdit}
      handleCloseModal={handleCloseModal}
      withSaveAndAddMore
    />
  )
}

export default ThemeModal
