import styled from '@emotion/styled'
import ContentBlock from '@atoms/ContentBlock'
import { isMobile } from '@const/device'

export const StyledContentBlock = styled(ContentBlock)`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const DesktopWrapper = styled.div`
  padding: 0 20px;
`
const MobileWrapper = styled.div`
  padding: 0;
`
export const Wrapper = isMobile ? MobileWrapper : DesktopWrapper
