import { FC } from 'react'
import RiskMemberMultilineFields from '@templates/RiskMemberMultilineFields'
import DivisionFields from './DivisionFields'
import InfoFields from './InfoFields'

type Props = {
  notDraft: boolean
}

const MainRisks: FC<Props> = ({ notDraft }) => {
  return (
    <div>
      <DivisionFields isDisabled={notDraft} />
      <InfoFields fileDisabled={notDraft} />
      <RiskMemberMultilineFields />
    </div>
  )
}

export default MainRisks
