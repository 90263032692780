import { FC } from 'react'
import { FooterProps } from '@templates/AddNewItemModalLayout/Footer'
import { Routes } from '@enums/Routes'
import JobsFooter from '@templates/JobsFooter'
import { CompanyDetailed } from '@models/company'
import { FilialDetailed } from '@models/filial'

const Footer: FC<FooterProps> = (props) => {
  return (
    <JobsFooter
      {...props}
      additionalProps={{
        nextRoute: Routes.FILIAL_MODAL,
        nextRouteDescription: 'Переход к созданию филиала',
        getNextState: (data: CompanyDetailed): Partial<FilialDetailed> => ({
          company: data,
        }),
      }}
    />
  )
}

export default Footer
