import * as React from 'react'
import { FC, useEffect } from 'react'
import { useMount } from 'react-use'
import { useGetScormByIdQuery } from '@services/scorms'
import EditModal from '@templates/EditModal'
import { AccordionBodyProps } from '@organisms/Accordion'
import ThemeModal from '@modals/ScormModal'
import DeleteScormButton from './DeleteScormButton'
import { Footer } from './styled'
import ThemeTable from './ThemeTable'

type Props = {
  id: string
} & AccordionBodyProps

const Body: FC<Props> = ({ id, onChangeSize, toggleLoading }) => {
  const { data, isSuccess } = useGetScormByIdQuery(id)

  useMount(() => {
    toggleLoading(true)
  })

  useEffect(() => {
    if (isSuccess) {
      toggleLoading(false)
      onChangeSize()
    }
  }, [isSuccess])

  if (!data) return null

  return (
    <>
      <ThemeTable courseChapterThemes={data.courseChapterThemes || []} />
      <Footer>
        <EditModal
          ModalContent={ThemeModal}
          modalProps={{
            theme: data,
          }}
        />
        <DeleteScormButton id={data.id} />
      </Footer>
    </>
  )
}

export default Body
