import { FC } from 'react'
import { add, isAfter, isBefore, sub } from 'date-fns'
import { BannerSettings, BannerSettingsCodes } from '@models/settings'
import { useGetBannerSettingsByCodeQuery } from '@services/settings'
import useMe from '@hooks/useMe'
import Greetings from '../Greetings'
import Interview from './Interview'
import BodyCheck from './BodyCheck'
import Rebriefing from './Rebriefing'

const EmployeeBanner: FC = () => {
  const { nextBodyCheckDate, registeredAt, needShowReBriefingBanner } = useMe()
  const needRenderBodyCheck =
    nextBodyCheckDate &&
    isAfter(new Date(nextBodyCheckDate), sub(new Date(), { days: 14 }))

  const needRenderInterview =
    isAfter(new Date(), add(new Date(registeredAt), { days: 7 })) &&
    isBefore(new Date(), add(new Date(registeredAt), { days: 14 }))

  const { data: greetings = {} as BannerSettings } =
    useGetBannerSettingsByCodeQuery(BannerSettingsCodes.EMPLOYEE_GREETINGS)

  const { data: interview = {} as BannerSettings } =
    useGetBannerSettingsByCodeQuery(BannerSettingsCodes.EMPLOYEE_INTERVIEW, {
      skip: !needRenderInterview,
    })

  const { data: bodyCheck = {} as BannerSettings } =
    useGetBannerSettingsByCodeQuery(BannerSettingsCodes.EMPLOYEE_BODY_CHECK, {
      skip: !needRenderBodyCheck,
    })

  const { data: reBriefing = {} as BannerSettings } =
    useGetBannerSettingsByCodeQuery(BannerSettingsCodes.EMPLOYEE_RE_BRIEFING, {
      skip: !needShowReBriefingBanner,
    })

  if (needRenderInterview && interview?.text) {
    return <Interview bannerSettings={interview} />
  }

  if (needRenderBodyCheck && bodyCheck) {
    return <BodyCheck bannerSettings={bodyCheck} />
  }

  if (needShowReBriefingBanner && reBriefing) {
    return <Rebriefing bannerSettings={reBriefing} />
  }

  return <Greetings bannerSettings={greetings} />
}

export default EmployeeBanner
