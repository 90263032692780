import styled from '@emotion/styled'
import Title from '@organisms/Title'
import { isMobile } from '@const/device'

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #f2f6fa;
  padding: ${() => (isMobile ? '16px' : '24px 48px')};
  border-radius: 24px;
  gap: 25px;
  align-items: center;
`

export const StyledTitle = styled(Title)`
  margin: 3.5px 0 0;
  white-space: pre-wrap;
`
