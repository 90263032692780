import { isEmpty } from 'lodash'
import * as React from 'react'
import { FC, useEffect, useRef } from 'react'
import { FieldValues, FormProvider, SubmitHandler } from 'react-hook-form'
import useFormProcessor from '@hooks/useFormProcessor'
import { useGetPhoneCallMutation } from '@services/auth/api'
import BackButton from '@organisms/BackButton'
import { phoneField } from '@templates/AddNewItemModal/templates'
import Field from '@templates/Field'
import PrimaryButton from '@organisms/PrimaryButton'
import { ButtonWrapper, StyledSubtitle } from '../../../styled'

const PHONE_FIELD_NAME = 'phone'

type Props = {
  handleBack: () => void
  handleSubmitPhoneForm: (phoneNumber: string) => void
}

const PhoneForm: FC<Props> = ({ handleSubmitPhoneForm, handleBack }) => {
  const submittedRef = useRef<string>()
  const [getPhoneCall, { error, isLoading, data }] = useGetPhoneCallMutation()

  const methods = useFormProcessor({ apiErrors: error })
  const {
    formState: { errors },
    handleSubmit,
  } = methods

  const phoneFieldProps = phoneField({
    isRequired: true,
    widthMultiplier: 1.2,
  })

  useEffect(() => {
    if (data && submittedRef.current && isEmpty(errors)) {
      handleSubmitPhoneForm(submittedRef.current)
    }
  }, [data, submittedRef, errors])

  const onSubmit: SubmitHandler<FieldValues> = (form) => {
    void getPhoneCall(form[PHONE_FIELD_NAME])
    submittedRef.current = form[PHONE_FIELD_NAME]
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledSubtitle isLast>Войти по номеру телефона</StyledSubtitle>
        <Field {...phoneFieldProps} />
        <ButtonWrapper>
          <BackButton onClick={handleBack} big />
          <PrimaryButton type="submit" isLoading={isLoading} big>
            Далее
          </PrimaryButton>
        </ButtonWrapper>
      </form>
    </FormProvider>
  )
}

export default PhoneForm
