import { FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useToggle, useUnmount } from 'react-use'
import { useGetCourseThemeByIdQuery } from '@services/courseThemes'
import { Routes } from '@enums/Routes'
import { useGetCourseByIdQuery } from '@services/courses'
import Onboarding from '@templates/Onboarding'
import OnboardingCodes from '@enums/OnboardingCodes'
import ScormViewer from './ScormViewer'
import { ScormWrapper, Wrapper } from './styled'
import { Container } from './ScormViewer/styled'
import useScormEventSender from './useScormEventSender'
import { ONBOARDING_STEPS, ONBOARDING_TITLE } from './onboarding'

const Theme: FC = () => {
  const { themeId = '', courseId = '', educationId = '' } = useParams()
  const navigate = useNavigate()

  const [inModal, toggleInModal] = useToggle(false)

  const { data: courseData } = useGetCourseByIdQuery(courseId, {
    skip: !courseId,
  })
  const { data: courseThemeData } = useGetCourseThemeByIdQuery(themeId)
  const { title, nextThemeId, prevThemeId, courseTheme, test } =
    courseThemeData || {}

  const handleBack = () => {
    navigate(
      courseId
        ? Routes.COURSES + `/${courseId}/education/${educationId}`
        : Routes.COURSES
    )
  }

  const handleChangePage = useScormEventSender(
    courseData?.type,
    courseTheme?.scormType,
    test
  )

  useUnmount(handleChangePage)

  const navigationProps = {
    nextRoute: nextThemeId
      ? `/themes/${nextThemeId}/course/${courseId}/education/${educationId}`
      : undefined,
    prevRoute: prevThemeId
      ? `/themes/${prevThemeId}/course/${courseId}/education/${educationId}`
      : undefined,
    onChangePage: handleChangePage,
  }

  return (
    <>
      <Onboarding
        firstStepTitle={ONBOARDING_TITLE}
        steps={ONBOARDING_STEPS}
        code={OnboardingCodes.my_education_theme}
        withScroll
      />
      <Wrapper id="themeContainer">
        <Container>
          <ScormWrapper inModal={inModal}>
            <ScormViewer
              {...navigationProps}
              title={title}
              currentVote={courseTheme?.currentVote}
              scormId={courseTheme?.id || ''}
              scormIri={courseTheme?.['@id']}
              scormDirectory={courseTheme?.scormDirectory || ''}
              inModal={inModal}
              toggleInModal={toggleInModal}
              scormType={courseTheme?.scormType || 'articulate'}
              handleBack={handleBack}
            />
          </ScormWrapper>
        </Container>
      </Wrapper>
    </>
  )
}

export default Theme
