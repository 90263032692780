import { FC } from 'react'
import PrimaryButton from '@organisms/PrimaryButton'
import ButtonWithConfirm from '@templates/ButtonWithConfirm'
import { useMarkRiskMapAsReviewedMutation } from '@services/riskMapVersions'

type Props = {
  revision: string
}

const Archive: FC<Props> = ({ revision }) => {
  const [markAsReviewed, { isLoading }] = useMarkRiskMapAsReviewedMutation()

  const handleClick = () => {
    void markAsReviewed(revision)
  }

  return (
    <ButtonWithConfirm
      ButtonComponent={({ onClick }) => (
        <PrimaryButton big onClick={onClick} isLoading={isLoading}>
          Ознакомиться
        </PrimaryButton>
      )}
      onConfirm={handleClick}
      confirmText="Вы ознакомились с картой рисков?"
      confirmButtonText="Да"
      cancelText="Нет"
    />
  )
}

export default Archive
