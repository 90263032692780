import { FC, ReactNode } from 'react'
import { ReactComponent as QuestionIcon } from '@interface-images/question.svg'
import Tooltip from '@atoms/Tooltip'
import { Wrapper } from './styled'

type Props = {
  promptText: ReactNode
  width?: number
  iconColor?: string
}

const Prompt: FC<Props> = ({ promptText, iconColor, width }) => {
  return (
    <Wrapper>
      <Tooltip tooltipContent={promptText} width={width}>
        <QuestionIcon color={iconColor} />
      </Tooltip>
    </Wrapper>
  )
}

export default Prompt
