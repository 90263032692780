import { ReactNode } from 'react'
import { textField } from '@templates/AddNewItemModal/templates'
import { Fields } from '@templates/AddNewItemModal/Content'
import NewPasswordPrompt from '@templates/NewPasswordPrompt'

const getField = (name: string, placeholder: string, promptText?: ReactNode) =>
  textField({
    placeholder,
    name,
    label: placeholder,
    isRequired: true,
    componentProps: {
      type: 'password',
    },
    promptText,
  })

const currentPasswordFieldProps = getField('oldPassword', 'Текущий пароль')
const newPasswordFieldProps = getField(
  'newPassword',
  'Новый пароль',
  NewPasswordPrompt({})
)
const repeatPasswordFieldProps = getField(
  'newPasswordRepeat',
  'Повторите новый пароль'
)

const usePasswordFields = (): Fields => {
  return [
    {
      fields: [
        currentPasswordFieldProps,
        newPasswordFieldProps,
        repeatPasswordFieldProps,
      ],
    },
  ]
}

export default usePasswordFields
