import { FC } from 'react'
import { ReactComponent as Logo } from '@interface-images/main-logo.svg'
import { Routes } from '@enums/Routes'
import LinkItem from '@templates/LinkItem'
import AuthBlock from './AuthBlock'
import {
  AuthBlockWrapper,
  CentralColumnWrapper,
  CentralWrapper,
  Wrapper,
} from './styled'

const Auth: FC = () => {
  return (
    <Wrapper>
      <CentralWrapper>
        <CentralColumnWrapper>
          <div>
            <Logo />
          </div>
          <AuthBlockWrapper>
            <AuthBlock />
          </AuthBlockWrapper>
          <LinkItem
            text="Политика конфиденциальности"
            route={Routes.PRIVACY_POLICY}
          />
        </CentralColumnWrapper>
      </CentralWrapper>
    </Wrapper>
  )
}

export default Auth
