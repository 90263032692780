import { FC, useEffect } from 'react'
import { Skeleton } from 'antd'
import Pagination from '@templates/Pagination'
import useRiskMaps from '@hooks/useRiskMaps'
import { FilterState } from '@hooks/useItemsFilters'
import Title from '@organisms/Title'
import RiskMapItem from './RiskMapItem'
import { Wrapper } from './styled'

const SKELETON_HEIGHT = 70

type Props = {
  initFilters: FilterState
  withoutReviewed?: boolean
  onLoaded?: () => void
}

const RiskMapsBodyList: FC<Props> = ({
  initFilters,
  withoutReviewed,
  onLoaded,
}) => {
  const {
    data: riskMapsData,
    setPage,
    isSuccess,
    isLoading,
  } = useRiskMaps({
    initFilters,
    limit: 10,
  })
  const riskMaps = riskMapsData?.items || []
  const itemsCount = riskMapsData?.length || 0

  const handleChangePagination = (page: number) => {
    setPage?.(page)
  }

  useEffect(() => {
    if (isSuccess) {
      onLoaded?.()
    }
  }, [isSuccess])

  if (isLoading) {
    return (
      <div>
        <Skeleton.Button
          active
          block
          style={{
            height: SKELETON_HEIGHT,
            marginBottom: 12,
            borderRadius: 30,
          }}
        />
      </div>
    )
  }

  if (isSuccess && itemsCount === 0) {
    return (
      <Wrapper>
        <Title mySize="h4">Карт рисков не найдено</Title>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      {riskMaps.map((item) => (
        <RiskMapItem
          key={item.id}
          item={item}
          withoutReviewed={withoutReviewed}
        />
      ))}
      <Pagination
        itemsCount={itemsCount}
        onChangePagination={handleChangePagination}
      />
    </Wrapper>
  )
}

export default RiskMapsBodyList
