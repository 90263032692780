import * as React from 'react'
import { FC, useEffect } from 'react'
import { useMount } from 'react-use'
import { useNavigate } from 'react-router-dom'
import {
  useDeleteEmployeeEducationMutation,
  useGetEmployeeEducationQuery,
} from '@services/employeeEducation'
import ASSIGN_TYPES from '@const/assignTypes'
import { formatServerDate } from '@utils/date'
import { ProgressStatuses } from '@enums/ProgressStatuses'
import { AccordionBodyProps } from '@organisms/Accordion'
import AccordionBodyField from '@organisms/AccordionBodyField'
import EditModal from '@templates/EditModal'
import EmployeesCountField from '@templates/EmployeesCountField'
import EmployeeEducationModal from '@modals/EmployeeEducationModal'
import DeleteButton from '@templates/DeleteButton'
import AccordionBodyFieldWithTabs from '@templates/AccordionBodyFieldWithTabs'
import { Routes } from '@enums/Routes'
import Onboarding from '@templates/Onboarding'
import OnboardingCodes from '@enums/OnboardingCodes'
import { Footer } from './styled'
import AssignedGroupField from './AssignedGroupField'
import { getOnboardingSteps, ONBOARDING_TITLE } from './onboarding'

type Props = { id: number; index: number } & AccordionBodyProps

const Body: FC<Props> = ({ onChangeSize, id, toggleLoading, index }) => {
  const navigate = useNavigate()
  const [deleteEducation, deleteData] = useDeleteEmployeeEducationMutation()
  const { data } = useGetEmployeeEducationQuery(id)
  const {
    conductsBriefing,
    type,
    trainingReason,
    localActName,
    protocolDate,
    protocolNumber,
    employeeCount = 0,
    passedEmployeeCount = 0,
    repeatedEducationDestination,
  } = data || {}

  const handleDelete = () => {
    void deleteEducation(id)
  }

  useMount(() => {
    toggleLoading(true)
  })

  useEffect(() => {
    if (data) {
      toggleLoading(false)
      onChangeSize()
    }
  }, [data?.id])

  return (
    <>
      <Onboarding
        firstStepTitle={ONBOARDING_TITLE}
        steps={getOnboardingSteps(index)}
        code={OnboardingCodes.education_open_card}
        withScroll
      />
      <div>
        <AssignedGroupField education={data} />
        <div id={`stats${index}`}>
          <EmployeesCountField
            label="Сотрудники, которым назначен курс"
            countEmployees={employeeCount}
            educationId={id}
            isAccordionField
          />
          <EmployeesCountField
            label="Сотрудники, которые прошли курс"
            countEmployees={passedEmployeeCount}
            educationId={id}
            statuses={[ProgressStatuses.PASSED]}
            isAccordionField
          />
          <EmployeesCountField
            label="Сотрудники, которые не прошли курс"
            countEmployees={employeeCount - passedEmployeeCount}
            educationId={id}
            statuses={[
              ProgressStatuses.NOT_STARTED,
              ProgressStatuses.IN_PROCESS,
            ]}
            isAccordionField
          />
        </div>
        {repeatedEducationDestination && (
          <AccordionBodyFieldWithTabs
            label="Повторное назначение"
            items={[
              {
                id: '1',
                title: formatServerDate(repeatedEducationDestination.createdAt),
                onClick: () =>
                  navigate(
                    Routes.EMPLOYEES_EDUCATION +
                      `?id=${repeatedEducationDestination.id}`
                  ),
              },
            ]}
          />
        )}
        <AccordionBodyField
          label="Проводит инструктаж"
          value={conductsBriefing?.fullName}
        />
        <AccordionBodyField
          label="Тип назначения"
          value={
            ASSIGN_TYPES.find((assignType) => assignType.value === type)?.label
          }
        />
        <AccordionBodyField label="Причина проведения" value={trainingReason} />
        <AccordionBodyField
          label="Наименование локального акта"
          value={localActName}
        />
        {protocolDate && protocolNumber && (
          <AccordionBodyField
            label="номер протокола / дата протокола"
            value={protocolNumber + ' / ' + formatServerDate(protocolDate)}
          />
        )}
        <Footer>
          <EditModal
            ModalContent={EmployeeEducationModal}
            modalProps={{
              employeeEducation: data,
            }}
          />
          <DeleteButton {...deleteData} onClick={handleDelete} />
        </Footer>
      </div>
    </>
  )
}

export default Body
