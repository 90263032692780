import { CourseSubTypes } from '@models/course'
import { Option } from '@organisms/Select'

const COURSE_SUB_TYPES: Option[] = [
  {
    value: CourseSubTypes.COURSE,
    label: 'Курс',
  },
  {
    value: CourseSubTypes.DEMO,
    label: 'Демо-курс',
  },
  {
    value: CourseSubTypes.INSTRUCTION,
    label: 'Инструктаж',
  },
]

export default COURSE_SUB_TYPES
