import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { User } from '@models/user'
import AccordionBodyFieldWithTabs from '@templates/AccordionBodyFieldWithTabs'
import { Routes } from '@enums/Routes'

type Props = {
  label: string
  users?: User[]
}

const AccordionBodyFieldsEmployees: FC<Props> = ({ label, users }) => {
  const navigate = useNavigate()

  const handleOpenCreator = (id: number) => async () => {
    navigate(Routes.EMPLOYEES + `?id=${id}`)
  }

  if (!users || users?.length === 0) {
    return null
  }

  return (
    <AccordionBodyFieldWithTabs
      label={label}
      items={users.map((item) => ({
        id: item.fullName,
        title: item.fullName,
        onClick: handleOpenCreator(item.id),
      }))}
    />
  )
}

export default AccordionBodyFieldsEmployees
