import styled from '@emotion/styled'

export const Wrapper = styled.div`
  padding: 20px;
`

export const BackButtonWrapper = styled.div`
  display: inline-block;
  margin-bottom: 10px;
`
