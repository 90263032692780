import { FC, ReactNode } from 'react'
import { BACKEND_URL } from '@const/env'
import { RightWrapper, StyledTitle, Wrapper } from './styled'

type Props = {
  leftIcon: string
  rightContent: ReactNode
  iconSize?: number
  title: string
}

const ConfirmModalContent: FC<Props> = ({
  leftIcon,
  rightContent,
  iconSize = 150,
  title,
}) => {
  return (
    <Wrapper>
      <img
        alt="bear"
        src={`${BACKEND_URL}/images/${leftIcon}`}
        width={iconSize}
        height={iconSize}
      />
      <RightWrapper>
        <StyledTitle>{title}</StyledTitle>
        <div>{rightContent}</div>
      </RightWrapper>
    </Wrapper>
  )
}

export default ConfirmModalContent
