import { FC } from 'react'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller'
import * as React from 'react'
import { FilterItem, FilterState } from '@hooks/useItemsFilters'
import { InputProps } from '@organisms/Input'
import HiddenFilter from '@templates/ListPageLayout/Filters/Filter/HiddenFilter'
import { CheckboxProps } from '@organisms/Checkbox'
import { EmployeeSelectProps } from '@templates/EmployeeSelect'
import { SelectProps } from '@organisms/Select'
import Prompt from '@organs/Prompt'
import Skeleton from '../Skeleton'
import { ComponentWrapper, Wrapper } from './styled'
import SelectFilter from './SelectFilter'
import SearchSelectFilter from './SearchSelectFilter'
import DateFilter from './DateFilter'
import CheckboxFilter from './CheckboxFilter'
import EmployeeFilter from './EmployeeFilter'

export type FilterProps<T> = {
  fieldName: string
  fieldNameForSubmit?: string
  filterType?: FilterTypes
  transformValue?: (filter: FilterState) => any
  promptText?: string
} & T

export type FilterComponentProps<T> = {
  filter: FilterProps<T>
  onFilter: (newFilter: FilterState) => void
  field: ControllerRenderProps<FieldValues, string>
  value?: FilterItem
  initialFiltersState: FilterState
}

export enum FilterTypes {
  SELECT,
  SELECT_SEARCH,
  DATE,
  CHECKBOX,
  EMPLOYEE,
  HIDDEN,
}

const COMPONENT_TYPE_MAP: {
  [key in FilterTypes]: FC<FilterComponentProps<any>>
} = {
  [FilterTypes.SELECT]: SelectFilter,
  [FilterTypes.SELECT_SEARCH]: SearchSelectFilter,
  [FilterTypes.DATE]: DateFilter,
  [FilterTypes.CHECKBOX]: CheckboxFilter,
  [FilterTypes.EMPLOYEE]: EmployeeFilter,
  [FilterTypes.HIDDEN]: HiddenFilter,
}

type Props = {
  type?: FilterTypes
  filter: FilterProps<
    SelectProps | InputProps | CheckboxProps | EmployeeSelectProps
  >
  onFilter: (newFilter: FilterState) => void
  filtersState: FilterState
  isLoading?: boolean
  initialFiltersState: FilterState
}

const Filter: FC<Props> = ({
  filter: { filterType = FilterTypes.SELECT, ...filter },
  onFilter,
  filtersState,
  isLoading,
  initialFiltersState,
}) => {
  const { control } = useFormContext()

  const Component = COMPONENT_TYPE_MAP[filterType]

  if (isLoading) {
    return <Skeleton />
  }

  return (
    <Wrapper hidden={filterType === FilterTypes.HIDDEN}>
      <Controller
        control={control}
        name={filter.fieldName}
        render={({ field }) => (
          <ComponentWrapper>
            <Component
              filter={filter}
              field={field}
              onFilter={onFilter}
              value={filtersState[filter.fieldName]}
              initialFiltersState={initialFiltersState}
            />
            {filter.promptText && <Prompt promptText={filter.promptText} />}
          </ComponentWrapper>
        )}
      />
    </Wrapper>
  )
}

export default Filter
