import styled from '@emotion/styled'
import Text from '@organisms/Text'

export const Description = styled(Text)`
  margin: 6px 10px ${({ theme }) => theme.sizes.twoBase} -4px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -15px;
`
export const DownloadWrapper = styled.div`
  display: flex;
`
