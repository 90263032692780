import styled from '@emotion/styled'
import Title from '@organisms/Title'

export const StyledThemeTitle = styled(Title)`
  margin: 30px 10px;
`

export const ThemeWrapper = styled.div`
  margin-top: 20px;
`

export const EditButtonsWrapper = styled.div`
  display: flex;
  gap: 5px;
`
