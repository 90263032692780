/* eslint-disable no-unused-expressions, no-sequences */
import Cookies from 'js-cookie'
import { isEmpty } from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import { Me } from '@models/me'
import OnboardingCodes from '@enums/OnboardingCodes'
import { newUsersApi } from '../users'
import { authApi } from './api'

const TOKEN_PATH = 'authToken'
const ACCEPTED_COOKIE = 'acceptedCookie'
export const getAcceptedCookie = (): boolean =>
  !!Cookies.get(ACCEPTED_COOKIE) && Cookies.get(ACCEPTED_COOKIE) === 'true'
export const setTokenToCookie = (token: string): void => {
  if (getAcceptedCookie()) {
    Cookies.set(TOKEN_PATH, token)
  }
}

export type State = {
  token: string | undefined
  id: string | null
  me: Me
  needCheckExpiredPassword: boolean
  onboardingVisited: Record<OnboardingCodes, boolean>
}

const initialState: State = {
  token: getAcceptedCookie() ? Cookies.get(TOKEN_PATH) : undefined,
  id: null,
  me: {} as Me,
  needCheckExpiredPassword: false,
  onboardingVisited: {} as Record<OnboardingCodes, boolean>,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, { payload }) => {
      state.token = payload
    },
    logout: (state) => {
      state.token = undefined
      state.me = {} as Me
      state.id = null
      Cookies.remove(TOKEN_PATH)
    },
    acceptCookie: (state) => {
      Cookies.set(ACCEPTED_COOKIE, 'true')
      if (state.token) {
        setTokenToCookie(state.token)
      }
    },
    toggleNeedCheckExpiredPassword: (state, { payload }) => {
      state.needCheckExpiredPassword = !!payload
    },
    setOnboardingVisited: (state, { payload }) => {
      state.onboardingVisited = {
        ...state.onboardingVisited,
        ...payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.postValidationCode.matchFulfilled,
      (state, action) => {
        state.token = action.payload.id
        setTokenToCookie(action.payload.id)
      }
    ),
      builder.addMatcher(
        authApi.endpoints.postLoginAuth.matchFulfilled,
        (state, action) => {
          state.token = action.payload.id
          setTokenToCookie(action.payload.id)
        }
      ),
      builder.addMatcher(
        newUsersApi.endpoints.getMe.matchFulfilled,
        (state, action) => {
          state.me = action.payload as Me

          if (isEmpty(state.onboardingVisited)) {
            state.onboardingVisited =
              (action.payload.userConfig?.onboarding as Record<
                OnboardingCodes,
                boolean
              >) || {}
          }
        }
      )
  },
})

export const {
  login,
  logout,
  acceptCookie,
  toggleNeedCheckExpiredPassword,
  setOnboardingVisited,
} = authSlice.actions

export default authSlice.reducer
