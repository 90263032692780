import { Theme } from '@emotion/react'
import { isMobile } from '@const/device'

const theme: Theme = {
  colors: {
    text: {
      primary: '#1D4E89',
      secondary: '#7B7B7B',
      surface: 'white',
      black: 'black',
      link: '#0061D9',
    },
    background: {
      primary: '#1D4E89',
      surface: 'white',
      disable: '#C4C4C4',
      primaryAccent: '#F2F6FA',
    },
    main: '#1D4E89',
    mainAccent: '#1f5291',
    disable: '#C4C4C4',
    warning: '#FEBB02',
    error: '#EF4949',
    success: '#23AF11',
  },
  sizes: {
    base: isMobile ? '12px' : '16px',
    twoBase: isMobile ? '16px' : '24px',
    threeBase: isMobile ? '24px' : '32px',
    fourBase: isMobile ? '32px' : '48px',
    fiveBase: isMobile ? '40px' : '64px',
    sixBase: isMobile ? '48px' : '80px',
    sevenBase: isMobile ? '60px' : '96px',
    inputWidth: isMobile ? 160 : 260,
    inputHeight: isMobile ? 30 : 40,
    accordionHeight: isMobile ? 30 : 40,
  },
  text: {
    h1: {
      fontSize: isMobile ? '24px' : '36px',
      lineHeight: isMobile ? '28px' : '44px',
    },
    h2: {
      fontSize: isMobile ? '20px' : '28px',
      lineHeight: isMobile ? '24px' : '34px',
    },
    h3: {
      fontSize: isMobile ? '18px' : '24px',
      lineHeight: isMobile ? '20px' : '28px',
    },
    h4: {
      fontSize: isMobile ? '16px' : '18px',
      lineHeight: isMobile ? '19px' : '24px',
    },
    h5: {
      fontSize: '14px',
      lineHeight: '16px',
    },
    h6: {
      fontSize: '12px',
      lineHeight: isMobile ? '14px' : '16px',
    },
    pNormal: {
      fontSize: isMobile ? '14px' : '16px',
      lineHeight: isMobile ? '20px' : '24px',
    },
    pMini: {
      fontSize: isMobile ? '12px' : '14px',
      lineHeight: isMobile ? '18px' : '20px',
    },
    blockquote: {
      fontSize: isMobile ? '16px' : '18px',
      lineHeight: isMobile ? '24px' : '28px',
    },
  },
}

export default theme
