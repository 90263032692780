declare global {
  interface Window {
    ENABLE_LOGS: boolean
    ENABLE_LOGS_MEMO: boolean
    ENABLE_LOGS_EQUAL: boolean
    ENABLE_SELECT_VALUE: boolean
  }
}

export const log = (...args: any[]): void => {
  if (window.ENABLE_LOGS) {
    console.log(...args)
  }
}
