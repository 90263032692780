import * as React from 'react'
import { FC, useEffect } from 'react'
import { useGetFilialQuery } from '@services/filials'
import FilialModal from '@modals/FilialModal'
import { formatServerDate } from '@utils/date'
import AccordionBodyField from '@organisms/AccordionBodyField'
import Loader from '@organisms/Loader'
import AccordionBodyFieldWithTabs from '@templates/AccordionBodyFieldWithTabs'
import AccordionBodyFieldsEmployees from '@templates/AccordionBodyFieldsEmployees'
import EmployeesCountField from '@templates/EmployeesCountField'

type Props = {
  id: number
  onSuccess?: () => void
}

const FilialListItem: FC<Props> = ({ id, onSuccess }) => {
  const { data, isLoading, isSuccess } = useGetFilialQuery(id)
  const {
    fullTitle = '',
    countEmployees = 0,
    subscriptionStartAt,
    subscriptionEndAt,
    subscriptionsCount = 0,
    usedSubscriptionsCount = 0,
    curators,
    company,
  } = data || {}

  useEffect(() => {
    if (isSuccess) {
      onSuccess?.()
    }
  }, [isSuccess])

  return (
    <Loader isLoading={isLoading}>
      <AccordionBodyFieldWithTabs
        label="Полное наименование"
        items={[
          {
            id: '1',
            title: fullTitle,
            editModal: {
              Component: FilialModal,
              props: {
                filial: data,
              },
            },
          },
        ]}
      />
      <EmployeesCountField
        countEmployees={countEmployees}
        company={
          company
            ? {
                label: company.title,
                value: company['@id'],
              }
            : undefined
        }
        filial={
          data
            ? {
                label: data.title,
                value: data['@id'],
              }
            : undefined
        }
        isAccordionField
      />
      <AccordionBodyField
        label="Дата начала"
        value={
          subscriptionStartAt
            ? formatServerDate(subscriptionStartAt)
            : undefined
        }
      />
      <AccordionBodyField
        label="Дата окончания"
        value={
          subscriptionEndAt ? formatServerDate(subscriptionEndAt) : undefined
        }
      />
      <AccordionBodyField
        label="Количество учетных записей"
        value={subscriptionsCount}
      />
      <AccordionBodyField
        label="Количество использованных учетных записей"
        value={usedSubscriptionsCount}
      />
      <AccordionBodyField
        label="Количество доступных учетных записей"
        value={subscriptionsCount - usedSubscriptionsCount}
      />
      <AccordionBodyFieldsEmployees label="Кураторы филиала" users={curators} />
    </Loader>
  )
}

export default FilialListItem
