import { FC } from 'react'
import StyledButton, { StyledButtonProps } from '@organs/StyledButton'
import ButtonStyle from '@enums/ButtonStyle'
import { CloseOutlined } from '@ant-design/icons'

const CloseButton: FC<StyledButtonProps> = ({
  buttonStyle = ButtonStyle.GRAY,
  children = 'Закрыть',
  ...restProps
}) => {
  return (
    <StyledButton
      {...restProps}
      buttonStyle={buttonStyle}
      leftIcon={<CloseOutlined />}
    >
      {children}
    </StyledButton>
  )
}

export default CloseButton
