import StoreTags from '@enums/StoreTags'
import { CommonQueryParams, ItemsFromResponse } from '@models/common'
import {
  CreateFilialBody,
  CreateFilialResponse,
  Filial,
  FilialDetailed,
  FilialOption,
  UpdateFilialBody,
  UpdateFilialResponse,
} from '@models/filial'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'
import emptySplitApi from '../api'

const BASE_URL = '/api/company_branches'

export const filialsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getFilials: builder.query<
      { items: FilialOption[]; length: number },
      CommonQueryParams
    >({
      query: (params) => ({
        url: BASE_URL,
        params,
      }),
      transformResponse: (response: ItemsFromResponse<Filial>) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          value: item['@id'],
          label: item.title,
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
      providesTags: [StoreTags.FILIALS],
    }),
    createFilial: builder.mutation<CreateFilialResponse, CreateFilialBody>({
      invalidatesTags: (result) =>
        result ? [StoreTags.FILIALS, StoreTags.COMPANY] : [],
      query: (body) => ({
        url: BASE_URL,
        method: 'POST',
        body,
      }),
    }),
    updateFilial: builder.mutation<UpdateFilialResponse, UpdateFilialBody>({
      invalidatesTags: (result) =>
        result
          ? [
              StoreTags.FILIALS,
              StoreTags.FILIAL,
              StoreTags.COMPANY,
              StoreTags.ME,
            ]
          : [],
      query: ({ id, ...body }) => ({
        url: `${BASE_URL}/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    getFilial: builder.query<FilialDetailed, number>({
      providesTags: [StoreTags.FILIAL],
      query: (id) => `${BASE_URL}/${id}`,
    }),
    deleteFilial: builder.mutation<void, number>({
      invalidatesTags: [
        StoreTags.FILIALS,
        StoreTags.COMPANIES,
        StoreTags.COMPANY,
      ],
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetFilialsQuery,
  useCreateFilialMutation,
  useUpdateFilialMutation,
  useGetFilialQuery,
  useLazyGetFilialQuery,
  useDeleteFilialMutation,
} = filialsApi
