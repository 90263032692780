import styled from '@emotion/styled'
import Title from '@organisms/Title'

export const AuthorWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;

  span {
    white-space: nowrap;
  }
`

export const SubWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const Label = styled(Title)`
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.1em;
  font-weight: 400;
  margin-left: 0;
`
