import { ChangePasswordBody, Me, MeServerResponse } from '@models/me'
import { Roles } from '@enums/Roles'
import StoreTags from '@enums/StoreTags'
import { User } from '@models/user'
import { createDownloadExcelReportQuery } from '@utils/files'
import { CommonQueryParams } from '@models/common'
import {
  BODY_CHECK_REPORT_NAME,
  EMPLOYEES_EDUCATION_STATS_REPORT_NAME,
  BODY_CHECK_REPORT,
  EMPLOYEES_EDUCATION_STATS_REPORT,
} from '@const/reportNames'
import { CompanyDetailed } from '@models/company'
import { FilialDetailed } from '@models/filial'
import emptySplitApi from '../api'

const BASE_URL = '/api/users'

export const newUsersApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getMe: builder.query<Me, void>({
      providesTags: [StoreTags.ME],
      async queryFn(_params, _queryApi, _extraOptions, fetchWithBQ) {
        const meResponse = await fetchWithBQ({
          url: `${BASE_URL}/me`,
        })
        const meServerResponse = (meResponse.data as MeServerResponse) || {}

        const companyResponse = await fetchWithBQ({
          url: `/api/companies/${meServerResponse.company?.id}`,
        })
        const companyServerResponse =
          (companyResponse.data as CompanyDetailed) || {}

        const filialResponse = await fetchWithBQ({
          url: `/api/company_branches/${meServerResponse.companyBranch?.id}`,
        })
        const filialServerResponse =
          (filialResponse.data as FilialDetailed) || {}

        const { roles: rolesSrc, ...rest } = meServerResponse

        const rolesSet = new Set(rolesSrc)
        const isAdmin = rolesSet.has(Roles.ADMIN)
        const isCompanyGroupCurator = rolesSet.has(Roles.COMPANY_GROUP_CURATOR)
        const isCompanyCurator = rolesSet.has(Roles.COMPANY_CURATOR)
        const isFilialCurator = rolesSet.has(Roles.FILIAL_CURATOR)
        const isDivisionCurator = rolesSet.has(Roles.DIVISION_CURATOR)

        if (
          isAdmin ||
          isFilialCurator ||
          isDivisionCurator ||
          isCompanyCurator ||
          isCompanyGroupCurator
        ) {
          rolesSet.delete(Roles.EMPLOYEE)
        }

        const isEmployee = rolesSet.has(Roles.EMPLOYEE)

        const onlyEmployee =
          isEmployee &&
          !isDivisionCurator &&
          !isCompanyCurator &&
          !isFilialCurator &&
          !isCompanyGroupCurator

        const divisionCuratorWithOneDivision =
          isDivisionCurator &&
          !isCompanyCurator &&
          !isFilialCurator &&
          rest.curatorCompanyBranchSubdivisions?.length === 1 &&
          rest.curatorCompanyBranchSubdivisions[0]

        const filialCuratorWithOneDivision =
          !isCompanyCurator &&
          isFilialCurator &&
          rest.curatorCompanyBranches?.length === 1 &&
          rest.curatorCompanyBranches[0]

        let result: Me = {
          ...rest,
          roles: rolesSrc,
          isAdmin,
          isCompanyGroupCurator,
          isCompanyCurator,
          isFilialCurator,
          isDivisionCurator,
          isEmployee,
          divisionCuratorWithOneDivision:
            divisionCuratorWithOneDivision || null,
          filialCuratorWithOneDivision: filialCuratorWithOneDivision || null,
          company: companyServerResponse,
          companyBranch: filialServerResponse,
          meUser: {} as User,
          onlyEmployee,
        }

        // добавляем поля только если мы не под админом
        if (
          !isAdmin &&
          meServerResponse &&
          meServerResponse.company &&
          meServerResponse.companyBranch
        ) {
          const companyResponse = await fetchWithBQ({
            url: `/api/companies/${meServerResponse.company?.id}`,
          })
          const companyServerResponse =
            (companyResponse.data as CompanyDetailed) || {}

          const filialResponse = await fetchWithBQ({
            url: `/api/company_branches/${meServerResponse.companyBranch?.id}`,
          })
          const filialServerResponse =
            (filialResponse.data as FilialDetailed) || {}

          const userResponse = await fetchWithBQ({
            url: `/api/users/${meServerResponse.id}`,
          })
          const userServerResponse = (userResponse.data as User) || {}

          result = {
            ...result,
            company: companyServerResponse,
            companyBranch: filialServerResponse,
            meUser: userServerResponse,
          }
        }

        return {
          data: result,
        }
      },
    }),
    getUser: builder.query<User, number>({
      providesTags: [StoreTags.USER],
      query: (id) => `${BASE_URL}/${id}`,
    }),
    toggleActiveUser: builder.mutation<void, number>({
      invalidatesTags: (result) =>
        result ? [StoreTags.USER, StoreTags.EMPLOYEES] : [],
      query: (id) => ({
        url: `${BASE_URL}/${id}/toggleActive`,
        method: 'POST',
      }),
    }),
    getBodyCheckReport: builder.query<void, CommonQueryParams>({
      query: createDownloadExcelReportQuery(
        `/api/reports/employees/${BODY_CHECK_REPORT}`,
        BODY_CHECK_REPORT_NAME + '.xls'
      ),
    }),
    getEmployeesReport: builder.query<void, CommonQueryParams>({
      query: createDownloadExcelReportQuery(
        `/api/reports/employees/${EMPLOYEES_EDUCATION_STATS_REPORT}`,
        EMPLOYEES_EDUCATION_STATS_REPORT_NAME + '.xls'
      ),
    }),
    changePassword: builder.mutation<void, ChangePasswordBody>({
      invalidatesTags: (result) => (result ? [StoreTags.ME] : []),
      query: (body) => ({
        url: `${BASE_URL}/me/change_password`,
        method: 'POST',
        body,
      }),
    }),
    deleteUser: builder.mutation<void, string | number>({
      invalidatesTags: [
        StoreTags.EMPLOYEES,
        StoreTags.COMPANY,
        StoreTags.COMPANIES,
        StoreTags.FILIAL,
        StoreTags.FILIALS,
        StoreTags.DIVISION,
        StoreTags.DIVISIONS,
        StoreTags.POSITION,
        StoreTags.POSITIONS,
        StoreTags.EMPLOYEE_EDUCATIONS,
        StoreTags.EMPLOYEE_EDUCATION,
        StoreTags.RISK_MAPS,
        StoreTags.RISK_MAP,
      ],
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetMeQuery,
  useGetUserQuery,
  useToggleActiveUserMutation,
  useLazyGetUserQuery,
  useLazyGetBodyCheckReportQuery,
  useLazyGetEmployeesReportQuery,
  useChangePasswordMutation,
  useDeleteUserMutation,
} = newUsersApi
