import { FC, useState } from 'react'
import Tabs from '@organisms/Tabs'
import RiskMaps from '@pages/Settings/RiskMaps'
import Library from './Library'
import { Wrapper } from './styled'
import Texts from './Texts'
import WelcomeBanners from './WelcomeBanners'
import Onboarding from './Onboarding'

const Settings: FC = () => {
  const [active, setActive] = useState(0)

  return (
    <Wrapper>
      <Tabs
        active={active}
        setActive={setActive}
        tabSize="big"
        items={[
          {
            id: '1',
            title: 'Баннеры',
            children: <WelcomeBanners />,
          },
          {
            id: '2',
            title: 'Тексты',
            children: <Texts />,
          },
          {
            id: '3',
            title: 'Библиотека',
            children: <Library />,
          },
          {
            id: '4',
            title: 'Онбординг',
            children: <Onboarding />,
          },
          {
            id: '5',
            title: 'Карты рисков',
            children: <RiskMaps />,
          },
        ]}
      />
    </Wrapper>
  )
}

export default Settings
