import {
  createMaxLengthRule,
  REQUIRED_VALIDATION_RULE,
} from '@const/validation'
import Checkbox, { CheckboxProps } from '@organisms/Checkbox'
import { FieldForRender, FieldParams } from '..'

const checkboxField = (params: FieldParams): FieldForRender => {
  const { label, isRequired, componentProps: props, isDisabled } = params || {}

  const componentProps: CheckboxProps = {
    ...props,
    marginLeft: 8,
    disabled: isDisabled,
  }

  return {
    ...params,
    label: isRequired ? `${label}*` : label,
    rules: {
      ...createMaxLengthRule(),
      ...(isRequired ? REQUIRED_VALIDATION_RULE : {}),
    },
    Component: Checkbox,
    componentProps,
  }
}

export default checkboxField
