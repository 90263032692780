import { FC } from 'react'
import useEmployees from '@hooks/useEmployees'
import useFormProcessor from '@hooks/useFormProcessor'
import ListPage from '@templates/ListPage'
import { REGISTERED_AT_SORTER } from '@const/sorters'
import EmployeeModal from '@modals/EmployeeModal'
import { createInitFilter } from '@utils/filters'
import Onboarding from '@templates/Onboarding'
import OnboardingCodes from '@enums/OnboardingCodes'
import useEmployeesReports from './useEmployeesReports'
import { ONBOARDING_STEPS, ONBOARDING_TITLE } from './onboarding'
import Employee from './Employee'
import useEmployeesFilters from './useEmployeesFilters'
import {
  REPORTS_ONBOARDING_STEPS,
  REPORTS_ONBOARDING_TITLE,
} from './reportsOnboarding'

const Employees: FC = () => {
  const reports = useEmployeesReports()
  const methods = useFormProcessor()
  const filters = useEmployeesFilters(methods)
  const { data, ...rest } = useEmployees()
  const { items = [], length = 0 } = data || {}

  return (
    <>
      <Onboarding
        firstStepTitle={ONBOARDING_TITLE}
        steps={ONBOARDING_STEPS}
        code={OnboardingCodes.employee_section}
      />
      <ListPage
        {...rest}
        methods={methods}
        titleTabs={[
          {
            title: 'Сотрудники',
            counter: length,
            index: 0,
          },
        ]}
        initialFiltersState={{ ...createInitFilter('active', true) }}
        ItemComponent={Employee}
        ModalContent={EmployeeModal}
        list={items}
        length={length}
        sorters={[REGISTERED_AT_SORTER]}
        filters={filters}
        reports={reports}
        searchPlaceholder="Введите фио, email, телефон сотрудника"
        withSearch
        reportsOnboarding={{
          firstStepTitle: REPORTS_ONBOARDING_TITLE,
          steps: REPORTS_ONBOARDING_STEPS,
          code: OnboardingCodes.employee_reports,
          withFeedback: true,
        }}
        resetOnboardingCodes={[
          OnboardingCodes.employee_section,
          OnboardingCodes.employee_create,
          OnboardingCodes.employee_state_table_upload,
          OnboardingCodes.employee_reports,
          OnboardingCodes.employee_probation_sheets,
        ]}
      />
    </>
  )
}

export default Employees
