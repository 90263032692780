import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { RootState } from '../index'

const prepareHeaders = (
  headers: Headers,
  {
    getState,
  }: Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' | 'forced'>
) => {
  headers.set(
    'authorization',
    process.env.REACT_APP_STAGE_BASIC_AUTH || 'Basic ZXhwOmRldg=='
  )

  const token = (getState() as RootState).auth?.token
  if (token) {
    headers.set('access-token', token)
  }

  return headers
}

export default prepareHeaders
