import { FC } from 'react'
import useFormProcessor from '@hooks/useFormProcessor'
import AddNewItemModal from '@templates/AddNewItemModal'
import { dateField } from '@templates/AddNewItemModal/templates'
import { useUpdateAllProtectionMeansMutation } from '@services/riskMaps'
import { toServerDate } from '@utils/date'
import { RiskMapDetailed } from '@models/riskMap'

export type FormValues = {
  date: string
}

type Props = {
  riskMap: RiskMapDetailed
  handleCloseModal: () => void
}

const UpdateAllModal: FC<Props> = ({ handleCloseModal, riskMap }) => {
  const fields = [
    {
      fields: [
        dateField({
          label: 'Дата обновления',
          name: 'date',
          isRequired: true,
          promptText:
            'При клике все даты “Обновить до” будут пересчитаны по формуле: дата обновления + периодичность обновления (месяцев)',
        }),
      ],
    },
  ]

  const [updateAllProtectionMeans, updateData] =
    useUpdateAllProtectionMeansMutation()

  const methods = useFormProcessor({
    apiErrors: updateData.error,
  })

  const handleSubmitForm = (form: FormValues) => {
    void updateAllProtectionMeans({
      id: riskMap.id,
      dateTime: toServerDate(form.date),
    })
  }

  return (
    <AddNewItemModal
      {...updateData}
      handleCloseModal={handleCloseModal}
      onSubmitForm={handleSubmitForm}
      methods={methods}
      fields={fields}
      customFullTitle="Обновить все СИЗ"
      successContentAddTitle="Вы успешно обновили все СИЗ для карты рисков"
      withStopPropagation
      withoutAddMore
    />
  )
}

export default UpdateAllModal
