import { useState } from 'react'
import { SelectSearchFieldParams } from '@templates/AddNewItemModal/templates/selectSearchField'
import { ReturnType } from '@app-types/riskMapFields'
import dataSelectField from '@templates/AddNewItemModal/templates/dataSelectField'
import { FilterState } from '@hooks/useItemsFilters'
import { useGetProtectionMeanTypesQuery } from '@services/protectionMeans'

const useProtectionMeanTypesField = (
  params: Partial<SelectSearchFieldParams>
): ReturnType => {
  const [filters, setFilters] = useState<FilterState | undefined>()

  return {
    setFilters,
    field: () =>
      dataSelectField({
        filters,
        query: useGetProtectionMeanTypesQuery,
        setFilters,
        dropdownWidth: 500,
        label: 'Тип СИЗ',
        name: 'type',
        withManualEntryText: true,
        withShowLabelInDescription: true,
        ...params,
      }),
  }
}

export default useProtectionMeanTypesField
