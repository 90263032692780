import * as React from 'react'
import { FC, useMemo } from 'react'
import { CourseChapterTheme } from '@models/scorm'
import Table from '@organisms/Table'

const COLUMNS = [
  {
    title: 'Курс',
    dataIndex: 'course',
  },
  {
    title: 'Раздел',
    dataIndex: 'courseChapter',
  },
]

type DataItem = {
  course: string
  courseChapter: string
  key: string
}

type Props = {
  courseChapterThemes: CourseChapterTheme[]
}

const ThemeTable: FC<Props> = ({ courseChapterThemes }) => {
  const tableData = useMemo(() => {
    const data: DataItem[] = []
    for (const { courseChapter } of courseChapterThemes) {
      data.push({
        key: courseChapter['@id'],
        courseChapter: courseChapter.title,
        course: courseChapter.course.title,
      })
    }

    return data
  }, [courseChapterThemes])

  return <Table columns={COLUMNS} data={tableData} />
}

export default ThemeTable
