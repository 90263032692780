import { FC } from 'react'
import { BannerSettings } from '@models/settings'
import BannerComponent from '../../BannerComponent'
import BearButton from '../../BearButton'

type Props = {
  bannerSettings: BannerSettings
}

const SubscribeEnd: FC<Props> = ({ bannerSettings }) => {
  const buttons = bannerSettings.buttons || []
  const filteredButtons = buttons.filter(({ title }) => title?.length > 0)

  return (
    <BannerComponent
      buttons={filteredButtons.map((item) => (
        <BearButton {...item} key={item.id} />
      ))}
      description={bannerSettings.text || 'Подписка заканчивается'}
    />
  )
}

export default SubscribeEnd
