import { FC } from 'react'
import { useToggle } from 'react-use'
import { CourseChapter } from '@models/courseChapter'
import Link, { LinkStyle } from '@organisms/Link'
import Modal from '@organisms/Modal'
import ChapterModal from '@modals/ChapterModal'

type Props = {
  courseIri: string
  chapter: CourseChapter
}

const EditChapterLink: FC<Props> = ({ courseIri, chapter }) => {
  const [visible, toggleVisible] = useToggle(false)

  const handleClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation()
    toggleVisible(true)
  }

  return (
    <>
      <Link
        text="Изменить"
        linkStyle={LinkStyle.DEFAULT}
        onClick={handleClick}
        fontSize={10}
      />
      <Modal visible={visible} onModalClose={toggleVisible}>
        <ChapterModal
          isEdit
          courseIri={courseIri}
          handleCloseModal={toggleVisible}
          chapter={chapter}
        />
      </Modal>
    </>
  )
}

export default EditChapterLink
