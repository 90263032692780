import { FilterItem, FilterState } from '@hooks/useItemsFilters'

export const calculateFilters = (
  filters: Record<string, Record<string, unknown>>
) => {
  return Object.entries(filters).reduce((acc, [key, value]) => {
    if (!value) {
      return acc
    }

    acc[key] = Array.isArray(value) ? value.map((item) => item) : value
    return acc
  }, {} as Record<string, unknown>)
}

export const createInitFilter = (
  name: string,
  value: FilterItem
): FilterState => ({
  [name]: value,
})
