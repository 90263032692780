import { UseFormReturn } from 'react-hook-form'
import usePositions from '@hooks/usePositions'
import { Division } from '@models/division'
import { createInitFilter } from '@utils/filters'
import { JobData } from './useJobDataForFields'
import useHasJobs from './useHasJobs'

const usePositionsJobData = (
  methods: UseFormReturn,
  divisionsJobData: JobData | null
): JobData | null => {
  const { watch } = methods
  const division: Division | undefined = watch('division')

  const { hasDivision } = useHasJobs()

  const positionsResponse = usePositions({
    skip: hasDivision && !division,
    ...(division?.id
      ? {
          initFilters: createInitFilter('subdivision', division?.id),
        }
      : {}),
  })
  const { items = [], length = 0 } = positionsResponse.data || {}
  const positionsIsLoading = positionsResponse.isLoading

  return {
    isDisabled: hasDivision && (!division || !!divisionsJobData?.isLoading),
    options: items,
    length,
    isLoading: positionsIsLoading,
    setFilters: positionsResponse.setFilters,
    setPage: positionsResponse.setPage,
  }
}

export default usePositionsJobData
