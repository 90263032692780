import { FetchArgs } from '@reduxjs/toolkit/query/react'
import { ItemsFromResponse } from '@models/common'
import {
  CreateDocumentBody,
  Document,
  DocumentOption,
  DocumentQueryParams,
  DocumentServerResponse,
  UpdateDocumentBody,
} from '@models/document'
import StoreTags from '@enums/StoreTags'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'
import emptySplitApi from '../api'

const BASE_URL = '/api/documents'

export const documentsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDocuments: builder.query<
      { items: DocumentOption[]; length: number },
      DocumentQueryParams
    >({
      providesTags: [StoreTags.DOCUMENTS],
      query: (params) => ({
        url: BASE_URL,
        params,
      }),
      transformResponse: (response: ItemsFromResponse<Document>) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          value: item['@id'],
          label: item.title,
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
    }),
    postDocument: builder.mutation<DocumentServerResponse, CreateDocumentBody>({
      invalidatesTags: (result) => (result ? [StoreTags.DOCUMENTS] : []),
      query: (body) => ({
        url: BASE_URL,
        method: 'POST',
        body,
      }),
    }),
    putDocument: builder.mutation<DocumentServerResponse, UpdateDocumentBody>({
      invalidatesTags: (result) =>
        result ? [StoreTags.DOCUMENTS, StoreTags.DOCUMENT] : [],
      query: ({ id, ...body }) => ({
        url: `${BASE_URL}/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteDocument: builder.mutation<unknown, number>({
      invalidatesTags: [StoreTags.DOCUMENTS],
      query: (id): FetchArgs => ({
        url: `${BASE_URL}/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetDocumentsQuery,
  usePostDocumentMutation,
  usePutDocumentMutation,
  useDeleteDocumentMutation,
} = documentsApi
