import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import useMe from '@hooks/useMe'
import InfoField from '@organisms/InfoField'
import { Subdivision } from '@models/user'
import { Routes } from '@enums/Routes'

type Props = {
  division: Subdivision
}

const DivisionInfoField: FC<Props> = ({ division }) => {
  const { isAdmin, isCompanyCurator, isFilialCurator } = useMe()
  const navigate = useNavigate()

  const handleOpen = async () => {
    navigate(Routes.DIVISIONS + `?id=${division.id}`)
  }

  if (isAdmin || isCompanyCurator || isFilialCurator) {
    return (
      <InfoField
        onTabClick={handleOpen}
        label="Подразделение"
        value={division.title}
        withStopPropagation={false}
      />
    )
  }

  return null
}

export default DivisionInfoField
