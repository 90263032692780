import React, { Dispatch, FC, SetStateAction, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { FilterState } from '@hooks/useItemsFilters'
import Link, { LinkStyle } from '@organisms/Link'
import { InputProps } from '@organisms/Input'
import { CheckboxProps } from '@organisms/Checkbox'
import { EmployeeSelectProps } from '@templates/EmployeeSelect'
import { Company } from '@models/company'
import { Filial } from '@models/filial'
import { Division } from '@models/division'
import { SelectProps } from '@organisms/Select'
import CollapsibleFilters from './CollapsibleFilters'
import { FiltersWrapper, LinkWrapper } from './styled'
import Sorter, { SorterProps, SortStates } from './Sorter'
import Filter, { FilterProps } from './Filter'

export type FiltersProps = FilterProps<
  SelectProps | InputProps | CheckboxProps | EmployeeSelectProps
>[]

type Props = {
  filters: FiltersProps
  sorters?: SorterProps[]
  setFilters?: Dispatch<SetStateAction<FilterState>>
  onSort?: (state: { sortState: SortStates; fieldName: string }) => void
  isLoading?: boolean
  filtersState: FilterState
  initialFiltersState: FilterState
  setFiltersState: Dispatch<SetStateAction<FilterState>>
  handleClearFiltersState: () => void
}

const getFieldNameForSubmit = (name: string, filters: FiltersProps): string => {
  return (
    filters.find((filter) => filter.fieldName === name)?.fieldNameForSubmit ||
    name
  )
}

const Filters: FC<Props> = ({
  filters,
  setFilters,
  isLoading,
  sorters,
  onSort,
  filtersState,
  initialFiltersState,
  setFiltersState,
  handleClearFiltersState,
}) => {
  const onFilter = (newFilter: FilterState) => {
    setFilters?.((prev) => ({
      ...prev,
      ...newFilter,
    }))
    setFiltersState((prev) => ({
      ...prev,
      ...newFilter,
    }))
  }

  const {
    watch,
    formState: { isDirty },
  } = useFormContext()

  const company: Company | undefined = watch('company')
  const filial: Filial | undefined = watch('filial')
  const division: Division | undefined = watch('division')

  useEffect(() => {
    if (isDirty) {
      onFilter({
        [getFieldNameForSubmit('filial', filters)]: null,
        [getFieldNameForSubmit('division', filters)]: null,
        [getFieldNameForSubmit('position', filters)]: null,
      })
    }
  }, [company])

  useEffect(() => {
    if (isDirty) {
      onFilter({
        [getFieldNameForSubmit('division', filters)]: null,
        [getFieldNameForSubmit('position', filters)]: null,
      })
    }
  }, [filial])

  useEffect(() => {
    if (isDirty) {
      onFilter({
        [getFieldNameForSubmit('position', filters)]: null,
      })
    }
  }, [division])

  if (filters.length === 0 && (!sorters || sorters?.length === 0)) {
    return null
  }

  const inCollapsible = (sorters?.length || 0) + filters.length > 4

  const filtersContent = (
    <FiltersWrapper>
      {sorters?.map((sorter) => (
        <Sorter
          {...sorter}
          onSort={onSort}
          key={sorter.title}
          isLoading={isLoading}
        />
      ))}
      {filters.map((item) => (
        <Filter
          key={item.fieldName}
          filter={item}
          onFilter={onFilter}
          filtersState={filtersState}
          isLoading={isLoading}
          initialFiltersState={initialFiltersState}
        />
      ))}
      {Object.entries(filtersState).some(([key, item]) => {
        if (key === 'search') {
          return false
        }

        return Array.isArray(item) ? item.length > 0 : !!item
      }) && (
        <LinkWrapper>
          <Link
            onClick={handleClearFiltersState}
            text="Очистить фильтры"
            linkStyle={LinkStyle.DEFAULT}
          />
        </LinkWrapper>
      )}
    </FiltersWrapper>
  )

  if (inCollapsible) {
    return <CollapsibleFilters>{filtersContent}</CollapsibleFilters>
  }

  return filtersContent
}

export default Filters
