import React, { FC } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { useToggle } from 'react-use'
import { useMediaQuery } from 'react-responsive'
import NotFound from '@pages/NotFound'
import { isMobile } from '@const/device'
import useAuth from '@hooks/useAuth'
import useMe from '@hooks/useMe'
import PrivacyPolicy from '@pages/PrivacyPolicy'
import { Routes as RoutesEnum } from '@enums/Routes'
import Init from '@pages/Init'
import Auth from '@pages/Auth'
import Loader from '@organisms/Loader'
import MODAL_ROUTES_CONFIG from '../routes/modalRoutes'
import { xl } from '../theme/breakpoints'
import CookieModal from './CookieModal'
import Header from './Header'
import Menu from './Menu'
import { ContentWrapper, MainContainer, MainLayoutWrapper } from './styled'
import InfoBanner from './InfoBanner'
import ChangeExpiredPasswordModal from './ChangeExpiredPasswordModal'
import ROUTES_CONFIG from 'src/routes'

const App: FC = () => {
  const location = useLocation()
  const state = location.state as { backgroundLocation?: Location }
  const me = useMe()
  const { roles: myRoles = [], id } = me
  const { auth } = useAuth()
  const isTablet = useMediaQuery({ query: `(max-width: ${xl})` })

  const [expandedMobileMenu, toggleExpandedMobileMenu] = useToggle(false)
  const [isSmallDesktopMenu, toggleIsSmallDesktopMenu] = useToggle(isTablet)

  const isAuth = !!auth.token

  return (
    <>
      <InfoBanner />
      {isAuth ? (
        <MainLayoutWrapper>
          <Menu
            setExpandedMobileMenu={toggleExpandedMobileMenu}
            onExpandMenu={toggleIsSmallDesktopMenu}
          />
          <MainContainer>
            <div
              id="top-header-scroll"
              style={{
                width: 1,
                height: 1,
              }}
            />
            {!isMobile && <Header />}
            <Loader isLoading={!id}>
              <ContentWrapper
                expandedMobileMenu={expandedMobileMenu}
                expandedDesktopMenu={!isSmallDesktopMenu}
              >
                {id && (
                  <>
                    <Routes location={state?.backgroundLocation || location}>
                      <Route element={<Init />} path={RoutesEnum.AUTH} />
                      {ROUTES_CONFIG.map(({ element, roles, route }) => {
                        const enabled = roles
                          ? myRoles.some((myRole) => roles.has(myRole))
                          : true

                        if (!enabled || !element) return null

                        return (
                          <Route element={element} path={route} key={route} />
                        )
                      })}
                      <Route element={<NotFound />} path="*" />
                    </Routes>
                    <Routes>
                      {MODAL_ROUTES_CONFIG.map(({ element, roles, route }) => {
                        const enabled = roles
                          ? myRoles.some((myRole) => roles.has(myRole))
                          : true

                        if (!enabled) return null

                        return (
                          <Route element={element} path={route} key={route} />
                        )
                      })}
                    </Routes>
                  </>
                )}
              </ContentWrapper>
            </Loader>
          </MainContainer>
        </MainLayoutWrapper>
      ) : (
        <Routes>
          <Route element={<Auth />} path={RoutesEnum.AUTH} />
          <Route element={<PrivacyPolicy />} path={RoutesEnum.PRIVACY_POLICY} />
          <Route element={<Init />} path="*" />
        </Routes>
      )}
      <CookieModal />
      <ChangeExpiredPasswordModal />
    </>
  )
}

export default App
