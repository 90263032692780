import { FC } from 'react'
import { ReactComponent as Close } from '@interface-images/close-white.svg'
import { DocumentType } from '@models/documentType'
import { useDeleteDocumentTypeMutation } from '@services/documentTypes'
import DeleteButton from '@templates/DeleteButton'
import { CloseWrapper, Item, TextWrapper } from './styled'

type Props = {
  item: DocumentType
}

const DocumentTypeItem: FC<Props> = ({ item: { title, id } }) => {
  const [deleteDocumentType, data] = useDeleteDocumentTypeMutation()

  const handleDelete = () => {
    deleteDocumentType(id)
  }

  return (
    <Item key={title}>
      <TextWrapper>{title}</TextWrapper>
      <CloseWrapper>
        <DeleteButton
          {...data}
          onClick={handleDelete}
          CustomComponent={Close}
          deleteDescription={`Вы действительно хотите удалить тип: ${title}?`}
        />
      </CloseWrapper>
    </Item>
  )
}

export default DocumentTypeItem
