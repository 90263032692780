import { JoyStep } from '@templates/Onboarding'

export const ONBOARDING_TITLE = 'Расскажем про содержание раздела'

const TITLE = 'Список тем'

export const ONBOARDING_STEPS: JoyStep[] = [
  {
    target: '#chapter',
    title: TITLE,
    content: (
      <div>
        <div>Чтобы перейти к теме, нажмите на неё.</div>
        <div>В списке тем вы будете видеть статусы их прохождения:</div>
        <ul>
          <li>Серый: тема не была открыта</li>
          <li>Жёлтый: тема была начата, но не завершена</li>
          <li>Зелёный: тема была полностью пройдена</li>
        </ul>
      </div>
    ),
  },
]
