import { Skeleton } from 'antd'
import { FC } from 'react'
import useMe from '@hooks/useMe'
import { useGetEmployeeCourseStatsQuery } from '@services/statistic'
import { User } from '@models/user'
import StatisticTemplate from '@templates/StatisticTemplate'
import ContentBlock from '@organisms/ContentBlock'
import Title from '@organisms/Title'
import { Wrapper } from './styled'

const Statistic: FC = () => {
  const me = useMe()
  const { data: stats, isLoading } = useGetEmployeeCourseStatsQuery(
    me?.id as number,
    { skip: !me }
  )
  const { items = [] } = stats || {}
  return (
    <Wrapper>
      <Title>Статистика</Title>
      {isLoading ? (
        <Skeleton.Button
          active
          block
          style={{
            borderRadius: 25,
            height: '62vh',
          }}
        />
      ) : (
        <ContentBlock>
          <StatisticTemplate user={me as unknown as User} stats={items} />
        </ContentBlock>
      )}
    </Wrapper>
  )
}

export default Statistic
