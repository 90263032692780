import { FC } from 'react'
import useFormProcessor from '@hooks/useFormProcessor'
import {
  usePostCourseThemeMutation,
  usePutCourseThemeMutation,
} from '@services/courseThemes'
import { CommonCourseThemeBody, CourseThemeDetailed } from '@models/courseTheme'
import AddNewItemModal from '@templates/AddNewItemModal'
import { Option } from '@organisms/Select'
import useThemeFields from './useThemeFields'
import getDefaultValues from './getDefaultValues'

export type FormValues = {
  courseTheme: Option
  title: string
  test?: boolean
  hoursTime?: string
}

type Props = {
  isEdit?: boolean
  theme?: CourseThemeDetailed
  chapterIri: string
  handleCloseModal: () => void
}

const ThemeModal: FC<Props> = ({
  isEdit = false,
  theme,
  chapterIri,
  handleCloseModal,
}) => {
  const fields = useThemeFields()

  const [postCourseTheme, postData] = usePostCourseThemeMutation()
  const [putCourseTheme, putData] = usePutCourseThemeMutation()
  const additionalProps = isEdit ? putData : postData

  const methods = useFormProcessor({
    defaultValues: getDefaultValues(theme),
    apiErrors: additionalProps.error,
  })

  const test = methods.watch('test')

  const handleSubmitForm = (form: FormValues) => {
    const commonForm: CommonCourseThemeBody = {
      title: form.title,
      courseTheme: form.courseTheme?.value,
      courseChapter: chapterIri,
      test,
      hoursTime: Number(form.hoursTime),
    }

    if (isEdit) {
      if (theme) {
        void putCourseTheme({
          ...commonForm,
          id: theme.id,
        })
      }
    } else {
      void postCourseTheme(commonForm)
    }
  }

  return (
    <AddNewItemModal
      {...additionalProps}
      handleCloseModal={handleCloseModal}
      onSubmitForm={handleSubmitForm}
      methods={methods}
      fields={fields}
      isEdit={isEdit}
      titlePostfix="тему"
      successContentAddTitle="Вы успешно добавили тему"
      successContentEditTitle="Вы успешно изменили тему"
      withSaveAndAddMore
    />
  )
}

export default ThemeModal
