import styled from '@emotion/styled'
import { isMobile } from '@const/device'
import Text from '@organisms/Text'

const DesktopWrapper = styled.div<{ isAdmin: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 15px ${({ isAdmin }) => (isAdmin ? '10px' : '20px')};
  width: 23vw;
  background: ${({ theme }) => theme.colors.background.surface};
  box-shadow: 5px 5px 20px rgba(29, 78, 137, 0.1);
  border-radius: 24px;
  margin: ${({
    theme: {
      sizes: { twoBase },
    },
  }) => `0 ${twoBase} ${twoBase} 0`};
  justify-content: space-between;
`
const MobileWrapper = styled(DesktopWrapper)<{ isAdmin: boolean }>`
  width: 100%;
`
export const Wrapper = isMobile ? MobileWrapper : DesktopWrapper

export const TopText = styled(Text)<{
  hovered: boolean
}>`
  color: ${({
    hovered,
    theme: {
      colors: {
        text: { primary, black },
      },
    },
  }) => (hovered ? primary : black)};
  margin-left: 8px;
  font-weight: 500;
`

export const BottomText = styled.div`
  font-size: ${({ theme }) => theme.text.h6.fontSize};
  line-height: ${({ theme }) => theme.text.h6.lineHeight};
  font-weight: 400;
  letter-spacing: 0.1em;
  text-align: left;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-top: 8px;
  margin-left: 8px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: left;
  margin-top: ${({ theme }) => theme.sizes.base};
  width: 100%;
  gap: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
`
