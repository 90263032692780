import { DatePicker } from 'antd'
import styled from '@emotion/styled'

export const StyledDatePicker = styled(DatePicker)<{ hasError?: boolean }>`
  height: 40px;

  ${({ hasError }) => (hasError ? 'border: 1px solid #EF4949;' : '')}
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.disable};
  }
`
