import styled from '@emotion/styled'

export const MobWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 96%;
  background-color: ${({ theme }) => theme.colors.background.surface};
  padding: 25px;
  overflow-y: scroll;
  margin-top: 55px;
  gap: 25px;
`
