import { UseFormReturn } from 'react-hook-form'
import { compact } from 'lodash'
import useJobFields from '@hooks/useJobFields'
import { fileField } from '@templates/AddNewItemModal/templates'
import { Fields } from '@templates/AddNewItemModal/Content'

const useManyEmployeesFields = (methods: UseFormReturn): Fields => {
  const { company, filial } = useJobFields(methods)

  return [
    {
      lineId: 'fieldsLine',
      fields: compact([
        company?.({
          isRequired: true,
        }),
        filial?.({
          isRequired: true,
        }),
        fileField({
          name: 'file',
          label: 'Штатное расписание',
        }),
      ]),
    },
  ]
}

export default useManyEmployeesFields
