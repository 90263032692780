import { ItemsFromResponse } from '@models/common'
import {
  CreateDocumentTypeBody,
  DocumentType,
  DocumentTypeServerResponse,
} from '@models/documentType'
import StoreTags from '@enums/StoreTags'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'
import emptySplitApi from '../api'

const BASE_URL = '/api/document_types'

export const documentTypesApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDocumentTypes: builder.query<
      { items: DocumentType[]; length: number },
      Record<string, unknown>
    >({
      providesTags: [StoreTags.DOCUMENT_TYPES],
      query: (params) => ({
        url: BASE_URL,
        params,
      }),
      transformResponse: (
        response: ItemsFromResponse<DocumentTypeServerResponse>
      ) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          label: item.title,
          value: item['@id'],
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
    }),
    createDocumentType: builder.mutation<unknown, CreateDocumentTypeBody>({
      invalidatesTags: (result) => (result ? [StoreTags.DOCUMENT_TYPES] : []),
      query: (body) => ({
        url: BASE_URL,
        body,
        method: 'POST',
      }),
    }),
    deleteDocumentType: builder.mutation<unknown, string | number>({
      invalidatesTags: [StoreTags.DOCUMENT_TYPES, StoreTags.DOCUMENTS],
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetDocumentTypesQuery,
  useCreateDocumentTypeMutation,
  useDeleteDocumentTypeMutation,
} = documentTypesApi
