import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { FooterProps } from '@templates/AddNewItemModalLayout/Footer'
import { Routes } from '@enums/Routes'
import JobsFooter from '@templates/JobsFooter'
import { PositionDetailed } from '@models/position'
import { EmployeeDetailed } from '@models/employee'

const Footer: FC<FooterProps> = (props) => {
  const { state } = useLocation()

  return (
    <JobsFooter
      {...props}
      additionalProps={{
        createOptions: [
          {
            label: 'Компанию',
            value: Routes.COMPANY_MODAL,
          },
          {
            label: 'Филиал',
            value: Routes.FILIAL_MODAL,
          },
          {
            label: 'Подразделение',
            value: Routes.DIVISION_MODAL,
          },
        ],
        nextRoute: Routes.EMPLOYEE_MODAL,
        nextRouteDescription: 'Переход к созданию сотрудника',
        getNextState: (data: PositionDetailed): Partial<EmployeeDetailed> => ({
          company: state?.company,
          companyBranch: state?.companyBranch,
          subdivisionJobPositionRels: [
            {
              subdivision: state?.subdivision,
              jobPosition: data,
              '@id': '',
            },
          ],
        }),
      }}
    />
  )
}

export default Footer
