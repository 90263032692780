import { FC } from 'react'
import { ReactComponent as Logo } from '@interface-images/main-logo.svg'
import { ReactComponent as Burger } from '@main-menu/burger.svg'
import { ReactComponent as BlackBurger } from '@main-menu/black-burger.svg'
import useMe from '@hooks/useMe'
import { useGetBlobFileQuery } from '@services/files'
import { isMobile } from '@const/device'
import Bell from '../../../Header/Bell'
import NameWithAvatar from '../../../Header/NameWithAvatar'
import { Container, RightWrapper, Wrapper } from './styled'

type Props = {
  handleBurgerClick: () => void
}

const MobileHeader: FC<Props> = ({ handleBurgerClick }) => {
  const { company } = useMe()

  const { data: logoUrl } = useGetBlobFileQuery(
    company?.logo?.filePath as string,
    {
      skip: !company?.logo?.filePath,
    }
  )

  const BurgerComponent = isMobile ? BlackBurger : Burger

  return (
    <Container>
      <Wrapper>
        {logoUrl ? <img src={logoUrl} width={25} /> : <Logo />}
        <RightWrapper>
          <Bell />
          <NameWithAvatar />
          <div onClick={handleBurgerClick}>
            <BurgerComponent />
          </div>
        </RightWrapper>
      </Wrapper>
    </Container>
  )
}

export default MobileHeader
