import { UseFormReturn } from 'react-hook-form'
import useDivisions from '@hooks/useDivisions'
import { Filial } from '@models/filial'
import { createInitFilter } from '@utils/filters'
import useHasJobs from './useHasJobs'
import { JobData } from './useJobDataForFields'

const useDivisionsJobData = (
  methods: UseFormReturn,
  filialsJobData: JobData | null
): JobData | null => {
  const { watch } = methods
  const filial: Filial | undefined = watch('filial')

  const { hasFilial, hasDivision } = useHasJobs()

  const divisionsResponse = useDivisions({
    skip: hasFilial && !filial,
    ...(filial?.id
      ? { initFilters: createInitFilter('companyBranch', filial?.id) }
      : {}),
  })
  const { items = [], length = 0 } = divisionsResponse.data || {}
  const divisionsIsLoading = divisionsResponse.isLoading

  if (!hasDivision) {
    return null
  }

  return {
    isDisabled: hasFilial && (!filial || !!filialsJobData?.isLoading),
    options: items,
    length,
    isLoading: divisionsIsLoading,
    setPage: divisionsResponse.setPage,
    setFilters: divisionsResponse.setFilters,
  }
}

export default useDivisionsJobData
