import { FC } from 'react'
import { Scorm } from '@models/scorm'
import { ListItemProps } from '@templates/List'
import Accordion, { AccordionHeaderProps } from '@organisms/Accordion'
import ThemeSmiles from '@templates/ThemeSmiles'
import { ReactComponent as Eye } from '@interface-images/eye.svg'
import Body from './Body'
import {
  HeaderWrapper,
  RightWrapper,
  StyledTitle,
  ViewsWrapper,
} from './styled'

const Theme: FC<ListItemProps<Scorm>> = ({
  isLoading,
  item: { title, id, badVotes, excellentVotes, goodVotes, views = 0 },
  opened,
  handleClickOnItem,
}) => {
  const header: FC<AccordionHeaderProps> = ({ hovered }) => (
    <HeaderWrapper>
      <StyledTitle hovered={hovered}>{title}</StyledTitle>
      <RightWrapper>
        <ThemeSmiles
          sadDescription={badVotes}
          happyDescription={goodVotes}
          lovelyDescription={excellentVotes}
        />
        <ViewsWrapper>
          <Eye />
          {views}
        </ViewsWrapper>
      </RightWrapper>
    </HeaderWrapper>
  )

  return (
    <Accordion
      skeletonHeight={100}
      handleClickOnHeader={handleClickOnItem}
      expanded={opened}
      initLoading={isLoading}
      Header={header}
      Body={({ onChangeSize, toggleLoading }) => (
        <Body
          id={id}
          onChangeSize={onChangeSize}
          toggleLoading={toggleLoading}
        />
      )}
      key={id}
    />
  )
}

export default Theme
