import { FC, useContext } from 'react'
import { Switch } from 'antd'
import { TopControllersContext } from '@modals/RiskMapModal/Dangers'
import {
  BottomWrapper,
  Container,
  EtnSubTitle,
  EtnTitle,
  Wrapper,
} from './styled'

const TopControllers: FC = () => {
  const {
    enabledEtn,
    enabledAutoAddSiz,
    toggleEnabledEtn,
    toggleEnabledAutoAddSiz,
  } = useContext(TopControllersContext)

  const handleChangeEtn = () => {
    toggleEnabledEtn(!enabledEtn)
  }

  const handleChangeAutoAdd = () => {
    toggleEnabledAutoAddSiz(!enabledAutoAddSiz)
  }

  return (
    <Container>
      <Wrapper>
        <EtnTitle>Выберите тип наименования</EtnTitle>
        <BottomWrapper>
          <EtnSubTitle>Только по ЕТН</EtnSubTitle>
          <Switch checked={!enabledEtn} onChange={handleChangeEtn} />
          <EtnSubTitle>Все опасности</EtnSubTitle>
        </BottomWrapper>
      </Wrapper>
      <Wrapper>
        <EtnTitle>Добавлять СИЗ</EtnTitle>
        <BottomWrapper>
          <EtnSubTitle>Автоматически</EtnSubTitle>
          <Switch checked={!enabledAutoAddSiz} onChange={handleChangeAutoAdd} />
          <EtnSubTitle>Только вручную</EtnSubTitle>
        </BottomWrapper>
      </Wrapper>
    </Container>
  )
}

export default TopControllers
