import styled from '@emotion/styled'
import { isMobile } from '@const/device'

export const Wrapper = styled.div<{ hidden: boolean }>`
  margin-top: 10px;
  margin-left: -5px;

  ${({ hidden }) => (hidden ? 'visibility: hidden;' : '')}
`

export const AddButtonWrapper = styled.div`
  display: inline-block;
  margin-bottom: 25px;
`

export const DeleteWrapper = styled.div`
  padding-top: ${() => (isMobile ? 0 : 28)}px;

  margin: ${() => (isMobile ? -15 : 0)}px 5px 15px -5px;
`

export const StyledFlexWrapper = styled.div`
  display: flex;
  flex-direction: ${() => (isMobile ? 'column' : 'row')};
`

export const Container = styled.div`
  margin-top: 20px;
  margin-left: -5px;
`
