import * as React from 'react'
import { FC } from 'react'
import { WhomAssignee } from '@enums/WhomAssignee'
import AccordionBodyField from '@organisms/AccordionBodyField'
import { EmployeeEducationDetailed } from '@models/employeeEducation'
import CompanyInfoField from '@templates/CompanyInfoField'
import FilialInfoField from '@templates/FilialInfoField'
import DivisionInfoField from '@templates/DivisionInfoField'
import PositionInfoField from '@templates/PositionInfoField'
import { GroupWrapper } from './styled'

type Props = {
  education: EmployeeEducationDetailed | undefined
}

const AssignedGroupField: FC<Props> = ({ education }) => {
  const {
    company,
    companyBranch,
    subdivision,
    jobPosition,
    whomAssign,
    employees = [],
  } = education || {}

  let content = null

  if (whomAssign !== WhomAssignee.EMPLOYEE) {
    content = (
      <GroupWrapper>
        {company && <CompanyInfoField company={company} />}
        {companyBranch && <FilialInfoField filial={companyBranch} />}
        {subdivision && <DivisionInfoField division={subdivision} />}
        {jobPosition && <PositionInfoField position={jobPosition} />}
      </GroupWrapper>
    )
  } else if (employees.length > 0) {
    content = (
      <GroupWrapper>
        {employees.map(({ fullName }) => (
          <div key={fullName}>{fullName}</div>
        ))}
      </GroupWrapper>
    )
  }

  return (
    <AccordionBodyField
      withoutFixedHeight
      label="Группа назначения"
      value={content}
    />
  )
}

export default AssignedGroupField
