import styled from '@emotion/styled'
import { isMobile } from '@const/device'

const DesktopH1 = styled.div`
  font-size: ${({ theme }) => theme.text.h2.fontSize};
  line-height: ${({ theme }) => theme.text.h2.lineHeight};
  font-weight: 700;
  letter-spacing: 0;
  text-align: left;
  white-space: nowrap;
`
const MobileH1 = styled(DesktopH1)``
export const H1 = isMobile ? MobileH1 : DesktopH1

export const H2 = styled.div`
  font-size: ${({ theme }) => theme.text.h5.fontSize};
  line-height: ${({ theme }) => theme.text.h5.lineHeight};
  font-weight: 400;
  letter-spacing: 0.1em;
  text-align: left;
  text-transform: uppercase;
  white-space: nowrap;
`

export const H3 = styled.div`
  font-size: ${({ theme }) => theme.text.h6.fontSize};
  line-height: ${({ theme }) => theme.text.h6.lineHeight};
  font-weight: 400;
  letter-spacing: 0.1em;
  text-align: left;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.secondary};
  width: 100px;
  white-space: nowrap;
`

export const H4 = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
`
