import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { CourseThemeListItemServerResponse } from '@models/courseTheme'
import getThemeRoute from '@utils/getThemeRoute'
import FlexWrapper from '@organisms/FlexWrapper'
import Badge from '@organisms/Badge'
import { useTheme } from '@emotion/react'
import { ProgressStatuses } from '@enums/ProgressStatuses'
import { Number, Wrapper } from './styled'

type Props = {
  courseIri: string
  educationId: number
  number: string
} & CourseThemeListItemServerResponse

const Theme: FC<Props> = ({
  number,
  id,
  title,
  courseIri,
  educationId,
  currentListenerStatus,
}) => {
  const navigate = useNavigate()
  const {
    colors: { warning, success, disable },
  } = useTheme()

  const handleClick = () => {
    navigate(getThemeRoute(id, courseIri, educationId))
  }

  const badgeColor = !currentListenerStatus
    ? 'none'
    : currentListenerStatus === ProgressStatuses.PASSED
    ? success
    : currentListenerStatus === ProgressStatuses.IN_PROCESS
    ? warning
    : disable

  return (
    <Wrapper key={id} onClick={handleClick}>
      <FlexWrapper>
        <Number>{number}</Number>
        {title}
      </FlexWrapper>
      <Badge badgeColor={badgeColor} />
    </Wrapper>
  )
}

export default Theme
