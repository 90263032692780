/* eslint-disable sonarjs/no-duplicate-string */

import { JoyStep } from '@templates/Onboarding'

export const ONBOARDING_TITLE = 'Расскажем как создать обучение'

export const ONBOARDING_STEPS: JoyStep[] = [
  {
    target: '#whomAssign',
    title: 'Создание обучения',
    content: (
      <div>
        <div>Выберете для кого будет создано обучение.</div>
        <div>
          Обучение станет доступно для всех сотрудников, которые будут входить
        </div>
        <div>
          в выбранный филиал/подразделение/должность/список сотрудников.
        </div>
        <div>После сохранения вы не сможете изменить выбор!</div>
      </div>
    ),
  },
  {
    target: '#title',
    title: 'Создание обучения',
    content: (
      <div>
        <div>Введите название обучения.</div>
        <div>По нему вы сможете в дальнейшем найти обучение.</div>
      </div>
    ),
  },
  {
    target: '#type',
    title: 'Создание обучения',
    content: (
      <div>
        <div>Выберите тип назначения.</div>
        <div>В дальнейшем вы не сможете его изменить!</div>
      </div>
    ),
  },
  {
    target: '#course',
    title: 'Создание обучения',
    content: (
      <div>
        <div>
          <div>Вы можете выбрать курс для обучения или инструктажа</div>
          <div>Доступны следующие инструктажи:</div>
          <ol>
            <li>Охрана труда</li>
            <li>Противопожарный инструктаж</li>
            <li>
              Электробезопасность - I группа по электробезопасности
              неэлектротезническому персоналу
            </li>
          </ol>
        </div>
        <div>После сохранения вы не сможете изменить выбранный курс!</div>
      </div>
    ),
  },
  {
    target: '#deadlineAt',
    title: 'Создание обучения',
    content: (
      <div>
        <div>
          Вы можете указать рекомендуемую дату окончания обучения по курсу.
        </div>
        <div>
          Дата не влияет на доступность курса сотруднику, а носит исключительно
          информативный характер.
        </div>
      </div>
    ),
  },
  {
    target: '#thirdLine',
    title: 'Создание обучения',
    content: 'Заполните данные об обучении.',
  },
  {
    target: '#fourthLine',
    title: 'Создание обучения',
    content: 'Заполните данные об обучении.',
  },
  {
    target: '#fifthLine',
    title: 'Поля для реестра минтруда',
    content:
      'Выделенные поля необходимы для корректной генерации реестра минтруда.',
  },
  {
    target: '#repeatEducationAtMonthBlock',
    title: 'Создание обучения',
    content: (
      <div>
        <div>
          Укажите, через какое количество месяцев повторно назначить обучение,
          если это необходимо.
        </div>
        <div>
          Повторное обучение будет создано автоматически, сотрудники получат
          уведомление на платформе и почте.
        </div>
      </div>
    ),
  },
  {
    target: '#modal-footer',
    title: 'Дальнейшие действия',
    content: (
      <div>
        <div>Сохраните обучение.</div>
        <div>Чтобы получить статистику по обучению,</div>
        <div>создать протокол или реестр минтруда,</div>
        <div>найдите обучение и нажмите соответствующую кнопку.</div>
        <br />
        <div>Чтобы зачесть самостоятельно прохождение обучения,</div>
        <div>перейдите в карточку сотрудника,</div>
        <div>найдите обучение и нажмите кнопку "Зачесть обучение".</div>
      </div>
    ),
  },
]
