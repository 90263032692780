import { FC } from 'react'
import useFormProcessor from '@hooks/useFormProcessor'
import { usePostCourseMutation, usePutCourseMutation } from '@services/courses'
import { CommonCourseBody, CourseDetailed } from '@models/course'
import AddNewItemModal from '@templates/AddNewItemModal'
import { Option } from '@organisms/Select'
import useCoursesFields from './useCoursesFields'
import getDefaultValues from './getDefaultValues'

export type FormValues = {
  courseCategory: Option
  hoursCount: string
  descriptionLink: string
  title: string
  type: Option
  subType: Option
  free: boolean
  safeProgram: boolean
  ministryLaborId: string
  company?: Option
  filial?: Option
  electricalSafetyFirstGroup: boolean
}

type Props = {
  isEdit?: boolean
  handleCloseModal: () => void
  course?: CourseDetailed
}

const CourseModal: FC<Props> = ({
  isEdit = false,
  handleCloseModal,
  course,
}) => {
  const [createCourse, createData] = usePostCourseMutation()
  const [updateCourse, updateData] = usePutCourseMutation()
  const additionalProps = isEdit ? updateData : createData

  const methods = useFormProcessor({
    apiErrors: additionalProps.error,
    defaultValues: getDefaultValues(course),
  })

  const fields = useCoursesFields(methods)

  const free = methods.watch('free')
  const safeProgram = methods.watch('safeProgram')
  const electricalSafetyFirstGroup = methods.watch('electricalSafetyFirstGroup')

  const handleSubmitForm = (form: FormValues) => {
    const resultForm: CommonCourseBody = {
      descriptionLink: form.descriptionLink,
      title: form.title,
      hoursCount: +form.hoursCount,
      courseCategory: form.courseCategory.value,
      type: form.type.value,
      subType: form.subType.value,
      published: course ? course.published : false,
      free,
      safeProgram,
      company: form.company?.value,
      companyBranch: form.filial?.value,
      ministryLaborId: form.ministryLaborId,
      electricalSafetyFirstGroup,
    }

    if (isEdit) {
      if (course) {
        updateCourse({
          ...resultForm,
          id: course.id,
        })
      }
    } else {
      createCourse(resultForm)
    }
  }

  return (
    <AddNewItemModal
      {...additionalProps}
      handleCloseModal={handleCloseModal}
      onSubmitForm={handleSubmitForm}
      methods={methods}
      fields={fields}
      isEdit={isEdit}
      titlePostfix="курс"
      successContentAddTitle="Курс успешно добавлен"
      successContentEditTitle="Курс успешно изменен"
    />
  )
}

export default CourseModal
