import { Option } from '@organisms/Select'
import { AssignType } from '@enums/AssignType'

const ASSIGN_TYPES: Option[] = [
  {
    label: 'Вводный инструктаж',
    value: AssignType.INITIAL_BRIEFING,
  },
  {
    label: 'Первичный инструктаж',
    value: AssignType.INITIATORY_BRIEFING,
  },
  {
    label: 'Повторный инструктаж',
    value: AssignType.RE_BRIEFING,
  },
  {
    label: 'Внеплановый инструктаж',
    value: AssignType.UNSCHEDULED_BRIEFING,
  },
  {
    label: 'Целевой инструктаж',
    value: AssignType.TARGETED_BRIEFING,
  },
  {
    label: 'Плановое обучение',
    value: AssignType.SCHEDULED_TRAINING,
  },
  {
    label: 'Внеплановое обучение',
    value: AssignType.UNSCHEDULED_TRAINING,
  },
]

export default ASSIGN_TYPES
