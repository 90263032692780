import { UseFormReturn } from 'react-hook-form'
import { compact } from 'lodash'
import {
  dateField,
  selectField,
  textField,
} from '@templates/AddNewItemModal/templates'
import { Fields } from '@templates/AddNewItemModal/Content'
import { Option } from '@organisms/Select'
import RiskMapStatuses from '@enums/RiskMapStatuses'

const NOT_CHANGE_STATUSES = new Set([
  RiskMapStatuses.CANCELED,
  RiskMapStatuses.ARCHIVED,
])

const getStatusesOptions = (currentStatus: RiskMapStatuses): Option[] =>
  compact([
    !NOT_CHANGE_STATUSES.has(currentStatus) && {
      value: RiskMapStatuses.CHANGED,
      label: 'Изменён',
    },
    !NOT_CHANGE_STATUSES.has(currentStatus) && {
      value: RiskMapStatuses.CANCELED,
      label: 'Отменён',
    },
    {
      value: RiskMapStatuses.EXTENDED,
      label: 'Продлён',
    },
  ])

const useApproveModalFields = (
  { watch }: UseFormReturn,
  currentStatus: RiskMapStatuses
): Fields => {
  const status: Option<RiskMapStatuses> | undefined = watch('status')

  return [
    {
      fields: [
        selectField({
          name: 'status',
          label: 'Статус пересмотра',
          isRequired: true,
          options: getStatusesOptions(currentStatus),
          dropdownWidth: 350,
          withoutFetchOptions: true,
        }),
      ],
    },
    {
      lineTitle: 'Основание',
      fields: compact([
        textField({
          label: 'Номер',
          name: 'number',
          placeholder: 'Номер карты рисков',
        }),
        dateField({
          label: 'Дата',
          name: 'date',
        }),
        status?.value !== RiskMapStatuses.CANCELED &&
          dateField({
            label: 'Пересмотреть до',
            name: 'reviseBeforeAt',
          }),
      ]),
    },
  ]
}

export default useApproveModalFields
