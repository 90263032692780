import StoreTags from '@enums/StoreTags'
import { ItemsFromResponse } from '@models/common'
import {
  EndBody,
  IspringTestResults,
  PauseBody,
  StartBody,
  TestResultsBody,
} from '@models/statistic'
import { EmployeeEducationStat } from '@models/employeeEducationStats'
import emptySplitApi from '../api'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'

const INVALIDATED_TAGS = [
  StoreTags.STATISTICS,
  StoreTags.COURSES,
  StoreTags.EMPLOYEE_EDUCATION_DESTINATIONS,
  StoreTags.EMPLOYEE_EDUCATION_DESTINATION,
  StoreTags.CERTIFICATES,
  StoreTags.COURSE_THEMES,
  StoreTags.COURSE_THEME,
  StoreTags.EMPLOYEE_EDUCATION_STATS,
]

export const statisticApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postEnd: builder.mutation<void, EndBody>({
      invalidatesTags: INVALIDATED_TAGS,
      query: (body) => ({
        url: '/api/listener_course_chapter_theme_stats/end',
        method: 'POST',
        body,
      }),
    }),
    postStart: builder.mutation<void, StartBody>({
      invalidatesTags: INVALIDATED_TAGS,
      query: (body) => ({
        url: '/api/listener_course_chapter_theme_stats/start',
        method: 'POST',
        body,
      }),
    }),
    postPause: builder.mutation<void, PauseBody>({
      invalidatesTags: INVALIDATED_TAGS,
      query: (body) => ({
        url: '/api/listener_course_chapter_theme_stats/pause',
        method: 'POST',
        body,
      }),
    }),
    postTestResults: builder.mutation<void, TestResultsBody>({
      invalidatesTags: INVALIDATED_TAGS,
      query: (body) => ({
        url: '/api/listener_course_test_stats',
        method: 'POST',
        body,
      }),
    }),

    getEmployeeCourseStats: builder.query<
      { items: EmployeeEducationStat[]; length: number },
      number
    >({
      query: (id) => ({
        url: '/api/courses/employeeCoursesStats/' + id,
      }),
      transformResponse: (
        response: ItemsFromResponse<EmployeeEducationStat>
      ) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          title: item.title,
          code: item['@id'],
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
      providesTags: [StoreTags.EMPLOYEE_EDUCATION_STATS],
    }),
    postIspringTestResults: builder.mutation<void, IspringTestResults>({
      invalidatesTags: INVALIDATED_TAGS,
      query: (body) => ({
        url: '/api/listener_course_chapter_theme_stats/ispring',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  usePostEndMutation,
  usePostStartMutation,
  usePostPauseMutation,
  usePostTestResultsMutation,
  useGetEmployeeCourseStatsQuery,
  usePostIspringTestResultsMutation,
} = statisticApi
