import { Dispatch, SetStateAction, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import {
  dateField,
  employeeField,
  tabsField,
  textField,
} from '@templates/AddNewItemModal/templates'
import { Fields } from '@templates/AddNewItemModal/Content'

export const EMPLOYEE_PROBATION_PREFIX = 'employeeProbationSheets::'
export const EMPLOYEE_PROBATION_INDEX_SEPARATOR = '____'

export const getName = (name: string, listActive: number) =>
  `${EMPLOYEE_PROBATION_PREFIX}${name}${EMPLOYEE_PROBATION_INDEX_SEPARATOR}${listActive}`

export type StaffingReturnType = {
  fields: Fields
  listActive: number
  setListActive: Dispatch<SetStateAction<number>>
}

const useStaffingFields = (
  methods: UseFormReturn,
  intershipList: number[],
  initActiveList?: number
): StaffingReturnType => {
  const [listActive, setListActive] = useState(initActiveList || 0)

  const company = methods.watch('company')
  const filial = methods.watch('filial')

  const initEmployeeValues = {
    company,
    filial,
  }

  return {
    setListActive,
    listActive,
    fields: [
      {
        fields: [
          tabsField({
            label: '',
            name: '',
            tabProps: {
              tabSize: 'small',
              items: intershipList.map((item) => ({
                id: item.toString(),
                title: `Стажировочный лист №${item + 1}`,
                children: null,
              })),
              active: listActive,
              setActive: setListActive,
            },
            widthMultiplier: 4,
          }),
        ],
      },
      {
        fields: [
          textField({
            name: getName('title', listActive),
            label: 'Наименование',
            placeholder: 'Наименование',
          }),
          dateField({
            name: getName('issueDate', listActive),
            label: 'Дата выдачи',
          }),
          employeeField({
            label: 'Ответственный за стажировку',
            name: getName('responsibleOfProbation', listActive),
            initValues: initEmployeeValues,
            isDisabled: !filial,
          }),
          textField({
            name: getName('probationShiftCount', listActive),
            label: 'Количество смен стажировки',
            placeholder: 'Количество смен стажировки',
          }),
        ],
      },
      {
        fields: [
          dateField({
            name: getName('probationStartAt', listActive),
            label: 'Начало проведения стажировки',
          }),
          dateField({
            name: getName('probationEndAt', listActive),
            label: 'Конец проведения стажировки',
          }),
          employeeField({
            label: 'На стажировку направил',
            name: getName('probationSender', listActive),
            initValues: initEmployeeValues,
            isDisabled: !filial,
          }),
        ],
      },
      {
        fields: [
          textField({
            name: getName('probationReason', listActive),
            label: 'Основание для стажировки',
            placeholder: 'Основание для стажировки',
            widthMultiplier: 3,
          }),
        ],
      },
    ],
  }
}

export default useStaffingFields
