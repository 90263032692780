import { FC } from 'react'
import { Company } from '@models/company'
import Accordion, { AccordionHeaderProps } from '@organisms/Accordion'
import { ListItemProps } from '@templates/List'
import EmployeesCountField from '@templates/EmployeesCountField'
import { StyledTitle, Wrapper } from './styled'
import Body from './Body'

const CompanyItem: FC<ListItemProps<Company>> = ({
  isLoading,
  handleClickOnItem,
  opened,
  item,
  item: { id, title, countEmployees = 0 },
}) => {
  const header: FC<AccordionHeaderProps> = () => (
    <Wrapper>
      <StyledTitle mySize="h4">{title}</StyledTitle>
      <EmployeesCountField
        countEmployees={countEmployees}
        company={{
          label: title,
          value: item['@id'],
        }}
      />
    </Wrapper>
  )

  return (
    <Accordion
      skeletonHeight={120}
      handleClickOnHeader={handleClickOnItem}
      expanded={opened}
      initLoading={isLoading}
      Header={header}
      Body={({ onChangeSize, toggleLoading }) => (
        <Body
          onChangeSize={onChangeSize}
          toggleLoading={toggleLoading}
          id={id}
        />
      )}
    />
  )
}

export default CompanyItem
