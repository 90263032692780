import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { SecondaryButtonProps } from '@organisms/SecondaryButton'

type Props = {
  newRoute?: string | null
  onChangePage: () => Promise<void>
  ButtonComponent: FC<SecondaryButtonProps>
  id?: string
}

const ChangeThemeButton: FC<Props> = ({
  newRoute,
  onChangePage,
  ButtonComponent,
  id,
}) => {
  const navigate = useNavigate()

  const handleClick = async () => {
    if (newRoute) {
      await onChangePage()
      navigate(newRoute)
    }
  }

  if (!newRoute) return null

  return <ButtonComponent onClick={handleClick} id={id} />
}
export default ChangeThemeButton
