import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import useFormProcessor from '@hooks/useFormProcessor'
import {
  useCreatePositionMutation,
  useUpdatePositionMutation,
} from '@services/positions'
import { CommonPositionBody, PositionDetailed } from '@models/position'
import AddNewItemModal from '@templates/AddNewItemModal'
import { Option } from '@organisms/Select'
import OnboardingCodes from '@enums/OnboardingCodes'
import getDefaultValues from './getDefaultValues'
import usePositionFields from './usePositionFields'
import Footer from './Footer'
import { ONBOARDING_STEPS, ONBOARDING_TITLE } from './onboarding'

export type FormValues = {
  title: Option
  company: Option
  filial: Option
  division: Option
}

type Props = {
  isEdit?: boolean
  handleCloseModal: () => void
  position?: PositionDetailed
}

const PositionModal: FC<Props> = ({ isEdit, position, handleCloseModal }) => {
  const { state } = useLocation()

  const [create, createData] = useCreatePositionMutation()
  const [update, updateData] = useUpdatePositionMutation()
  const additionalProps = isEdit ? updateData : createData

  const methods = useFormProcessor({
    apiErrors: additionalProps.error,
    defaultValues: getDefaultValues(state || position),
  })
  const fields = usePositionFields(methods)

  const handleSubmitForm = (form: FormValues) => {
    const commonForm: CommonPositionBody = {
      title: form.title?.label,
      subdivision: form.division?.value,
    }

    if (isEdit) {
      if (position) {
        update({
          ...commonForm,
          id: position.id,
        })
      }
    } else {
      create(commonForm)
    }
  }

  return (
    <AddNewItemModal
      {...additionalProps}
      CustomFooter={isEdit ? undefined : Footer}
      titlePostfix="должность"
      fields={fields}
      onSubmitForm={handleSubmitForm}
      methods={methods}
      withSaveAndAddMore
      handleCloseModal={handleCloseModal}
      isEdit={isEdit}
      withoutAddMore
      onboarding={{
        steps: ONBOARDING_STEPS,
        firstStepTitle: ONBOARDING_TITLE,
        code: OnboardingCodes.job_position_create,
        withScroll: true,
        withFeedback: true,
      }}
    />
  )
}

export default PositionModal
