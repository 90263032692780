import { User } from '@models/user'
import { formatServerDate } from '@utils/date'
import {
  EmployeeProbationSheetResult,
  ProbationSheetQuestion,
} from '@models/employee'
import { renderPhone } from '@utils/phone'
import setDefaultSubdivisionJobPosionValues from '@templates/DivisionPositionMultiline/setDefaultSubdivisionJobPosionValues'
import {
  EMPLOYEE_PROBATION_INDEX_SEPARATOR,
  EMPLOYEE_PROBATION_PREFIX,
} from './useStaffingFields'
import {
  PROBATION_DATE_NAME,
  PROBATION_QUESTION_NAME,
} from './IntershipProgramTable'
import {
  MULTILINE_PREFIX,
  MULTILINE_SEPARATOR,
} from './IntershipProgramTable/const'
import { FormValues } from './index'

const DATE_FIELDS: Array<keyof EmployeeProbationSheetResult> = [
  'issueDate',
  'probationEndAt',
  'probationStartAt',
  'workPermittedAt',
]
const STRING_FIELDS: Array<keyof EmployeeProbationSheetResult> = [
  'probationConclusion',
  'probationReason',
  'probationShiftCount',
  'title',
]

const getProbationSheetFieldName = (name: string, i: number): string =>
  EMPLOYEE_PROBATION_PREFIX + name + EMPLOYEE_PROBATION_INDEX_SEPARATOR + i

const setProbationSheetFieldDateValue = (
  sheet: EmployeeProbationSheetResult,
  key: string,
  i: number,
  result: Partial<FormValues>
) => {
  if (sheet[key as keyof EmployeeProbationSheetResult]) {
    result[getProbationSheetFieldName(key, i)] = formatServerDate(
      sheet[key as keyof EmployeeProbationSheetResult] as string
    )
  }

  return result
}

const setProbationSheetFieldStringValue = (
  sheet: EmployeeProbationSheetResult,
  key: string,
  i: number,
  result: Partial<FormValues>
) => {
  if (sheet[key as keyof EmployeeProbationSheetResult]) {
    result[getProbationSheetFieldName(key, i)] =
      sheet[key as keyof EmployeeProbationSheetResult]
  }

  return result
}

const getDefaultProbationSheetQuestions = (
  probationSheetQuestions: ProbationSheetQuestion[],
  result: Partial<FormValues>,
  i: number
) => {
  probationSheetQuestions.forEach((item, valueIndex) => {
    result[
      EMPLOYEE_PROBATION_PREFIX +
        MULTILINE_PREFIX +
        PROBATION_QUESTION_NAME +
        MULTILINE_SEPARATOR +
        valueIndex +
        EMPLOYEE_PROBATION_INDEX_SEPARATOR +
        i
    ] = item.question
    result[
      EMPLOYEE_PROBATION_PREFIX +
        MULTILINE_PREFIX +
        PROBATION_DATE_NAME +
        MULTILINE_SEPARATOR +
        valueIndex +
        EMPLOYEE_PROBATION_INDEX_SEPARATOR +
        i
    ] = item.date ? formatServerDate(item.date) : ''
  })

  return result
}

const getDefaultProbationSheets = (
  employeeProbationSheets: EmployeeProbationSheetResult[],
  result: Partial<FormValues>
) => {
  let i = 0
  while (i < employeeProbationSheets.length) {
    const sheet = employeeProbationSheets[i]

    for (const stringField of STRING_FIELDS) {
      result = setProbationSheetFieldStringValue(sheet, stringField, i, result)
    }

    for (const dateField of DATE_FIELDS) {
      result = setProbationSheetFieldDateValue(sheet, dateField, i, result)
    }

    if (sheet.probationSender) {
      result[getProbationSheetFieldName('probationSender', i)] = {
        ...sheet.probationSender,
        label: sheet.probationSender.fullName,
        value: sheet.probationSender['@id'],
      }
    }

    if (sheet.responsibleOfProbation) {
      result[getProbationSheetFieldName('responsibleOfProbation', i)] = {
        ...sheet.responsibleOfProbation,
        label: sheet.responsibleOfProbation.fullName,
        value: sheet.responsibleOfProbation['@id'],
      }
    }

    if (
      sheet.probationSheetQuestions &&
      sheet.probationSheetQuestions.length > 0
    ) {
      result = getDefaultProbationSheetQuestions(
        sheet.probationSheetQuestions,
        result,
        i
      )
    }
    i++
  }

  return result
}

const getDefaultValues = (employee?: User): Partial<FormValues> | void => {
  if (!employee) return setDefaultSubdivisionJobPosionValues({}, [])

  let result: Partial<FormValues> = {
    fullName: employee.fullName,
    phone: employee.phone ? renderPhone(employee.phone) : undefined,
    email: employee.email || undefined,
    education: employee.education || undefined,
    comment: employee.comment || undefined,
    inn: employee.inn || undefined,
    insuranceNumber: employee.insuranceNumber || undefined,
    userConfig: employee.userConfig || undefined,
  }

  if (employee.company) {
    result.company = {
      ...employee.company,
      value: employee.company['@id'],
      label: employee.company.title,
    }
  }

  if (employee.companyBranch) {
    result.filial = {
      ...employee.companyBranch,
      value: employee.companyBranch['@id'],
      label: employee.companyBranch.title,
    }
  }

  if (employee.birthDate) {
    result.birthDate = formatServerDate(employee.birthDate)
  }

  if (employee.employmentDate) {
    result.employmentDate = formatServerDate(employee.employmentDate)
  }

  if (employee.nextBodyCheckDate) {
    result.nextBodyCheckDate = formatServerDate(employee.nextBodyCheckDate)
  }

  if (
    employee.employeeProbationSheets &&
    employee.employeeProbationSheets.length > 0
  ) {
    result = getDefaultProbationSheets(employee.employeeProbationSheets, result)
  }

  return setDefaultSubdivisionJobPosionValues(
    result,
    employee.subdivisionJobPositionRels
  )
}

export default getDefaultValues
