import { FC } from 'react'
import { FooterProps } from '@templates/AddNewItemModalLayout/Footer'
import { Routes } from '@enums/Routes'
import JobsFooter from '@templates/JobsFooter'

const Footer: FC<FooterProps> = (props) => {
  return (
    <JobsFooter
      {...props}
      additionalProps={{
        createOptions: [
          {
            label: 'Компанию',
            value: Routes.COMPANY_MODAL,
          },
          {
            label: 'Филиал',
            value: Routes.FILIAL_MODAL,
          },
          {
            label: 'Подразделение',
            value: Routes.DIVISION_MODAL,
          },
          {
            label: 'Должность',
            value: Routes.POSITION_MODAL,
          },
          {
            label: 'Сотрудник',
            value: Routes.EMPLOYEE_MODAL,
          },
        ],
      }}
    />
  )
}

export default Footer
