import React, { FC } from 'react'
import Accordion, { AccordionHeaderProps } from '@organisms/Accordion'
import { ListItemProps } from '@templates/List'
import Title from '@organisms/Title'
import InfoField from '@organisms/InfoField'
import { ReactComponent as BlackRound } from '@interface-images/black-round.svg'
import { Label } from '@organisms/InfoField/styled'
import { RiskMap } from '@models/riskMap'
import { formatServerDate } from '@utils/date'
import RiskMapStatus from '@templates/RiskMapStatus'
import DownloadRiskMap from '@templates/DownloadRiskMap'
import RiskMapStatuses from '@enums/RiskMapStatuses'
import Prompt from '@organisms/Prompt'
import { Container, LineWrapper, MiddleWrapper, Wrapper } from './styled'
import ActionButton from './ActionButton'
import Body from './Body'

const RiskMapItem: FC<ListItemProps<RiskMap>> = ({
  isLoading,
  handleClickOnItem,
  opened,
  item,
  index,
  item: {
    id,
    number,
    status,
    approvedAt,
    dangersCount,
    jobPosition,
    employeeReviewedAt,
    lastSendForReviewRevision,
  },
}) => {
  const { subdivision, title: positionTitle } = jobPosition || {}
  const { title: divisionTitle } = subdivision || {}

  const file = lastSendForReviewRevision?.file
  const disabledDownload = status === RiskMapStatuses.ARCHIVED

  const header: FC<AccordionHeaderProps> = () => (
    <Container>
      <Wrapper>
        <LineWrapper>
          <Title mySize="h4">Карта рисков № {number}</Title>
          <RiskMapStatus status={status} />
          {file && (
            <DownloadRiskMap
              file={file}
              isDisabled={disabledDownload}
              revision={lastSendForReviewRevision['@id']}
            />
          )}
          {disabledDownload && (
            <Prompt promptText="Вы не можете сказать архивный документ" />
          )}
        </LineWrapper>
        <MiddleWrapper>
          <LineWrapper>
            <Label mySize="h4">{divisionTitle}</Label>
            <BlackRound />
            <Label mySize="h4">{positionTitle}</Label>
            <BlackRound />
            <InfoField
              label="Количество опасностей"
              value={dangersCount}
              withStopPropagation={false}
              isLoading={isLoading}
              withoutFixedWidth
              withoutHover
            />
          </LineWrapper>
        </MiddleWrapper>
        <LineWrapper>
          <InfoField
            label="Дата утверждения"
            value={approvedAt ? formatServerDate(approvedAt) : null}
            withStopPropagation={false}
            isLoading={isLoading}
            withoutFixedWidth
            withoutHover
          />
          <InfoField
            label="Дата ознакомления"
            value={
              employeeReviewedAt ? formatServerDate(employeeReviewedAt) : null
            }
            withStopPropagation={false}
            isLoading={isLoading}
            withoutFixedWidth
            withoutHover
          />
        </LineWrapper>
      </Wrapper>
      <ActionButton item={item} />
    </Container>
  )

  return (
    <Accordion
      skeletonHeight={120}
      handleClickOnHeader={handleClickOnItem}
      expanded={opened}
      initLoading={isLoading}
      Header={header}
      Body={({ onChangeSize, toggleLoading }) => (
        <Body
          onChangeSize={onChangeSize}
          toggleLoading={toggleLoading}
          id={id}
          index={index}
        />
      )}
    />
  )
}

export default RiskMapItem
