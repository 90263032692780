import { JoyStep } from '@templates/Onboarding'

export const ONBOARDING_TITLE = 'Расскажем как создать филиал'

export const ONBOARDING_STEPS: JoyStep[] = [
  {
    target: '#contentFields',
    title: 'Создание филиала',
    content: 'Заполните данные о филиале',
  },
  {
    target: '#add-button-multiline',
    title: 'Добавление куратора',
    content: (
      <div>
        <div>
          Куратор — это ответственное лицо за охрану труда в компании, который
          будет представлять филиал в системе.
        </div>
        <div>Он имеет право:</div>
        <div>
          - изменять подразделения, должности, сотрудников своего филиала;
        </div>
        <div>- назначать обучения на сотрудников своего филиала;</div>
        <div>- создавать отчёты по своему филиалу.</div>
        <br />
        <div>У филиала может быть несколько кураторов.</div>
      </div>
    ),
  },
  {
    target: '#add-button-multiline',
    title: 'Добавление куратора',
    content: (
      <div>
        <div>Чтобы добавить куратора филиала:</div>
        <div>- Создайте сотрудника в разделе “Сотрудники”</div>
        <div>- Вернитесь к редактированию филиала</div>
        <div>- Выберете сотрудника в качестве куратора</div>
      </div>
    ),
  },
  {
    target: '#comission-block',
    title: 'Добавление комиссии по обучению',
    content: (
      <div>
        <div>Чтобы добавить членов комиссии по обучению:</div>
        <div>- Создайте сотрудника в разделе “Сотрудники”</div>
        <div>- Вернитесь к редактированию филиала</div>
        <div>
          - Выберите сотрудника в качестве члена или председателя комиссии
        </div>
      </div>
    ),
  },
  {
    target: '#comission-block',
    title: 'Добавление комиссии по обучению',
    content: (
      <div>
        <div>
          При создании обучения по умолчанию будут подставляться указанные члены
          комиссии.
        </div>
        <div>Вы сможете изменить членов комиссии.</div>
      </div>
    ),
  },
  {
    target: '#modal-footer',
    title: 'Дальнейшие действия',
    content: (
      <div>
        <div>Перейдите к одному из следующих шагов.</div>
        <div>
          Если вы хотите добавить сотрудников через загрузку штатного
          расписания, перейдите в раздел “Сотрудники”.
        </div>
      </div>
    ),
  },
  {
    target: '#modal-save-and-close',
    title: 'Сохраните и вернитесь к списку филиалов',
    content: '',
  },
  {
    target: '#modal-save-and-add-more',
    title: 'Сохраните этот филиал и добавьте ещё один',
    content: '',
  },
  {
    target: '#modal-next',
    title: 'Перейдите к созданию подразделений',
    content: '',
  },
]
