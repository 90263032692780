import { UseFormReturn } from 'react-hook-form'
import { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { useGetFilialQuery } from '@services/filials'
import { FilialOption } from '@models/filial'
import { Option } from '@organisms/Select'
import { User } from '@models/user'
import { WhomAssignee } from '@enums/WhomAssignee'

const getCommonFilialId = (
  whoAssigneeCode: WhomAssignee | undefined,
  employees: Array<User & Option> | undefined
) => {
  const selectedForEmployees = whoAssigneeCode === WhomAssignee.EMPLOYEE
  if (!selectedForEmployees) {
    return null
  }

  if (!employees || employees?.length === 0) {
    return null
  }

  const firstEmployeeFilialId = employees?.[0]?.companyBranch?.id

  if (
    employees.every(
      (employee) =>
        employee.companyBranch?.id === firstEmployeeFilialId ||
        isEmpty(employee)
    )
  ) {
    return firstEmployeeFilialId
  }

  return null
}

const useSetFilialInfoFields = (
  { watch, setValue, formState: { touchedFields } }: UseFormReturn,
  isEdit: boolean
) => {
  const employees: Array<User & Option> | undefined = watch('employees')
  const filial: FilialOption | undefined = watch('filial')
  const whoAssigneeCode: WhomAssignee | undefined = watch('whomAssign')?.value

  const employeesCommonFilialId = getCommonFilialId(whoAssigneeCode, employees)

  const { data } = useGetFilialQuery(employeesCommonFilialId! || filial?.id!, {
    skip: !filial && !employeesCommonFilialId,
  })

  useEffect(() => {
    if (
      (touchedFields.filial ||
        (whoAssigneeCode === WhomAssignee.EMPLOYEE &&
          employeesCommonFilialId)) &&
      data
    ) {
      setValue('workPlace', data.fullTitle)
      setValue('employerInn', data.inn)

      if (data.commissionMembers && data.commissionMembers.length > 0) {
        setValue(
          'commissionMembers',
          data.commissionMembers?.map((item) => ({
            ...item,
            label: item.fullName,
            value: item['@id'],
          }))
        )
      } else {
        setValue('commissionMembers', [])
      }

      if (data.commissionHead) {
        setValue('commissionHead', {
          label: data.commissionHead.fullName,
          value: data.commissionHead['@id'],
        })
      } else {
        setValue('commissionHead', {})
      }
    }
  }, [data, touchedFields.filial, employeesCommonFilialId, whoAssigneeCode])

  useEffect(() => {
    if (
      !isEdit &&
      whoAssigneeCode === WhomAssignee.EMPLOYEE &&
      !employeesCommonFilialId
    ) {
      setValue('workPlace', null)
      setValue('employerInn', null)
      setValue('commissionMembers', [])
      setValue('commissionHead', {})
    }
  }, [isEdit, touchedFields.filial, employeesCommonFilialId])
}

export default useSetFilialInfoFields
