import { FC } from 'react'
import { Skeleton } from 'antd'
import useMe from '@hooks/useMe'
import EmployeeBanner from './EmployeeBanner'
import CompanyCuratorBanner from './CompanyCuratorBanner'
import FilialCuratorBanner from './FilialCuratorBanner'

const BearBanner: FC = () => {
  const { isCompanyCurator, isFilialCurator, id } = useMe()

  if (!id) {
    return (
      <Skeleton.Button
        active
        style={{
          height: 250,
          borderRadius: 25,
          margin: '0 20px',
          width: '80vw',
        }}
      />
    )
  }

  if (isCompanyCurator) {
    return <CompanyCuratorBanner />
  }

  if (isFilialCurator) {
    return <FilialCuratorBanner />
  }

  return <EmployeeBanner />
}

export default BearBanner
