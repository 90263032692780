import styled from '@emotion/styled'
import { isMobile } from '@const/device'
import Text from '@organisms/Text'

const DesktopWrapper = styled.div`
  background: ${({ theme }) => theme.colors.background.surface};
  box-shadow: 5px 5px 20px rgba(29, 78, 137, 0.1);
  border-radius: 36px;
  padding: ${({ theme }) => theme.sizes.fourBase};
  margin: 30px 20px;
`
const MobileWrapper = styled(DesktopWrapper)`
  padding: 10px;
`
export const Wrapper = isMobile ? MobileWrapper : DesktopWrapper

export const StyledParagraph = styled(Text)`
  margin-bottom: ${({ theme }) => theme.sizes.base};
`

export const ButtonWrapper = styled.div`
  display: inline-block;
`
