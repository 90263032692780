import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react'
import EmployeeSelect, { EmployeeSelectProps } from '@templates/EmployeeSelect'
import { FilterComponentProps } from '..'
import useSelectFilterMethods from '../useSelectFilterMethods'

const EmployeeFilter: ForwardRefExoticComponent<
  FilterComponentProps<EmployeeSelectProps> &
    RefAttributes<typeof EmployeeSelect>
> = forwardRef((props, ref) => {
  const { field, filter } = props
  const { handleSelect } = useSelectFilterMethods(props as any)

  return (
    <EmployeeSelect
      {...field}
      {...filter}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref={ref}
      onChange={handleSelect as any}
      placeholder="Сотрудник"
    />
  )
})

export default EmployeeFilter
