import { FC } from 'react'
import Text from '@organisms/Text'
import ConfirmModalContent from '@organisms/ConfirmModalContent'
import PrimaryButton from '@organisms/PrimaryButton'
import { ButtonWrapper, ErrorsWrapper } from './styled'

type Props = {
  error: {
    data?: {
      'hydra:description'?: string
      errors?: {
        line: string
        column: string
        error: string
      }[]
    }
  }
  onClose: () => void
}

const FileErrorComponent: FC<Props> = ({ error, onClose }) => {
  const errors = error?.data?.errors || []

  return (
    <ConfirmModalContent
      leftIcon="sad-bear-new.svg"
      title="Исправьте, пожалуйста, ошибки в файле:"
      rightContent={
        <>
          <ErrorsWrapper>
            {errors.length > 0
              ? errors.map(({ line, column, error }) => (
                  <Text
                    key={line}
                  >{`- ${line} строка. Колонка ${column}. ${error}`}</Text>
                ))
              : error?.data?.['hydra:description'] || 'В файле что-то не так.'}
          </ErrorsWrapper>
          <ButtonWrapper>
            <PrimaryButton onClick={onClose} big>
              Загрузить еще раз
            </PrimaryButton>
          </ButtonWrapper>
        </>
      }
    />
  )
}

export default FileErrorComponent
