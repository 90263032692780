import { FC } from 'react'
import useDocuments from '@hooks/useDocuments'
import useMe from '@hooks/useMe'
import ListPage from '@templates/ListPage'
import { ALPHABETICAL_SORTER } from '@const/sorters'
import LibraryModal from '@modals/LibraryModal'
import LibraryItem from './LibraryItem'
import useLibraryFilters from './useLibraryFilters'

const Library: FC = () => {
  const { isAdmin } = useMe()
  const filters = useLibraryFilters()

  const { data, ...rest } = useDocuments()
  const { items = [], length = 0 } = data || {}

  return (
    <ListPage
      {...rest}
      titleTabs={[
        {
          index: 1,
          title: 'Библиотека',
          counter: length,
        },
      ]}
      selectedTab={1}
      ModalContent={isAdmin ? LibraryModal : undefined}
      ItemComponent={LibraryItem}
      list={items}
      length={length}
      filters={filters}
      withSearch
      perLine={3}
      sorters={[ALPHABETICAL_SORTER]}
    />
  )
}

export default Library
