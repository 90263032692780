import * as React from 'react'
import { FC } from 'react'
import AccordionBodyField from '@organisms/AccordionBodyField'
import { TabsWrapper } from './styled'
import FieldTab, { EditFieldTabItemProps } from './FieldTab'

type Props = {
  label: string
  items: EditFieldTabItemProps[]
  withoutFixedHeight?: boolean
}

const AccordionBodyFieldWithTabs: FC<Props> = ({
  label,
  items,
  withoutFixedHeight,
}) => {
  if (items.length === 0) return null

  return (
    <AccordionBodyField
      withoutFixedHeight={withoutFixedHeight}
      label={label}
      value={
        <TabsWrapper>
          {items.map((item) => (
            <FieldTab item={item} key={item.title} />
          ))}
        </TabsWrapper>
      }
    />
  )
}

export default AccordionBodyFieldWithTabs
