import { useGetMeQuery } from '@services/users'
import { Me } from '@models/me'
import { useAppSelector } from './useAppSelector'

const useMe = () => {
  const id = useAppSelector((state) => state.auth.id)
  const me = useAppSelector((state) => state.auth.me)
  const state = useGetMeQuery(undefined, {
    skip: !!id || !me,
  })

  return me || state.data || ({} as Me)
}

export default useMe
