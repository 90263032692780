import { FC } from 'react'
import Link, { LinkStyle } from '@organisms/Link'
import useUpdateMe from '@hooks/useUpdateMe'
import useMe from '@hooks/useMe'
import OnboardingCodes from '@enums/OnboardingCodes'
import { useAppDispatch } from '@hooks/useAppDispatch'
import { startOnboarding } from '@services/onboarding/slice'
import { Wrapper } from '@templates/ResetOnboarding/styled'
import { setOnboardingVisited } from '@services/auth/slice'

type Props = {
  resetOnboardingCodes: OnboardingCodes[]
  onResetOnboarding?: () => void
}

const ResetOnboarding: FC<Props> = ({
  resetOnboardingCodes,
  onResetOnboarding,
}) => {
  const dispatch = useAppDispatch()
  const { isAdmin, userConfig = { onboarding: {} } } = useMe()
  const [updateMe, { isLoading }] = useUpdateMe()

  const handleClick = () => {
    const onboarding: any = {
      ...userConfig?.onboarding,
    }

    const reset: any = {}

    for (const item of resetOnboardingCodes) {
      onboarding[item] = false
      reset[item] = false
    }

    dispatch(startOnboarding(resetOnboardingCodes[0]))

    dispatch(setOnboardingVisited(reset))
    void updateMe({
      userConfig: {
        ...userConfig,
        onboarding,
      },
    })

    onResetOnboarding?.()
  }

  if (isAdmin || !resetOnboardingCodes) {
    return null
  }

  return (
    <Wrapper>
      <Link
        text="Повторить инструкцию"
        linkStyle={LinkStyle.DEFAULT}
        onClick={handleClick}
        isLoading={isLoading}
      />
    </Wrapper>
  )
}

export default ResetOnboarding
