import { FC } from 'react'
import PrimaryButton from '@organisms/PrimaryButton'
import ConfirmModalContent from '@organisms/ConfirmModalContent'
import { ButtonsWrapper, TextWrapper } from '@templates/DeleteButton/styled'
import Text from '@organisms/Text'
import SecondaryButton from '@organisms/SecondaryButton'
import { RiskMapDetailed } from '@models/riskMap'
import { useArchiveMutation } from '@services/riskMaps'

type Props = {
  data: RiskMapDetailed
  closeModal: () => void
}

const ToArchiveModal: FC<Props> = ({ data, closeModal }) => {
  const [archive] = useArchiveMutation()

  const handleYes = () => {
    void archive(data)
    closeModal()
  }

  return (
    <ConfirmModalContent
      title="Подтвердите действие"
      leftIcon="sad-bear-new.svg"
      rightContent={
        <>
          <TextWrapper>
            <Text>
              После переноса карты рисков в архив, она не будет показана
              сотрудникам.
            </Text>
            <Text>Перенести в архив?</Text>
          </TextWrapper>
          <ButtonsWrapper>
            <PrimaryButton onClick={handleYes} big>
              да
            </PrimaryButton>
            <SecondaryButton onClick={closeModal} big>
              нет
            </SecondaryButton>
          </ButtonsWrapper>
        </>
      }
    />
  )
}

export default ToArchiveModal
