import styled from '@emotion/styled'
import { isMobile } from '@const/device'
import { IconWrapper } from '../../styled'

const ARROW_SIZE = isMobile ? 30 : 40

const DesktopArrowWrapper = styled(IconWrapper)`
  justify-content: right;
`
const MobileArrowWrapper = styled(DesktopArrowWrapper)``
export const ArrowWrapper = isMobile ? MobileArrowWrapper : DesktopArrowWrapper

export const Square = styled.div<{
  hovered: boolean
}>`
  border: 1px solid ${({ theme }) => theme.colors.main};
  background-color: ${({ hovered, theme }) =>
    hovered ? theme.colors.main : 'white'};
  height: ${ARROW_SIZE}px;
  width: ${ARROW_SIZE}px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`
