import StoreTags from '@enums/StoreTags'
import {
  CompanyGroup,
  CompanyGroupDetailed,
  CompanyGroupOption,
  CreateCompanyGroupBody,
  CreateCompanyGroupResponse,
  UpdateCompanyGroupBody,
  UpdateCompanyGroupResponse,
} from '@models/companyGroup'
import { CommonQueryParams, ItemsFromResponse } from '@models/common'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'
import emptySplitApi from '../api'

const BASE_URL = '/api/company_groups'

export const CompanyGroupsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyGroups: builder.query<
      { items: CompanyGroupOption[]; length: number },
      CommonQueryParams
    >({
      query: (params) => ({
        url: BASE_URL,
        params,
      }),
      transformResponse: (response: ItemsFromResponse<CompanyGroup>) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          value: item['@id'],
          label: item.title,
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
      providesTags: [StoreTags.COMPANY_GROUPS],
    }),
    createCompanyGroup: builder.mutation<
      CreateCompanyGroupResponse,
      CreateCompanyGroupBody
    >({
      invalidatesTags: (result) =>
        result
          ? [StoreTags.COMPANY_GROUPS, StoreTags.COMPANIES, StoreTags.COMPANY]
          : [],
      query: (body) => ({
        url: BASE_URL,
        method: 'POST',
        body,
      }),
    }),
    updateCompanyGroup: builder.mutation<
      UpdateCompanyGroupResponse,
      UpdateCompanyGroupBody
    >({
      invalidatesTags: (result) =>
        result
          ? [
              StoreTags.COMPANY_GROUPS,
              StoreTags.COMPANY_GROUP,
              StoreTags.COMPANIES,
              StoreTags.COMPANY,
              StoreTags.ME,
            ]
          : [],
      query: ({ id, ...body }) => ({
        url: `${BASE_URL}/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    getCompanyGroup: builder.query<CompanyGroupDetailed, number>({
      providesTags: [StoreTags.COMPANY_GROUP],
      query: (id) => `${BASE_URL}/${id}`,
    }),
    deleteCompanyGroup: builder.mutation<void, number>({
      invalidatesTags: [
        StoreTags.COMPANY_GROUPS,
        StoreTags.COMPANIES,
        StoreTags.COMPANY,
      ],
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetCompanyGroupsQuery,
  useCreateCompanyGroupMutation,
  useUpdateCompanyGroupMutation,
  useGetCompanyGroupQuery,
  useLazyGetCompanyGroupQuery,
  useDeleteCompanyGroupMutation,
} = CompanyGroupsApi
