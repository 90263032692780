import { FilterState } from '@hooks/useItemsFilters'

export const setSearchValue =
  (value: string) =>
  (prev: FilterState): FilterState => {
    return {
      ...prev,
      search: value,
    }
  }
