import { Option } from '@organisms/Select'
import RiskMapStatuses from '@enums/RiskMapStatuses'

const RISK_MAP_STATUSES_EMPLOYEE: Option[] = [
  {
    value: RiskMapStatuses.CHANGED,
    label: 'Изменён',
  },
  {
    value: RiskMapStatuses.APPROVED,
    label: 'Утверждён',
  },
  {
    value: RiskMapStatuses.ARCHIVED,
    label: 'Архив',
  },
  {
    value: RiskMapStatuses.EXTENDED,
    label: 'Продлён',
  },
]

export default RISK_MAP_STATUSES_EMPLOYEE
