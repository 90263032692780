import COURSE_LEARN_TYPES from '@const/courseSubTypes'
import COURSE_TYPES from '@const/courseTypes'
import { CourseDetailed } from '@models/course'
import { FormValues } from './index'

const getDefaultValues = (
  course?: CourseDetailed
): Partial<FormValues> | void => {
  if (!course) return

  const result: Partial<FormValues> = {
    title: course.title,
    descriptionLink: course.descriptionLink
      ? String(course.descriptionLink)
      : undefined,
    safeProgram: course.safeProgram,
    free: course.free,
    ministryLaborId: course.ministryLaborId || undefined,
    electricalSafetyFirstGroup: course.electricalSafetyFirstGroup,
  }

  if (course.hoursCount) {
    result.hoursCount = course.hoursCount.toString()
  }

  if (course.type) {
    const findedType = COURSE_TYPES.find(({ value }) => value === course.type)
    if (findedType) {
      result.type = findedType
    }
  }

  if (course.subType) {
    const findedSubType = COURSE_LEARN_TYPES.find(
      ({ value }) => value === course.subType
    )
    if (findedSubType) {
      result.subType = findedSubType
    }
  }

  if (course.courseCategory) {
    result.courseCategory = {
      value: course.courseCategory['@id'],
      label: course.courseCategory.title,
    }
  }

  if (course.company) {
    result.company = {
      ...course.company,
      value: course.company['@id'],
      label: course.company.title,
    }
  }

  if (course.companyBranch) {
    result.filial = {
      ...course.companyBranch,
      value: course.companyBranch['@id'],
      label: course.companyBranch.title,
    }
  }

  return result
}

export default getDefaultValues
