import { FC, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import useMe from '@hooks/useMe'
import { Routes } from '@enums/Routes'

const Init: FC = () => {
  const { search } = useLocation()
  const navigate = useNavigate()
  const { id, isAdmin } = useMe()

  useEffect(() => {
    if (id) {
      navigate(isAdmin ? Routes.COMPANIES : Routes.STUDY)
    } else {
      navigate(Routes.AUTH + search)
    }
  }, [id])

  return null
}

export default Init
