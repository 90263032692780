import { FC } from 'react'
import StyledButton, { StyledButtonProps } from '@organs/StyledButton'
import ButtonStyle from '@enums/ButtonStyle'
import { CheckOutlined } from '@ant-design/icons'

const DoneButton: FC<StyledButtonProps> = ({
  buttonStyle = ButtonStyle.GRAY,
  children = 'Выполнить',
  ...restProps
}) => {
  return (
    <StyledButton
      {...restProps}
      buttonStyle={buttonStyle}
      leftIcon={<CheckOutlined />}
    >
      {children}
    </StyledButton>
  )
}

export default DoneButton
