import styled from '@emotion/styled'
import { isMobile } from '@const/device'

export const FileWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${() => (isMobile ? 24 : 0)}px;
`

export const TabWrapper = styled.div`
  padding-top: ${() => (isMobile ? 0 : 32)}px;
`
