import { FC } from 'react'
import useMe from '@hooks/useMe'
import useEmployeeEducationDestinations from '@hooks/useEmployeeEducationDestinations'
import { createInitFilter } from '@utils/filters'
import List from '@templates/List'
import Onboarding from '@templates/Onboarding'
import OnboardingCodes from '@enums/OnboardingCodes'
import ResetOnboarding from '@templates/ResetOnboarding'
import BearBanner from './BearBanner'
import { StyledTitle, TitleWrapper } from './styled'
import StudyItem from './StudyItem'
import { ONBOARDING_STEPS, ONBOARDING_TITLE } from './onboarding'

const Study: FC = () => {
  const { id } = useMe()
  const { data, isLoading, setPage } = useEmployeeEducationDestinations({
    initFilters: createInitFilter('employee', id),
  })
  const { items = [], length = 0 } = data || {}

  return (
    <>
      {items.length > 0 && (
        <Onboarding
          firstStepTitle={ONBOARDING_TITLE}
          steps={ONBOARDING_STEPS}
          code={OnboardingCodes.my_education_section}
        />
      )}
      <BearBanner />
      <TitleWrapper>
        <StyledTitle>Мое обучение</StyledTitle>
        <ResetOnboarding
          resetOnboardingCodes={[
            OnboardingCodes.my_education_section,
            OnboardingCodes.my_education_course,
            OnboardingCodes.my_education_chapter,
            OnboardingCodes.my_education_theme,
          ]}
        />
      </TitleWrapper>
      <List
        list={items}
        ItemComponent={StudyItem}
        length={length}
        setPage={setPage}
        isLoading={isLoading}
      />
    </>
  )
}

export default Study
