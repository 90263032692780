import Title from '@organisms/Title'
import MultilineFieldNew from '@templates/MultilineFieldNew'
import { selectSearchField } from '@templates/AddNewItemModal/templates'
import useJobDataForFields from '@hooks/useJobDataForFields'
import useFormProcessor from '@hooks/useFormProcessor'
import { Wrapper } from './styled'

const MultilineCompanies = () => {
  const methods = useFormProcessor()
  const { company } = useJobDataForFields(methods)

  if (!company) return null

  return (
    <Wrapper>
      <Title>Компании</Title>
      <MultilineFieldNew
        fieldsName="companies"
        fieldsSrc={[
          selectSearchField({
            ...company,
            label: 'Компания',
            name: 'company',
          }),
        ]}
        amendValue={{}}
      />
    </Wrapper>
  )
}

export default MultilineCompanies
