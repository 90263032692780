import styled from '@emotion/styled'

export const Subtitle = styled.div`
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: ${({ theme }) => theme.colors.text.secondary};
  margin: 12px 4px;
`

export const LinkWrapper = styled.div`
  margin: 0 4px 0;
`

export const BackButtonContainer = styled.div`
  display: inline-block;
  margin-bottom: 10px;
`
