import styled from '@emotion/styled'
import { isMobile } from '@const/device'

const DesktopWrapper = styled.div<{
  hovered: boolean
}>`
  background: ${({ theme }) => theme.colors.background.surface};
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  color: ${({
    hovered,
    theme: {
      colors: {
        text: { black, primary },
      },
    },
  }) => (hovered ? primary : black)};
  padding: 20px 0;
  flex-wrap: wrap;
`
const MobileWrapper = styled(DesktopWrapper)<{
  hovered: boolean
}>`
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 10px 0;
`
export const Wrapper = isMobile ? MobileWrapper : DesktopWrapper
