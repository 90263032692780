import useFormProcessor from '@hooks/useFormProcessor'
import useJobFilters from '@hooks/useJobFilters'
import { FiltersProps } from '@templates/ListPageLayout/Filters'
import { FilterTypes } from '@templates/ListPageLayout/Filters/Filter'

const useFilialsFilters = (): FiltersProps => {
  const methods = useFormProcessor()
  const { company } = useJobFilters(methods)

  const filters: FiltersProps = [
    {
      filterType: FilterTypes.HIDDEN,
      fieldName: 'id',
    },
  ]

  if (company) {
    filters.push(company)
  }

  return filters
}

export default useFilialsFilters
