import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { FooterProps } from '@templates/AddNewItemModalLayout/Footer'
import { Routes } from '@enums/Routes'
import JobsFooter from '@templates/JobsFooter'
import { DivisionDetailed } from '@models/division'
import { PositionDetailed } from '@models/position'

const Footer: FC<FooterProps> = (props) => {
  const { state } = useLocation()

  return (
    <JobsFooter
      {...props}
      additionalProps={{
        createOptions: [
          {
            label: 'Компанию',
            value: Routes.COMPANY_MODAL,
          },
          {
            label: 'Филиал',
            value: Routes.FILIAL_MODAL,
          },
        ],
        nextRoute: Routes.POSITION_MODAL,
        nextRouteDescription: 'Переход к созданию должности',
        getNextState: (data: DivisionDetailed): Partial<PositionDetailed> => ({
          company: state?.company,
          companyBranch: state?.companyBranch,
          subdivision: data,
        }),
      }}
    />
  )
}

export default Footer
