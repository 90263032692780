import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { isEqual } from 'lodash'
import useNotifications from '@hooks/useNotifications'
import { isMobile } from '@const/device'
import Modal from '@organisms/Modal'
import { Wrapper } from './styled'
import Notification from './Notification'
import DownButton from './DownButton'

type Props = {
  listIsVisible: boolean
  toggleListIsVisible: Dispatch<SetStateAction<boolean>>
}

const List: FC<Props> = ({ listIsVisible, toggleListIsVisible }) => {
  const { data, setPage } = useNotifications()
  const { items = [] } = data || {}

  const [itemsState, setItemsState] = useState(items)

  const needShowMore = items.length > 0 && items.length % 20 === 0

  useEffect(() => {
    if (!isEqual(items, itemsState)) {
      setItemsState((prev) => [...prev, ...items])
    }
  }, [items])

  if (!listIsVisible || !data || items.length === 0) {
    return null
  }

  const list = (
    <Wrapper>
      {itemsState.map((notification) => (
        <Notification key={notification.id} notification={notification} />
      ))}
      {needShowMore && (
        <DownButton onClick={() => setPage((prev) => prev + 1)} />
      )}
    </Wrapper>
  )

  return (
    <>
      {isMobile ? (
        <Modal
          visible={listIsVisible}
          onModalClose={() => toggleListIsVisible(false)}
          withoutWhiteBackground
        >
          {list}
        </Modal>
      ) : (
        list
      )}
    </>
  )
}

export default List
