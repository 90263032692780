import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useToggle } from 'react-use'
import { Skeleton } from 'antd'
import { a, useSpring } from '@react-spring/web'
import { ReactComponent as ArrowDownIconActive } from '@interface-images/select-arrow-down-active.svg'
import { ReactComponent as ArrowDownIcon } from '@interface-images/select-arrow-down-inactive.svg'
import { ReactComponent as ArrowUpIconActive } from '@interface-images/select-arrow-up-active.svg'
import { ReactComponent as ArrowUpIcon } from '@interface-images/select-arrow-up-inactive.svg'
import useMouseOverOut from '@hooks/useMouseOverOut'
import { DEFAULT_ANIMATION_DURATION } from '@const/animation'
import Loader from '@atoms/Loader'
import {
  ArrowWrapper,
  BodyWrapper,
  HeaderContainer,
  HeaderWrapper,
  MainWrapper,
} from './styled'

const SMALL_HEIGHT = 0
const HEADER_BORDER_RADIUS = 24

type AccordionHeaderProps = {
  hovered: boolean
  expanded?: boolean
}

type AccordionBodyProps = {
  onChangeSize: () => void
  toggleLoading: Dispatch<SetStateAction<boolean>>
}

type Props = {
  Header: FC<AccordionHeaderProps>
  Body: FC<AccordionBodyProps>
  height?: number
  initLoading?: boolean
  skeletonHeight?: number
  itemId?: number
  expanded?: boolean
  handleClickOnHeader?: () => void
  withoutArrow?: boolean
  withAutoRefreshSize?: boolean
  id?: string
}

const Accordion: FC<Props> = ({
  Header,
  Body,
  height = 0,
  initLoading = false,
  skeletonHeight = 55,
  itemId,
  expanded: expandedProp,
  handleClickOnHeader,
  withoutArrow = false,
  withAutoRefreshSize = false,
  id,
}) => {
  const bodyRef = useRef<HTMLDivElement | null>(null)
  const [expanded, toggleExpanded] = useToggle(!!expandedProp)
  const [size, setSize] = useState<number>(height)
  const [isLoading, toggleLoading] = useToggle(false)
  const [hovered, , handleMouseEnter, handleMouseLeave] = useMouseOverOut({
    isLoading,
  })

  useEffect(() => {
    if (expandedProp != null) {
      toggleExpanded(expandedProp)
    }
  }, [expandedProp])

  useEffect(() => {
    if (itemId) {
      toggleExpanded(false)
      toggleLoading(false)
    }
  }, [itemId])

  const animation = useSpring({
    height: expanded ? size : SMALL_HEIGHT,
    config: { duration: DEFAULT_ANIMATION_DURATION },
  })

  const handleHeaderClick = () => {
    handleClickOnHeader?.()
    toggleExpanded()
  }

  const changeSize = () =>
    setTimeout(() => {
      if (
        bodyRef?.current?.clientHeight &&
        bodyRef.current.clientHeight !== size
      ) {
        setSize(bodyRef?.current?.clientHeight + 30)
      }
    }, 300)

  useEffect(() => {
    if (withAutoRefreshSize) {
      const intervalId = setInterval(changeSize)

      return () => clearInterval(intervalId)
    }
  }, [])

  let arrowIcon
  if (expanded) {
    arrowIcon = hovered || expanded ? <ArrowUpIconActive /> : <ArrowUpIcon />
  } else {
    arrowIcon = hovered ? <ArrowDownIconActive /> : <ArrowDownIcon />
  }

  if (initLoading) {
    return (
      <Skeleton.Button
        active
        block
        style={{
          marginBottom: 12,
          borderRadius: HEADER_BORDER_RADIUS,
          height: skeletonHeight,
        }}
      />
    )
  }

  return (
    <MainWrapper id={id}>
      <HeaderContainer
        onClick={handleHeaderClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        withoutDefaultHeight={skeletonHeight !== 55}
        borderRadius={HEADER_BORDER_RADIUS}
      >
        <Loader isLoading={isLoading} fontSize={26}>
          <HeaderWrapper>
            <Header hovered={hovered || expanded} expanded={expanded} />
            {!withoutArrow && <ArrowWrapper>{arrowIcon}</ArrowWrapper>}
          </HeaderWrapper>
        </Loader>
      </HeaderContainer>
      <a.div
        style={{
          ...animation,
          overflow: 'hidden',
          width: '100%',
          borderRadius: '0 0 30px 30px',
        }}
      >
        <BodyWrapper ref={(value) => (bodyRef.current = value)}>
          {expanded && (
            <Body onChangeSize={changeSize} toggleLoading={toggleLoading} />
          )}
        </BodyWrapper>
      </a.div>
    </MainWrapper>
  )
}

export type { AccordionHeaderProps, AccordionBodyProps }
export default Accordion
