import { createApi } from '@reduxjs/toolkit/query/react'
import StoreTags from '@enums/StoreTags'
import getBaseApiConfig from '../config/getBaseApiConfig'

const emptySplitApi = createApi({
  baseQuery: getBaseApiConfig(),
  endpoints: () => ({}),
}).enhanceEndpoints({
  addTagTypes: Object.values(StoreTags),
})

export default emptySplitApi
