import { FC } from 'react'
import Select from '@organisms/Select'
import useMe from '@hooks/useMe'
import CuratorStatuses from '@enums/CuratorStatuses'
import CURATOR_STATUS_OPTIONS, {
  CuratorStatusValue,
} from '@const/curatorStatuses'
import useUpdateMe from '@hooks/useUpdateMe'
import { Wrapper } from './styled'

const CuratorStatus: FC = () => {
  const me = useMe()
  const {
    isCompanyCurator,
    isDivisionCurator,
    isFilialCurator,
    curatorStatus = CuratorStatuses.ACTIVE,
  } = me

  const [updateMe] = useUpdateMe()

  const handleChange = (newValue: CuratorStatusValue) => {
    void updateMe({
      curatorStatus: newValue.value,
    })
  }

  if (!(isDivisionCurator || isCompanyCurator || isFilialCurator)) {
    return null
  }

  return (
    <Wrapper>
      <Select
        value={CURATOR_STATUS_OPTIONS.find(
          ({ value }) => value === curatorStatus
        )}
        options={CURATOR_STATUS_OPTIONS}
        onChange={handleChange}
        withoutFillSelected
        isSearchable={false}
        isClearable={false}
      />
    </Wrapper>
  )
}

export default CuratorStatus
