import StoreTags from '@enums/StoreTags'
import { PostOnboardingBody } from '@models/onboarding'
import { createDownloadExcelReportQuery } from '@utils/files'
import emptySplitApi from '../api'

const TAGS = [StoreTags.ONBOARDING]
const BASE_URL = '/api/user_onboarding_votes'

export const onboardingApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    sendFeedback: builder.mutation<void, PostOnboardingBody>({
      invalidatesTags: (result) => (result ? TAGS : []),
      query: (body) => ({
        url: BASE_URL,
        method: 'POST',
        body,
      }),
    }),
    getFeedbackReport: builder.query<
      void,
      Record<'beforeAt' | 'afterAt', Record<string, unknown>>
    >({
      query: (params) =>
        createDownloadExcelReportQuery(
          BASE_URL + '/votes_report',
          'Отчет по оценкам онбординга.xls'
        )(params),
    }),
  }),
})

export const { useSendFeedbackMutation, useLazyGetFeedbackReportQuery } =
  onboardingApi
