/* eslint-disable sonarjs/no-duplicate-string */
import { Dispatch, SetStateAction } from 'react'
import { JoyStep } from '@templates/Onboarding'
import { InfoCircleTwoTone } from '@ant-design/icons'

export const ONBOARDING_TITLE = 'Расскажем о действиях с картой рисков'

export const getOnboardingSteps = (
  index: number,
  setActive: Dispatch<SetStateAction<number>>
): JoyStep[] => [
  {
    target: `#risk-map-status-${index}`,
    title: 'Статус карты рисков',
    content: (
      <div>
        <div>Карта рисков может иметь следующий статус:</div>
        <ul>
          <li>
            <div>Черновик: карта не утверждена.</div>
            <div>Такую карту нельзя отправить на ознакомление сотрудникам.</div>
          </li>
          <li>
            <div>Утверждён: карта утверждена.</div>
            <div>Карту можно утвердить только после загрузки в неё файла.</div>
            <div>
              После утверждения карту можно отправить на ознакомление
              сотрудникам.
            </div>
          </li>
        </ul>
      </div>
    ),
  },
  {
    target: `#risk-map-status-${index}`,
    title: 'Статус карты рисков',
    content: (
      <div>
        <div>Карта рисков может иметь следующий статус:</div>
        <ul>
          <li>
            <div>Продлён: карту пересмотрели со статусом "Продлён".</div>
            <div>
              Значит, в текущей версии карты нет изменений, ставится новый срок
              пересмотра.
            </div>
            <div>У сотрудников не нужно заново собирать ознакомление.</div>
          </li>
          <li>
            <div>Изменён: карту пересмотрели со статусом "Изменён".</div>
            <div>Значит, в текущей версии карты произошли изменения.</div>
            <div>
              Карту нужно заново отправить на ознакомление сотрудникам после
              этого.
            </div>
          </li>
          <li>
            <div>Отменён: карту пересмотрели со статусом "Отменён".</div>
            <div>Значит, текущая карта больше неактуальна.</div>
            <div>
              После отмены её нельзя вернуть, нужно создавать новую карту
              рисков.
            </div>
          </li>
        </ul>
      </div>
    ),
  },
  {
    target: `#risk-map-status-${index}`,
    title: 'Статус карты рисков',
    content: (
      <div>
        <div>Карта рисков может иметь следующий статус:</div>
        <ul>
          <li>
            <div>Архив: карту переместили в архив.</div>
            <div>Значит, текущая версия карты больше неактульна.</div>
            <div>Из архива карту можно вернуть.</div>
            <div>
              Сотрудники не могут ознакамливаться с такой картой и скачивать её
              файл.
            </div>
          </li>
        </ul>
      </div>
    ),
  },
  {
    target: `#reviseBeforeAt-${index}`,
    title: 'Дата пересмотра',
    content: (
      <div>
        <div>
          Вам поступит уведомление на email и платформу о необходимости
          пересмотра карты.
        </div>
        <div>Уведомления за 14 и 30 дней до даты пересмотра.</div>
      </div>
    ),
  },
  {
    target: `#download-risk-map-${index}`,
    title: 'Актуальная версия карты рисков',
    content: (
      <div>
        <div>Вы можете скачать актуальную версию карты рисков.</div>
        <div>
          <InfoCircleTwoTone style={{ color: 'white' }} /> Версия файла
          обновляется после пересмотра со статусом "Изменён".
        </div>
      </div>
    ),
  },
  {
    target: `#risk-map-report-${index}`,
    title: 'Отчёт по карте рисков',
    content: (
      <div>
        <div>
          Вы можете скачать отчёт по карте рисков с перечнем опасностей и мер.
        </div>
        <div>
          В дальнейшем вы можете подписать его и загрузить в карту рисков, чтобы
          отправить на ознакомление сотрудникам.
        </div>
      </div>
    ),
  },
  {
    target: `#select-risk-map-status-${index}`,
    title: 'Выбор статуса карты рисков',
    content: (
      <div>
        <div>Здесь вы можете утвердить или архивировать карту рисков</div>
        <div>
          <InfoCircleTwoTone style={{ color: 'white' }} /> Утвердить карту можно
          только после загрузки файла.
        </div>
        <div>
          <InfoCircleTwoTone style={{ color: 'white' }} /> С архивной картой
          рисков сотрудник не сможет ознакомиться. Вы можете вернуть из архива
          карту.
        </div>
      </div>
    ),
  },
  {
    target: `#risk-map-send-for-review-${index}`,
    title: 'Отправка на ознакомление',
    content: (
      <div>
        <div>
          После оценки рисков, загрузки файла и утверждения вы можете отправить
          карту рисков на ознакомление сотрудникам:
        </div>
        <div>— Карта рисков появится в личном кабинете сотрудников.</div>
        <div>
          — Отправится уведомление всем сотрудникам, входящим в должность карты
          рисков.
        </div>
      </div>
    ),
  },
  {
    target: `#risk-map-revise-${index}`,
    title: 'Пересмотр (изменение, продление, отмена) карты рисков',
    content:
      'Если вам нужно продлить, изменить, отменить карту рисков, то нажмите на кнопку “Пересмотреть”',
  },
  {
    target: `#risk-map-revise-${index}`,
    title: 'Пересмотр (изменение, продление, отмена) карты рисков',
    content: (
      <div>
        <div>
          В модальном окне выберите статус пересмотра, заполните поля и
          сохраните.
        </div>
        <div>
          <InfoCircleTwoTone style={{ color: 'white' }} /> На изменённую версию
          понадобится заново собирать ознакомление сотрудников. После изменения
          карты рисков отправьте повторно её на ознакомление.
        </div>
        <div>
          <InfoCircleTwoTone style={{ color: 'white' }} /> Отменённую карту
          рисков не видят сотрудники.
        </div>
      </div>
    ),
  },
  {
    target: `#risk-map-main-${index}`,
    title: 'Статистика ознакомления с картой рисков',
    content: (
      <div>
        <div>
          В этом блоке вы можете увидеть, сколько сотрудников ознакомились или
          не ознакомились с картой рисков.
        </div>
        <div>
          Нажмите на число, чтобы попасть в соответствующий список сотрудников.
        </div>
      </div>
    ),
  },
  {
    target: `#dangers-tab-${index}`,
    title: 'Опасности и риски',
    content: 'Перейдите к информации об опасностях и рисках',
    onOpen: () => setActive(1),
  },
  {
    target: `#dangers-tab-content-${index}`,
    title: 'Опасности и риски',
    content: (
      <div>
        <div>Здесь вы можете увидеть список опасностей, рисков и мер.</div>
        <div>
          Вы можете изменить этот список, перейдя в редактирование карты рисков.
        </div>
      </div>
    ),
  },
  {
    target: `#protectionMeans-tab-${index}`,
    title: 'Средства индивидуальной защиты',
    content: 'Перейдите к информации о СИЗ',
    onOpen: () => setActive(2),
  },
  {
    target: `#protectionMeans-tab-content-${index}`,
    title: 'Средства индивидуальной защиты',
    content:
      'Здесь вы можете увидеть список средств индивидуальной защиты, выбранных для опасностей из карты рисков',
  },
  {
    target: `#action-log-tab-${index}`,
    title: 'Лог действий',
    content: 'Перейдите к информации о действиях с картой рисков',
    onOpen: () => setActive(3),
  },
  {
    target: `#action-log-tab-content-${index}`,
    title: 'Лог действий',
    content: 'Здесь вы можете увидеть изменение статуса карты рисков',
  },
  {
    target: `#versions-history-tab-${index}`,
    title: 'История версий',
    content: 'Перейдите к истории версий карты рисков',
    onOpen: () => setActive(4),
  },
  {
    target: `#versions-history-tab-content-${index}`,
    title: 'Лог действий',
    content: (
      <div>
        <div>Здесь вы можете увидеть версии файлов с картой рисков,</div>
        <div>которые были добавлены при создании, изменении и продлении.</div>
      </div>
    ),
  },
]
