import styled from '@emotion/styled'
import { isMobile } from '@const/device'
import Title from '@organisms/Title'

const DesktopHeaderWrapper = styled.div<{ hasAdditionalTitleContent: boolean }>`
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;

  ${({ hasAdditionalTitleContent }) =>
    hasAdditionalTitleContent ? '' : 'height: 50px;'}
`
const MobileHeaderWrapper = styled(DesktopHeaderWrapper)<{
  hasAdditionalTitleContent: boolean
}>`
  flex-direction: column;

  ${({ hasAdditionalTitleContent }) =>
    hasAdditionalTitleContent ? '' : 'height: auto;'}
`
export const HeaderWrapper = isMobile
  ? MobileHeaderWrapper
  : DesktopHeaderWrapper

const DesktopStyledTitle = styled(Title)`
  margin-bottom: 0;
  margin-top: 0;
  white-space: nowrap;
`
const MobileStyledTitle = styled(DesktopStyledTitle)`
  margin-top: 10px;
  white-space: pre-wrap;
`
export const StyledTitle = isMobile ? MobileStyledTitle : DesktopStyledTitle
