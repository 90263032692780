import {
  DetailedHTMLProps,
  FC,
  InputHTMLAttributes,
  MouseEventHandler,
} from 'react'
import { Course } from '@models/course'
import InfoCounter from '@organisms/InfoCounter'
import { AccordionHeaderProps } from '@organisms/Accordion'
import DownloadButton from '@organisms/DownloadButton'
import { useLazyGetSafeProgramReportQuery } from '@services/courses'
import CourseInfo from '@templates/CourseInfo'
import GreenYellowStatus from '@templates/GreenYellowStatus'
import {
  DownloadReportWrapper,
  LeftWrapper,
  LineWrapper,
  StyledSubtitle,
  TitleText,
} from '../../styled'
import { Wrapper } from './styled'

type Props = {
  item: Course
} & AccordionHeaderProps &
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

const Header: FC<Props> = ({
  hovered,
  item: {
    safeProgram,
    id: courseId,
    title,
    listenersCount,
    subType,
    hoursCount,
    type,
    published,
  },
  ...rest
}) => {
  const [getSafeProgramReport, { isLoading: getSafeProgramReportIsLoading }] =
    useLazyGetSafeProgramReportQuery()

  const handleGetSafeProgramClick: MouseEventHandler = (event) => {
    event.stopPropagation()
    void getSafeProgramReport(courseId)
  }

  return (
    <Wrapper {...rest} hovered={hovered}>
      <LeftWrapper>
        <LineWrapper>
          <StyledSubtitle>
            <TitleText>{title}</TitleText>
            <InfoCounter>{listenersCount}</InfoCounter>
          </StyledSubtitle>
        </LineWrapper>
        <CourseInfo
          withDeadlineBadge={false}
          createdAt={undefined}
          deadlineAt={undefined}
          type={type}
          subType={subType}
          hoursCount={hoursCount}
        />
      </LeftWrapper>
      <LineWrapper>
        <DownloadReportWrapper>
          {safeProgram && (
            <DownloadButton
              onClick={handleGetSafeProgramClick}
              isLoading={getSafeProgramReportIsLoading}
            >
              Скачать программу
            </DownloadButton>
          )}
        </DownloadReportWrapper>
        <GreenYellowStatus
          success={published}
          successText="Опубликован"
          notSuccessText="Не опубликован"
        />
      </LineWrapper>
    </Wrapper>
  )
}

export default Header
