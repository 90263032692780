import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { fileField } from '@templates/AddNewItemModal/templates'
import FieldComponent from '@templates/Field'
import { File } from '@models/file'
import { Tab } from '@organisms/Tabs'
import Prompt from '@organisms/Prompt'
import { FileWrapper, TabWrapper } from './styled'

type Props = {
  isDisabled?: boolean
}

const RiskMapFileField: FC<Props> = ({ isDisabled }) => {
  const { watch } = useFormContext()

  const file: File | undefined = watch('file')

  const fileFieldProps = fileField({
    name: 'file',
    label: 'Приложить файл карты риска',
    formats: ['pdf', 'xls', 'xlsx', 'doc', 'docx', 'jpg', 'jpeg', 'png'],
    maxSizeMegaBytes: 50,
    isDisabled,
    value: file,
  })

  return (
    <FileWrapper id="risk-map-file">
      <FieldComponent {...fileFieldProps} />
      {isDisabled && (
        <Prompt promptText='Чтобы заменить файл карты рисков, нажмите на кнопку "Пересмотреть"' />
      )}
      <TabWrapper>
        <Tab
          id="id"
          title="Формат файла: pdf, xls, xlsx, doc, docx, jpg, jpeg, png. Вес файла: до 50Мб"
          tabSize="small"
          withoutHover
        />
      </TabWrapper>
    </FileWrapper>
  )
}

export default RiskMapFileField
