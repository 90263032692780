import { useEffect, useRef } from 'react'
import { useToggle } from 'react-use'
import { useParams } from 'react-router-dom'
import { BACKEND_URL } from '@const/env'
import {
  usePostEndMutation,
  usePostIspringTestResultsMutation,
  usePostPauseMutation,
  usePostStartMutation,
  usePostTestResultsMutation,
} from '@services/statistic'
import useMe from '@hooks/useMe'
import { ScormEvents } from '@enums/ScormEvents'
import { CommonBody } from '@models/statistic'
import { CourseTypes } from '@models/course'
import { log } from '@utils/console'

type ScormEvent = {
  data: { type: ScormEvents; data: Record<string, unknown> }
  origin: string
}

type IspringScormEvent = {
  data: {
    type: ScormEvents
    awardedPercent: string
    awardedScore: string
    eventTime: Date
    passingPercent: string
    passingScore: string
    quizDuration: string
    quizStatus: string
  }
  origin: string
}

const createCommonForm = (
  themeId: string,
  educationId: string
): CommonBody => ({
  theme: '/api/course_chapter_themes/' + themeId,
  educationDestination: +educationId
    ? '/api/employee_education_destinations/' + educationId
    : undefined,
})

const isCourseWithoutStats = (
  educationId: string,
  type: CourseTypes | undefined
): boolean => {
  log('isCourseWithoutStats', type, educationId)

  return educationId === '0' || type === CourseTypes.MINI
}

const useScormEventSender = (
  type: CourseTypes | undefined,
  scormType: 'articulate' | 'ispring' | undefined,
  test: boolean | undefined
) => {
  const { themeId = '', educationId = '' } = useParams()
  const lastMessageRef = useRef('')

  const { isAdmin } = useMe()

  const [started, toggleStarted] = useToggle(false)
  const [ended, toggleEnded] = useToggle(false)

  const [postStart] = usePostStartMutation()
  const [postEnd] = usePostEndMutation()
  const [postPause] = usePostPauseMutation()
  const [postResults] = usePostTestResultsMutation()
  const [postIspringResults] = usePostIspringTestResultsMutation()

  const handleInit = () => {
    log('handleInit', started)
    if (started) return

    const commonForm: CommonBody = createCommonForm(themeId, educationId)

    if (lastMessageRef.current !== JSON.stringify(commonForm) + 'INIT') {
      postStart(commonForm)
      lastMessageRef.current = JSON.stringify(commonForm) + 'INIT'
      toggleStarted(true)

      log('handleInit success', JSON.stringify(commonForm))
    }
  }

  const handleEnd = (testSuccess: boolean) => {
    log('handleEnd', ended)
    if (ended) return

    const commonForm: CommonBody = createCommonForm(themeId, educationId)

    const newMessage = {
      ...commonForm,
      testSuccess,
    }
    if (lastMessageRef.current !== JSON.stringify(newMessage) + 'END') {
      postEnd(newMessage)
      lastMessageRef.current = JSON.stringify(newMessage) + 'END'
      toggleEnded(true)

      log('handleEnd success', JSON.stringify(commonForm))
    }
  }

  const handleResults = (event: ScormEvent) => {
    log('handleResults')
    const commonForm: CommonBody = createCommonForm(themeId, educationId)

    const newMessage = {
      ...commonForm,
      questionNumber: event?.data?.data?.questionNumber as number,
      questionCount: event?.data?.data?.questionsCount as number,
      success: event?.data?.data?.success as boolean,
    }
    if (lastMessageRef.current !== JSON.stringify(newMessage) + 'TEST') {
      postResults(newMessage)
      lastMessageRef.current = JSON.stringify(newMessage) + 'TEST'

      log('handleResults success', JSON.stringify(commonForm))
    }
  }

  const handleSpringResults = async (event: IspringScormEvent) => {
    log('handleSpringResults', test)
    if (test) {
      const commonForm: CommonBody = createCommonForm(themeId, educationId)

      await postIspringResults({
        ...commonForm,
        passingScore: +event.data.passingScore,
        passingPercent: +event.data.passingPercent,
        awardedScore: +event.data.awardedScore,
        awardedPercent: +event.data.awardedPercent,
        quizDuration: +event.data.quizDuration,
      })
    }

    handleEnd(event.data.quizStatus === 'Passed')
  }

  useEffect(() => {
    toggleStarted(false)
    toggleEnded(false)

    log('COURSE CHANGED EFFECT', themeId, educationId, type, scormType)
    if (isCourseWithoutStats(educationId, type)) {
      return
    }

    if (scormType === 'ispring') {
      handleInit()
    }
  }, [themeId, educationId, type, scormType])

  const listener = (event: ScormEvent) => {
    if (!isAdmin && event.origin === BACKEND_URL && themeId) {
      log(
        'listener params',
        JSON.stringify({ themeId, educationId, type, scormType })
      )
      log('SCORM EVENT:', event.data)
      switch (event.data.type) {
        case ScormEvents.INIT:
          handleInit()
          break
        case ScormEvents.END:
          handleEnd(!!event?.data?.data?.testSuccess)
          break
        case ScormEvents.TEST_RESULTS:
          handleResults(event)
          break
        case ScormEvents.ISPRING:
          void handleSpringResults(event as unknown as IspringScormEvent)
          break
      }
    }
  }

  useEffect(() => {
    window.removeEventListener('message', listener)
    window.addEventListener('message', listener)

    return () => window.removeEventListener('message', listener)
  }, [themeId, educationId, type, scormType])

  return async () => {
    const commonForm: CommonBody = createCommonForm(themeId, educationId)

    log(
      'useScormEventSender callback',
      { started, ended },
      JSON.stringify(commonForm)
    )

    if (isCourseWithoutStats(educationId, type)) {
      return
    }

    if (started && !ended) {
      await postPause(commonForm)
    }

    toggleEnded(false)
    toggleStarted(false)
    lastMessageRef.current = ''
  }
}

export default useScormEventSender
