import { Skeleton } from 'antd'
import { FC } from 'react'
import useMouseOverOut from '@hooks/useMouseOverOut'
import { EmployeeEducationDestination } from '@models/employeeEducationDestination'
import { ListItemProps } from '@templates/List'
import Header from './Header'

const StudyItem: FC<ListItemProps<EmployeeEducationDestination>> = ({
  isLoading,
  item: { educationDestination, progress, id },
  index,
}) => {
  const { course, createdAt, deadlineAt } = educationDestination || {}
  const [hovered, , handleMouseEnter, handleMouseLeave] = useMouseOverOut()

  if (isLoading) {
    return (
      <Skeleton.Button
        active
        block
        style={{
          height: 170,
          marginBottom: 12,
          borderRadius: 30,
        }}
      />
    )
  }

  return (
    <Header
      index={index}
      progress={progress}
      withDeadlineBadge={progress !== 100}
      employeeEducationId={id}
      createdAt={createdAt}
      deadlineAt={deadlineAt}
      item={course}
      hovered={hovered}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  )
}

export default StudyItem
