import { JoyStep } from '@templates/Onboarding'

export const ONBOARDING_TITLE =
  'Расскажем, как добавить или найти подразделение'

export const ONBOARDING_STEPS: JoyStep[] = [
  {
    target: '#add-button',
    title: 'Как добавить подразделение',
    content: 'Чтобы добавить подразделение, нажмите кнопку “Добавить”',
  },
  {
    target: '#search-block',
    title: 'Как найти подразделение',
    content: 'Вы можете найти нужное подразделение по наименованию',
  },
  {
    target: '#filter-panel',
    title: 'Как найти подразделение',
    content: 'Также можно найти подразделение с помощью фильтров',
  },
]
