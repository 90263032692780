import StoreTags from '@enums/StoreTags'
import {
  Company,
  CompanyDetailed,
  CompanyOption,
  CreateCompanyBody,
  CreateCompanyResponse,
  UpdateCompanyBody,
  UpdateCompanyResponse,
} from '@models/company'
import { CommonQueryParams, ItemsFromResponse } from '@models/common'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'
import emptySplitApi from '../api'

const BASE_URL = '/api/companies'

export const companiesApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompanies: builder.query<
      { items: CompanyOption[]; length: number },
      CommonQueryParams
    >({
      query: (params) => ({
        url: BASE_URL,
        params,
      }),
      transformResponse: (response: ItemsFromResponse<Company>) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          value: item['@id'],
          label: item.title,
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
      providesTags: [StoreTags.COMPANIES],
    }),
    createCompany: builder.mutation<CreateCompanyResponse, CreateCompanyBody>({
      invalidatesTags: (result) => (result ? [StoreTags.COMPANIES] : []),
      query: (body) => ({
        url: BASE_URL,
        method: 'POST',
        body,
      }),
    }),
    updateCompany: builder.mutation<UpdateCompanyResponse, UpdateCompanyBody>({
      invalidatesTags: (result) =>
        result ? [StoreTags.COMPANIES, StoreTags.COMPANY, StoreTags.ME] : [],
      query: ({ id, ...body }) => ({
        url: `${BASE_URL}/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    getCompany: builder.query<CompanyDetailed, number>({
      providesTags: [StoreTags.COMPANY],
      query: (id) => `${BASE_URL}/${id}`,
    }),
    deleteCompany: builder.mutation<void, number>({
      invalidatesTags: [StoreTags.COMPANIES],
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetCompaniesQuery,
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useGetCompanyQuery,
  useLazyGetCompanyQuery,
  useDeleteCompanyMutation,
} = companiesApi
