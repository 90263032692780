import { isMobile } from '@const/device'
import styled from '@emotion/styled'
import Subtitle from '@organisms/Subtitle'

export const LineWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const DesktopStyledSubtitle = styled(Subtitle)`
  font-size: ${({ theme }) => theme.text.h4.fontSize};
  line-height: ${({ theme }) => theme.text.h4.lineHeight};
  margin-right: 10px;
  margin-bottom: ${({ theme }) => theme.sizes.base};
  display: flex;
  min-height: 25px;
`
const MobileStyledSubtitle = styled(DesktopStyledSubtitle)`
  min-height: inherit;
`
export const StyledSubtitle = isMobile
  ? MobileStyledSubtitle
  : DesktopStyledSubtitle

const DesktopTitleText = styled.div`
  display: block;
  margin-right: 15px;
  max-width: 45vw;
  white-space: pre-wrap;
`
const MobileTitleText = styled(DesktopTitleText)`
  margin-right: 0;
  max-width: 100%;
`
export const TitleText = isMobile ? MobileTitleText : DesktopTitleText

export const LeftWrapper = styled.div``

export const RightContentWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: ${() => (isMobile ? '10px' : '0')};
  justify-content: ${() => (isMobile ? 'flex-end' : 'flex-start')};
  width: 100%;
`

export const DownloadReportWrapper = styled.div`
  margin-right: 10px;
`
