import { FC } from 'react'
import StyledButton, { StyledButtonProps } from '@organs/StyledButton'
import ButtonStyle from '@enums/ButtonStyle'

const WhiteButton: FC<StyledButtonProps> = ({
  buttonStyle = ButtonStyle.WHITE,
  children = 'Кнопка',
  ...restProps
}) => {
  return (
    <StyledButton buttonStyle={buttonStyle} {...restProps}>
      {children}
    </StyledButton>
  )
}

export default WhiteButton
