import { FC, ReactNode, useEffect } from 'react'
import { useToggle } from 'react-use'
import { useLocation } from 'react-router-dom'
import Link, { LinkStyle } from '@organisms/Link'
import SecondaryButton from '@organisms/SecondaryButton'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import {
  ArrowWrapper,
  CloseFiltersBlock,
  FiltersWrapper,
  StyledContentBlock,
} from './styled'

type Props = {
  children: ReactNode
}

const CollapsibleFilters: FC<Props> = ({ children }) => {
  const { search } = useLocation()

  const [expanded, toggleExpanded] = useToggle(true)

  useEffect(() => {
    if (!expanded && search?.length > 3) {
      setTimeout(() => {
        toggleExpanded(true)
      }, 1000)
    }
  }, [search])

  if (expanded) {
    return (
      <StyledContentBlock>
        <CloseFiltersBlock>
          <ArrowWrapper onClick={toggleExpanded}>
            <Link text="Фильтры" linkStyle={LinkStyle.DEFAULT} />
          </ArrowWrapper>
          <ArrowUpOutlined onClick={toggleExpanded} />
        </CloseFiltersBlock>
        <FiltersWrapper>{children}</FiltersWrapper>
      </StyledContentBlock>
    )
  }

  return (
    <SecondaryButton rightIcon={<ArrowDownOutlined />} onClick={toggleExpanded}>
      Фильтры
    </SecondaryButton>
  )
}

export default CollapsibleFilters
