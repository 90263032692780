import { JoyStep } from '@templates/Onboarding'
import {
  BODY_CHECK_REPORT_NAME,
  LINKS_FOR_PASSWORDS_NAME,
  BODY_CHECK_REPORT,
  LINKS_REPORT,
} from '@const/reportNames'

export const REPORTS_ONBOARDING_TITLE =
  'Расскажем, как создавать отчёты по сотрудникам'

export const REPORTS_ONBOARDING_STEPS: JoyStep[] = [
  {
    target: '#reportsContainer',
    title: 'Расскажем, как создавать отчёты по сотрудникам',
    content: 'Чтобы создать отчет, нажмите на него',
  },
  {
    target: `#${BODY_CHECK_REPORT}`,
    title: `Отчёт “${BODY_CHECK_REPORT_NAME}”`,
    content: (
      <div>
        <div>
          В отчёт выводятся данные о сотрудниках, которым нужно будет пройти
          медосмотр.
        </div>
        <div>
          При переходе к отчёту вы сможете дополнительно отфильтровать данные.
        </div>
      </div>
    ),
  },
  {
    target: `#${LINKS_REPORT}`,
    title: `Отчёт “${LINKS_FOR_PASSWORDS_NAME}”`,
    content: (
      <div>
        <div>В отчёте выводятся ссылки и логины для пользователей.</div>
        <div>
          Вы можете передать ссылки сотрудникам, при переходе они смогут задать
          пароли для доступа к платформе.
        </div>
        <div>
          При переходе к отчёту вы сможете дополнительно отфильтровать данные.
        </div>
      </div>
    ),
  },
]
