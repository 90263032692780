import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useToggle } from 'react-use'
import OutsideClickHandler from 'react-outside-click-handler'
import { ReactComponent as ActiveArrowDown } from '@interface-images/arrow-down-active.svg'
import { ReactComponent as InactiveArrowDown } from '@interface-images/arrow-down.svg'
import { ReactComponent as EmptyAvatar } from '@interface-images/empty-avatar.svg'
import useAuth from '@hooks/useAuth'
import useMe from '@hooks/useMe'
import { Routes } from '@enums/Routes'
import { isMobile } from '@const/device'
import Modal from '@atoms/Modal'
import {
  ArrowWrapper,
  MenuElement,
  MenuWrapper,
  MobileProfileMenuWrapper,
  NameWrapper,
  SelectWrapper,
  Wrapper,
} from './styled'

const NameWithAvatar: FC = () => {
  const [expanded, toggleExpanded] = useToggle(false)
  const { fullName } = useMe()
  const { logout } = useAuth()
  const navigate = useNavigate()

  const handleProfile = () => {
    navigate(Routes.PROFILE)
    toggleExpanded(false)
  }

  const handleExit = () => {
    navigate(Routes.INIT)
    logout()
    toggleExpanded(false)
  }

  const name = fullName?.split(' ')[1] || fullName || 'Профиль'

  const menuItems = (
    <>
      <MenuElement onClick={handleProfile}>Мой профиль</MenuElement>
      <MenuElement onClick={handleExit}>Выход</MenuElement>
    </>
  )

  const menuComponent = isMobile ? (
    <Modal visible={expanded} onModalClose={toggleExpanded}>
      <MobileProfileMenuWrapper>{menuItems}</MobileProfileMenuWrapper>
    </Modal>
  ) : (
    <MenuWrapper onClick={(event) => event.stopPropagation()}>
      {menuItems}
    </MenuWrapper>
  )

  return (
    <OutsideClickHandler onOutsideClick={() => toggleExpanded(false)}>
      <SelectWrapper>
        <Wrapper onClick={toggleExpanded}>
          <EmptyAvatar />
          {!isMobile && <NameWrapper expanded={expanded}>{name}</NameWrapper>}
          <ArrowWrapper>
            {expanded ? <ActiveArrowDown /> : <InactiveArrowDown />}
          </ArrowWrapper>
        </Wrapper>
        {expanded && menuComponent}
      </SelectWrapper>
    </OutsideClickHandler>
  )
}

export default NameWithAvatar
