import styled from '@emotion/styled'
import { isMobile } from '@const/device'

const DesktopWrapper = styled.div`
  background: ${({ theme }) => theme.colors.background.surface};
  box-shadow: 5px 5px 20px rgba(29, 78, 137, 0.1);
  border-radius: 24px;
  width: 23vw;
  padding: ${({ theme }) => theme.sizes.twoBase};
  margin: ${({
    theme: {
      sizes: { twoBase },
    },
  }) => `0 ${twoBase} ${twoBase} 0`};
  cursor: pointer;
`
const MobileWrapper = styled(DesktopWrapper)`
  width: 100%;
`
export const Wrapper = isMobile ? MobileWrapper : DesktopWrapper

export const SubWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const IconWrapper = styled.div`
  margin-right: ${({ theme }) => theme.sizes.twoBase};
  width: 50px;
`

export const Date = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-align: left;
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-top: 8px;
`
