import { FC } from 'react'
import { useToggle } from 'react-use'
import AddButton from '@organisms/AddButton'
import Modal from '@organisms/Modal'
import ChapterModal from '@modals/ChapterModal'

type Props = {
  courseIri: string
}

const AddChapterButton: FC<Props> = ({ courseIri }) => {
  const [visible, toggleVisible] = useToggle(false)

  return (
    <>
      <AddButton onClick={toggleVisible}>Добавить раздел</AddButton>
      <Modal visible={visible} onModalClose={toggleVisible}>
        <ChapterModal
          isEdit={false}
          courseIri={courseIri}
          handleCloseModal={toggleVisible}
        />
      </Modal>
    </>
  )
}

export default AddChapterButton
