import { FC } from 'react'
import ConfirmModalContent from '@organisms/ConfirmModalContent'
import SecondaryButton from '@organisms/SecondaryButton'
import { ButtonWrapper, StyledParagraph, Wrapper } from './styled'

type Props = {
  onRefresh?: () => void
}

const EmptyList: FC<Props> = ({ onRefresh }) => {
  return (
    <Wrapper>
      <ConfirmModalContent
        title="Ничего не найдено"
        iconSize={200}
        leftIcon="sad-bear-new.svg"
        rightContent={
          <div>
            <StyledParagraph>
              Попробуйте скорректировать запрос, или начните заново
            </StyledParagraph>
            {onRefresh && (
              <ButtonWrapper>
                <SecondaryButton onClick={onRefresh} big>
                  Назад
                </SecondaryButton>
              </ButtonWrapper>
            )}
          </div>
        }
      />
    </Wrapper>
  )
}

export default EmptyList
