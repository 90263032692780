import styled from '@emotion/styled'

export const Wrapper = styled.div`
  padding: 20px 0;
`

export const UpdateAllWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
`
