import { fileField } from '@templates/AddNewItemModal/templates'
import { Fields } from '@templates/AddNewItemModal/Content'

const useUploadExternalEducationsFields = (): Fields => {
  return [
    {
      fields: [
        fileField({
          name: 'file',
          label: 'Файл',
          isRequired: true,
        }),
      ],
    },
  ]
}

export default useUploadExternalEducationsFields
