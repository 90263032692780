import styled from '@emotion/styled'

export const ContentWrapper = styled.div`
  margin: ${({ theme }) => `${theme.sizes.base} -25px`};
`

export const ContentBeforeItemsWrapper = styled.div`
  padding: 0 30px;
  margin-bottom: 20px;
`

export const MultilineFieldWrapper = styled.div`
  padding: 0 30px;
`
