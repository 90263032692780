import { notification } from 'antd'
import { isEmpty } from 'lodash'
import { Middleware, MiddlewareAPI } from '@reduxjs/toolkit'
import { logout, toggleNeedCheckExpiredPassword } from '@services/auth/slice'
import { RootState } from '../index'

const toastError = (message: string) => notification.error({ message })

const Unauthorized: Middleware =
  ({ dispatch, getState }: MiddlewareAPI) =>
  (next) =>
  (action: any) => {
    if (
      action?.payload?.status >= 400 &&
      isEmpty(action?.payload?.data?.violations)
    ) {
      if (action.payload.status === 401) {
        const state: RootState = getState()
        if (state.auth.token) {
          dispatch(toggleNeedCheckExpiredPassword(true))
        } else {
          dispatch(logout())
        }
      } else {
        toastError(
          action.payload?.data?.['hydra:description'] ||
            'Что-то пошло не так, проверьте корректность введенных данных и повторите запрос снова'
        )
      }
    }

    return next(action)
  }
export default Unauthorized
