import * as React from 'react'
import { DetailedHTMLProps, FC, InputHTMLAttributes, useRef } from 'react'
import { Icon } from '@app-types/common'
import { IconWrapper, StyledInput, StyledInputWrapper, Wrapper } from './styled'

type Props = {
  LeftIcon?: Icon
  RightIcon?: Icon
  rightIconProps?: DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

/** @deprecated **/
const Input: FC<Props> = ({ LeftIcon, RightIcon, rightIconProps, ...rest }) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleWrapperClick = () => {
    inputRef?.current?.focus()
  }

  return (
    <Wrapper onClick={handleWrapperClick}>
      <StyledInputWrapper>
        {LeftIcon && (
          <IconWrapper>
            <LeftIcon />
          </IconWrapper>
        )}
        <StyledInput {...rest} ref={inputRef} />
        {RightIcon && (
          <IconWrapper {...rightIconProps}>
            <RightIcon />
          </IconWrapper>
        )}
      </StyledInputWrapper>
    </Wrapper>
  )
}

export type { Props as InputProps }
export default Input
