import { SelectProps } from '@organisms/Select'
import {
  FilterProps,
  FilterTypes,
} from '@templates/ListPageLayout/Filters/Filter'
import useDangerWorkPlaces from '@hooks/useDangerWorkPlaces'

const useDangerWorkPlacesFilter = (
  fieldNameForSubmit?: string
): FilterProps<SelectProps> => {
  const { data, isLoading, setFilters, setPage } = useDangerWorkPlaces()
  const { items = [], length = 0 } = data || {}

  return {
    options: items,
    length,
    isLoading,
    placeholder: 'Работы / Место / Авария',
    fieldName: 'workPlace',
    fieldNameForSubmit,
    filterType: FilterTypes.SELECT_SEARCH,
    setFilters,
    setPage,
    dropdownWidth: 500,
  }
}

export default useDangerWorkPlacesFilter
