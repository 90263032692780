import {
  BannerSettings,
  BannerSettingsCodes,
  TextSettings,
  TextSettingsCodes,
  UpdateBannerSettingsBody,
  UpdateTextSettingsBody,
} from '@models/settings'
import StoreTags from '@enums/StoreTags'
import emptySplitApi from '../api'

export const settingsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getBannerSettingsByCode: builder.query<BannerSettings, BannerSettingsCodes>(
      {
        providesTags: [StoreTags.SETTING],
        query: (code) => `/api/banner_settings/${code}`,
      }
    ),
    putBannerSettings: builder.mutation<
      BannerSettings[],
      UpdateBannerSettingsBody
    >({
      invalidatesTags: (result) => (result ? [StoreTags.SETTING] : []),
      query: ({ code, ...body }) => ({
        url: `/api/banner_settings/${code}`,
        method: 'PUT',
        body,
      }),
    }),
    getTextSettingsByCode: builder.query<TextSettings, TextSettingsCodes>({
      providesTags: [StoreTags.SETTINGS],
      query: (code) => `/api/text_settings/${code}`,
    }),
    putTextSettings: builder.mutation<TextSettings, UpdateTextSettingsBody>({
      invalidatesTags: (result) =>
        result ? [StoreTags.SETTING, StoreTags.SETTINGS] : [],
      query: ({ code, ...body }) => ({
        url: `/api/text_settings/${code}`,
        method: 'PUT',
        body,
      }),
    }),
  }),
})

export const {
  usePutBannerSettingsMutation,
  useGetBannerSettingsByCodeQuery,
  useGetTextSettingsByCodeQuery,
  usePutTextSettingsMutation,
} = settingsApi
