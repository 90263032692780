import StoreTags from '@enums/StoreTags'
import { CommonQueryParams, ItemsFromResponse } from '@models/common'
import {
  FilialLimitHistory,
  FilialLimitHistoryOption,
} from '@models/filialLimitHistories'
import emptySplitApi from '../api'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'

const BASE_URL = '/api/company_branch_limit_histories'

export const filialLimitHistoryApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getFilialLimitHistories: builder.query<
      { items: FilialLimitHistoryOption[]; length: number },
      CommonQueryParams
    >({
      query: (params) => ({
        url: BASE_URL,
        params,
      }),
      transformResponse: (response: ItemsFromResponse<FilialLimitHistory>) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          value: item['@id'],
          label: item.newLimit as unknown as string,
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
      providesTags: [StoreTags.EMPLOYEE_EDUCATION_DESTINATIONS],
    }),
  }),
})

export const { useGetFilialLimitHistoriesQuery } = filialLimitHistoryApi
