import { Skeleton } from 'antd'
import * as React from 'react'
import { FC } from 'react'
import { FormProvider } from 'react-hook-form'
import useFormProcessor from '@hooks/useFormProcessor'
import useJobFields from '@hooks/useJobFields'
import useFilialLimitHistories from '@hooks/useFilialLimitHistories'
import useMe from '@hooks/useMe'
import { createInitFilter } from '@utils/filters'
import Field from '@templates/Field'
import Table, { Column } from '@organisms/Table'
import ContentBlock from '@organisms/ContentBlock'
import { getTableData } from './utils'
import { StyledTitle, TableWrapper, Wrapper } from './styled'

const COLUMNS: Column[] = [
  {
    title: 'Наименование',
    dataIndex: 'title',
  },
  {
    title: 'Количество',
    dataIndex: 'count',
  },
  {
    title: 'Дата начала',
    dataIndex: 'startAt',
  },
  {
    title: 'Дата окончания',
    dataIndex: 'endAt',
  },
]

const EnrollmentItem: FC = () => {
  const { meUser: userData } = useMe()
  const methods = useFormProcessor()
  const { filial } = useJobFields(methods)
  const filialValue = methods.watch('filial')
  const { companyBranch } = userData || {}
  const filialField = filial ? filialValue : companyBranch
  const { data, isLoading } = useFilialLimitHistories({
    skip: !filialField,
    ...(filialField
      ? {
          initFilters: createInitFilter('companyBranch', filialField?.id),
        }
      : {}),
  })
  const { items = [] } = data || {}

  const tableData = getTableData(items, filialField?.title)

  if (isLoading) {
    return (
      <Skeleton.Button
        style={{
          height: 400,
          borderRadius: 25,
        }}
        active
        block
      />
    )
  }

  return (
    <FormProvider {...methods}>
      <Wrapper>
        <ContentBlock height={filial ? 500 : 400}>
          <StyledTitle>История зачислений</StyledTitle>
          {filial && <Field {...filial({ label: 'Выбрать филиал' })} />}
          {(filialValue || !filial) && (
            <>
              <TableWrapper>
                <Table
                  columns={COLUMNS}
                  data={tableData}
                  isLoading={isLoading}
                />
              </TableWrapper>
              {/* <Footer> */}
              {/*   <DownloadButton>Сохранить отчет</DownloadButton> */}
              {/* </Footer> */}
            </>
          )}
        </ContentBlock>
      </Wrapper>
    </FormProvider>
  )
}

export default EnrollmentItem
