import styled from '@emotion/styled'

export const StyledTitle = styled.div<{
  hovered: boolean
}>`
  color: ${({ hovered, theme }) => (hovered ? theme.colors.main : 'black')};
  font-weight: 500;
  font-size: ${({ theme }) => theme.text.pNormal.fontSize};
  line-height: ${({ theme }) => theme.text.pNormal.lineHeight};
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 97%;
  align-items: center;
  min-height: ${({ theme }) => theme.sizes.accordionHeight};
`

export const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`

export const ViewsWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  //styleName: desktop/h6;
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #7b7b7b;
`
