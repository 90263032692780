import * as React from 'react'
import { FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Skeleton } from 'antd'
import { useGetInstructionQuery } from '@services/instructions'
import Modal from '@organisms/Modal'
import { Routes } from '@enums/Routes'
import Title from '@organisms/Title'

const Instruction: FC = () => {
  const { code = '' } = useParams()
  const navigate = useNavigate()
  const { data, isLoading } = useGetInstructionQuery(code)
  const { text, title } = data || {}

  const handleClose = () => {
    navigate(Routes.GUIDES)
  }

  return (
    <Modal visible onModalClose={handleClose}>
      <Title>{title}</Title>
      {isLoading && (
        <Skeleton.Button
          active
          block
          style={{
            height: '85vh',
            width: '85vw',
            borderRadius: 50,
          }}
        />
      )}
      {text && (
        <div
          style={{ overflowY: 'scroll', marginTop: 20 }}
          dangerouslySetInnerHTML={{ __html: text }}
        ></div>
      )}
    </Modal>
  )
}

export default Instruction
