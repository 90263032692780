import * as React from 'react'
import { FC, useState } from 'react'
import Tabs, { TabsItem } from '@organisms/Tabs'
import Title from '@organisms/Title'
import { TabsWrapper, Wrapper } from './styled'

type Props = {
  title: string
  items: TabsItem[]
  onTabChange?: (activeIndex: number) => void
}

const AccordionBodySelectedTabs: FC<Props> = ({
  items,
  title,
  onTabChange,
}) => {
  const [active, setActive] = useState(0)

  return (
    <Wrapper>
      <Title mySize="h3">{title}</Title>
      <TabsWrapper>
        <Tabs
          onTabChange={onTabChange}
          active={active}
          setActive={setActive}
          tabSize="small"
          items={items}
        />
      </TabsWrapper>
    </Wrapper>
  )
}

export default AccordionBodySelectedTabs
