import React, { FC } from 'react'
import CloseButton from '@organisms/CloseButton'
import ButtonStyle from '@enums/ButtonStyle'
import BackButton from '@organisms/BackButton'
import NextButton from '@organisms/NextButton'
import WhiteButton from '@organisms/WhiteButton'
import { isMobile } from '@const/device'
import { BACKEND_URL } from '@const/env'
import { ButtonsWrapper, StyledTitle, TitleWrapper, Wrapper } from './styled'

const BEAR_ICON_SIZE = isMobile ? 70 : 100
const BUTTON_CHILDREN = isMobile ? null : undefined

const OnboardingTooltip: FC<any> = ({
  continuous,
  index,
  step,
  backProps,
  primaryProps,
  tooltipProps,
  closeProps,
  hideCloseButton,
  isLastStep,
}) => {
  return (
    <Wrapper {...tooltipProps}>
      <TitleWrapper>
        <img
          alt="bear"
          src={`${BACKEND_URL}/images/onboarding-bear-new.svg`}
          height={BEAR_ICON_SIZE}
          width={BEAR_ICON_SIZE}
        />
        <div>
          {step.title && <StyledTitle>{step.title}</StyledTitle>}
          {step.content && (
            <div style={{ fontSize: isMobile ? 12 : 14 }}>{step.content}</div>
          )}
        </div>
      </TitleWrapper>
      <ButtonsWrapper>
        {!isLastStep && !hideCloseButton && (
          <CloseButton
            {...closeProps}
            buttonStyle={ButtonStyle.WHITE_TRANSPARENT}
            className="onboarding"
            children={BUTTON_CHILDREN}
          />
        )}
        {index > 0 && (
          <BackButton
            {...backProps}
            buttonStyle={ButtonStyle.WHITE_TRANSPARENT}
            className="onboarding"
          />
        )}
        {!isLastStep && continuous && (
          <NextButton
            {...primaryProps}
            buttonStyle={ButtonStyle.WHITE}
            className="onboarding"
          />
        )}
        {isLastStep && (
          <WhiteButton {...closeProps} className="onboarding">
            Завершить
          </WhiteButton>
        )}
      </ButtonsWrapper>
    </Wrapper>
  )
}

export default OnboardingTooltip
