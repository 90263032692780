import { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { createInitFilter } from '@utils/filters'
import { Option, SelectProps } from '@organisms/Select'
import { FilterState } from '@hooks/useItemsFilters'
import useSearchParam from '@hooks/useSearchParam'
import { FilterComponentProps } from './index'

const useSelectFilterMethods = ({
  filter,
  field,
  onFilter,
  initialFiltersState,
}: FilterComponentProps<SelectProps>) => {
  const { fieldName, fieldNameForSubmit, options, transformValue } = filter
  const param = useSearchParam(fieldName)

  const resultFieldName = fieldNameForSubmit || fieldName

  const handleFilter = (newFilter: FilterState) => {
    return transformValue
      ? onFilter(transformValue(newFilter))
      : onFilter(newFilter)
  }

  const handleSelect = (values: Option[] | Option) => {
    field.onChange?.(values)

    if (!values) {
      const initialStateForFilter = initialFiltersState[resultFieldName]
      handleFilter(
        createInitFilter(resultFieldName, initialStateForFilter || [])
      )
      return
    }

    const resultValues = Array.isArray(values) ? values : [values]
    handleFilter(
      createInitFilter(
        resultFieldName,
        resultValues.map((value) => value?.value)
      )
    )
  }

  useEffect(() => {
    if (param && !isEmpty(options)) {
      const paramValue = JSON.parse(param)
      handleSelect(paramValue ? [paramValue] : [])
    }
  }, [param, options])

  return {
    handleSelect,
    defaultValue: initialFiltersState?.[resultFieldName],
  }
}

export default useSelectFilterMethods
