import React, { FC, memo } from 'react'
import useMe from '@hooks/useMe'
import useVisibleOnboarding from '@templates/Onboarding/useVisibleOnboarding'
import { useAppSelector } from '@hooks/useAppSelector'
import OnboardingContent, { OnboardingProps } from './OnboardingContent'

const Onboarding: FC<OnboardingProps> = (props) => {
  const { isAdmin } = useMe()
  const onboardingVisited = useAppSelector(
    (state) => state.auth.onboardingVisited
  )

  const visibleOnboarding = useVisibleOnboarding()

  if (isAdmin || onboardingVisited?.[props.code] || !visibleOnboarding) {
    return null
  }

  return <OnboardingContent {...props} />
}

export default memo(Onboarding)

export {
  type JoyStep,
  type OnboardingProps,
} from '@templates/Onboarding/OnboardingContent'
