import { FC } from 'react'
import { components, PlaceholderProps } from 'react-select'
import { isEmpty } from 'lodash'

const Placeholder: FC<PlaceholderProps> = (props) => {
  const notSelected = isEmpty(props.selectProps.value)
  return (
    <components.Placeholder
      {...props}
      innerProps={{
        ...props.innerProps,
        style: {
          color: notSelected ? '#7f7f7f' : 'white',
        },
      }}
    />
  )
}

export default Placeholder
