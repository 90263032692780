import { FC } from 'react'
import { Tab } from '@organisms/Tabs'
import Tooltip from '@organisms/Tooltip'
import DivisionInfoField from '@templates/DivisionInfoField'
import PositionInfoField from '@templates/PositionInfoField'
import { SubdivisionJobPositionRel } from '@models/user'
import { LineWrapper } from './styled'

type Props = {
  subdivisionJobPositionRels: SubdivisionJobPositionRel[]
}

const ManyPositionDivisionPopover: FC<Props> = ({
  subdivisionJobPositionRels,
}) => {
  if (!subdivisionJobPositionRels[1]) {
    return null
  }

  const [, ...subdivisionJobPositionRelsResult] = subdivisionJobPositionRels

  const tooltipContent = (
    <>
      {subdivisionJobPositionRelsResult.map(({ subdivision, jobPosition }) => (
        <LineWrapper>
          <DivisionInfoField division={subdivision} />
          <PositionInfoField position={jobPosition} />
        </LineWrapper>
      ))}
    </>
  )

  return (
    <Tooltip tooltipContent={tooltipContent}>
      <div>
        <Tab
          id="id"
          title={`+${subdivisionJobPositionRelsResult.length}`}
          tabSize="small"
        />
      </div>
    </Tooltip>
  )
}

export default ManyPositionDivisionPopover
