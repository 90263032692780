import { FC } from 'react'
import { BannerSettings } from '@models/settings'
import BannerComponent from '../../BannerComponent'
import BearButton from '../../BearButton'

type Props = {
  bannerSettings: BannerSettings
}

const BodyCheck: FC<Props> = ({ bannerSettings }) => {
  const buttons = bannerSettings.buttons || []
  const filteredButtons = buttons.filter(({ title }) => title?.length > 0)

  return (
    <BannerComponent
      bearPath="med-bear-new.svg"
      buttons={filteredButtons.map((item) => (
        <BearButton {...item} key={item.id} />
      ))}
      description={bannerSettings.text || 'У вас запланирован медосмотр'}
    />
  )
}

export default BodyCheck
