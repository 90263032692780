import { FC } from 'react'
import { isAfter, sub } from 'date-fns'
import { BannerSettings, BannerSettingsCodes } from '@models/settings'
import { useGetBannerSettingsByCodeQuery } from '@services/settings'
import useMe from '@hooks/useMe'
import Greetings from '../Greetings'
import SubscribeEnd from './SubscribeEnd'
import LicencesCountEnd from './LicencesCountEnd'

const FilialCuratorBanner: FC = () => {
  const { curatorCompanyBranches = [] } = useMe()

  const { data: greetings = {} as BannerSettings } =
    useGetBannerSettingsByCodeQuery(
      BannerSettingsCodes.FILIAL_CURATOR_GREETINGS
    )
  const { data: subscribeEnd = {} as BannerSettings } =
    useGetBannerSettingsByCodeQuery(
      BannerSettingsCodes.FILIAL_CURATOR_SUBSCRIBE_END
    )
  const { data: licencesCountEnd = {} as BannerSettings } =
    useGetBannerSettingsByCodeQuery(
      BannerSettingsCodes.FILIAL_CURATOR_LICENCES_COUNT_END
    )

  const needRenderSubscribeEnd = curatorCompanyBranches.some(
    ({ subscriptionEndAt }) =>
      isAfter(new Date(subscriptionEndAt), sub(new Date(), { days: 14 }))
  )
  const needRenderLicencesCountEnd = curatorCompanyBranches.some(
    ({ usedSubscriptionsCount = 0, subscriptionsCount = 0 }) =>
      subscriptionsCount - usedSubscriptionsCount <= 15
  )

  if (needRenderSubscribeEnd && subscribeEnd) {
    return <SubscribeEnd bannerSettings={subscribeEnd} />
  }

  if (needRenderLicencesCountEnd && licencesCountEnd) {
    return <LicencesCountEnd bannerSettings={licencesCountEnd} />
  }

  return <Greetings bannerSettings={greetings} />
}

export default FilialCuratorBanner
