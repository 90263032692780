import { FC } from 'react'
import { useToggle } from 'react-use'
import { UseFormReturn } from 'react-hook-form'
import AddNewItemModal from '@templates/AddNewItemModal'
import Modal from '@organisms/Modal'
import AddButton from '@organisms/AddButton'
import ButtonStyle from '@enums/ButtonStyle'
import { Fields } from '@templates/AddNewItemModal/Content'
import FileErrorComponent from '@templates/FileErrorComponent'
import useMe from '@hooks/useMe'
import { OnboardingProps } from '@templates/Onboarding'
import DownloadExampleButton from '../DownloadExampleButton'

type Props = {
  onSuccess: () => void
  fields: Fields
  methods: UseFormReturn
  handleSubmit: (values: any) => void // correct
  isSuccess: boolean
  isError: boolean
  isLoading: boolean
  reset: () => void
  error?: any
  onboarding?: OnboardingProps
}
const StaffingModal: FC<Props> = ({
  onSuccess,
  fields,
  methods,
  handleSubmit,
  isSuccess,
  isError,
  reset,
  isLoading,
  error,
  onboarding,
}) => {
  const { divisionCuratorWithOneDivision } = useMe()
  const templateLink = divisionCuratorWithOneDivision
    ? '/import_employees_template_for_curator_with_one_division.xls'
    : '/import_employees_template.xls'

  const [visible, toggleVisible] = useToggle(false)

  return (
    <>
      <AddButton
        onClick={toggleVisible}
        buttonStyle={ButtonStyle.PRIMARY}
        id="add-staffing"
      >
        Загрузить штатное расписание
      </AddButton>
      <Modal visible={visible} onModalClose={toggleVisible}>
        <AddNewItemModal
          isSuccess={isSuccess}
          isError={isError}
          reset={reset}
          titlePostfix="штатное расписание"
          contentBeforeFields={
            <DownloadExampleButton
              templateLink={templateLink}
              title="Школа экспертов — Шаблон импорта сотрудников.xls"
            />
          }
          methods={methods}
          onSubmitForm={handleSubmit}
          fields={fields}
          handleCloseModal={onSuccess}
          error={error}
          CustomErrorComponent={FileErrorComponent}
          successContentAddTitle="Все пользователи успешно добавлены"
          isLoading={isLoading}
          onboarding={onboarding}
        />
      </Modal>
    </>
  )
}

export default StaffingModal
