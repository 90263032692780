import styled from '@emotion/styled'
import { isMobile } from '@const/device'

const DesktopWrapper = styled.div<{
  hovered: boolean
}>`
  background: ${({ theme }) => theme.colors.background.surface};
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  color: ${({
    hovered,
    theme: {
      colors: {
        text: { black, primary },
      },
    },
  }) => (hovered ? primary : black)};
  padding: 30px;
  box-shadow: 5px 5px 20px rgba(29, 78, 137, 0.1);
  margin-bottom: 12px;
  border-radius: 30px;

  &:hover {
    box-shadow: 5px 5px 20px rgba(29, 78, 137, 0.1);
  }

  @media (max-width: 870px) {
    flex-wrap: wrap;
  }
`
const MobileWrapper = styled(DesktopWrapper)<{
  hovered: boolean
}>`
  flex-direction: column;
  width: 100%;
  padding: 25px 15px;
  margin: 10px 0 12px;
  box-shadow: 5px 5px 20px rgba(29, 78, 137, 0.1);
  border-radius: 30px;
  gap: 20px;

  &:hover {
    box-shadow: 5px 5px 20px rgba(29, 78, 137, 0.1);
  }
`
export const Wrapper = isMobile ? MobileWrapper : DesktopWrapper

const DesktopTitleText = styled.div`
  display: block;
  margin-right: 15px;
  max-width: 45vw;
  white-space: pre-wrap;
`
const MobileTitleText = styled(DesktopTitleText)`
  margin-right: 0;
  max-width: 100%;
`
export const TitleText = isMobile ? MobileTitleText : DesktopTitleText

export const LeftWrapper = styled.div``

export const RightContentWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
