import { FC } from 'react'
import ListPage from '@templates/ListPage'
import useRiskMaps from '@hooks/useRiskMaps'
import { createInitFilter } from '@utils/filters'
import useMe from '@hooks/useMe'
import RiskMap from './RiskMap'
import useRiskMapsFilters from './useRiskMapsFilters'

const RiskMapsEmployee: FC = () => {
  const { meUser, isEmployee } = useMe()
  const filters = useRiskMapsFilters()
  const { data, ...rest } = useRiskMaps(
    isEmployee
      ? undefined
      : {
          initFilters: createInitFilter('forEmployee', meUser?.['@id']),
        }
  )
  const { items = [], length = 0 } = data || {}

  return (
    <ListPage
      {...rest}
      filters={filters}
      titleTabs={[
        {
          title: 'Карты рисков',
          counter: length,
          index: 0,
        },
      ]}
      ItemComponent={RiskMap}
      list={items}
      length={length}
      withSearch
    />
  )
}

export default RiskMapsEmployee
