import styled from '@emotion/styled'

export const Wrapper = styled.div`
  width: 100%;
`

export const Description = styled.div`
  display: flex;
  gap: 2px;
  word-break: break-all;
  text-align: justify;
`
