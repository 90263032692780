import StoreTags from '@enums/StoreTags'
import { CommonQueryParams, ItemsFromResponse } from '@models/common'
import {
  CreateMeasuresContentBody,
  CreateMeasuresContentResponse,
  MeasuresContent,
  MeasuresContentOption,
} from '@models/measuresContent'
import emptySplitApi from '../api'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'

const BASE_URL = '/api/risk_map_danger_control_measure_contents'
const JOB_TAGS = [StoreTags.MEASURES_CONTENTS, StoreTags.MEASURES_CONTENT]

export const measuresContentsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getMeasuresContents: builder.query<
      { items: MeasuresContentOption[]; length: number },
      CommonQueryParams
    >({
      query: (params) => ({
        url: BASE_URL,
        params,
      }),
      transformResponse: (response: ItemsFromResponse<MeasuresContent>) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          label: item.value,
          value: item['@id'],
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
      providesTags: [StoreTags.MEASURES_CONTENTS],
    }),
    createMeasuresContent: builder.mutation<
      CreateMeasuresContentResponse,
      CreateMeasuresContentBody
    >({
      invalidatesTags: (result) => (result ? JOB_TAGS : []),
      query: (body) => ({
        url: BASE_URL,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useGetMeasuresContentsQuery, useCreateMeasuresContentMutation } =
  measuresContentsApi
