import { FiltersProps } from '@templates/ListPageLayout/Filters'
import useCoursesFilter from '@hooks/useCoursesFilter'

const useThemesFilters = (): FiltersProps => {
  const courses = useCoursesFilter('courseChapterThemes.courseChapter.course')

  return [courses]
}

export default useThemesFilters
