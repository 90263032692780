import * as React from 'react'
import { FC } from 'react'
import { employeeField } from '@templates/AddNewItemModal/templates'
import MultilineFieldNew from '@templates/MultilineFieldNew'
import ComissionHeadField from './ComissionHeadField'

const commissionMembersField = employeeField({
  name: 'commissionMembers',
  label: 'Член комиссии',
  isClearable: false,
})

const CommissionMemberMultilineFields: FC = () => {
  return (
    <div id="comission-block">
      <ComissionHeadField />
      <MultilineFieldNew
        fieldsSrc={[commissionMembersField]}
        fieldsName="commissionMembers"
        amendValue={{}}
        addLabel="Добавить члена комиссии"
      />
    </div>
  )
}

export default CommissionMemberMultilineFields
