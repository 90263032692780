import { ReactComponent as InfoIcon } from '@interface-images/info-orange-16.svg'
import { DescriptionInfoWrapper } from './styled'

const DescriptionInfo = () => {
  return (
    <DescriptionInfoWrapper>
      <InfoIcon />
      <span>Информация будет выведена в личном кабинете сотрудника</span>
    </DescriptionInfoWrapper>
  )
}

export default DescriptionInfo
