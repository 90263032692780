import { configureStore } from '@reduxjs/toolkit'
import { authSlice } from '@services/auth/slice'
import { onboardingSlice } from '@services/onboarding/slice'
import { riskMapModalSlice } from '@services/riskMapModal'
import { setupListeners } from '@reduxjs/toolkit/query'
import Unauthorized from './middlewares/unauthorized'
import emptySplitApi from './services/api'

export const store = configureStore({
  reducer: {
    [authSlice.name]: authSlice.reducer,
    [onboardingSlice.name]: onboardingSlice.reducer,
    [riskMapModalSlice.name]: riskMapModalSlice.reducer,
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(Unauthorized, emptySplitApi.middleware),
})
setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
