import { UseFormReturn } from 'react-hook-form'
import { Dispatch, SetStateAction } from 'react'
import { Option } from '@organisms/Select'
import { UseCompaniesParams } from '@hooks/useCompanies'
import useJobCleaner from './useJobCleaner'
import { FilterState } from './useItemsFilters'
import useCompaniesJobData from './useCompaniesJobData'
import useFilialsJobData from './useFilialsJobData'
import useDivisionsJobData from './useDivisionsJobData'
import usePositionsJobData from './usePositionsJobData'

export type JobData = {
  options: Option[]
  length: number
  isLoading: boolean
  setFilters: Dispatch<SetStateAction<FilterState>>
  setPage: Dispatch<SetStateAction<number>>
  isDisabled: boolean
}

type ReturnType = Record<
  'company' | 'filial' | 'division' | 'position',
  JobData | null
>

export type UseJobDataForFieldsParamsType = {
  company?: UseCompaniesParams
}

const useJobDataForFields = (
  methods: UseFormReturn,
  params?: UseJobDataForFieldsParamsType
): ReturnType => {
  useJobCleaner(methods)

  const companiesJobData = useCompaniesJobData(params?.company)
  const filialsJobData = useFilialsJobData(methods, companiesJobData)
  const divisionsJobData = useDivisionsJobData(methods, filialsJobData)
  const positionsJobData = usePositionsJobData(methods, divisionsJobData)

  return {
    company: companiesJobData,
    filial: filialsJobData,
    division: divisionsJobData,
    position: positionsJobData,
  }
}

export default useJobDataForFields
