import { FC } from 'react'
import { components, ControlProps } from 'react-select'
import { isEmpty } from 'lodash'

const Control: FC<ControlProps> = (props) => {
  const notSelected = isEmpty(props.selectProps.value)

  let color = 'white'
  let background = '#1d4e89'
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (props.selectProps.withoutFillSelected) {
    color = 'black'
    background = 'white'
    // eslint-disable-next-line sonarjs/no-duplicated-branches
  } else if (notSelected) {
    color = 'black'
    background = 'white'
  }

  if (props.selectProps.isDisabled) {
    color = '#C4C4C4'
    background = '#f2f6fa'
  }

  let borderColor = '#C4C4C4'
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (props.selectProps.hasError) {
    borderColor = '#EF4949'
  } else if (props.isFocused) {
    borderColor = '#1d4e89'
  }

  return (
    <components.Control
      {...props}
      innerProps={{
        ...props.innerProps,
        style: {
          ...props.innerProps.style,
          borderRadius: 24,
          border: `1px solid ${borderColor}`,
          boxShadow: 'none',
          height: 40,
          fontSize: 12,
          alignItems: 'center',
          minWidth: 200,
          width: '100%',
          cursor: 'pointer',
          background,
          color,
          paddingLeft: 5,
        },
      }}
    />
  )
}

export default Control
