import { FC, useEffect } from 'react'
import { FieldValues, useFormContext } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  FooterProps,
  prepareValues,
} from '@templates/AddNewItemModalLayout/Footer'
import PrimaryButton from '@organisms/PrimaryButton'
import SecondaryButton from '@organisms/SecondaryButton'
import Prompt from '@organisms/Prompt'
import Select, { Option } from '@organisms/Select'
import { FromButtons } from '@templates/AddNewItemModal'
import { Routes } from '@enums/Routes'
import { NextButtonWrapper, Wrapper } from './styled'

type AdditionalProps = {
  additionalProps: {
    nextRoute?: Routes
    nextRouteDescription?: string
    createOptions?: Option[]
    getNextState?: (data: any) => any
  }
}

const JobsFooter: FC<FooterProps & AdditionalProps> = ({
  onSubmitForm,
  isLoading,
  onSaveAndAddMore,
  fromButton,
  setFromButton,
  handleCloseModal,
  data,
  additionalProps: {
    nextRoute,
    createOptions,
    nextRouteDescription,
    getNextState,
  },
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const {
    handleSubmit,
    formState: { isSubmitSuccessful },
    reset,
  } = useFormContext()

  const handleSaveAndAddMore = (values: FieldValues) => {
    setFromButton(FromButtons.ADD_MORE)
    onSubmitForm(prepareValues(values))
    onSaveAndAddMore?.()
  }

  const handleSubmitForm = (values: FieldValues) => {
    setFromButton(FromButtons.MAIN)
    onSubmitForm(prepareValues(values))
  }

  const handleNext = (values: FieldValues) => {
    setFromButton(FromButtons.NEXT)
    onSubmitForm(prepareValues(values))
  }

  const handleChangeSelect = ({ value }: Option) => {
    navigate(value, {
      state: {
        backgroundLocation: location.state?.backgroundLocation || location,
      },
    })
    handleCloseModal?.()
  }

  useEffect(() => {
    if (
      nextRoute &&
      fromButton === FromButtons.NEXT &&
      isSubmitSuccessful &&
      !isLoading &&
      data
    ) {
      reset()
      navigate(nextRoute, {
        state: {
          backgroundLocation: location.state?.backgroundLocation || location,
          ...(getNextState ? getNextState(data) : {}),
        },
      })
      handleCloseModal?.()
    }
  }, [nextRoute, isSubmitSuccessful, fromButton, isLoading, data])

  return (
    <Wrapper>
      {createOptions && (
        <Select
          menuPosition="fixed"
          value={{
            label: 'Создать',
            value: '',
          }}
          options={createOptions}
          onChange={handleChangeSelect}
          withoutFillSelected
          withoutFetchOptions
          isSearchable={false}
          isClearable={false}
          id="modal-create-select"
        />
      )}
      <SecondaryButton
        onClick={handleSubmit(handleSubmitForm)}
        type="submit"
        isLoading={fromButton === FromButtons.MAIN && isLoading}
        id="modal-save-and-close"
      >
        Сохранить и закрыть
      </SecondaryButton>
      <SecondaryButton
        onClick={handleSubmit(handleSaveAndAddMore)}
        isLoading={fromButton === FromButtons.ADD_MORE && isLoading}
        id="modal-save-and-add-more"
      >
        Сохранить и добавить еще
      </SecondaryButton>
      {nextRoute && (
        <NextButtonWrapper>
          <PrimaryButton
            onClick={handleSubmit(handleNext)}
            isLoading={fromButton === FromButtons.NEXT && isLoading}
            id="modal-next"
          >
            Далее
          </PrimaryButton>
          {nextRouteDescription && (
            <Prompt promptText={nextRouteDescription} width={250} />
          )}
        </NextButtonWrapper>
      )}
    </Wrapper>
  )
}

export default JobsFooter
