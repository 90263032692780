import { FilialDetailed } from '@models/filial'
import setDefaultCommissionMembers from '@templates/CommissionMemberMultilineFields/setDefaultCommissionMembers'
import setDefaultRiskCommissionMembers from '@templates/RiskMemberMultilineFields/setDefaultRiskCommissionMembers'
import { FormValues } from './index'

const getDefaultValues = (
  filial?: FilialDetailed
): Partial<FormValues> | void => {
  if (!filial) return

  const result: Partial<FormValues> = {}

  return setDefaultRiskCommissionMembers(
    setDefaultCommissionMembers(
      result,
      filial.commissionHead,
      filial.commissionMembers
    ),
    filial.riskMapCommissionMembers
  )
}

export default getDefaultValues
