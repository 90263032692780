import { JoyStep } from '@templates/Onboarding'

export const ONBOARDING_TITLE = 'Расскажем как создать должность'

export const ONBOARDING_STEPS: JoyStep[] = [
  {
    target: '#content',
    title: 'Создание должности',
    content: 'Заполните данные о должности',
  },
  {
    target: '#modal-footer',
    title: 'Дальнейшие действия',
    content: (
      <div>
        <div>Перейдите к одному из следующих шагов.</div>
        <div>
          Если вы хотите добавить сотрудников через загрузку штатного
          расписания, перейдите в раздел “Сотрудники”.
        </div>
      </div>
    ),
  },
  {
    target: '#modal-save-and-close',
    title: 'Сохраните и вернитесь к списку должностей',
    content: '',
  },
  {
    target: '#modal-save-and-add-more',
    title: 'Сохраните эту должность и добавьте ещё одну',
    content: '',
  },
  {
    target: '#modal-next',
    title: 'Перейдите к созданию сотрудников',
    content: '',
  },
]
