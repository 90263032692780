import { Skeleton } from 'antd'
import { FC } from 'react'
import useMouseOverOut from '@hooks/useMouseOverOut'
import { Course } from '@models/course'
import { ListItemProps } from '@templates/List'
import Header from './Header'

const CourseItem: FC<ListItemProps<Course>> = ({ isLoading, item }) => {
  const [hovered, , handleMouseEnter, handleMouseLeave] = useMouseOverOut()

  if (isLoading) {
    return (
      <Skeleton.Button
        active
        block
        style={{
          height: 170,
          marginBottom: 12,
          borderRadius: 30,
        }}
      />
    )
  }

  return (
    <Header
      item={item}
      hovered={hovered}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  )
}

export default CourseItem
