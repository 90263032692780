import { FC } from 'react'
import { renderPhone } from '@utils/phone'
import { StyledA } from './styled'

type Props = {
  phone: string
}

const DisplayPhone: FC<Props> = ({ phone }) => (
  <StyledA href={`tel:${phone}`}>{renderPhone(phone)}</StyledA>
)

export default DisplayPhone
