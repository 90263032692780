import styled from '@emotion/styled'
import { isMobile } from '@const/device'

export const DesktopBlock = styled.div<{ height?: number | string }>`
  box-shadow: 5px 5px 20px rgba(29, 78, 137, 0.1);
  width: 100%;
  overflow-y: auto;

  background: ${({ theme }) => theme.colors.background.surface};

  border-radius: 36px;
  padding: ${({ theme }) => theme.sizes.fourBase};

  ${({ height }) => (height ? `min-height: ${height}px;` : '')}
`

export const MobileBlock = styled(DesktopBlock)<{ height?: number | string }>`
  border-radius: 15px;
  padding: 15px 15px 5px;
`

export const Block = isMobile ? MobileBlock : DesktopBlock
