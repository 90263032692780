import { FC } from 'react'
import DownloadButton from '@organisms/DownloadButton'
import { Description, DownloadWrapper, Wrapper } from './styled'

type Props = {
  templateLink: string
  title: string
  description?: string | null
}

const DownloadExampleButton: FC<Props> = ({
  templateLink,
  title,
  description = 'Добавьте файлы .xls, .xlsx, составленные по образцу',
}) => {
  const handleClick = () => {
    const link = document.createElement('a')
    link.setAttribute('type', 'hidden')
    link.href = process.env.PUBLIC_URL + templateLink
    link.download = title
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  return (
    <Wrapper id="download-example">
      {description !== null && <Description>{description}</Description>}
      <DownloadWrapper>
        <DownloadButton onClick={handleClick}>Скачать образец</DownloadButton>
      </DownloadWrapper>
    </Wrapper>
  )
}

export default DownloadExampleButton
