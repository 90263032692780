import { FilialDetailed } from '@models/filial'
import { formatServerDate } from '@utils/date'
import setDefaultCommissionMembers from '@templates/CommissionMemberMultilineFields/setDefaultCommissionMembers'
import setDefaultCurators from '@templates/CuratorsMultilineFields/setDefaultCurators'
import setDefaultRiskCommissionMembers from '@templates/RiskMemberMultilineFields/setDefaultRiskCommissionMembers'
import { FormValues } from './index'

const getDefaultValues = (
  filial?: FilialDetailed
): Partial<FormValues> | void => {
  if (!filial) return

  const result: Partial<FormValues> = {
    title: filial.title,
    fullTitle: filial.fullTitle,
    subscriptionsCount: filial.subscriptionsCount?.toString(),
    inn: filial.inn || undefined,
    protocolOrderDate: filial.protocolOrderDate
      ? formatServerDate(filial.protocolOrderDate)
      : undefined,
    protocolOrderNumber: filial.protocolOrderNumber || undefined,
  }

  if (filial.company) {
    result.company = {
      ...filial.company,
      label: filial.company.title,
      value: filial.company['@id'],
    }
  }

  if (filial.subscriptionStartAt) {
    result.subscriptionStartAt = formatServerDate(filial.subscriptionStartAt)
  }

  if (filial.subscriptionEndAt) {
    result.subscriptionEndAt = formatServerDate(filial.subscriptionEndAt)
  }

  return setDefaultRiskCommissionMembers(
    setDefaultCommissionMembers(
      setDefaultCurators(result, filial.curators),
      filial.commissionHead,
      filial.commissionMembers
    ),
    filial.riskMapCommissionMembers
  )
}

export default getDefaultValues
