import { UseFormReturn } from 'react-hook-form'
import { compact } from 'lodash'
import { parse } from 'date-fns'
import useJobFields from '@hooks/useJobFields'
import {
  dateField,
  emailField,
  maskedField,
  phoneField,
  textField,
} from '@templates/AddNewItemModal/templates'
import { Fields } from '@templates/AddNewItemModal/Content'
import { inRange } from '@utils/date'

const usePersonalFields = (methods: UseFormReturn): Fields => {
  const { company, filial } = useJobFields(methods)

  return [
    {
      fields: compact([
        company?.({
          isRequired: true,
        }),
        filial?.({
          isRequired: true,
        }),
        textField({
          label: 'ФИО',
          placeholder: 'Введите ФИО',
          name: 'fullName',
          isRequired: true,
        }),
        phoneField({}),
      ]),
    },
    {
      fields: [
        emailField({
          label: 'Электронная почта',
        }),
        maskedField({
          name: 'insuranceNumber',
          label: 'СНИЛС',
          mask: '000-000-000 00',
          placeholder: '___-___-___ __',
        }),
        textField({
          label: 'ИНН',
          placeholder: 'ИНН',
          name: 'inn',
        }),
        textField({
          label: 'Образование',
          placeholder: 'Высшее',
          name: 'education',
        }),
      ],
    },
    {
      fields: [
        dateField({
          label: 'Дата рождения',
          name: 'birthDate',
          validate: (value) => {
            if (!value) return true

            const date = parse(value, 'dd.MM.yyyy', new Date())

            return !inRange(date, 18, 80) ? 'Введите корректную дату' : true
          },
        }),
        dateField({
          label: 'Дата приёма на работу',
          name: 'employmentDate',
        }),
        dateField({
          label: 'Дата следующего медосмотра',
          name: 'nextBodyCheckDate',
        }),
      ],
    },
    {
      fields: [
        textField({
          label: 'Комментарий',
          placeholder: 'Введите комментарий о сотруднике',
          name: 'comment',
          widthMultiplier: 2,
        }),
      ],
    },
  ]
}

export default usePersonalFields
