import styled from '@emotion/styled'
import { isMobile } from '@const/device'
import { BIG_WIDTH_MENU, SMALL_WIDTH } from './Menu/DesktopMenu'

const LayoutWrapper = styled.div`
  overflow: hidden;
`
export const DesktopMainLayoutWrapper = styled(LayoutWrapper)`
  display: flex;
`
export const MobileMainLayoutWrapper = styled(LayoutWrapper)`
  display: block;
  font-size: 12px;
`
export const MainLayoutWrapper = isMobile
  ? MobileMainLayoutWrapper
  : DesktopMainLayoutWrapper

const DesktopContentWrapper = styled.div<{
  expandedMobileMenu: boolean
  expandedDesktopMenu: boolean
}>`
  margin-top: 10px;
  padding: 0;

  @media (max-width: 515px) {
    padding-left: 40px;
  }

  ${({ expandedDesktopMenu }) =>
    !isMobile
      ? `padding-left: ${expandedDesktopMenu ? BIG_WIDTH_MENU : SMALL_WIDTH}px;`
      : ''}
`
const MobileContentWrapper = styled.div<{ expandedMobileMenu: boolean }>`
  margin-top: 10px;
  padding: 75px 10px 0 10px;
`
export const ContentWrapper = isMobile
  ? MobileContentWrapper
  : DesktopContentWrapper

export const MainContainer = styled.div`
  width: 100vw;
`
