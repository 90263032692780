import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import Accordion, { AccordionHeaderProps } from '@organisms/Accordion'
import { ListItemProps } from '@templates/List'
import { InstructionOption } from '@models/instruction'
import { StyledTitle } from '@pages/Instructions/Instruction/styled'
import { Routes } from '@enums/Routes'

const FilialItem: FC<ListItemProps<InstructionOption>> = ({
  isLoading,
  opened,
  item: { title, code },
}) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(Routes.GUIDES + `/${code}`)
  }

  const header: FC<AccordionHeaderProps> = () => (
    <StyledTitle mySize="h4">{title}</StyledTitle>
  )

  return (
    <Accordion
      skeletonHeight={60}
      handleClickOnHeader={handleClick}
      expanded={opened}
      initLoading={isLoading}
      Header={header}
      Body={() => <></>}
      withoutArrow
    />
  )
}

export default FilialItem
