import styled from '@emotion/styled'

export const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 51;
  width: 24px;
`

export const Container = styled.div`
  position: relative;
  z-index: 50;
`
