import { FC } from 'react'
import RiskMapStatus from '@templates/RiskMapStatus'
import DownloadRiskMap from '@templates/DownloadRiskMap'
import InfoField from '@organisms/InfoField'
import GreenYellowStatus from '@templates/GreenYellowStatus'
import { RiskMap } from '@models/riskMap'
import { formatServerDate } from '@utils/date'
import { StyledTitle, Wrapper } from './styled'

type Props = {
  item: RiskMap
  withoutReviewed?: boolean
}

const RiskMapItem: FC<Props> = ({ item, withoutReviewed = false }) => {
  const { number, status, approvedAt, file, employeeReviewedAt } = item

  return (
    <Wrapper>
      <StyledTitle mySize="h4">Карта рисков № {number}</StyledTitle>
      <RiskMapStatus status={status} isWhiteBackground />
      {file && <DownloadRiskMap file={file} isWhiteBackground />}
      {approvedAt && (
        <InfoField
          label="Дата утверждения"
          value={formatServerDate(approvedAt)}
          withStopPropagation={false}
          withoutFixedWidth
          withoutMarginBottom
          isWhiteBackground
        />
      )}
      {!withoutReviewed && (
        <GreenYellowStatus
          success={!!employeeReviewedAt}
          successText="Ознакомлен"
          notSuccessText="Не ознакомлен"
        />
      )}
      {employeeReviewedAt && (
        <InfoField
          label="Дата ознакомления"
          value={formatServerDate(employeeReviewedAt)}
          withStopPropagation={false}
          withoutFixedWidth
          withoutMarginBottom
          isWhiteBackground
        />
      )}
    </Wrapper>
  )
}

export default RiskMapItem
