import { FC } from 'react'
import useEmployeeEducations from '@hooks/useEmployeeEducations'
import useFormProcessor from '@hooks/useFormProcessor'
import ListPage from '@templates/ListPage'
import EmployeeEducationModal from '@modals/EmployeeEducationModal'
import Onboarding from '@templates/Onboarding'
import OnboardingCodes from '@enums/OnboardingCodes'
import useEmployeesEducationReports from './useEmployeesEducationReports'
import EmployeesEducationItem from './EmployeesEducationItem'
import useEmployeesEducationFilters from './useEmployeesEducationFilters'
import { ONBOARDING_STEPS, ONBOARDING_TITLE } from './onboarding'
import {
  REPORTS_ONBOARDING_STEPS,
  REPORTS_ONBOARDING_TITLE,
} from './reportsOnboarding'

const EmployeesEducation: FC = () => {
  const reports = useEmployeesEducationReports()
  const methods = useFormProcessor()
  const filters = useEmployeesEducationFilters(methods)
  const { data, ...rest } = useEmployeeEducations()
  const { items = [], length = 0 } = data || {}

  return (
    <>
      <Onboarding
        firstStepTitle={ONBOARDING_TITLE}
        steps={ONBOARDING_STEPS}
        code={OnboardingCodes.education_section}
      />
      <ListPage
        {...rest}
        reports={reports}
        methods={methods}
        titleTabs={[
          {
            title: 'Обучение сотрудников',
            index: 0,
          },
        ]}
        ModalContent={EmployeeEducationModal}
        ItemComponent={EmployeesEducationItem}
        filters={filters}
        list={items}
        length={length}
        withSearch
        reportsOnboarding={{
          firstStepTitle: REPORTS_ONBOARDING_TITLE,
          steps: REPORTS_ONBOARDING_STEPS,
          code: OnboardingCodes.education_reports,
          withFeedback: true,
        }}
        resetOnboardingCodes={[
          OnboardingCodes.education_section,
          OnboardingCodes.education_create,
          OnboardingCodes.education_open_card,
          OnboardingCodes.education_reports,
        ]}
      />
    </>
  )
}

export default EmployeesEducation
