import { JoyStep } from '@templates/Onboarding'
import { InfoCircleTwoTone } from '@ant-design/icons'

export const ONBOARDING_TITLE = 'Расскажем как добавить сотрудника'

export const ONBOARDING_STEPS: JoyStep[] = [
  {
    target: '#add-staffing',
    title: 'Загрузка штатного расписания',
    content: (
      <div>
        <div>
          Вы можете загрузить сотрудников через загрузку xls файла со штатным
          расписанием.
        </div>
        <div>Кликните на выделенную кнопку, чтобы перейти к загрузке.</div>
      </div>
    ),
  },
  {
    target: '#staffing-fields',
    title: 'Заполнение стажировочных листов',
    content: 'Вы можете заполнить для сотрудника стажировочные листы',
  },
  {
    target: '#contentFields',
    title: 'Добавление сотрудника',
    content: (
      <div>
        <div>Заполните данные о сотруднике. Обязательные поля отмечены *.</div>
        <p>
          <div>
            <InfoCircleTwoTone style={{ color: 'white' }} /> Если у сотрудника
            нет email/телефона, то он будет авторизовываться через логин/пароль.
          </div>
          <div>
            Ссылку для задания пароля получите в отчёте “Ссылки для задания
            паролей сотрудникам” в разделе “Сотрудники”.
          </div>
        </p>
      </div>
    ),
  },
  {
    target: '#position-division',
    title: 'Выбор подразделения/должности',
    content:
      'Вы можете добавить неограниченное количество должностей сотрудника.',
  },
  {
    target: '#modal-footer',
    title: 'Дальнейшие действия',
    content: 'Перейдите к одному из следующих шагов',
  },
  {
    target: '#modal-save-and-close',
    title: 'Сохраните и вернитесь к списку сотрудников',
    content: '',
  },
  {
    target: '#modal-save-and-add-more',
    title: 'Сохраните этого сотрудника и добавьте ещё одного',
    content: '',
  },
  {
    target: '#modal-next',
    title: 'Перейдите к созданию обучения',
    content: '',
  },
]
