/* eslint-disable sonarjs/no-duplicate-string */
import * as React from 'react'
import { FC, ReactNode, useEffect } from 'react'
import { compact } from 'lodash'
import Table, { Column } from '@organisms/Table'
import { RiskMapDetailed } from '@models/riskMap'
import EditRiskMapButton from '@pages/RiskMaps/RiskMap/Body/EditRiskMapButton'
import { ProtectionMeanAdditionalType } from '@enums/ProtectionMeanAdditionalType'
import { UpdateAllWrapper, Wrapper } from './styled'
import UpdateBefore from './UpdateBefore'
import UpdateAll from './UpdateAll'

const COLUMNS: Column[] = [
  {
    title: 'Опасность / опасное событие',
    dataIndex: 'dangerous',
  },
  {
    title: 'Конструкция',
    dataIndex: 'construction',
  },
  {
    title: '№ п/п ЕТН',
    dataIndex: 'etnNumber',
    width: 90,
  },
  {
    title: 'Кол-во',
    dataIndex: 'count',
    width: 60,
  },
  {
    title: 'Период обновл.',
    dataIndex: 'period',
  },
  {
    title: 'Доп СИЗ',
    dataIndex: 'additional',
  },
  {
    title: 'Обновить до',
    dataIndex: 'updateBefore',
  },
  {
    title: '',
    dataIndex: 'edit',
  },
]

type DataItem = {
  dangerous: string
  construction: string
  etnNumber: string
  count: number
  period: string
  additional: string
  updateBefore: ReactNode
  edit: ReactNode
  key: string
}

type Props = {
  onChangeSize: () => void
  riskMap: RiskMapDetailed
  index: number
  noActions?: boolean
}

const ProtectionMeans: FC<Props> = ({
  riskMap,
  onChangeSize,
  index,
  noActions,
}) => {
  useEffect(() => {
    onChangeSize()
  }, [riskMap])

  const tabs = riskMap.dangers.reduce(
    (acc, { individualProtectionMeans = [], hazard, event }, dangerIndex) => {
      individualProtectionMeans.forEach(
        (
          {
            '@id': idVal,
            meanConstruction,
            quantity,
            updateFrequency,
            updateBeforeAt,
            additionalType,
            etnNumber,
          },
          protectionMeansIndex
        ) => {
          acc.push({
            key: idVal,
            dangerous: compact([hazard?.value, event?.value]).join(' / '),
            construction: meanConstruction?.title || '',
            etnNumber: etnNumber || '',
            count: quantity,
            period: updateFrequency ? String(updateFrequency) : '',
            additional:
              additionalType === ProtectionMeanAdditionalType.ADDITIONAL
                ? 'Да'
                : additionalType ===
                  ProtectionMeanAdditionalType.ADDITIONAL_TO_PREVIOUS
                ? 'Да, к пред'
                : 'Нет',
            updateBefore: updateBeforeAt ? (
              <UpdateBefore date={updateBeforeAt} />
            ) : (
              ''
            ),
            edit: noActions ? null : (
              <EditRiskMapButton
                riskMap={riskMap}
                dangersIndex={dangerIndex}
                protectionMeansIndex={protectionMeansIndex}
              />
            ),
          })
        }
      )

      return acc
    },
    [] as DataItem[]
  )

  return (
    <>
      <Wrapper id={`protectionMeans-tab-content-${index}`}>
        <Table columns={COLUMNS} data={tabs} />
      </Wrapper>
      {!noActions && (
        <UpdateAllWrapper>
          <UpdateAll riskMap={riskMap} />
        </UpdateAllWrapper>
      )}
    </>
  )
}

export default ProtectionMeans
