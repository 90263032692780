import * as React from 'react'
import { FC } from 'react'
import { FormProvider } from 'react-hook-form'
import useJobFields from '@hooks/useJobFields'
import useFormProcessor from '@hooks/useFormProcessor'
import Field from '@templates/Field'
import ContentBlock from '@organisms/ContentBlock'
import AccordionBodyField from '@organisms/AccordionBodyField'
import Loader from '@atoms/Loader'
import { CompanyOption } from '@models/company'
import { useGetCompanyQuery } from '@services/companies'
import { StyledTitle } from './styled'

const TariffItem: FC = () => {
  const methods = useFormProcessor()
  const { company } = useJobFields(methods)
  const companyValue: CompanyOption | undefined = methods.watch('company')

  const { data, isLoading } = useGetCompanyQuery(companyValue?.id!, {
    skip: !companyValue,
  })

  const { title, subscriptionsCount = 0, countEmployees = 0 } = data || {}

  return (
    <FormProvider {...methods}>
      <ContentBlock height={400}>
        <Loader isLoading={isLoading}>
          <StyledTitle>Тариф</StyledTitle>
          {company && <Field {...company({ label: 'Выбрать компанию' })} />}
          {data && (
            <>
              <AccordionBodyField label="Наименование компании" value={title} />
              <AccordionBodyField
                label="Количество лицензий"
                value={subscriptionsCount}
              />
              <AccordionBodyField
                label="Количество использованных лицензий"
                value={countEmployees}
              />
              <AccordionBodyField
                label="Количество доступных лицензий"
                value={subscriptionsCount - countEmployees}
              />
            </>
          )}
        </Loader>
      </ContentBlock>
    </FormProvider>
  )
}

export default TariffItem
