import { UseFormReturn } from 'react-hook-form'
import { compact } from 'lodash'
import { useEffect } from 'react'
import useJobFields from '@hooks/useJobFields'
import { Fields } from '@templates/AddNewItemModal/Content'
import useRiskMapPositionsField from '@hooks/useRiskMapPositionsField'
import useMe from '@hooks/useMe'
import useHasJobs from '@hooks/useHasJobs'
import { CompanyOption } from '@models/company'

const usePositionFields = (methods: UseFormReturn): Fields => {
  const { company, filial, division } = useJobFields(methods)

  const { company: meCompany } = useMe()
  const { hasCompany } = useHasJobs()
  const selectedCompany: CompanyOption | undefined = hasCompany
    ? methods.watch('company')
    : meCompany

  const { field: positionsField, setFilters } = useRiskMapPositionsField({
    label: 'Наименование',
    name: 'title',
    isDisabled: !selectedCompany,
    isRequired: true,
  })

  useEffect(() => {
    setFilters({
      'subdivision.companyBranch.company': selectedCompany?.['@id'] || null,
    })
  }, [selectedCompany])

  return [
    {
      fields: compact([
        company?.({
          isRequired: true,
        }),
        filial?.({
          isRequired: true,
        }),
        division?.({
          isRequired: true,
        }),
        positionsField(),
      ]),
    },
  ]
}

export default usePositionFields
