import { StateType } from '../useCommonRiskMapFields'

export enum RiskMapDangersFieldNames {
  WORK_PLACE = 'workPlace',
  OBJECT = 'object',
  KIND = 'kind',
  HAZARD = 'hazard',
  EVENT = 'event',
}

export const SEQUENCE_ARR: RiskMapDangersFieldNames[] = [
  RiskMapDangersFieldNames.WORK_PLACE,
  RiskMapDangersFieldNames.OBJECT,
  RiskMapDangersFieldNames.KIND,
  RiskMapDangersFieldNames.HAZARD,
  RiskMapDangersFieldNames.EVENT,
].reverse()

const getFilters = (
  values: Partial<Record<RiskMapDangersFieldNames, string | undefined>>
): StateType['relations'] => {
  const result: StateType['relations'] = {}

  SEQUENCE_ARR.forEach((fieldName, index) => {
    if (values[fieldName]) {
      result[fieldName] = values[fieldName]

      const slicedSequence = SEQUENCE_ARR.slice(index + 1)

      slicedSequence.forEach((sequence) => {
        result[sequence] = values[sequence] ? values[sequence] : undefined
      })
    }
  })

  return result
}

export default getFilters
