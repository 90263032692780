import styled from '@emotion/styled'

const BADGE_RADIUS = 15

export const Badge = styled.div<{
  badgeColor: string
}>`
  background-color: ${({ badgeColor }) => badgeColor};
  width: ${BADGE_RADIUS}px;
  height: ${BADGE_RADIUS}px;
  border-radius: 50%;
`
