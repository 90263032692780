import * as React from 'react'
import { FC } from 'react'
import { FormProvider } from 'react-hook-form'
import useFormProcessor from '@hooks/useFormProcessor'
import { useSendRecoveryMailMutation } from '@services/auth/api'
import BackButton from '@organisms/BackButton'
import Field from '@templates/Field'
import { textField } from '@templates/AddNewItemModal/templates'
import PrimaryButton from '@organisms/PrimaryButton'
import { ButtonWrapper, StyledSubtitle } from '../../../styled'
import { Description, Underline } from './styled'

type FormValues = {
  username: string
}

const emailFieldProps = textField({
  isRequired: true,
  widthMultiplier: 1.2,
  name: 'username',
  label: 'Логин/электронная почта',
  placeholder: 'Логин/Адрес электронной почты',
})

type Props = {
  handleBack: () => void
}

const RestorePasswordForm: FC<Props> = ({ handleBack }) => {
  const [sendRecoveryMail, { error, isLoading, isSuccess, data }] =
    useSendRecoveryMailMutation()
  const methods = useFormProcessor({ apiErrors: error })

  const onSubmit = (form: FormValues) => {
    sendRecoveryMail(form)
  }

  if (isSuccess && data) {
    if (data.type === 'login') {
      return (
        <>
          <StyledSubtitle>Ваш куратор получил уведомление</StyledSubtitle>
          <StyledSubtitle>со ссылкой на восстановление пароля</StyledSubtitle>
          <StyledSubtitle isLast>Куратор: {data.curatorName}</StyledSubtitle>
          <Description>
            Запросите у своего куратора ссылку для восстановления пароля
          </Description>
        </>
      )
    }

    return (
      <>
        <StyledSubtitle>На указанный адрес электронной</StyledSubtitle>
        <StyledSubtitle>почты отправлена ссылка</StyledSubtitle>
        <StyledSubtitle isLast>для восстановления пароля</StyledSubtitle>
        <Description>
          Если Вы не получили письмо, напишите нам на почту{' '}
          <Underline>support@expschool.ru</Underline>, мы обязательно поможем
        </Description>
      </>
    )
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <StyledSubtitle>Введите логин/электронную почту</StyledSubtitle>
        <Field {...emailFieldProps} />
        <ButtonWrapper>
          <BackButton onClick={handleBack} />
          <PrimaryButton type="submit" isLoading={isLoading}>
            Восстановить пароль
          </PrimaryButton>
        </ButtonWrapper>
      </form>
    </FormProvider>
  )
}

export default RestorePasswordForm
