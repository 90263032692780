import * as React from 'react'
import { FC, useState } from 'react'
import Tabs from '@organisms/Tabs'
import ContentBlock from '@organisms/ContentBlock'
import useMe from '@hooks/useMe'
import { useGetDivisionQuery } from '@services/divisions'
import { Wrapper } from './styled'
import EditDivisionBlock from './EditDivisionBlock'

const DivisionSettings: FC = () => {
  const { divisionCuratorWithOneDivision } = useMe()

  const [active, setActive] = useState(0)

  const { data } = useGetDivisionQuery(divisionCuratorWithOneDivision?.id!, {
    skip: !divisionCuratorWithOneDivision,
  })

  if (!data) {
    return null
  }

  return (
    <Wrapper>
      <Tabs
        active={active}
        setActive={setActive}
        tabSize="big"
        items={[
          {
            id: '1',
            title: 'Настройки подразделения',
            children: (
              <ContentBlock>
                <EditDivisionBlock divisionInfo={data} />
              </ContentBlock>
            ),
          },
        ]}
      />
    </Wrapper>
  )
}

export default DivisionSettings
