export const getKindName = (index?: number) =>
  typeof index !== 'undefined' && index >= 0
    ? `dangerDTOs.${index}.kind`
    : 'kind'
export const getDangerHazardName = (index?: number) =>
  typeof index !== 'undefined' && index >= 0
    ? `dangerDTOs.${index}.hazard`
    : 'hazard'
export const getEventName = (index?: number) =>
  typeof index !== 'undefined' && index >= 0
    ? `dangerDTOs.${index}.event`
    : 'event'
export const getWorkPlaceName = (index?: number) =>
  typeof index !== 'undefined' && index >= 0
    ? `dangerDTOs.${index}.workPlace`
    : 'workPlace'
export const getObjectName = (index?: number) =>
  typeof index !== 'undefined' && index >= 0
    ? `dangerDTOs.${index}.object`
    : 'object'
export const getHardGradeName = (index?: number) =>
  typeof index !== 'undefined' && index >= 0
    ? `dangerDTOs.${index}.hardGrade`
    : 'hardGrade'
export const getProbabilityName = (index?: number) =>
  typeof index !== 'undefined' && index >= 0
    ? `dangerDTOs.${index}.probability`
    : 'probability'
