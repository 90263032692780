import { useGetCourseThemesQuery } from '@services/courseThemes'
import useItemsFilters, { FilterState } from './useItemsFilters'

const useCourseThemes = (
  initFilters: FilterState = {},
  pagination = false,
  limit?: number
) => {
  const { filters, setFilters, setPage } = useItemsFilters(
    initFilters,
    pagination,
    limit
  )

  const state = useGetCourseThemesQuery(filters)

  return {
    ...state,
    setPage,
    setFilters,
  }
}

export default useCourseThemes
