import { FC } from 'react'
import useMouseOverOut from '@hooks/useMouseOverOut'
import { ReactComponent as InactiveToggleSmall } from '@main-menu/inactive/toggle-small.svg'
import { ReactComponent as ActiveToggleSmall } from '@main-menu/active/toggle-small.svg'
import { ReactComponent as InactiveToggleBig } from '@main-menu/inactive/toggle-big.svg'
import { ReactComponent as ActiveToggleBig } from '@main-menu/active/toggle-big.svg'
import { StyledMenuLink, Wrapper } from '../MainMenu/MenuItem/styled'

type Props = {
  onClick: () => void
  isSmall: boolean
}

const ExpandButton: FC<Props> = ({ isSmall, onClick }) => {
  const [hovered, , handleMouseEnter, handleMouseLeave] = useMouseOverOut()

  const ActiveIcon = isSmall ? ActiveToggleBig : ActiveToggleSmall
  const InactiveIcon = isSmall ? InactiveToggleBig : InactiveToggleSmall

  return (
    <Wrapper
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {hovered
        ? ActiveIcon && <ActiveIcon />
        : InactiveIcon && <InactiveIcon />}
      {!isSmall && <StyledMenuLink isActive={hovered}>Свернуть</StyledMenuLink>}
    </Wrapper>
  )
}

export default ExpandButton
