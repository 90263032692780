import styled from '@emotion/styled'
import { isMobile } from '@const/device'
import Title from '@atoms/Title'

const DesktopRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${({ theme }) => theme.sizes.twoBase};
`

const MobileRightWrapper = styled(DesktopRightWrapper)`
  margin: 10px;
`

export const RightWrapper = isMobile ? MobileRightWrapper : DesktopRightWrapper

export const StyledTitle = styled(Title)`
  color: ${({ theme }) => theme.colors.text.black};
  margin-bottom: ${({ theme }) => theme.sizes.base};
  white-space: pre-wrap;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`
