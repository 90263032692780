import * as React from 'react'
import { FC } from 'react'
import { noop } from 'lodash'
import { useToggleActiveUserMutation } from '@services/users'
import Link, { LinkStyle } from '@organisms/Link'
import Loader from '@organisms/Loader'
import { Tab } from '@organisms/Tabs'
import { ActiveWrapper, LinkWrapper } from './styled'

type Props = {
  id: number
  active: boolean
}

const ActiveBlock: FC<Props> = ({ id, active }) => {
  const [toggleActive, { isLoading }] = useToggleActiveUserMutation()

  const handleClick = () => {
    toggleActive(id)
  }

  return (
    <ActiveWrapper>
      <Loader isLoading={isLoading}>
        <Tab
          id="id"
          isActive={false}
          onClick={noop}
          tabSize="small"
          children={null}
          title={active ? 'Активен' : 'Не активен'}
          badgeColor={active ? 'green' : 'red'}
          withoutHover
        />
      </Loader>
      <LinkWrapper>
        <Link
          text={active ? 'Деактивировать' : 'Активировать'}
          linkStyle={LinkStyle.DEFAULT}
          onClick={handleClick}
        />
      </LinkWrapper>
    </ActiveWrapper>
  )
}

export default ActiveBlock
