import * as React from 'react'
import { FC } from 'react'
import { useFieldArray, UseFormReturn } from 'react-hook-form'
import AddNewItemModalLayout from '@templates/AddNewItemModalLayout'
import useFormProcessor from '@hooks/useFormProcessor'
import { useAppDispatch } from '@hooks/useAppDispatch'
import { setSelectedMeresForms } from '@services/riskMapModal'
import { useAppSelector } from '@hooks/useAppSelector'
import { MeasuresKindOption } from '@models/measuresKind'
import { MeasuresContentOption } from '@models/measuresContent'
import { User } from '@models/user'
import { Option } from '@organisms/Select'
import AddButton from '@organisms/AddButton'
import { AddButtonWrapper, BlocksWrapper } from './styled'
import MeresFieldsBlock from './MeresFieldsBlock'

export type MereFormItem = {
  mereKind: MeasuresKindOption | undefined
  content: MeasuresContentOption | undefined
  source: string | undefined
  deadline: string | undefined
  responsible: (Option & User) | undefined
}

type FormValues = {
  meres: MereFormItem[]
  prefilled: boolean
}

type Props = {
  dangerousIndex: number
  onModalClose: () => void
  hazard: Option
  event: Option
  company: Option
}

const MeresFieldsContent: FC<Props & { methods: UseFormReturn }> = ({
  dangerousIndex,
  onModalClose,
  hazard,
  event,
  company,
  methods,
}) => {
  const dispatch = useAppDispatch()
  const { control } = methods
  const { fields, append, remove } = useFieldArray({
    name: 'meres',
    control,
  })

  const handleSubmitForm = (form: FormValues) => {
    dispatch(
      setSelectedMeresForms({
        [dangerousIndex]: form,
      })
    )
    onModalClose()
  }

  return (
    <AddNewItemModalLayout
      handleCloseModal={onModalClose}
      onSubmitForm={handleSubmitForm}
      methods={methods}
      customFullTitle="Содержание мер"
      withStopPropagation
    >
      <BlocksWrapper>
        {fields.map((field, index, array) => (
          <MeresFieldsBlock
            index={index}
            id={field.id}
            remove={remove}
            event={event}
            hazard={hazard}
            isLast={index === array.length - 1}
            company={company}
            key={field.id}
          />
        ))}
      </BlocksWrapper>
      <AddButtonWrapper>
        <AddButton onClick={() => append({})}>Добавить меру</AddButton>
      </AddButtonWrapper>
    </AddNewItemModalLayout>
  )
}

const MeresFields: FC<Props> = (props) => {
  const defaultValues = useAppSelector(
    (state) => state.riskMapModal.selectedMeresForms[props.dangerousIndex]
  )
  const methods = useFormProcessor({
    defaultValues,
  })
  return <MeresFieldsContent {...props} methods={methods} />
}

export type { FormValues as MeresFieldsFormValues }
export default MeresFields
