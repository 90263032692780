import { FC } from 'react'
import { RedoOutlined } from '@ant-design/icons'
import StyledButton, { StyledButtonProps } from '@organs/StyledButton'
import ButtonStyle from '@enums/ButtonStyle'

type Props = { buttonStyle?: ButtonStyle } & Omit<
  StyledButtonProps,
  'buttonStyle'
>

const RepeatButton: FC<Props> = ({
  buttonStyle = ButtonStyle.GRAY,
  children = 'Повторить',
  ...restProps
}) => {
  return (
    <StyledButton
      {...restProps}
      buttonStyle={buttonStyle}
      leftIcon={<RedoOutlined />}
    >
      {children}
    </StyledButton>
  )
}

export default RepeatButton
