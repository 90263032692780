import StoreTags from '@enums/StoreTags'
import { CommonQueryParams, ItemsFromResponse } from '@models/common'
import {
  CreateDangerKindBody,
  CreateDangerKindResponse,
  DangerKind,
  DangerKindOption,
} from '@models/dangerKind'
import emptySplitApi from '../api'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'

const BASE_URL = '/api/risk_map_danger_kinds'
const JOB_TAGS = [StoreTags.DANGER_KINDS, StoreTags.DANGER_KIND]

export const DangerKindsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDangerKinds: builder.query<
      { items: DangerKindOption[]; length: number },
      CommonQueryParams
    >({
      query: (params) => ({
        url: BASE_URL,
        params,
      }),
      transformResponse: (response: ItemsFromResponse<DangerKind>) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          label: item.value,
          value: item['@id'],
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
      providesTags: [StoreTags.DANGER_KINDS],
    }),
    createDangerKind: builder.mutation<
      CreateDangerKindResponse,
      CreateDangerKindBody
    >({
      invalidatesTags: (result) => (result ? JOB_TAGS : []),
      query: (body) => ({
        url: BASE_URL,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useGetDangerKindsQuery, useCreateDangerKindMutation } =
  DangerKindsApi
