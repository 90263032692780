import styled from '@emotion/styled'
import { LinkStyle } from './index'

export const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
`

export const Text = styled.span<{
  isMatch: boolean
  linkStyle: LinkStyle
  fontSize?: number
}>`
  color: ${({
    theme: {
      colors: {
        text: { primary, secondary, link },
      },
    },
    isMatch,
    linkStyle,
  }) => {
    if (isMatch) {
      return primary
    }

    switch (linkStyle) {
      case LinkStyle.DEFAULT:
        return link
      case LinkStyle.GRAY:
      case LinkStyle.GRAY_UPPERCASE:
      default:
        return secondary
    }
  }};
  font-size: ${({
    fontSize,
    linkStyle,
    theme: {
      text: { h4, h6 },
    },
  }) => {
    if (fontSize) {
      return `${fontSize}px`
    }

    switch (linkStyle) {
      case LinkStyle.DEFAULT:
        return h6.fontSize
      case LinkStyle.GRAY:
      case LinkStyle.GRAY_UPPERCASE:
      default:
        return h4.fontSize
    }
  }};
  line-height: ${({
    linkStyle,
    theme: {
      text: { h4, h6 },
    },
  }) =>
    linkStyle === LinkStyle.DEFAULT || linkStyle === LinkStyle.GRAY_UPPERCASE
      ? h6.lineHeight
      : h4.lineHeight};
  font-weight: ${({ linkStyle }) =>
    linkStyle === LinkStyle.DEFAULT || linkStyle === LinkStyle.GRAY_UPPERCASE
      ? 'none'
      : 500};
  text-transform: ${({ linkStyle }) =>
    linkStyle === LinkStyle.DEFAULT || linkStyle === LinkStyle.GRAY_UPPERCASE
      ? 'uppercase'
      : 'none'};
  letter-spacing: ${({ linkStyle }) =>
    linkStyle === LinkStyle.DEFAULT || linkStyle === LinkStyle.GRAY_UPPERCASE
      ? '0.1em'
      : '0'};
  text-align: left;

  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`

export const StyledReactSVG = styled.div`
  margin-top: 2px;
  margin-right: 10px;
  min-width: 20px;
`
