import { JoyStep } from '@templates/Onboarding'

export const ONBOARDING_TITLE = 'Расскажем про раздел сотрудники'

export const ONBOARDING_STEPS: JoyStep[] = [
  {
    target: '#add-button',
    title: 'Как добавить сотрудника',
    content: 'Чтобы добавить сотрудника, нажмите кнопку “Добавить”',
  },
  {
    target: '#reports',
    title: 'Создание отчётов',
    content: 'Чтобы создать отчёт по сотрудникам, нажмите на кнопку “Отчёты”',
  },
  {
    target: '#search-block',
    title: 'Как найти сотрудника',
    content: 'Вы можете найти нужного сотрудника по ФИО/телефону/email/логину',
  },
  {
    target: '#filter-panel',
    title: 'Как найти сотрудника',
    content: 'Также можно найти сотрудника с помощью фильтров',
  },
]
