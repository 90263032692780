import { FC } from 'react'
import { useDeleteCourseChapterMutation } from '@services/courseChapters'
import { CourseChapter } from '@models/courseChapter'
import DeleteButton from '@templates/DeleteButton'

type Props = {
  chapter: CourseChapter
}

const DeleteChapterLink: FC<Props> = ({ chapter: { title, id } }) => {
  const [deleteCourseChapter, data] = useDeleteCourseChapterMutation()

  const handleDelete = async () => {
    await deleteCourseChapter(id)
  }

  return (
    <DeleteButton
      {...data}
      onClick={handleDelete}
      deleteDescription={`Вы точно хотите удалить раздел: ${title}? `}
      isLink
    />
  )
}

export default DeleteChapterLink
