import { BaseApiResponse } from './common'

export enum BannerSettingsCodes {
  COMPANY_CURATOR_GREETINGS = 'companyCurator_greetings',
  FILIAL_CURATOR_GREETINGS = 'filialCurator_greetings',
  FILIAL_CURATOR_SUBSCRIBE_END = 'filialCurator_subscribeEnd',
  FILIAL_CURATOR_LICENCES_COUNT_END = 'filialCurator_licencesCountEnd',
  EMPLOYEE_GREETINGS = 'employee_greetings',
  EMPLOYEE_INTERVIEW = 'employee_interview',
  EMPLOYEE_BODY_CHECK = 'employee_body_check',
  EMPLOYEE_RE_BRIEFING = 'employee_re_briefing',
}

export interface SettingsButton {
  id?: number
  text: string
  link: string
  title: string
}

export type BannerSettings = {
  id: number
  code: BannerSettingsCodes
  text?: string | null
  buttons: SettingsButton[]
} & BaseApiResponse

export interface UpdateBannerSettingsBody {
  text: string
  buttons: SettingsButton[]
  code: BannerSettingsCodes
}

export enum TextSettingsCodes {
  HOW_TO_STUDY = 'how_to_study',
  PRIVACY_POLICY = 'privacy_policy',
  SITE_LINK = 'site_link',
  SUBSCRIBE_REQUEST = 'subscribe_request_text',
  LISENCE_REQUEST = 'license_request_text',
}

export type TextSettings = {
  id: number
  code: string
  text: string
} & BaseApiResponse

export interface UpdateTextSettingsBody {
  text: string
  code: TextSettingsCodes
}
