import { useGetDocumentTypesQuery } from '@services/documentTypes'
import useItemsFilters, { FilterState } from './useItemsFilters'

const useDocumentTypes = (
  initFilters: FilterState = {},
  pagination = false,
  limit?: number
) => {
  const { filters, setFilters, setPage } = useItemsFilters(
    initFilters,
    pagination,
    limit
  )

  const state = useGetDocumentTypesQuery(filters)

  return {
    ...state,
    setPage,
    setFilters,
  }
}

export default useDocumentTypes
