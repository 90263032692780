import styled from '@emotion/styled'

export const SelectWrapper = styled.div`
  position: relative;
  cursor: pointer;
  z-index: 50;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: flex-end;
  gap: 10px;
`

export const NameWrapper = styled.span<{
  expanded: boolean
}>`
  color: ${({
    expanded,
    theme: {
      colors: {
        text: { black, primary },
      },
    },
  }) => (expanded ? primary : black)};
  margin: ${({ theme }) => `0 ${theme.sizes.base}`};
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.text.h4.fontSize};
  line-height: ${({ theme }) => theme.text.h4.lineHeight};
`

export const ArrowWrapper = styled.div`
  padding-bottom: 3px;
`

export const MenuWrapper = styled.div`
  background: ${({ theme }) => theme.colors.background.surface};
  border-radius: 25px;
  margin-top: 5px;
  box-shadow: 5px 5px 20px rgba(29, 78, 137, 0.1);
  padding: 30px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: absolute;
  width: 270px;
  right: -10px;
  top: 40px;
  z-index: 500;
`

export const MenuElement = styled.div`
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  &:hover {
    color: #1d4e89;
  }
`

export const MobileProfileMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  padding-top: 5px;
`
