import { FC, useState } from 'react'
import { Course } from '@models/course'
import Accordion from '@organisms/Accordion'
import { ListItemProps } from '@templates/List'
import Body from './Body'
import Header from './Header'

const EditableCourseItem: FC<ListItemProps<Course>> = ({
  isLoading,
  item,
  opened,
  handleClickOnItem,
}) => {
  const { id } = item
  const [expandedChapters, setExpandedChapters] = useState<Set<number>>(
    new Set([])
  )

  const handleExpanded = (index: number) => {
    setExpandedChapters((prev) => {
      if (prev.has(index)) {
        prev.delete(index)
      } else {
        prev.add(index)
      }

      return prev
    })
  }

  return (
    <Accordion
      expanded={opened}
      itemId={id}
      skeletonHeight={145}
      initLoading={isLoading}
      Header={({ hovered }) => (
        <Header onClick={handleClickOnItem} item={item} hovered={hovered} />
      )}
      Body={({ onChangeSize, toggleLoading }) => (
        <Body
          expandedChapters={expandedChapters}
          onExpanded={handleExpanded}
          onChangeSize={onChangeSize}
          toggleLoading={toggleLoading}
          id={id}
        />
      )}
    />
  )
}

export default EditableCourseItem
