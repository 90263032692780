import { FC } from 'react'
import { employeeField } from '@templates/AddNewItemModal/templates'
import Field from '@templates/Field'
import { Wrapper } from './styled'

const COMMISSION_HEAD_FIELD = employeeField({
  name: 'commissionHead',
  label: 'Председатель комиссии',
})

const ComissionHeadField: FC = () => {
  return (
    <Wrapper>
      <Field {...COMMISSION_HEAD_FIELD} />
    </Wrapper>
  )
}

export default ComissionHeadField
