import { FC } from 'react'
import { Skeleton } from 'antd'
import { useTheme } from '@emotion/react'
import { ReactComponent as CertIcon } from '@interface-images/cert-orange.svg'
import { isMobile } from '@const/device'
import { useLazyGetFileQuery } from '@services/files'
import { Certificate } from '@models/certificates'
import { formatServerDate } from '@utils/date'
import { ListItemProps } from '@templates/List'
import Loader from '@organisms/Loader'
import Text from '@organisms/Text'
import { Date, IconWrapper, SubWrapper, Wrapper } from './styled'

const CertificateItem: FC<ListItemProps<Certificate>> = ({
  isLoading: isLoadingProp,
  item: { course, createdAt, file },
}) => {
  const {
    sizes: { twoBase },
  } = useTheme()
  const [getFile, { isLoading }] = useLazyGetFileQuery()

  const handleClick = () => {
    void getFile({
      path: file.filePath,
      name: 'Сертификат.pdf',
    })
  }

  if (isLoadingProp) {
    return (
      <Skeleton.Button
        active
        style={{
          marginBottom: twoBase,
          marginRight: twoBase,
          borderRadius: 30,
          height: '10vh',
          width: isMobile ? '100%' : '23vw',
        }}
      />
    )
  }

  return (
    <Wrapper onClick={handleClick}>
      <Loader isLoading={isLoading}>
        <SubWrapper>
          <IconWrapper>
            <CertIcon />
          </IconWrapper>
          <div>
            <Text>{course?.title}</Text>
            {createdAt && <Date>{formatServerDate(createdAt)}</Date>}
          </div>
        </SubWrapper>
      </Loader>
    </Wrapper>
  )
}

export default CertificateItem
