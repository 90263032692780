import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  gap: 15px;
  cursor: pointer;
`
