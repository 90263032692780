import { FC } from 'react'
import { BannerSettings, BannerSettingsCodes } from '@models/settings'
import { useGetBannerSettingsByCodeQuery } from '@services/settings'
import Greetings from '../Greetings'

const CompanyCuratorBanner: FC = () => {
  const { data: bannerSettings = {} as BannerSettings } =
    useGetBannerSettingsByCodeQuery(
      BannerSettingsCodes.COMPANY_CURATOR_GREETINGS
    )

  return <Greetings bannerSettings={bannerSettings} />
}

export default CompanyCuratorBanner
