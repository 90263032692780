import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const AddWrapper = styled.div`
  display: flex;
  margin-left: -5px;
`
