import { TextAreaProps } from 'antd/lib/input/TextArea'
import { REQUIRED_VALIDATION_RULE } from '@const/validation'
import TextArea from '@organisms/TextArea'
import { FieldForRender, FieldParams } from '..'

type Params = {
  placeholder: string
  isLoading?: boolean
  componentProps?: TextAreaProps
  rows?: number
} & FieldParams

const textAreaField = (params: Params): FieldForRender => {
  const {
    label,
    placeholder,
    isRequired,
    isLoading = false,
    widthMultiplier = 1,
    rows,
    componentProps: props,
  } = params || {}

  const componentProps: TextAreaProps = {
    ...props,
    placeholder,
    isLoading,
    rows,
  }

  return {
    ...params,
    label: isRequired ? `${label}*` : label,
    rules: {
      ...(isRequired ? REQUIRED_VALIDATION_RULE : {}),
    },
    Component: TextArea,
    componentProps,
    widthMultiplier,
    withoutFixedHeight: true,
  }
}

export default textAreaField
