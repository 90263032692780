import { Skeleton } from 'antd'
import { useTheme } from '@emotion/react'

const SkeletonComponent = () => {
  const {
    sizes: { inputHeight },
  } = useTheme()

  return (
    <Skeleton.Button
      active
      style={{
        borderRadius: 25,
        height: inputHeight,
        width: 200,
      }}
    />
  )
}

export default SkeletonComponent
