import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { useToggle } from 'react-use'
import EditModal from '@templates/EditModal'
import AddButton from '@organisms/AddButton'
import ButtonStyle from '@enums/ButtonStyle'
import { BaseApiResponse } from '@models/common'
import UploadManyEmployeesFile from './UploadManyEmployeesFile'

type Props = {
  disabled?: boolean
}

const UploadEmployeesFile: FC<Props> = ({ disabled = false }) => {
  const { setValue } = useFormContext()

  const [isUploaded, toggleIsUploaded] = useToggle(false)

  return (
    <EditModal
      ModalContent={UploadManyEmployeesFile}
      modalProps={{
        onSave: (values: { data: BaseApiResponse }) => {
          setValue('employeesList', values.data['@id'])
          toggleIsUploaded(true)
        },
      }}
      CustomEditComponent={({ onClick }) => (
        <AddButton
          onClick={onClick}
          buttonStyle={isUploaded ? ButtonStyle.SUCCESS : undefined}
          disabled={disabled}
        >
          Загрузить файл
        </AddButton>
      )}
    />
  )
}

export default UploadEmployeesFile
