import { JoyStep } from '@templates/Onboarding'

export const ONBOARDING_TITLE =
  'Расскажем, как добавить  обучение или инструктаж'

export const ONBOARDING_STEPS: JoyStep[] = [
  {
    target: '#add-button',
    title: 'Как добавить обучение или инструктаж',
    content: 'Чтобы добавить обучение, нажмите кнопку “Добавить”',
  },
  {
    target: '#reports',
    title: 'Создание отчётов по обучению и инструктажам',
    content: 'Чтобы создать отчёт по обучению, нажмите на кнопку “Отчёты”',
  },
  {
    target: '#search-block',
    title: 'Как найти обучение или инструктаж',
    content: 'Вы можете найти нужное обучение или инструктаж по наименованию',
  },
  {
    target: '#filter-panel',
    title: 'Как найти обучение или инструктаж',
    content: 'Также можно найти обучение или инструктаж с помощью фильтров',
  },
]
