import { FC } from 'react'
import { Wrapper } from './styled'

type Props = {
  success: boolean
  successText: string
  notSuccessText: string
}

const GreenYellowStatus: FC<Props> = ({
  success,
  successText,
  notSuccessText,
}) => {
  return (
    <Wrapper success={success}>
      {success ? successText : notSuccessText}
    </Wrapper>
  )
}

export default GreenYellowStatus
