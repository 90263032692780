import * as React from 'react'
import { Dispatch, FC, SetStateAction } from 'react'
import BlueBorderCard from '@organisms/BlueBorderCard'
import { StyledSubtitle } from '../../styled'
import { AuthMethod } from '../index'
import { Wrapper } from './styled'

type Props = {
  setSelectedMethod: Dispatch<SetStateAction<AuthMethod | null>>
}

const InitForm: FC<Props> = ({ setSelectedMethod }) => {
  const handleSelectPhone = () => {
    setSelectedMethod(AuthMethod.PHONE)
  }

  const handleSelectEmail = () => {
    setSelectedMethod(AuthMethod.EMAIL)
  }

  return (
    <>
      <StyledSubtitle>Войти</StyledSubtitle>
      <Wrapper>
        <BlueBorderCard title="Номер телефона" onClick={handleSelectPhone} />
        <BlueBorderCard title="Почта / Логин" onClick={handleSelectEmail} />
      </Wrapper>
    </>
  )
}

export default InitForm
