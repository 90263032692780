import styled from '@emotion/styled'
import { isMobile } from '@const/device'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  padding: 20px;

  ${() =>
    isMobile
      ? ''
      : `
    position: absolute;
    z-index: 150;
    right: 1px;
    top: 45px;
    height: 700px;
  `}
`
