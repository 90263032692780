import { FC } from 'react'
import Tooltip from '@organisms/Tooltip'
import InfoCounter from '@organisms/InfoCounter'
import { formatServerDate } from '@utils/date'
import Text from '@organisms/Text'
import COURSE_TYPES from '@const/courseTypes'
import COURSE_SUB_TYPES from '@const/courseSubTypes'
import manyWords from '@utils/manyWords'
import { CourseSubTypes, CourseTypes } from '@models/course'
import { useAppSelector } from '@hooks/useAppSelector'
import { DateAlertWrapper, LineWrapper } from './styled'

type Props = {
  withDeadlineBadge: boolean
  createdAt: string | undefined
  deadlineAt: string | undefined
  type: CourseTypes
  subType: CourseSubTypes
  hoursCount: number
  index?: number
}

const CourseInfo: FC<Props> = ({
  withDeadlineBadge,
  createdAt,
  deadlineAt,
  type,
  subType,
  hoursCount,
  index,
}) => {
  const onboardingStarted = useAppSelector((state) => state.onboarding.started)

  const needShowDeadline = withDeadlineBadge && createdAt && deadlineAt

  return (
    <LineWrapper>
      {onboardingStarted && !needShowDeadline && index === 0 && (
        <DateAlertWrapper>
          <InfoCounter id="deadlineBlock">01.01.2010 / 01.01.2020</InfoCounter>
        </DateAlertWrapper>
      )}
      {needShowDeadline && (
        <DateAlertWrapper>
          <Tooltip tooltipContent="Указана дата назначения и рекомендованная дата окончания обучения. Курс будет доступен и после.">
            <InfoCounter id="deadlineBlock">
              {formatServerDate(createdAt) +
                ' / ' +
                formatServerDate(deadlineAt)}
            </InfoCounter>
          </Tooltip>
        </DateAlertWrapper>
      )}
      <Text type="tag">
        {COURSE_TYPES.find(({ value }) => type === value)?.label}
      </Text>
      <Text type="tag">
        {COURSE_SUB_TYPES.find(({ value }) => subType === value)?.label}
      </Text>
      <Text type="tag">{manyWords(hoursCount, 'час', 'часа', 'часов')}</Text>
    </LineWrapper>
  )
}

export default CourseInfo
