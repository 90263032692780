import * as React from 'react'
import { FC } from 'react'
import useMe from '@hooks/useMe'
import Title from '@organisms/Title'
import TechnicalSupportInfo from '@pages/Curator/TechnicalSupportInfo'
import { Wrapper } from './styled'
import CuratorField from './CuratorField'
import DivisionCurators from './DivisionCurators'

const CuratorComponent: FC = () => {
  const { company, companyBranch, meUser } = useMe()

  const hasCurators =
    !!company?.curators?.length ||
    !!companyBranch?.curators?.length ||
    !!meUser.subdivisionJobPositionRels?.length

  if (!hasCurators) {
    return (
      <Wrapper>
        <Title mySize="h4">Кураторов не найдено</Title>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      {company?.curators?.map((curator) => (
        <CuratorField
          title="Куратор компании"
          id={curator.id}
          key={'company' + curator.id + curator.fullName}
          additionalContent={
            <TechnicalSupportInfo description={company?.description} />
          }
        />
      ))}
      {companyBranch?.curators?.map((curator) => (
        <CuratorField
          title="Куратор филиала"
          id={curator.id}
          key={'filial' + curator.id + curator.fullName}
        />
      ))}
      {meUser.subdivisionJobPositionRels.map(({ subdivision }) => (
        <DivisionCurators key={subdivision.id} division={subdivision} />
      ))}
    </Wrapper>
  )
}

export default CuratorComponent
