import { FC } from 'react'
import { LinkOutlined } from '@ant-design/icons'
import GrayButton from '@organisms/GrayButton'

type Props = {
  descriptionLink: string | null
}

const DescriptionLink: FC<Props> = ({ descriptionLink }) => {
  if (!descriptionLink) return null

  const handleClick = () => {
    window.open(descriptionLink, '_blank')
  }

  return (
    <GrayButton leftIcon={<LinkOutlined />} onClick={handleClick}>
      Ссылка на описание
    </GrayButton>
  )
}

export default DescriptionLink
