import styled from '@emotion/styled'
import Title from '@organisms/Title'

export const Wrapper = styled.div`
  margin: 10px 0;
`

export const TitleWrapper = styled.div`
  margin: 5px 0 15px;
`

export const StyledTitle = styled(Title)`
  white-space: pre-line;
`
