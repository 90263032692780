import { BACKEND_URL } from '@const/env'
import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import paramsSerializer from './paramsSerializer'
import prepareHeaders from './prepareHeaders'

const getBaseApiConfig = () =>
  fetchBaseQuery({
    baseUrl: BACKEND_URL,
    prepareHeaders,
    paramsSerializer,
  })

export default getBaseApiConfig
