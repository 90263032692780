import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import useMe from '@hooks/useMe'
import InfoField from '@organisms/InfoField'
import { JobPosition } from '@models/user'
import { Routes } from '@enums/Routes'

type Props = {
  position: JobPosition
  forAllUsers?: boolean
  withoutTitle?: boolean
  withoutFixedWidth?: boolean
  withoutHover?: boolean
}

const PositionInfoField: FC<Props> = ({
  position,
  forAllUsers = false,
  withoutTitle = false,
  withoutFixedWidth = false,
  withoutHover = false,
}) => {
  const { isAdmin, isCompanyCurator, isFilialCurator } = useMe()
  const navigate = useNavigate()

  const handleOpen = async () => {
    navigate(Routes.POSITIONS + `?id=${position.id}`)
  }

  if (forAllUsers || isAdmin || isCompanyCurator || isFilialCurator) {
    return (
      <InfoField
        onTabClick={handleOpen}
        label={withoutTitle ? undefined : 'Должность'}
        value={position.title}
        withStopPropagation={false}
        withoutFixedWidth={withoutFixedWidth}
        withoutHover={withoutHover}
      />
    )
  }

  return null
}

export default PositionInfoField
