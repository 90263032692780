import * as React from 'react'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useToggle } from 'react-use'
import { notification } from 'antd'
import { isNil } from 'lodash'
import { usePostFileMutation } from '@services/files'
import StyledButton from '@organs/StyledButton'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import ButtonStyle from '@enums/ButtonStyle'
import Text from '@organs/Text'
import { BaseButtonProps } from '@organs/BaseButton'
import { File } from '@models/file'
import useDownloadFile from '@hooks/useDownloadFile'
import { Wrapper } from './styled'

type Props = {
  fileName?: string
  formats?: string[]
  maxSizeMegaBytes?: number
  onChange?: (file: File | null) => void
  value?: File
} & BaseButtonProps

const FileUploadButton = forwardRef<HTMLInputElement, Props>(
  (
    {
      onClick,
      onChange,
      fileName: fileNameProp = '',
      formats,
      value,
      maxSizeMegaBytes,
      ...rest
    },
    outerRef
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    useImperativeHandle(outerRef, () => inputRef.current!, [])

    const [loadedFiles, setLoadedFiles] = useState<string | null>(null)
    const [isLoadingState, toggleIsLoading] = useToggle(false)
    const [fileName, setFileName] = useState(fileNameProp)

    const [uploadFile, { data }] = usePostFileMutation()

    const { download, isLoading: downloadIsLoading } =
      useDownloadFile(loadedFiles)

    const isLoading = isLoadingState || downloadIsLoading

    const handleClick = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      onClick?.(e)

      if (loadedFiles) {
        download()
      } else {
        inputRef.current?.click()
      }
    }

    const handleFile = (files: FileList) => {
      const file = files.item(0)

      const formData = new FormData()
      if (file) {
        if (formats && !formats.some((format) => file.name.includes(format))) {
          notification.error({ message: 'Формат файла не поддерживается!' })
          return
        }

        if (maxSizeMegaBytes && file.size > maxSizeMegaBytes * 1_000_000) {
          notification.error({
            message: `Файл больше максимального размера: ${maxSizeMegaBytes} мегабайт!`,
          })
          return
        }

        toggleIsLoading(true)
        setFileName(file.name)
        formData.append('originalName', file.name)
        formData.append('file', file)
        uploadFile(formData)
      }
    }

    useEffect(() => {
      if (data) {
        const id = data['@id']
        setLoadedFiles(id)
        onChange?.(data)
        toggleIsLoading(false)
      }
    }, [data])

    useEffect(() => {
      if (isNil(value)) {
        setLoadedFiles(null)
        setFileName('')
      } else {
        setFileName(value?.originalName)
        setLoadedFiles(value?.['@id'])
      }
    }, [value?.['@id']])

    return (
      <Wrapper>
        <StyledButton
          {...rest}
          buttonStyle={
            isLoading
              ? ButtonStyle.WARNING
              : loadedFiles
              ? ButtonStyle.SUCCESS
              : ButtonStyle.PRIMARY
          }
          leftIcon={loadedFiles ? undefined : <PlusOutlined />}
          rightIcon={
            loadedFiles ? (
              <CloseOutlined
                onClick={(e) => {
                  e.stopPropagation()
                  setLoadedFiles(null)
                  setFileName('')
                  onChange?.(null)
                }}
              />
            ) : undefined
          }
          onClick={handleClick}
          isLoading={isLoading}
        >
          <input
            type="file"
            style={{
              display: 'none',
              width: '100%',
            }}
            onChange={(e) => handleFile(e.target.files!)}
            ref={(value) => (inputRef.current = value)}
          />
          <span>
            {isLoading
              ? 'Файл загружается'
              : loadedFiles
              ? 'Файл загружен'
              : 'Загрузить файл'}
          </span>
        </StyledButton>
        <Text type="description">{fileName}</Text>
      </Wrapper>
    )
  }
)

export type { Props as FileUploadButtonProps }
export default FileUploadButton
