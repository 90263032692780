import styled from '@emotion/styled'
import { isMobile } from '@const/device'

export const Wrapper = styled.div`
  margin: 25px 25px 30px;
`

const DesktopLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 25px;
`
const MobileLineWrapper = styled(DesktopLineWrapper)`
  flex-direction: column;
  align-items: flex-start;
`
export const LineWrapper = isMobile ? MobileLineWrapper : DesktopLineWrapper

export const ExternalLineWrapper = styled.div`
  margin: 10px -25px 30px;
`
