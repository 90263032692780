import * as React from 'react'
import { FC, ReactNode, useEffect } from 'react'
import { useMount } from 'react-use'
import {
  useDeleteCompanyGroupMutation,
  useGetCompanyGroupQuery,
} from '@services/companyGroups'
import { AccordionBodyProps } from '@organisms/Accordion'
import AccordionBodyField from '@organisms/AccordionBodyField'
import EditModal from '@templates/EditModal'
import CompanyGroupModal from '@modals/CompanyGroupModal'
import DeleteButton from '@templates/DeleteButton'
import Table from '@organisms/Table'
import AccordionBodySelectedTabs from '@templates/AccordionBodySelectedTabs'
import { renderPhone } from '@utils/phone'
import SimpleEmployeeModal from '@modals/SimpleEmployeeModal'
import { FooterWrapper, StyledTitle } from './styled'
import CompanyListItem from './CompanyListItem'

const COLUMNS = [
  {
    title: 'ФИО',
    dataIndex: 'fio',
  },
  {
    title: 'Телефон',
    dataIndex: 'phone',
  },
  {
    title: 'Электронная почта',
    dataIndex: 'email',
  },
  {
    title: '',
    dataIndex: 'edit',
  },
]

type DataItem = {
  fio: string
  phone: string
  email: string | null
  edit: ReactNode
  key: string
}

type Props = { id: number } & AccordionBodyProps

const Body: FC<Props> = ({ onChangeSize, toggleLoading, id }) => {
  const [deleteCompanyGroup, deleteData] = useDeleteCompanyGroupMutation()

  const { data, refetch } = useGetCompanyGroupQuery(id)
  const {
    curators = [],
    usedSubscriptionsCount = 0,
    subscriptionsCount = 0,
  } = data || {}

  useMount(() => {
    toggleLoading(true)
  })

  useEffect(() => {
    if (data) {
      toggleLoading(false)
      onChangeSize()
    }
  }, [data?.id])

  const tableData: DataItem[] = []

  for (const curator of curators) {
    const { '@id': idKey, fullName, phone, email = '' } = curator
    tableData.push({
      key: idKey,
      fio: fullName,
      phone: phone ? renderPhone(phone) : '',
      email,
      edit: (
        <EditModal
          ModalContent={SimpleEmployeeModal}
          modalProps={{ employee: curator, onSuccess: refetch }}
          editText=""
        />
      ),
    })
  }

  if (!data) return null

  const { companies } = data

  return (
    <div>
      <AccordionBodyField
        label="Сотрудники"
        value={`${usedSubscriptionsCount} / ${subscriptionsCount}`}
      />
      {tableData.length > 0 && (
        <>
          <StyledTitle mySize="h3">Кураторы</StyledTitle>
          <Table columns={COLUMNS} data={tableData} />
        </>
      )}
      {companies && companies?.length > 0 && (
        <AccordionBodySelectedTabs
          onTabChange={onChangeSize}
          title="Компании"
          items={companies.map((companies) => ({
            id: String(companies.id),
            title: companies.title,
            children: (
              <CompanyListItem id={companies.id} onSuccess={onChangeSize} />
            ),
          }))}
        />
      )}
      <FooterWrapper>
        <EditModal
          ModalContent={CompanyGroupModal}
          modalProps={{
            companyGroup: data,
          }}
        />
        <DeleteButton
          {...deleteData}
          onClick={() => deleteCompanyGroup(data.id)}
          deleteDescription="Вы уверены, что хотите удалить группу компаний?"
        />
      </FooterWrapper>
    </div>
  )
}

export default Body
