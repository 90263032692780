import styled from '@emotion/styled'

export const Wrapper = styled.div`
  width: 90%;
  padding: 20px 0;
`

export const EditBtnWrapper = styled.div`
  margin-top: -15px;
`
