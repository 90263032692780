import styled from '@emotion/styled'

export const ButtonWrapper = styled.div`
  display: flex;
`

export const PickersWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
`

export const PickerWrapper = styled.div`
  width: 200px;
`
