import { FC } from 'react'
import ListPage from '@templates/ListPage'
import useCompanyGroups from '@hooks/useCompanyGroups'
import CompanyGroupModal from '@modals/CompanyGroupModal'
import CompanyGroup from './CompanyGroup'
import useCompanyGroupsFilters from './useCompanyGroupsFilters'

const CompanyGroups: FC = () => {
  const filters = useCompanyGroupsFilters()
  const { data, ...rest } = useCompanyGroups()
  const { items = [], length = 0 } = data || {}

  return (
    <ListPage
      {...rest}
      titleTabs={[
        {
          title: 'Группы компаний',
          counter: length,
          index: 0,
        },
      ]}
      filters={filters}
      ModalContent={CompanyGroupModal}
      ItemComponent={CompanyGroup}
      list={items}
      length={length}
      withSearch
    />
  )
}

export default CompanyGroups
