import styled from '@emotion/styled'
import { isMobile } from '@const/device'

const DesktopWrapper = styled.div<{ minHeightVh: number }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-height: ${({ minHeightVh }) => `${minHeightVh}vh`};
  min-width: 800px;
`
const MobileWrapper = styled(DesktopWrapper)<{ minHeightVh: number }>`
  min-height: 10vh;
  min-width: 100%;
`
export const Wrapper = isMobile ? MobileWrapper : DesktopWrapper
