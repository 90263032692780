import { FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useCourseChapters from '@hooks/useCouseChapters'
import { useGetCourseByIdQuery } from '@services/courses'
import { PER_PAGE } from '@const/pagination'
import { createInitFilter } from '@utils/filters'
import List from '@templates/List'
import BackButton from '@organisms/BackButton'
import Onboarding from '@templates/Onboarding'
import OnboardingCodes from '@enums/OnboardingCodes'
import { Routes } from '@enums/Routes'
import useMe from '@hooks/useMe'
import Chapter from './Chapter'
import { ButtonWrapper, StyledTitle, Wrapper } from './styled'
import { ONBOARDING_STEPS, ONBOARDING_TITLE } from './onboarding'

const CourseComponent: FC = () => {
  const { isAdmin } = useMe()
  const { courseId, educationId } = useParams()
  const navigate = useNavigate()
  const { data: course, error } = useGetCourseByIdQuery(courseId || '', {
    skip: !courseId,
  })
  const { title } = course || {}
  const {
    data: chapters,
    page,
    setPage,
    isLoading,
  } = useCourseChapters(
    courseId ? createInitFilter('course', courseId) : undefined
  )
  const { items: chaptersItems = [], length = 0 } = chapters || {}
  const items =
    (error as { status: number })?.status === 404 ? [] : chaptersItems

  const handleBack = () => {
    navigate(isAdmin ? Routes.COURSES : Routes.STUDY)
  }

  return (
    <>
      <Onboarding
        firstStepTitle={ONBOARDING_TITLE}
        steps={ONBOARDING_STEPS}
        code={OnboardingCodes.my_education_course}
      />
      <ButtonWrapper>
        <BackButton onClick={handleBack} />
      </ButtonWrapper>
      <Wrapper>
        <StyledTitle>{title}</StyledTitle>
      </Wrapper>
      <List
        isLoading={isLoading}
        list={items.map((item, index) => ({
          ...item,
          number: index + 1 + PER_PAGE * (page - 1),
          courseId,
          educationId,
        }))}
        setPage={setPage}
        length={length}
        ItemComponent={Chapter}
      />
    </>
  )
}

export default CourseComponent
