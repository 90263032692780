import styled from '@emotion/styled'
import { isMobile } from '@const/device'

const DesktopCounter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  background: ${({ theme }) => theme.colors.background.primaryAccent};
  border-radius: 50px;
  justify-content: space-around;
  height: 25px;
  gap: 5px;
`

const MobileCounter = styled(DesktopCounter)`
  height: 20px;
`

export const Counter = isMobile ? MobileCounter : DesktopCounter

export const RightContent = styled.div`
  font-weight: 400;
  letter-spacing: 0.1em;
  text-align: left;
  white-space: nowrap;

  font-size: ${({ theme }) => theme.text.h6.fontSize};
  line-height: ${({ theme }) => theme.text.h6.lineHeight};
`
