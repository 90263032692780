import useDocumentTypes from '@hooks/useDocumentTypes'
import { FieldProps } from '@templates/Field'
import {
  fileField,
  selectField,
  textField,
} from '@templates/AddNewItemModal/templates'
import { Fields } from '@templates/AddNewItemModal/Content'

const FILE_FORMATS = [
  'pdf',
  'jpg',
  'png',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'txt',
  'ppt',
  'pps',
  'pptx',
  'ppsx',
  'avi',
  'mp4',
  'mp3',
]

const useLibraryFields = (isEdit = false): Fields => {
  const { data, isLoading: documentTypesIsLoading } = useDocumentTypes()
  const { items: documentTypes = [] } = data || {}

  const common: FieldProps[] = [
    textField({
      name: 'title',
      label: 'Название',
      isRequired: true,
      placeholder: 'Название документа',
    }),
    selectField({
      name: 'documentType',
      label: 'Тип документа',
      isRequired: true,
      options: documentTypes,
      isLoading: documentTypesIsLoading,
    }),
  ]

  if (!isEdit) {
    common.push(
      fileField({
        name: 'file',
        label: 'Файл',
        isRequired: true,
        fileName: FILE_FORMATS.join(' '),
        formats: FILE_FORMATS,
      })
    )
  }

  return [{ fields: common }]
}

export default useLibraryFields
