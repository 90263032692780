import StoreTags from '@enums/StoreTags'
import { CommonQueryParams, ItemsFromResponse } from '@models/common'
import {
  CreateDangerObjectBody,
  CreateDangerObjectResponse,
  DangerObject,
  DangerObjectOption,
} from '@models/dangerObject'
import emptySplitApi from '../api'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'

const BASE_URL = '/api/risk_map_danger_objects'
const JOB_TAGS = [StoreTags.DANGER_OBJECTS, StoreTags.DANGER_OBJECT]

export const DangerObjectsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDangerObjects: builder.query<
      { items: DangerObjectOption[]; length: number },
      CommonQueryParams
    >({
      query: (params) => ({
        url: BASE_URL,
        params,
      }),
      transformResponse: (response: ItemsFromResponse<DangerObject>) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          label: item.value,
          value: item['@id'],
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
      providesTags: [StoreTags.DANGER_OBJECTS],
    }),
    createDangerObject: builder.mutation<
      CreateDangerObjectResponse,
      CreateDangerObjectBody
    >({
      invalidatesTags: (result) => (result ? JOB_TAGS : []),
      query: (body) => ({
        url: BASE_URL,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useGetDangerObjectsQuery, useCreateDangerObjectMutation } =
  DangerObjectsApi
