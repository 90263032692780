enum ButtonStyle {
  PRIMARY,
  SECONDARY,
  WARNING,
  SUCCESS,
  GRAY,
  SIMPLE,
  FILTER,
  SECONDARY_WITH_WHITE_BACKGROUND,
  SELECT,
  WHITE,
  WHITE_TRANSPARENT,
}

export default ButtonStyle
