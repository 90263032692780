import styled from '@emotion/styled'
import { isMobile } from '@const/device'

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 10px;
  flex-wrap: wrap;
`

const DesktopTextWrapper = styled.div`
  max-width: 30vw;
`
const MobileTextWrapper = styled(DesktopTextWrapper)`
  max-width: inherit;
`
export const TextWrapper = isMobile ? MobileTextWrapper : DesktopTextWrapper
