import styled from '@emotion/styled'
import { isMobile } from '@const/device'

const DesktopWrapper = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 10px 0 -5px;
  flex-direction: column;
  min-height: 55px;
  width: 100%;
  padding-bottom: 10px;
`
const MobileWrapper = styled(DesktopWrapper)`
  align-items: flex-start;
  flex-direction: column;
`
export const Wrapper = isMobile ? MobileWrapper : DesktopWrapper

const DesktopTitleWrapper = styled.div`
  width: 350px;
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: nowrap;
`
const MobileTitleWrapper = styled(DesktopTitleWrapper)`
  display: block;
`
export const TitleWrapper = isMobile ? MobileTitleWrapper : DesktopTitleWrapper

export const InactiveDescription = styled.div`
  margin-top: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #7b7b7b;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  white-space: nowrap;
`

export const DivisionPositionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 15px;
`
