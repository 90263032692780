import { useGetCertificatesQuery } from '@services/certificates'
import { PER_PAGE } from '@const/pagination'
import useItemsFilters, { FilterState } from './useItemsFilters'

const useCertificates = (
  initFilters: FilterState = {},
  pagination = true,
  limit = PER_PAGE
) => {
  const { filters, setFilters, setPage, setOrder } = useItemsFilters(
    initFilters,
    pagination,
    limit
  )

  const state = useGetCertificatesQuery(filters)

  return {
    ...state,
    setPage,
    setFilters,
    setOrder,
  }
}

export default useCertificates
