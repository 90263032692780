import { FC } from 'react'
import Badge from '@organisms/Badge'
import Prompt from '@organisms/Prompt'
import { Wrapper } from './styled'
import getComponentConfig from './utils/getComponentConfig'

type Props = {
  date: string
}

const UpdateBefore: FC<Props> = ({ date }) => {
  const { formattedDate, promptText, color } = getComponentConfig(date)

  return (
    <Wrapper>
      <Badge badgeColor={color} />
      {formattedDate}
      {promptText && <Prompt promptText={promptText} />}
    </Wrapper>
  )
}

export default UpdateBefore
