import { JoyStep } from '@templates/Onboarding'
import { Routes } from '@enums/Routes'
import { InfoCircleTwoTone } from '@ant-design/icons'

export const ONBOARDING_TITLE = 'Расскажем об обучении'

export const getOnboardingSteps = (index: number): JoyStep[] => [
  {
    target: `#stats${index}`,
    title: 'Статистика по обучению',
    content: (
      <div>
        <div>
          В этом блоке вы можете увидеть, сколько сотрудников прошли или не
          прошли обучение.
        </div>
        <div>
          Нажмите на число, чтобы попасть в соответствующий список сотрудников.
        </div>
      </div>
    ),
  },
  {
    target: `#educationReportButtons${index}`,
    title: 'Создание протокола и реестра Минтруда',
    content: (
      <div>
        <div>Вы можете создать протокол или реестр минтруда по обучению.</div>
        <div>
          <InfoCircleTwoTone style={{ color: 'white' }} /> О том, как загрузить
          реестр минтруда на сайт минтруда,
        </div>
        <div>
          вы можете узнать в{' '}
          <a
            style={{
              color: 'white',
              textDecoration: 'underline',
            }}
            href={Routes.GUIDES}
          >
            разделе “Инструкции”
          </a>{' '}
          в вопросе “Личный кабинет по охране труда: инструкция по доступу от
          Минтруда”.
        </div>
      </div>
    ),
  },
]
