enum OnboardingCodes {
  branch_section = 'branch_section', // Филиалы. Раздел.
  branch_create = 'branch_create', // Филиалы. Создание.
  subdivision_section = 'subdivision_section', // Подразделение. Раздел.
  subdivision_create = 'subdivision_create', // Подразделение. Создание.
  job_position_section = 'job_position_section', // Должности. Раздел.
  job_position_create = 'job_position_create', // Должности. Создание.
  employee_section = 'employee_section', // Сотрудники. Раздел.
  employee_create = 'employee_create', // Сотрудники. Создание.
  employee_state_table_upload = 'employee_state_table_upload', // Сотрудники. Загрузка штатного расписания.
  employee_probation_sheets = 'employee_probation_sheets', // Сотрудники. Стажировочные листы.
  employee_reports = 'employee_reports', // Сотрудники. Отчёты.
  education_section = 'education_section', // Обучение. Раздел.
  education_create = 'education_create', // Обучение. Раскрытая карточка.
  education_open_card = 'education_open_card', // Обучение. Отчёты.
  education_reports = 'education_reports', // Обучение. Отчёты.
  report_modal = 'report_modal', // Модальные окна отчётов.
  briefing = 'briefing', // Инструктажи.
  risk_map_section = 'risk_map_section', // Карты рисков. Раздел.
  risk_map_create = 'risk_map_create', // Карты рисков. Создание.
  risk_map_open_card = 'risk_map_open_card', // Карты рисков. Раскрытая карточка.
  risk_map_reports = 'risk_map_reports', // Карты рисков. Отчеты.
  my_education_section = 'my_education_section', // Моё обучение. Раздел.
  my_education_course = 'my_education_course', // Моё обучение. Курс.
  my_education_theme = 'my_education_theme', // Моё обучение. Тема.

  // не хватает
  my_education_chapter = 'my_education_chapter', // Моё обучение. Раздел обучения.
  risk_map_create_dangers = 'risk_map_create_dangers', // Карты рисков. Создание опасностей.
}

export default OnboardingCodes
