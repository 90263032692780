import styled from '@emotion/styled'

export const StyledLink = styled.a`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
  color: #7b7b7b;

  &:hover {
    color: #1d4e89;
  }
`
