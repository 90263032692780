import StoreTags from '@enums/StoreTags'
import { CommonQueryParams, ItemsFromResponse } from '@models/common'
import {
  CreateMeasuresKindBody,
  CreateMeasuresKindResponse,
  MeasuresKind,
  MeasuresKindOption,
} from '@models/measuresKind'
import emptySplitApi from '../api'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'

const BASE_URL = '/api/risk_map_danger_control_measure_kinds'
const JOB_TAGS = [StoreTags.MEASURES_KIND, StoreTags.MEASURES_KINDS]

export const measuresKindsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getMeasuresKinds: builder.query<
      { items: MeasuresKindOption[]; length: number },
      CommonQueryParams
    >({
      query: (params) => ({
        url: BASE_URL,
        params,
      }),
      transformResponse: (response: ItemsFromResponse<MeasuresKind>) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          label: item.value,
          value: item['@id'],
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
      providesTags: [StoreTags.MEASURES_KINDS],
    }),
    createMeasuresKind: builder.mutation<
      CreateMeasuresKindResponse,
      CreateMeasuresKindBody
    >({
      invalidatesTags: (result) => (result ? JOB_TAGS : []),
      query: (body) => ({
        url: BASE_URL,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useGetMeasuresKindsQuery, useCreateMeasuresKindMutation } =
  measuresKindsApi
