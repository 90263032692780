import { notification } from 'antd'
import { FC, ReactNode, useEffect, useState } from 'react'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { useToggle } from 'react-use'
import SuccessContentComponent from './SuccessContent'
import { Wrapper } from './styled'
import Header, { HeaderProps } from './Header'
import Footer, { FooterProps } from './Footer'

export enum FromButtons {
  MAIN,
  ADD_MORE,
  NEXT,
}

type Props = {
  children?: ReactNode
  successContentAddTitle?: string
  successContentEditTitle?: string
  successContentDescription?: string
  methods: UseFormReturn
  isSuccess?: boolean
  isError?: boolean
  reset?: () => void
  withNotificationBanner?: boolean
  CustomErrorComponent?: FC<any & { onClose: () => void }> // correct
  error?: any // correct
  withStopPropagation?: boolean
  withoutReset?: boolean
  withoutAddMore?: boolean
  minHeightVh?: number
  CustomFooter?: FC<FooterProps>
  data?: any // correct
} & HeaderProps &
  Omit<FooterProps, 'fromButton' | 'setFromButton'>

const AddNewItemModalLayout: FC<Props> = ({
  isEdit = false,
  titlePostfix,
  additionalTitleContent,
  onSubmitForm,
  methods,
  isLoading,
  onSaveAndAddMoreText,
  customAddText,
  successContentAddTitle,
  handleCloseModal,
  successContentEditTitle,
  successContentDescription,
  isError,
  isSuccess,
  withSaveAndAddMore = false,
  withoutAddMore = false,
  onSaveAndAddMore,
  withNotificationBanner = false,
  CustomErrorComponent,
  error: errorProps,
  withStopPropagation = false,
  withoutReset = false,
  saveText,
  minHeightVh = 40,
  customFullTitle,
  CustomFooter,
  data,
  children,
}) => {
  const successAddText = successContentAddTitle || 'Добавление прошло успешно'
  const successEditText = successContentEditTitle || 'Изменение прошло успешно'
  const { reset } = methods

  const [error, toggleError] = useToggle(false)
  const [success, toggleSuccess] = useToggle(false)
  const [fromButton, setFromButton] = useState<FromButtons>(FromButtons.MAIN)

  const handleAddMore = () => {
    toggleSuccess(false)
  }

  const fromMain = fromButton === FromButtons.MAIN

  useEffect(() => {
    if (isSuccess) {
      if (!withoutReset) {
        reset()
      }

      if (!isEdit && !withNotificationBanner && fromMain) {
        toggleSuccess(true)
      } else {
        notification.success({
          message: isEdit ? successEditText : successAddText,
        })

        if (fromMain) {
          handleCloseModal?.()
        }
      }
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      toggleError(true)
    }
  }, [isError])

  if (CustomErrorComponent && error) {
    return <CustomErrorComponent error={errorProps} onClose={toggleError} />
  }

  if (handleCloseModal && success) {
    return (
      <SuccessContentComponent
        isEdit={isEdit}
        onAddMore={handleAddMore}
        onClose={handleCloseModal}
        addTitle={successAddText}
        editTitle={successEditText}
        description={successContentDescription || ''}
        withoutAddMore={withoutAddMore}
      />
    )
  }

  const FooterComponent = CustomFooter || Footer

  return (
    <FormProvider {...methods}>
      <Wrapper
        minHeightVh={minHeightVh}
        onClick={withStopPropagation ? (e) => e.stopPropagation() : undefined}
      >
        <div>
          {(customFullTitle || titlePostfix) && (
            <Header
              customFullTitle={customFullTitle}
              additionalTitleContent={additionalTitleContent}
              customAddText={customAddText}
              isEdit={isEdit}
              titlePostfix={titlePostfix}
            />
          )}
          {children}
        </div>
        <FooterComponent
          isEdit={isEdit}
          onSubmitForm={onSubmitForm}
          isLoading={isLoading}
          onSaveAndAddMore={onSaveAndAddMore}
          onSaveAndAddMoreText={onSaveAndAddMoreText}
          withSaveAndAddMore={withSaveAndAddMore}
          saveText={saveText}
          fromButton={fromButton}
          setFromButton={setFromButton}
          handleCloseModal={handleCloseModal}
          data={data}
        />
      </Wrapper>
    </FormProvider>
  )
}

export type { Props as AddNewItemModalLayoutProps }
export default AddNewItemModalLayout
