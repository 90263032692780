import {
  PHONE_VALIDATION_RULE,
  REQUIRED_VALIDATION_RULE,
} from '@const/validation'
import MaskedInput, { MaskedInputProps } from '@organisms/MaskedInput'
import { FieldForRender, FieldParams } from '..'

type Params = {
  name?: string
  componentProps?: MaskedInputProps
} & Omit<FieldParams, 'label' | 'name'>

const phoneField = (params?: Params): FieldForRender => {
  const paramsObj = params || ({} as FieldParams)
  const { name, componentProps: props, big, isRequired, rules } = paramsObj

  const componentProps: MaskedInputProps = {
    ...props,
    maskOptions: {
      mask: '+{7} (000)000-00-00',
    },
    placeholder: '+7 (___)___-__-__',
    big,
  }

  return {
    ...paramsObj,
    name: name || 'phone',
    label: isRequired ? 'Телефон*' : 'Телефон',
    rules: {
      ...PHONE_VALIDATION_RULE,
      ...(isRequired ? REQUIRED_VALIDATION_RULE : {}),
      ...rules,
    },
    Component: MaskedInput,
    componentProps,
  }
}

export default phoneField
