import styled from '@emotion/styled'
import { isMobile } from '@const/device'
import Title from '@organisms/Title'

const DesktopWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
`
const MobileWrapper = styled(DesktopWrapper)`
  align-items: flex-end;
  flex-direction: column;
`
export const Wrapper = isMobile ? MobileWrapper : DesktopWrapper

export const StyledTitle = styled(Title)`
  padding-top: 30px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
`
