import styled from '@emotion/styled'
import { isMobile } from '@const/device'

export const ThemeTitle = styled.div<{
  hovered: boolean
}>`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({
    hovered,
    theme: {
      colors: {
        text: { primary, black },
      },
    },
  }) => (hovered ? primary : black)};
  margin-bottom: 10px;
`

const DesktopSquare = styled.div<{
  active: boolean
}>`
  border: 1px solid ${({ theme }) => theme.colors.main};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 35px;
  width: 35px;
  min-height: 35px;
  min-width: 35px;
  margin-right: 25px;

  ${({ active, theme }) =>
    active
      ? `
    background-color: ${theme.colors.main};
    color: white;
  `
      : ''}
`
const MobileSquare = styled(DesktopSquare)<{
  active: boolean
}>`
  margin-right: 15px;
`
export const Square = isMobile ? MobileSquare : DesktopSquare

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`

const DesktopRightWrapper = styled.div`
  padding-top: 10px;
  min-height: 75px;
`
const MobileRightWrapper = styled(DesktopRightWrapper)`
  min-height: inherit;
`
export const RightWrapper = isMobile ? MobileRightWrapper : DesktopRightWrapper

export const TagsWrapper = styled.div`
  display: flex;
  gap: 10px;
`
