import { FC, useEffect } from 'react'
import { useToggle } from 'react-use'
import { UseFormReturn } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { OPENED_REPORT_QUERY } from '@const/reportNames'
import { Fields } from '@templates/AddNewItemModal/Content'
import { FilterState } from '@hooks/useItemsFilters'
import { ReactComponent as Document } from '@interface-images/document.svg'
import FiltersModal from './FiltersModal'
import { Wrapper } from './styled'

type Props = {
  methods: UseFormReturn
  title: string
  filterModalFields: Fields
  onSubmitFilter: (form: FilterState) => void
  isLoading: boolean
  transformFormValues?: (form: any) => any
  onSelectReport?: () => void
  id: string
}

const ReportCard: FC<Props> = ({
  title,
  filterModalFields,
  onSubmitFilter,
  isLoading,
  methods,
  transformFormValues,
  onSelectReport,
  id,
}) => {
  const { search, pathname } = useLocation()
  const navigate = useNavigate()

  const isCurrentReportActive =
    search?.includes(OPENED_REPORT_QUERY) && search?.includes(id)
  const [filtersIsVisible, toggleFiltersIsVisible] = useToggle(
    isCurrentReportActive
  )

  const handleReportClick = () => {
    toggleFiltersIsVisible(true)
    onSelectReport?.()
  }

  const onModalClose = () => {
    methods.reset((formValues) => {
      const resultForm: any = {}
      for (const key in formValues) {
        resultForm[key] = null
      }

      return resultForm
    })

    toggleFiltersIsVisible(false)

    if (isCurrentReportActive) {
      navigate(pathname)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (isCurrentReportActive) {
        handleReportClick()
      }
    })
  }, [isCurrentReportActive])

  return (
    <>
      <Wrapper id={id} key={title} onClick={handleReportClick}>
        <div className="circle">
          <Document />
        </div>
        {title}
      </Wrapper>
      <FiltersModal
        transformFormValues={transformFormValues}
        methods={methods}
        handleSubmit={onSubmitFilter}
        fields={filterModalFields}
        visible={filtersIsVisible}
        onModalClose={onModalClose}
        isLoading={isLoading}
      />
    </>
  )
}

export default ReportCard
