import { UseFormReturn } from 'react-hook-form'
import useJobFilters from '@hooks/useJobFilters'
import { FilterTypes } from '@templates/ListPageLayout/Filters/Filter'
import { FiltersProps } from '@templates/ListPageLayout/Filters'
import RISK_MAP_STATUSES from '@const/riskMapStatuses'
import useDangerWorkPlacesFilter from './useDangerWorkPlacesFilter'
import useDangerKindsFilter from './useDangerKindsFilter'
import useDangerHazardsFilter from './useDangerHazardsFilter'

enum ExpireValues {
  EXPIRED = 'true',
  NOT_EXPIRED = 'false',
}

enum ReviseValues {
  NOT_REVISED = 'true',
  ACTIVE = 'false',
}

const useRiskMapsFilters = (methods: UseFormReturn): FiltersProps => {
  const { company, filial, division, position } = useJobFilters(methods)

  const type = useDangerWorkPlacesFilter('dangers.workPlace')
  const kinds = useDangerKindsFilter(methods, 'dangers.kind')
  const dangers = useDangerHazardsFilter(methods, 'dangers.hazard')

  const filters: FiltersProps = [
    {
      filterType: FilterTypes.HIDDEN,
      fieldName: 'id',
    },
  ]

  if (company) {
    filters.push({
      ...company,
      fieldNameForSubmit: 'jobPosition.subdivision.companyBranch.company',
    })
  }

  if (filial) {
    filters.push({
      ...filial,
      fieldNameForSubmit: 'jobPosition.subdivision.companyBranch',
    })
  }

  if (division) {
    filters.push({ ...division, fieldNameForSubmit: 'jobPosition.subdivision' })
  }

  if (position) {
    filters.push({ ...position, fieldNameForSubmit: 'jobPosition' })
  }

  filters.push(
    {
      filterType: FilterTypes.SELECT,
      fieldName: 'status',
      placeholder: 'Статус',
      options: RISK_MAP_STATUSES,
      withoutFetchOptions: true,
    },
    type,
    kinds,
    dangers,
    {
      filterType: FilterTypes.SELECT_SEARCH,
      fieldName: 'significance',
      placeholder: 'Значимость риска',
      options: [
        {
          label: 'Низкая',
          value: 1,
        },
        {
          label: 'Средняя',
          value: 2,
        },
        {
          label: 'Высокая',
          value: 3,
        },
      ],
      withoutFetchOptions: true,
    },
    {
      filterType: FilterTypes.DATE,
      fieldName: 'approvedAt[after]',
      placeholder: 'Дата утверждения от',
    },
    {
      filterType: FilterTypes.DATE,
      fieldName: 'approvedAt[before]',
      placeholder: 'Дата утверждения до',
    },
    {
      filterType: FilterTypes.SELECT,
      fieldName: 'overdue',
      placeholder: 'Пересмотр',
      options: [
        {
          label: 'Просрочено',
          value: ReviseValues.NOT_REVISED,
        },
        {
          label: 'Активно',
          value: ReviseValues.ACTIVE,
        },
      ],
      withoutFetchOptions: true,
    },
    {
      filterType: FilterTypes.SELECT,
      fieldName: 'expire',
      placeholder: 'Обновление СИЗ',
      options: [
        {
          value: ExpireValues.EXPIRED,
          label: 'Срок СИЗ истёк',
        },
        {
          value: ExpireValues.NOT_EXPIRED,
          label: 'Срок СИЗ истекает',
        },
      ],
      withoutFetchOptions: true,
      transformValue: (filter) => {
        if (filter.expire[0] === ExpireValues.EXPIRED) {
          return {
            individualProtectionMeanExpires: null,
            individualProtectionMeanExpired: 'true',
          }
        } else if (filter.expire[0] === ExpireValues.NOT_EXPIRED) {
          return {
            individualProtectionMeanExpired: null,
            individualProtectionMeanExpires: 'true',
          }
        }

        return {
          individualProtectionMeanExpires: null,
          individualProtectionMeanExpired: null,
        }
      },
    }
  )

  return filters
}

export default useRiskMapsFilters
