import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import useFormProcessor from '@hooks/useFormProcessor'
import {
  useGetTextSettingsByCodeQuery,
  usePutTextSettingsMutation,
} from '@services/settings'
import { TextSettingsCodes } from '@models/settings'
import Tabs from '@organisms/Tabs'
import Title from '@organisms/Title'
import AddNewItemModal from '@templates/AddNewItemModal'
import { textAreaField } from '@templates/AddNewItemModal/templates'
import { Fields } from '@templates/AddNewItemModal/Content'
import { StyledContentBlock } from '../styled'

const TABS = [
  {
    title: 'Политика конфиденциальности',
    value: TextSettingsCodes.PRIVACY_POLICY,
  },
  {
    title: 'Текст при заявке на тариф «Подписка»',
    value: TextSettingsCodes.SUBSCRIBE_REQUEST,
  },
  {
    title: 'Текст при заявке на тариф «Покупка лицензий»',
    value: TextSettingsCodes.LISENCE_REQUEST,
  },
  {
    title: 'Ссылка на сайт',
    value: TextSettingsCodes.SITE_LINK,
  },
]

type FormValues = {
  text: string
}

const Texts: FC = () => {
  const [active, setActive] = useState(0)

  const [
    updateText,
    {
      error: updateError,
      isLoading: updateIsLoading,
      isSuccess,
      isError,
      reset,
    },
  ] = usePutTextSettingsMutation()

  const { data, error, isLoading } = useGetTextSettingsByCodeQuery(
    TABS[active]?.value
  )
  const methods = useFormProcessor({
    apiErrors: error || updateError,
    defaultValues: data,
  })

  const handleSubmitForm = (form: FormValues) => {
    updateText({
      text: form.text,
      code: TABS[active]?.value,
    })
  }

  useEffect(() => {
    if (data) {
      methods.setValue('text', data.text)
    }
  }, [data])

  const fields: Fields = [
    {
      fields: [
        textAreaField({
          name: 'text',
          placeholder: 'Текст',
          label: 'Текст',
          isRequired: true,
          widthMultiplier: 3,
          isLoading: isLoading || updateIsLoading,
        }),
      ],
    },
  ]

  return (
    <StyledContentBlock>
      <Title>Тексты</Title>
      <Tabs
        active={active}
        setActive={setActive}
        tabSize="small"
        items={TABS.map(({ title }) => ({
          id: title,
          title,
          children: (
            <AddNewItemModal
              onSubmitForm={handleSubmitForm}
              methods={methods}
              isLoading={updateIsLoading}
              fields={fields}
              isError={isError}
              isSuccess={isSuccess}
              reset={reset}
              successContentAddTitle="Тексты успешно обновлены"
              withNotificationBanner
              withoutReset
            />
          ),
        }))}
      />
    </StyledContentBlock>
  )
}

export default Texts
