const template = `
  <!DOCTYPE html>
  <html lang='ru'>
      <head>
          <link rel='stylesheet' href='{{ fullPublicDir }}/certificate/fonts/Gilroy/stylesheet.css'>
          <title>Certificate</title>
      </head>
      <body>
        <div style="
          font-family: 'Gilroy';
          font-style: normal; 
          height: 980px;
          background-image: url({{ fullPublicDir }}/certificate/background.svg);
        ">
          <div>
            <div style='
              padding: 70px 0 100px 100px;
            '>
              <div style='
                color: #1D4E89; 
                font-weight: bold; 
                font-size: 24px; 
                line-height: 28px; 
                margin-top: 20px;
              '>
                <img src='{{ fullPublicDir }}/certificate/logo.svg' height='23' width='320'/>
              </div>
              <div style='
                margin-top: 120px; 
                font-weight: 700;
                font-size: 42px;
              '>
                {{ listener.fullName }}
              </div>
              <div style='
                margin-top: 60px;
                font-weight: 400;
                font-size: 18px;
                color: #7B7B7B;
                text-transform: uppercase;
              '>
                прошел(шла) курс
              </div>
              <div style='
                margin-top: 35px;
                font-weight: 700;
                font-size: 30px;
                max-height: 500px;
                width: 1000px;
              '>
                {{ course.title }} 
              </div>
              <div style='
                font-weight: 700;
                font-size: 26px;
                line-height: 24px;
                margin-top: 300px;
                display: flex;
              '>
                Анохин Алексей Владимирович
              </div>
              <div style='
                font-weight: 400;
                font-size: 18px;
                line-height: 16px;
                color: #7B7B7B;
                text-transform: uppercase;
                margin-top: 30px;
              '>
                генеральный директор
              </div>
            </div>
            <div style='
                  width: 200px;
                  height: 200px;
                  position: fixed;
                  left: 500px;
                  top: 650px;
                  z-index: 500;
                '>
                <img src='{{ fullPublicDir }}/certificate/sign-and-stamp.png'/>
                </div>
            <div style='
              display: flex;
              background-color: #FEBB02;
              width: 250px;
              float: right;
              position: fixed;
              right: 0;
              top: 0;
              height: 980px;
              padding: 10px;
            '>
              <img src='{{ fullPublicDir }}/certificate/certificate-banner.png' height='970' />
              <div>
                <div style='
                  color: white;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 30px;
                  line-height: 16px;
                  text-transform: uppercase;
                  position: fixed;
                  right: -120px;
                  top: 200px;
                  -webkit-transform: rotate(-90deg);
                '>
                  go.weexperts.study
                </div>
                <div style='
                  color: white;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 30px;
                  line-height: 16px;
                  text-transform: uppercase;
                  position: fixed;
                  -webkit-transform: rotate(-90deg);
                  top: 500px;
                  right: -30px;
                '>
                  {{ courseDate.format("d.m.Y") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
  </html>
`

export default template
