import useCompanies, { UseCompaniesParams } from '@hooks/useCompanies'
import useHasJobs from './useHasJobs'
import { JobData } from './useJobDataForFields'

const useCompaniesJobData = (params?: UseCompaniesParams): JobData | null => {
  const { hasCompany } = useHasJobs()

  const companiesResponse = useCompanies(params)
  const { items = [], length = 0 } = companiesResponse.data || {}
  const companiesIsLoading = companiesResponse.isLoading

  if (!hasCompany) {
    return null
  }

  return {
    isDisabled: false,
    options: items,
    length,
    isLoading: companiesIsLoading,
    setPage: companiesResponse.setPage,
    setFilters: companiesResponse.setFilters,
  }
}

export default useCompaniesJobData
