import { RiskMapDetailed } from '@models/riskMap'
import { CompanyOption } from '@models/company'
import { DivisionOption } from '@models/division'
import { FilialOption } from '@models/filial'
import { PositionOption } from '@models/position'
import { formatServerDate } from '@utils/date'
import { getPossibilityLabel, getSeverityLabel } from '@utils/riskMaps'
import setDefaultRiskCommissionMembers from '@templates/RiskMemberMultilineFields/setDefaultRiskCommissionMembers'
import INJURY_POSSIBILITIES from '@const/injuryPossibilities'
import SEVERITY_DEGREES from '@const/severityDegrees'
import { FormValues } from './index'

export const DEFAULT_OPTIONS = {
  hardGrade: SEVERITY_DEGREES[2],
  probability: INJURY_POSSIBILITIES[1],
  meres: [{}],
  protectionMeans: [{}],
}

const getDefaultValues = (
  riskMap?: RiskMapDetailed
  // eslint-disable-next-line sonarjs/cognitive-complexity
): Partial<FormValues> | void => {
  let result: Partial<FormValues> = {
    dangerDTOs: [DEFAULT_OPTIONS as any],
    autoAddProtectionMeans: true,
  }

  if (!riskMap) return result

  result = {
    number: riskMap.number,
    file: riskMap.file,
    autoAddProtectionMeans: riskMap.autoAddProtectionMeans,
    //   dangers: {
    //     meres: {
    //       mereKind?: Option
    //       content?: Option
    //       source?: string
    //       deadline?: string
    //       responsible?: Option
    //     }[]
    //     type?: Option
    //     kind?: Option
    //     danger?: Option
    //     workPlace?: Option
    //     object?: Option
    //     hardGrade?: Option
    //     probability?: Option
    //   }[]
  }

  if (riskMap.dangers) {
    result.dangerDTOs = riskMap.dangers.map(
      ({
        event,
        injuryPossibility,
        injurySeverity,
        kind,
        object,
        workPlace,
        hazard,
        riskMapDangerControlMeasures = [],
      }) => {
        return {
          workPlace: workPlace
            ? {
                label: workPlace.value,
                value: workPlace['@id'],
              }
            : undefined,
          kind: kind
            ? {
                label: kind.value,
                value: kind['@id'],
              }
            : undefined,
          event: event
            ? {
                label: event.value,
                value: event['@id'],
              }
            : undefined,
          hazard: hazard
            ? {
                label: hazard.value,
                value: hazard['@id'],
              }
            : undefined,
          object: object
            ? {
                label: object.value,
                value: object['@id'],
              }
            : undefined,
          hardGrade: injurySeverity
            ? {
                label: getSeverityLabel(injurySeverity),
                value: injurySeverity,
              }
            : undefined,
          probability: injuryPossibility
            ? {
                label: getPossibilityLabel(injuryPossibility),
                value: injuryPossibility,
              }
            : undefined,
          meres: riskMapDangerControlMeasures.map((measure) => ({
            mereKind: measure.kind
              ? {
                  label: measure.kind?.value || '',
                  value: measure.kind.id,
                }
              : undefined,
            content: measure.content
              ? {
                  label: measure.content.value,
                  value: measure.content.id,
                }
              : undefined,
            source: measure.fundingSource,
            deadline: measure.plannedCompletionDate
              ? formatServerDate(measure.plannedCompletionDate)
              : undefined,
            responsible: measure.responsiblePerson
              ? {
                  label: measure.responsiblePerson.fullName,
                  value: measure.responsiblePerson.id,
                }
              : undefined,
          })),
        }
      }
    )
  }

  if (riskMap.reviseBeforeAt) {
    result.reviseBeforeAt = formatServerDate(riskMap.reviseBeforeAt)
  }

  if (riskMap.jobPosition) {
    const position = riskMap.jobPosition
    const division = position.subdivision
    const filial = division.companyBranch
    const company = filial.company

    result.company = {
      ...company,
      value: company['@id'],
      label: company.title,
    } as CompanyOption
    result.filial = {
      ...filial,
      value: filial['@id'],
      label: filial.title,
    } as FilialOption
    result.division = {
      ...division,
      value: division['@id'],
      label: division.title,
    } as unknown as DivisionOption
    result.position = {
      ...position,
      value: position['@id'],
      label: position.title,
    } as unknown as PositionOption
  }

  return setDefaultRiskCommissionMembers(result, riskMap.commissionMembers)
}

export default getDefaultValues
