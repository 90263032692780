import styled from '@emotion/styled'
import { isMobile } from '@const/device'

const DesktopWrapper = styled.div`
  padding: 0 20px;
`
const MobileWrapper = styled.div`
  padding: 0;
`
export const Wrapper = isMobile ? MobileWrapper : DesktopWrapper
