import styled from '@emotion/styled'
import { isMobile } from '@const/device'
import Subtitle from '@organisms/Subtitle'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  overflow-x: hidden;
`

const DesktopCentralColumnWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const MobileCentralColumnWrapper = styled(DesktopCentralColumnWrapper)`
  width: 100%;
`
export const CentralColumnWrapper = isMobile
  ? MobileCentralColumnWrapper
  : DesktopCentralColumnWrapper

export const CentralWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const FormLabel = styled.div`
  font-size: ${({ theme }) => theme.text.h6.fontSize};
  line-height: ${({ theme }) => theme.text.h6.lineHeight};
  margin-top: ${({ theme }) => theme.sizes.twoBase};
  margin-bottom: 12px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-align: left;
`

export const StyledSubtitle = styled(Subtitle)<{ isLast?: boolean }>`
  margin: 0 4px ${({ isLast = false }) => (isLast ? '15' : '4')}px 4px;
`

const DesktopAuthBlockWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background.surface};
  border-radius: 30px;
  padding: ${({
    theme: {
      sizes: { fourBase, fiveBase },
    },
  }) => `${fiveBase} ${fourBase}`};
  width: 435px;
  margin: ${({ theme }) => theme.sizes.fourBase} 0;
`
const MobileAuthBlockWrapper = styled(DesktopAuthBlockWrapper)`
  width: 95%;
`
export const AuthBlockWrapper = isMobile
  ? MobileAuthBlockWrapper
  : DesktopAuthBlockWrapper

export const ButtonWrapper = styled.div`
  margin: 15px 0 0;
  display: flex;
  gap: 10px;
`
