import React, { Dispatch, FC, SetStateAction } from 'react'
import { isMobile } from '@const/device'
import DesktopMenu from './DesktopMenu'
import MobileMenu from './MobileMenu'

type Props = {
  setExpandedMobileMenu: Dispatch<SetStateAction<boolean>>
  onExpandMenu: (expanded: boolean) => void
}

const Menu: FC<Props> = ({ setExpandedMobileMenu, onExpandMenu }) =>
  isMobile ? (
    <MobileMenu setExpandedMobileMenu={setExpandedMobileMenu} />
  ) : (
    <DesktopMenu onExpandMenu={onExpandMenu} />
  )

export default Menu
