import BaseButton from '@molecules/BaseButton'
import styled from '@emotion/styled'

export const StyledPrimaryButton = styled(BaseButton)`
  background: #1d4e89;
  color: white;

  &:hover {
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    &:hover {
      color: #c7c7c7;
      border: 1px solid #c7c7c7;
    }
  }
`

export const StyledSecondaryButton = styled(BaseButton)`
  border: 1px solid #1d4e89;
  background: transparent;
  color: black;

  &:hover {
    background: #1d4e89;
    color: white;
  }
`

export const StyledWarningButton = styled(StyledSecondaryButton)<{
  isActive?: boolean
}>`
  color: #7f7f7f;

  ${({ isActive }) =>
    isActive
      ? 'background: #febb02; color: white; border: 1px solid #febb02;'
      : 'border: 1px solid #c4c4c4;'}
  &:hover {
    border: 1px solid #febb02;
    color: ${({ isActive }) => (isActive ? 'white' : '#7f7f7f')};
    background: ${({ isActive }) => (isActive ? '#febb02' : 'transparent')};
  }
`

export const StyledSuccessButton = styled(StyledPrimaryButton)`
  background: #23af11;
`

export const StyledGrayButton = styled(StyledSecondaryButton)`
  border: 1px solid #c4c4c4;

  &:hover {
    border: 1px solid #1d4e89;
  }

  &:disabled {
    &:hover {
      color: #c7c7c7;
      border: 1px solid #c7c7c7;
    }
  }
`

export const StyledSecondaryWithWhiteBgButton = styled(StyledSecondaryButton)`
  background: white;
`

export const StyledSelectButton = styled(StyledGrayButton)`
  background: transparent;
  text-transform: none;
  letter-spacing: unset;
  color: #7f7f7f;

  &:hover {
    background: transparent;
    color: #7f7f7f;
  }
`

export const StyledWhiteButton = styled(StyledGrayButton)`
  background: white;
  letter-spacing: unset;
  color: ${({ theme }) => theme.colors.main};

  &:hover {
    background: white;
    color: ${({ theme }) => theme.colors.main};
    border: 1px solid white;
  }
`

export const StyledWhiteTransparentButton = styled(StyledGrayButton)`
  background: transparent;
  letter-spacing: unset;
  color: white;

  &:hover {
    background: transparent;
    color: white;
    border: 1px solid white;
  }
`
