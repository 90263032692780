import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useMount, useToggle } from 'react-use'
import { Skeleton } from 'antd'
import {
  EmployeeProbationSheetResult,
  ProbationSheetQuestion,
} from '@models/employee'
import Title from '@organisms/Title'
import AddButton from '@organisms/AddButton'
import Field from '@templates/Field'
import { dateField, textField } from '@templates/AddNewItemModal/templates'
import DeleteButton from '@templates/DeleteButton'
import {
  AddButtonWrapper,
  DeleteWrapper,
  StyledFlexWrapper,
  Wrapper,
} from '@templates/MultilineFieldNew/styled'
import Onboarding from '@templates/Onboarding'
import OnboardingCodes from '@enums/OnboardingCodes'
import { EMPLOYEE_PROBATION_PREFIX, getName } from '../useStaffingFields'
import { getMultilineName } from './const'
import { ONBOARDING_STEPS, ONBOARDING_TITLE } from './onboarding'

export const PROBATION_QUESTION_NAME = 'probationSheetQuestions_question'
export const PROBATION_DATE_NAME = 'probationSheetQuestions_date'

type Props = {
  methods: UseFormReturn
  listActive: number
  setListActive: Dispatch<SetStateAction<number>>
  setIntershipList: Dispatch<SetStateAction<number[]>>
  employeeProbationSheets?: EmployeeProbationSheetResult[]
  hidden: boolean
}

/**
 * @deprecated
 */
const IntershipProgramTable: FC<Props> = ({
  methods,
  setIntershipList,
  listActive,
  setListActive,
  employeeProbationSheets,
  hidden,
}) => {
  const initialState: Record<number, number[]> =
    employeeProbationSheets && employeeProbationSheets?.length > 0
      ? employeeProbationSheets?.reduce((acc, item, index) => {
          acc[index] = item.probationSheetQuestions?.map((_, idx) => idx) || [0]

          return acc
        }, {} as Record<number, number[]>)
      : { 0: [0] }
  const [itemsState, setItemsState] =
    useState<Record<number, number[]>>(initialState)

  const sheets = employeeProbationSheets?.[listActive]?.probationSheetQuestions
    ? (employeeProbationSheets[listActive]
        .probationSheetQuestions as ProbationSheetQuestion[])
    : [0]
  const initState = sheets.map((_, index) => index)
  const [items, setItems] = useState(initState)
  const [mounted, toggleMounted] = useToggle(true)

  const probationConclusion = getName('probationConclusion', listActive)
  const intershipLeaderConclusionsField = textField({
    widthMultiplier: 2,
    placeholder: 'Выводы руководителя стажировки',
    label: 'Выводы руководителя стажировки',
    name: probationConclusion,
  })

  const workPermittedAt = getName('workPermittedAt', listActive)
  const intershipLeaderConclusionsDateField = dateField({
    label: 'Дата допуска к работе',
    name: workPermittedAt,
  })

  const handleAdd = () => {
    setItems((prev) => [...prev, prev.length])
  }

  const handleDeleteCreator = (index: number) => () => {
    setItems((prev) => {
      const newItems = []
      for (const item of prev) {
        if (item === index) continue

        newItems.push(item)
      }

      return newItems
    })
  }

  const handleDeleteIntershipList = () => {
    setIntershipList((prev) => {
      const newItems = []
      for (const item of prev) {
        if (item === listActive) {
          const formState = methods.getValues()
          for (const field in formState) {
            if (
              field.startsWith(EMPLOYEE_PROBATION_PREFIX) &&
              field.endsWith(String(listActive))
            ) {
              methods.setValue(field, null)
            }
          }
          continue
        }

        newItems.push(item)
      }

      return newItems
    })
    setListActive(0)
  }

  const handleAddIntershipList = () => {
    setIntershipList((prev) => [...prev, prev.length])
    setListActive((prev) => prev + 1)
  }

  useMount(() => {
    if (employeeProbationSheets) {
      setIntershipList(employeeProbationSheets.map((_, index) => index))
    }
  })

  useEffect(() => {
    setItemsState((prev) => ({
      ...prev,
      [listActive]: items,
    }))
  }, [items, listActive])

  useEffect(() => {
    toggleMounted(false)
    setTimeout(() => toggleMounted(true), 1000)
    setItems(itemsState[listActive] || [0])
  }, [listActive])

  if (!mounted && !hidden) {
    return <Skeleton.Button block active style={{ height: 200 }} />
  }

  return (
    <Wrapper hidden={hidden}>
      {!hidden && (
        <Onboarding
          firstStepTitle={ONBOARDING_TITLE}
          steps={ONBOARDING_STEPS}
          code={OnboardingCodes.employee_probation_sheets}
          withScroll
        />
      )}
      <Title>Таблица программы стажировки</Title>
      {(itemsState[listActive] || []).map((item, index) => {
        const answer = getName(
          getMultilineName(PROBATION_QUESTION_NAME, index),
          listActive
        )
        const answerField = textField({
          widthMultiplier: 2,
          placeholder: 'Вопрос',
          label: 'Вопрос',
          name: answer,
        })

        const date = getName(
          getMultilineName(PROBATION_DATE_NAME, index),
          listActive
        )
        const dateFieldProps = dateField({
          label: 'Дата',
          name: date,
        })

        return (
          <StyledFlexWrapper key={item}>
            <Field {...answerField} />
            <Field {...dateFieldProps} />
            {item !== 0 && (
              <DeleteWrapper>
                <DeleteButton onClick={handleDeleteCreator(item)} />
              </DeleteWrapper>
            )}
          </StyledFlexWrapper>
        )
      })}
      <AddButtonWrapper>
        <AddButton onClick={handleAdd} />
      </AddButtonWrapper>
      <StyledFlexWrapper>
        <Field {...intershipLeaderConclusionsField} />
        <Field {...intershipLeaderConclusionsDateField} />
      </StyledFlexWrapper>
      <AddButtonWrapper>
        <StyledFlexWrapper>
          <AddButton onClick={handleAddIntershipList}>
            добавить стажировочный лист
          </AddButton>
          {listActive !== 0 && (
            <DeleteButton onClick={handleDeleteIntershipList}>
              Удалить стажировочный лист
            </DeleteButton>
          )}
        </StyledFlexWrapper>
      </AddButtonWrapper>
    </Wrapper>
  )
}

export default IntershipProgramTable
