import { Roles } from '@enums/Roles'
import { Routes } from '@enums/Routes'
import CompanyModalOnRoute from '@modals/CompanyModalOnRoute'
import FilialModalOnRoute from '@modals/FilialModalOnRoute'
import DivisionModalOnRoute from '@modals/DivisionModalOnRoute'
import PositionModalOnRoute from '@modals/PositionModalOnRoute'
import EmployeeModalOnRoute from '@modals/EmployeeModalOnRoute'
import EducationModalOnRoute from '@modals/EducationModalOnRoute'
import { RouteConfig } from './index'

const MODAL_ROUTES_CONFIG: RouteConfig[] = [
  {
    route: Routes.COMPANY_MODAL,
    element: <CompanyModalOnRoute />,
    roles: new Set([Roles.ADMIN]),
  },
  {
    route: Routes.FILIAL_MODAL,
    element: <FilialModalOnRoute />,
    roles: new Set([Roles.ADMIN, Roles.COMPANY_CURATOR]),
  },
  {
    route: Routes.DIVISION_MODAL,
    element: <DivisionModalOnRoute />,
    roles: new Set([Roles.ADMIN, Roles.COMPANY_CURATOR, Roles.FILIAL_CURATOR]),
  },
  {
    route: Routes.POSITION_MODAL,
    element: <PositionModalOnRoute />,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
  },
  {
    route: Routes.EMPLOYEE_MODAL,
    element: <EmployeeModalOnRoute />,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
  },
  {
    route: Routes.EDUCATION_MODAL,
    element: <EducationModalOnRoute />,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
  },
]

export default MODAL_ROUTES_CONFIG
