import { Dispatch, FC, SetStateAction } from 'react'
import useFormProcessor from '@hooks/useFormProcessor'
import {
  useCreateEmployeeMutation,
  useUpdateEmployeeMutation,
} from '@services/employees'
import { User } from '@models/user'
import { CommonEmployeeBody, EmployeeDetailed } from '@models/employee'
import AddNewItemModal from '@templates/AddNewItemModal'
import getDefaultValues from '@modals/EmployeeModal/getDefaultValues'
import { FormValues } from '@modals/EmployeeModal'
import { CompanyGroupDetailed } from '@models/companyGroup'
import useSimpleEmployeeFields from './useSimpleEmployeeFields'

const formatEmployee = (employee: EmployeeDetailed) => ({
  ...employee,
  '@id': employee['@id'].replace('/api/users/employees', '/api/users'),
})

type Props = {
  isEdit?: boolean
  handleCloseModal: () => void
  employee?: User
  setSelectedCurators: Dispatch<SetStateAction<EmployeeDetailed[]>>
  companyGroup: CompanyGroupDetailed | undefined
  onSuccess?: () => void
}

const SimpleEmployeeModal: FC<Props> = ({
  isEdit,
  employee,
  handleCloseModal,
  setSelectedCurators,
  companyGroup,
  onSuccess,
}) => {
  const fields = useSimpleEmployeeFields()

  const [create, createData] = useCreateEmployeeMutation()
  const [update, updateData] = useUpdateEmployeeMutation()
  const additionalProps = isEdit ? updateData : createData

  const methods = useFormProcessor({
    apiErrors: additionalProps.error,
    defaultValues: getDefaultValues(employee),
  })

  const handleSubmitForm = async (form: FormValues) => {
    const commonForm: CommonEmployeeBody = {
      companyGroup: companyGroup?.['@id'] || undefined,
      fullName: form.fullName,
      phone: form.phone || undefined,
      email: form.email || undefined,
      companyBranch: undefined,
      birthDate: undefined,
      employmentDate: undefined,
      education: undefined,
      nextBodyCheckDate: undefined,
      comment: undefined,
      inn: undefined,
      insuranceNumber: undefined,
      subdivisionJobPositionRels: undefined,
      employeeProbationSheets: undefined,
      curatorStatus: undefined,
      userConfig: undefined,
      withoutRefetchTags: true,
    }

    if (isEdit) {
      if (employee) {
        const updated = await update({
          ...commonForm,
          id: employee.id,
        })

        if (!updated.data) return

        setSelectedCurators?.((prevState) =>
          prevState.map((item) => {
            return item.id === updated.data.id
              ? formatEmployee(updated.data)
              : item
          })
        )
      }
    } else {
      const created = await create(commonForm)

      if (!created.data) return

      setSelectedCurators?.((prevState) => [
        ...prevState,
        formatEmployee(created.data),
      ])
    }

    onSuccess?.()
  }

  return (
    <AddNewItemModal
      {...additionalProps}
      isEdit={isEdit}
      titlePostfix="сотрудника"
      fields={fields}
      onSubmitForm={handleSubmitForm}
      handleCloseModal={handleCloseModal}
      methods={methods}
      withSaveAndAddMore={false}
    />
  )
}

export default SimpleEmployeeModal
