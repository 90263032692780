import { useState } from 'react'
import { SelectSearchFieldParams } from '@templates/AddNewItemModal/templates/selectSearchField'
import dataSelectField from '@templates/AddNewItemModal/templates/dataSelectField'
import { useGetDangerHazardsQuery } from '@services/dangerHazards'
import { ReturnType, Setter } from '@app-types/riskMapFields'
import { StateType } from '@modals/RiskMapModal/Dangers/useCommonRiskMapFields'

const useDangerHazardsField = (
  params: Partial<SelectSearchFieldParams>,
  setter: Setter,
  skip?: boolean
): ReturnType<StateType | undefined> => {
  const [filters, setFilters] = useState<StateType | undefined>()

  return {
    setFilters,
    field: () =>
      dataSelectField({
        query: useGetDangerHazardsQuery,
        filters,
        setFilters,
        dropdownWidth: 500,
        label: 'Опасность',
        name: 'hazard',
        withManualEntryText: true,
        withShowLabelInDescription: true,
        onOneItem: setter,
        skip,
        ...params,
      }),
  }
}

export default useDangerHazardsField
