import {
  DetailedHTMLProps,
  FC,
  InputHTMLAttributes,
  MouseEventHandler,
} from 'react'
import { useNavigate } from 'react-router-dom'
import useMe from '@hooks/useMe'
import { Routes } from '@enums/Routes'
import { Course } from '@models/course'
import InfoCounter from '@organisms/InfoCounter'
import { AccordionHeaderProps } from '@organisms/Accordion'
import DownloadButton from '@organisms/DownloadButton'
import { useLazyGetSafeProgramReportQuery } from '@services/courses'
import CourseInfo from '@templates/CourseInfo'
import {
  DownloadReportWrapper,
  LeftWrapper,
  LineWrapper,
  RightContentWrapper,
  StyledSubtitle,
  TitleText,
} from '../../styled'
import { Wrapper } from './styled'
import ArrowRight from './ArrowRight'

type Props = {
  item: Course
  deadlineAt?: string
  createdAt?: string
  withDeadlineBadge?: boolean
} & AccordionHeaderProps &
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

const Header: FC<Props> = ({
  hovered,
  item: {
    safeProgram,
    id: courseId,
    title,
    listenersCount,
    subType,
    hoursCount,
    type,
  },
  deadlineAt,
  createdAt,
  withDeadlineBadge = false,
  ...rest
}) => {
  const [getSafeProgramReport, { isLoading: getSafeProgramReportIsLoading }] =
    useLazyGetSafeProgramReportQuery()

  const handleGetSafeProgramClick: MouseEventHandler = (event) => {
    event.stopPropagation()
    void getSafeProgramReport(courseId)
  }

  const { isEmployee } = useMe()
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(Routes.COURSES + `/${courseId}/education/0`)
  }

  return (
    <Wrapper {...rest} hovered={hovered} onClick={handleClick}>
      <LeftWrapper>
        <LineWrapper>
          <StyledSubtitle>
            <TitleText>{title}</TitleText>
            {!isEmployee && <InfoCounter>{listenersCount}</InfoCounter>}
          </StyledSubtitle>
        </LineWrapper>
        <CourseInfo
          createdAt={createdAt}
          deadlineAt={deadlineAt}
          hoursCount={hoursCount}
          subType={subType}
          type={type}
          withDeadlineBadge={withDeadlineBadge}
        />
      </LeftWrapper>
      <LineWrapper>
        <RightContentWrapper>
          <DownloadReportWrapper>
            {safeProgram && (
              <DownloadButton
                onClick={handleGetSafeProgramClick}
                isLoading={getSafeProgramReportIsLoading}
              >
                Скачать программу
              </DownloadButton>
            )}
          </DownloadReportWrapper>
          <ArrowRight hovered={hovered} />
        </RightContentWrapper>
      </LineWrapper>
    </Wrapper>
  )
}

export default Header
