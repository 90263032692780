import { FC } from 'react'
import CuratorStatus from './CuratorStatus'
import NameWithAvatar from './NameWithAvatar'
import { Wrapper } from './styled'
import Bell from './Bell'

const Header: FC = () => {
  return (
    <Wrapper>
      <Bell />
      <CuratorStatus />
      <NameWithAvatar />
    </Wrapper>
  )
}

export default Header
