import { FC, MouseEvent, MouseEventHandler, ReactNode } from 'react'
import useMouseOverOut from '@hooks/useMouseOverOut'
import Loader from '@atoms/Loader'
import Badge from '@atoms/Badge'
import { SmallInnerWrapper, SmallWrapper, Text, Wrapper } from './styled'

type Props = {
  children?: ReactNode
  isActive?: boolean
  onClick?: MouseEventHandler
  title: string | number
  tabSize: 'big' | 'small'
  badgeColor?: string
  withStopPropagation?: boolean
  isLoading?: boolean
  withoutHover?: boolean
  id: string
  withoutMarginBottom?: boolean
  isWhiteBackground?: boolean
}

const Tab: FC<Props> = ({
  isActive,
  onClick,
  title,
  tabSize,
  badgeColor,
  withStopPropagation = false,
  isLoading = false,
  withoutHover = false,
  id,
  withoutMarginBottom = false,
  isWhiteBackground = false,
}) => {
  const [hovered, , handleMouseEnter, handleMouseLeave] = useMouseOverOut()
  const active = isActive || hovered

  if (tabSize === 'small') {
    return (
      <div key={id}>
        <SmallWrapper
          id={id}
          onClick={(e) => {
            if (withStopPropagation) {
              e.stopPropagation()
            }
            onClick?.(e)
          }}
          selected={active}
          onMouseEnter={withoutHover ? undefined : handleMouseEnter}
          onMouseLeave={withoutHover ? undefined : handleMouseLeave}
          withoutMarginBottom={withoutMarginBottom}
          isWhiteBackground={isWhiteBackground}
          withoutHover={withoutHover}
        >
          <Loader isLoading={isLoading}>
            <SmallInnerWrapper>
              {badgeColor && <Badge badgeColor={badgeColor} />}
              <Text selected={active} isWhiteBackground={isWhiteBackground}>
                {title}
              </Text>
            </SmallInnerWrapper>
          </Loader>
        </SmallWrapper>
      </div>
    )
  }

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      id={id}
    >
      <Wrapper
        isActive={active}
        onClick={(e: MouseEvent) => {
          e.stopPropagation()
          onClick?.(e)
        }}
      >
        <Loader isLoading={isLoading}>{title}</Loader>
      </Wrapper>
    </div>
  )
}

export type { Props as TabProps }
export default Tab
