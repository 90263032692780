import { FC, useEffect, useState } from 'react'
import { useMount, useToggle } from 'react-use'
import { isEmpty } from 'lodash'
import useFormProcessor from '@hooks/useFormProcessor'
import useEmployees from '@hooks/useEmployees'
import { FilterState } from '@hooks/useItemsFilters'
import Modal from '@organisms/Modal'
import { ReactComponent as FilterIconActive } from '@interface-images/filter-icon-active.svg'
import { ReactComponent as FilterIconInactive } from '@interface-images/filter-icon-inactive.svg'
import Select, { Option, SelectProps } from '@organisms/Select'
import AddNewItemModal from '../AddNewItemModal'
import { EnterButtonWrapper, Wrapper } from './styled'
import useEmployeeSelectFields from './useEmployeeSelectFields'

const ICON_SIZE = 25

export type EmployeeSelectFormValues = {
  company?: Option
  filial?: Option
  division?: Option
  position?: Option
}

type Props = {
  initValues?: EmployeeSelectFormValues
} & SelectProps

const EmployeeSelect: FC<Props> = ({ initValues, isDisabled, ...props }) => {
  const { data, setFilters, setPage, isLoading } = useEmployees({
    skip: isDisabled,
  })
  const { items = [], length = 0 } = data || {}

  const [openMenuOnClick, toggleOpenMenuOnClick] = useToggle(true)
  const [visibleModal, toggleVisibleModal] = useToggle(false)
  const [savedInitValues, setSavedInitialValues] =
    useState<EmployeeSelectFormValues>(initValues as EmployeeSelectFormValues)

  const methods = useFormProcessor()
  const fields = useEmployeeSelectFields(methods, initValues)

  const notInitFilters: any = {} // correct
  for (const key in savedInitValues) {
    const typedKey = key as keyof EmployeeSelectFormValues
    const value = savedInitValues[typedKey]

    if (!isEmpty(value) && !initValues?.[typedKey]) {
      notInitFilters[typedKey] = value
    }
  }

  const handleSubmit = (form: EmployeeSelectFormValues) => {
    const filters: FilterState = {}

    if (form.company) {
      filters['companyBranch.company'] = form.company.value
    }

    if (form.filial) {
      filters.companyBranch = form.filial.value
    }

    if (form.division) {
      filters['subdivisionJobPositionRels.subdivision'] = form.division.value
    }

    if (form.position) {
      filters['subdivisionJobPositionRels.jobPosition'] = form.position.value
    }

    setFilters(filters)
    setPage?.(1)
    setSavedInitialValues(form)
    toggleVisibleModal(false)
  }

  useMount(() => {
    if (initValues) {
      for (const key in initValues) {
        const value = initValues[key as keyof EmployeeSelectFormValues]
        methods.setValue(key, value)
      }
      handleSubmit(initValues)
    }
  })

  useEffect(() => {
    if (visibleModal && savedInitValues) {
      setTimeout(() => {
        for (const key in savedInitValues) {
          methods.setValue(
            key,
            savedInitValues[key as keyof EmployeeSelectFormValues]
          )
        }
      })
    }
  }, [visibleModal])

  return (
    <Wrapper>
      <Select
        {...props}
        isDisabled={isDisabled}
        prepareSearch={(value) => value.replace(/[^\d .@a-zа-я]+/g, '')}
        openMenuOnClick={openMenuOnClick}
        isLoading={isLoading}
        length={length}
        setPage={setPage}
        options={items}
        setFilters={setFilters}
        menuPosition="fixed"
        DropdownIndicator={() => (
          <EnterButtonWrapper
            onMouseEnter={() => {
              toggleOpenMenuOnClick(false)
            }}
            onMouseLeave={() => {
              toggleOpenMenuOnClick(true)
            }}
            onClick={() => {
              toggleVisibleModal(true)
              setTimeout(() => {
                toggleOpenMenuOnClick(true)
              }, 100)
            }}
          >
            {!isEmpty(notInitFilters) ? (
              <FilterIconActive height={ICON_SIZE} width={ICON_SIZE} />
            ) : (
              <FilterIconInactive height={ICON_SIZE} width={ICON_SIZE} />
            )}
          </EnterButtonWrapper>
        )}
        hideSeparator
      />
      <Modal visible={visibleModal} onModalClose={toggleVisibleModal}>
        <AddNewItemModal
          saveText="Применить"
          customAddText=" "
          titlePostfix="Сотрудник"
          fields={fields}
          onSubmitForm={handleSubmit}
          methods={methods}
          withStopPropagation
        />
      </Modal>
    </Wrapper>
  )
}

export type EmployeeSelectProps = Props
export default EmployeeSelect
