import { ProgressStatuses } from '@enums/ProgressStatuses'
import { CheckboxProps } from '@organisms/Checkbox'
import {
  FilterProps,
  FilterTypes,
} from '@templates/ListPageLayout/Filters/Filter'

export const CURRENT_STATUS_FILTER: FilterProps<CheckboxProps> = {
  filterType: FilterTypes.CHECKBOX,
  fieldName: 'check',
  fieldNameForSubmit: 'employeeEducationDestinations.currentStatus',
  label: 'Есть незавершенные курсы',
  valueOnChecked: [ProgressStatuses.NOT_STARTED, ProgressStatuses.IN_PROCESS],
}
