import { FC, ReactNode, useEffect, useRef, useState } from 'react'
import { useToggle } from 'react-use'
import { a, useSpring } from '@react-spring/web'
import { ReactComponent as EqualActive } from '@interface-images/equal-blue.svg'
import { ReactComponent as EqualInactive } from '@interface-images/equal-gray.svg'
import { ReactComponent as ArrowDownIconActive } from '@interface-images/select-arrow-down-active.svg'
import { ReactComponent as ArrowDownIcon } from '@interface-images/select-arrow-down-inactive.svg'
import { ReactComponent as ArrowUpIconActive } from '@interface-images/select-arrow-up-active.svg'
import { ReactComponent as ArrowUpIcon } from '@interface-images/select-arrow-up-inactive.svg'
import useMouseOverOut from '@hooks/useMouseOverOut'
import { DEFAULT_ANIMATION_DURATION } from '@const/animation'
import {
  ArrowWrapper,
  BodyWrapper,
  EqualWrapper,
  HeaderWrapper,
  LeftWrapper,
  MainWrapper,
  TitleWrapper,
} from './styled'

const SMALL_HEIGHT = 0

type Props = {
  Body: ReactNode
  height?: number
  title: string
  rightHeaderContent?: ReactNode
  onChangeSize?: () => void
  initialExpanded?: boolean
  onExpanded?: (index: number) => void
  index: number
}

const SubAccordion: FC<Props> = ({
  Body,
  title,
  rightHeaderContent,
  height = 0,
  onChangeSize,
  initialExpanded = false,
  onExpanded,
  index,
}) => {
  const bodyRef = useRef<HTMLDivElement | null>(null)
  const [hovered, , handleMouseEnter, handleMouseLeave] = useMouseOverOut()
  const [expanded, toggleExpanded] = useToggle(initialExpanded)
  const [size, setSize] = useState<number>(height)

  const animation = useSpring({
    height: expanded ? size + 30 : SMALL_HEIGHT,
    config: { duration: DEFAULT_ANIMATION_DURATION },
  })

  const changeSizeSubAccordion = () => {
    if (
      bodyRef?.current?.clientHeight &&
      bodyRef.current.clientHeight !== size
    ) {
      setSize(bodyRef?.current?.clientHeight || height)
    }
  }

  useEffect(() => {
    const intervalId = setInterval(changeSizeSubAccordion, 300)

    return () => clearInterval(intervalId)
  }, [])

  const handleClick = () => {
    onExpanded?.(index)
    toggleExpanded()
    onChangeSize?.()
  }

  let arrowIcon
  if (expanded) {
    arrowIcon = hovered ? <ArrowUpIconActive /> : <ArrowUpIcon />
  } else {
    arrowIcon = hovered ? <ArrowDownIconActive /> : <ArrowDownIcon />
  }

  const equalIcon = expanded ? <EqualActive /> : <EqualInactive />

  return (
    <MainWrapper>
      <HeaderWrapper
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <LeftWrapper>
          <EqualWrapper>{equalIcon}</EqualWrapper>
          <TitleWrapper expanded={expanded}>{title}</TitleWrapper>
        </LeftWrapper>
        <LeftWrapper>
          {rightHeaderContent}
          <ArrowWrapper>{arrowIcon}</ArrowWrapper>
        </LeftWrapper>
      </HeaderWrapper>
      <a.div
        style={{
          ...animation,
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <BodyWrapper ref={(value) => (bodyRef.current = value)}>
          {Body}
        </BodyWrapper>
      </a.div>
    </MainWrapper>
  )
}

export default SubAccordion
