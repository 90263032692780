import { FetchArgs } from '@reduxjs/toolkit/query/react'
import { AuthServerResponse, UpdateAuthBody } from '@models/auth'
import StoreTags from '@enums/StoreTags'
import emptySplitApi from '../api'

const TAGS = [StoreTags.ME]
const BASE_URL = '/api/public'

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getPhoneCall: builder.mutation<AuthServerResponse<number>, string>({
      invalidatesTags: (result) => (result ? TAGS : []),
      query: (phone) => ({
        url: BASE_URL + '/user_validation_codes',
        method: 'POST',
        body: { phone },
      }),
    }),
    postValidationCode: builder.mutation<
      AuthServerResponse<string>,
      { phone: string; code: string }
    >({
      invalidatesTags: (result) => (result ? TAGS : []),
      query: (body): FetchArgs => ({
        url: BASE_URL + '/access_token',
        method: 'POST',
        body,
      }),
    }),
    postLoginAuth: builder.mutation<
      AuthServerResponse<string>,
      { email: string; password: string }
    >({
      invalidatesTags: (result) => (result ? TAGS : []),
      query: (body): FetchArgs => ({
        url: BASE_URL + '/access_token_login',
        method: 'POST',
        body,
      }),
    }),
    sendRecoveryMail: builder.mutation<
      { success: true; type: 'login' | 'email'; curatorName: string },
      { username: string }
    >({
      invalidatesTags: (result) => (result ? TAGS : []),
      query: (body): FetchArgs => ({
        url: BASE_URL + '/users/password_recovery',
        method: 'POST',
        body,
      }),
    }),
    setNewPassword: builder.mutation<
      AuthServerResponse<string>,
      UpdateAuthBody
    >({
      invalidatesTags: (result) => (result ? TAGS : []),
      query: (body): FetchArgs => ({
        url: BASE_URL + '/users/change_password_from_recovery',
        method: 'PUT',
        body,
      }),
    }),
  }),
})

export const {
  useGetPhoneCallMutation,
  usePostValidationCodeMutation,
  usePostLoginAuthMutation,
  useSendRecoveryMailMutation,
  useSetNewPasswordMutation,
} = authApi
