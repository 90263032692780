import { UseFormReturn } from 'react-hook-form'
import { useEffect } from 'react'
import { SelectProps } from '@organisms/Select'
import {
  FilterProps,
  FilterTypes,
} from '@templates/ListPageLayout/Filters/Filter'
import useDangerHazards from '@hooks/useDangerHazards'

const useDangerHazardsFilter = (
  { watch, setValue }: UseFormReturn,
  fieldNameForSubmit?: string
): FilterProps<SelectProps> => {
  const kind: string | undefined = watch('kind')?.value

  const { data, isLoading, setFilters, setPage } = useDangerHazards({
    skip: !kind,
  })
  const { items = [], length = 0 } = data || {}

  useEffect(() => {
    if (kind) {
      setFilters({
        relations: {
          kind,
        },
      })
    }
    setValue('hazard', null)
  }, [kind])

  return {
    isDisabled: !kind,
    options: items,
    length,
    isLoading,
    placeholder: 'Опасность',
    fieldName: 'hazard',
    fieldNameForSubmit,
    filterType: FilterTypes.SELECT_SEARCH,
    setFilters,
    setPage,
    dropdownWidth: 500,
  }
}

export default useDangerHazardsFilter
