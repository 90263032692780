import styled from '@emotion/styled'
import { isMobile } from '@const/device'
import Title from '@organisms/Title'

const DesktopWrapper = styled.div`
  padding: 0 20px;
`
const MobileWrapper = styled(DesktopWrapper)`
  padding: 0;
`
export const Wrapper = isMobile ? MobileWrapper : DesktopWrapper

export const ButtonWrapper = styled(Wrapper)`
  display: inline-block;
  margin-bottom: 10px;
`

const DesktopStyledTitle = styled(Title)`
  width: 100%;
  white-space: break-spaces;
  text-align: left;
`
const MobileStyledTitle = styled(DesktopStyledTitle)`
  text-align: center;
`
export const StyledTitle = isMobile ? MobileStyledTitle : DesktopStyledTitle
