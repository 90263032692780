import { FC, useState } from 'react'
import { Employee } from '@models/employee'
import Accordion, { AccordionHeaderProps } from '@organisms/Accordion'
import Title from '@organisms/Title'
import { ListItemProps } from '@templates/List'
import CompanyInfoField from '@templates/CompanyInfoField'
import FilialInfoField from '@templates/FilialInfoField'
import DivisionInfoField from '@templates/DivisionInfoField'
import PositionInfoField from '@templates/PositionInfoField'
import { formatServerDate } from '@utils/date'
import ManyPositionDivisionPopover from './ManyPositionDivisionPopover'
import {
  DivisionPositionWrapper,
  InactiveDescription,
  TitleWrapper,
  Wrapper,
} from './styled'
import Body from './Body'

const EmployeeItem: FC<ListItemProps<Employee>> = ({
  isLoading,
  handleClickOnItem,
  opened,
  item: {
    id,
    fullName,
    company,
    companyBranch,
    active: activeProp,
    subdivisionJobPositionRels,
    employmentDate,
    birthDate,
  },
}) => {
  const [active, setActive] = useState(0)

  const header: FC<AccordionHeaderProps> = () => (
    <Wrapper>
      <TitleWrapper>
        <Title mySize="h4">{fullName}</Title>
        <InactiveDescription>
          {activeProp ? 'Активен' : 'Деактивирован'}
        </InactiveDescription>
        {birthDate && (
          <InactiveDescription>
            Дата рождения {formatServerDate(birthDate)}
          </InactiveDescription>
        )}
        {employmentDate && (
          <InactiveDescription>
            Дата приема на работу {formatServerDate(employmentDate)}
          </InactiveDescription>
        )}
      </TitleWrapper>
      <DivisionPositionWrapper>
        {company && <CompanyInfoField company={company} />}
        {companyBranch && <FilialInfoField filial={companyBranch} />}
      </DivisionPositionWrapper>
      {subdivisionJobPositionRels?.[0] && (
        <DivisionPositionWrapper>
          <DivisionInfoField
            division={subdivisionJobPositionRels[0].subdivision}
          />
          <PositionInfoField
            position={subdivisionJobPositionRels[0].jobPosition}
          />
          <ManyPositionDivisionPopover
            subdivisionJobPositionRels={subdivisionJobPositionRels}
          />
        </DivisionPositionWrapper>
      )}
    </Wrapper>
  )

  return (
    <Accordion
      skeletonHeight={145}
      handleClickOnHeader={handleClickOnItem}
      expanded={opened}
      initLoading={isLoading}
      Header={header}
      Body={({ onChangeSize, toggleLoading }) => (
        <Body
          active={active}
          setActive={setActive}
          onChangeSize={onChangeSize}
          toggleLoading={toggleLoading}
          id={id}
        />
      )}
    />
  )
}

export default EmployeeItem
