import { omit } from 'lodash'
import StoreTags from '@enums/StoreTags'
import { CommonQueryParams, ItemsFromResponse } from '@models/common'
import {
  CreateEmployeeBody,
  CreateEmployeeResponse,
  CreateEmployeesListsBody,
  Employee,
  EmployeeOptions,
  UpdateEmployeeBody,
  UpdateEmployeeResponse,
  UploadEmployeeStateTable,
} from '@models/employee'
import { createDownloadExcelReportQuery } from '@utils/files'
import {
  LINKS_FOR_PASSWORDS_NAME,
  POSITIONS_REPORT_NAME,
  LINKS_REPORT,
  POSITIONS_REPORT,
} from '@const/reportNames'
import emptySplitApi from '../api'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'

const BASE_URL = '/api/users/employees'

const COMMON_TAGS = [
  StoreTags.USER,
  StoreTags.EMPLOYEES,
  StoreTags.EMPLOYEE,
  StoreTags.COMPANIES,
  StoreTags.COMPANY,
  StoreTags.FILIALS,
  StoreTags.FILIAL,
  StoreTags.DIVISION,
  StoreTags.DIVISIONS,
  StoreTags.POSITION,
  StoreTags.POSITIONS,
  StoreTags.COMPANY_GROUPS,
  StoreTags.COMPANY_GROUP,
  StoreTags.ME,
]

export const employeesApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getEmployees: builder.query<
      { items: EmployeeOptions[]; length: number },
      CommonQueryParams
    >({
      query: (params) => ({
        url: BASE_URL,
        params,
      }),
      transformResponse: (response: ItemsFromResponse<Employee>) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          label: item.fullName,
          value: item['@id'],
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
      providesTags: [StoreTags.EMPLOYEES],
    }),
    createEmployee: builder.mutation<
      CreateEmployeeResponse,
      CreateEmployeeBody
    >({
      invalidatesTags: (result, _, { withoutRefetchTags }) =>
        result && !withoutRefetchTags ? COMMON_TAGS : [],
      query: (body) => ({
        url: BASE_URL,
        method: 'POST',
        body: omit(body, 'withoutRefetchTags'),
      }),
    }),
    updateEmployee: builder.mutation<
      UpdateEmployeeResponse,
      UpdateEmployeeBody
    >({
      invalidatesTags: (result, _, { withoutRefetchTags }) =>
        result && !withoutRefetchTags ? COMMON_TAGS : [],
      query: ({ id, ...body }) => ({
        url: `${BASE_URL}/${id}`,
        method: 'PUT',
        body: omit(body, 'withoutRefetchTags'),
      }),
    }),
    uploadEmployeesStateTable: builder.mutation<void, UploadEmployeeStateTable>(
      {
        query: (body) => ({
          url: `${BASE_URL}/stateTable`,
          method: 'POST',
          body,
        }),
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          await queryFulfilled
          setTimeout(() => {
            dispatch(
              employeesApi.util.invalidateTags([
                StoreTags.EMPLOYEES,
                StoreTags.USER,
                StoreTags.DIVISIONS,
                StoreTags.POSITIONS,
              ])
            )
          }, 1500)
        },
      }
    ),
    getPositionsReport: builder.query<void, CommonQueryParams>({
      query: createDownloadExcelReportQuery(
        `/api/reports/employees/${POSITIONS_REPORT}`,
        POSITIONS_REPORT_NAME + '.xls'
      ),
    }),
    getLoginLinks: builder.query<void, CommonQueryParams>({
      query: createDownloadExcelReportQuery(
        `/api/reports/employees/${LINKS_REPORT}`,
        LINKS_FOR_PASSWORDS_NAME + '.xls'
      ),
    }),
    postEmployeesList: builder.mutation<
      { ['@id']: string },
      CreateEmployeesListsBody
    >({
      query: (body) => ({
        url: '/api/employees_lists',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useGetEmployeesQuery,
  useCreateEmployeeMutation,
  useUpdateEmployeeMutation,
  useUploadEmployeesStateTableMutation,
  useLazyGetPositionsReportQuery,
  usePostEmployeesListMutation,
  useLazyGetLoginLinksQuery,
} = employeesApi
