import { FC } from 'react'
import StyledButton, { StyledButtonProps } from '@organisms/StyledButton'
import ButtonStyle from '@enums/ButtonStyle'
import { DownOutlined } from '@ant-design/icons'
import { ShowMoreButtonWrapper } from './styled'

const DownButton: FC<StyledButtonProps> = (restProps) => {
  return (
    <ShowMoreButtonWrapper>
      <StyledButton
        {...restProps}
        buttonStyle={ButtonStyle.SECONDARY_WITH_WHITE_BACKGROUND}
        rightIcon={<DownOutlined />}
      >
        Показать ещё
      </StyledButton>
    </ShowMoreButtonWrapper>
  )
}

export default DownButton
