import {
  login as storeLogin,
  logout as storeLogout,
} from '@services/auth/slice'
import { useAppDispatch } from './useAppDispatch'
import { useAppSelector } from './useAppSelector'

const useAuth = () => {
  const dispatch = useAppDispatch()
  const auth = useAppSelector((state) => state.auth)

  const login = (jwt: string) => {
    dispatch(storeLogin(jwt))
  }

  const logout = () => {
    dispatch(storeLogout())
    location.reload()
  }

  return {
    auth,
    login,
    logout,
  }
}

export default useAuth
