import { FC } from 'react'
import { useToggle } from 'react-use'
import AddButton from '@organisms/AddButton'
import Modal from '@organisms/Modal'
import ThemeModal from '@modals/ThemeModal'
import { ButtonWrapper } from './styled'

type Props = {
  chapterIri: string
}

const AddThemeButton: FC<Props> = ({ chapterIri }) => {
  const [visible, toggleVisible] = useToggle(false)

  return (
    <div>
      <ButtonWrapper>
        <AddButton onClick={toggleVisible}>Добавить тему</AddButton>
      </ButtonWrapper>
      <Modal visible={visible} onModalClose={toggleVisible}>
        <ThemeModal chapterIri={chapterIri} handleCloseModal={toggleVisible} />
      </Modal>
    </div>
  )
}

export default AddThemeButton
