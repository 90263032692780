import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 23px 28px;
  width: 480px;
  background: #ffffff;
  box-shadow: 5px 5px 20px rgba(29, 78, 137, 0.1);
  border-radius: 24px;
  justify-content: space-between;
`

export const MobileWrapper = styled(Wrapper)`
  width: 90%;
  height: auto;
`

export const LeftWrapper = styled.div`
  display: flex;
  gap: 10px;
`

export const TextWrapper = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`

export const DateTimeWrapper = styled.div`
  margin-top: 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #7b7b7b;
`

export const TextContainer = styled.div`
  max-width: 85%;
`

export const InfoWrapper = styled.div`
  padding-top: 3px;
`

export const CopyLinkWrapper = styled.a`
  margin-top: 10px;
  display: flex;
  align-items: baseline;
  gap: 10px;
  cursor: pointer;
`
