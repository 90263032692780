import styled from '@emotion/styled'
import { isMobile } from '@const/device'

const DesktopWrapper = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.sizes.base};
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
`
const MobileWrapper = styled(DesktopWrapper)`
  padding: 0 10px;
`
export const Wrapper = isMobile ? MobileWrapper : DesktopWrapper

const DesktopItemsWrapper = styled.div`
  display: flex;
`
const MobileItemsWrapper = styled(DesktopItemsWrapper)`
  flex-direction: column;
`
export const ItemsWrapper = isMobile ? MobileItemsWrapper : DesktopItemsWrapper

export const ListWrapper = styled.div`
  margin-bottom: 10px;
`
