import { useState } from 'react'
import { SelectSearchFieldParams } from '@templates/AddNewItemModal/templates/selectSearchField'
import dataSelectField from '@templates/AddNewItemModal/templates/dataSelectField'
import { useGetDangerObjectsQuery } from '@services/dangerObjects'
import { ReturnType, Setter } from '@app-types/riskMapFields'
import { StateType } from '@modals/RiskMapModal/Dangers/useCommonRiskMapFields'

const useDangerObjectsField = (
  params: Partial<SelectSearchFieldParams>,
  setter: Setter,
  skip?: boolean
): ReturnType<StateType | undefined> => {
  const [filters, setFilters] = useState<StateType | undefined>()

  return {
    setFilters,
    field: () =>
      dataSelectField({
        query: useGetDangerObjectsQuery,
        filters,
        setFilters,
        dropdownWidth: 500,
        label: 'Объект возникновения опасности',
        name: 'object',
        withManualEntryText: true,
        withShowLabelInDescription: true,
        onOneItem: setter,
        skip,
        ...params,
      }),
  }
}

export default useDangerObjectsField
