import styled from '@emotion/styled'

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
`

export const Container = styled.div<{ isLast: boolean }>`
  padding-bottom: ${({ isLast }) => (isLast ? '0px' : '25px')};
  border-bottom: ${({ isLast }) => (isLast ? 'none' : '1.5px solid #C4C4C4')};
`

export const BadgeWrapper = styled.div`
  margin-bottom: 15px;
`
