import CuratorStatuses from '@enums/CuratorStatuses'

export type CuratorStatusValue = {
  label: string
  value: CuratorStatuses
  badgeColor: string
}

const CURATOR_STATUS_OPTIONS: CuratorStatusValue[] = [
  {
    label: 'На связи',
    value: CuratorStatuses.ACTIVE,
    badgeColor: 'green',
  },
  {
    label: 'В отпуске',
    value: CuratorStatuses.SICK_LEAVE,
    badgeColor: 'gray',
  },
  {
    label: 'На больничном',
    value: CuratorStatuses.VACATION,
    badgeColor: 'red',
  },
]

export default CURATOR_STATUS_OPTIONS
