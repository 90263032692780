import { useEffect } from 'react'
import { RiskMapDetailed } from '@models/riskMap'
import { useAppDispatch } from '@hooks/useAppDispatch'
import { setSelectedMeresForms } from '@services/riskMapModal'
import { formatServerDate } from '@utils/date'
import {
  MereFormItem,
  MeresFieldsFormValues,
} from './Dangers/DangersFields/DangersFieldsBlock/MeresFields'

const useSetDefaultMeres = (riskMap: RiskMapDetailed | undefined) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!riskMap?.dangers) return

    const selectedMeresForms = riskMap.dangers.reduce((acc, item, index) => {
      const meres = item.riskMapDangerControlMeasures.reduce(
        (
          accumulator,
          {
            kind,
            content,
            fundingSource,
            plannedCompletionDate,
            responsiblePerson,
          }
        ) => {
          accumulator.push({
            mereKind: kind
              ? {
                  ...kind,
                  label: kind.value,
                  value: kind.value,
                }
              : undefined,
            content: content
              ? {
                  ...content,
                  label: content.value,
                  value: content.value,
                }
              : undefined,
            source: fundingSource,
            deadline: plannedCompletionDate
              ? formatServerDate(plannedCompletionDate)
              : undefined,
            responsible: responsiblePerson
              ? {
                  ...responsiblePerson,
                  label: responsiblePerson.fullName,
                  value: responsiblePerson['@id'],
                }
              : undefined,
          } as MereFormItem)

          return accumulator
        },
        [] as MereFormItem[]
      )

      acc[index] = {
        prefilled: true,
        meres,
      }

      return acc
    }, {} as Record<number, MeresFieldsFormValues>)

    dispatch(setSelectedMeresForms(selectedMeresForms))
  }, [riskMap])
}

export default useSetDefaultMeres
