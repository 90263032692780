import styled from '@emotion/styled'
import { isMobile } from '@const/device'

export const ErrorsWrapper = styled.div`
  margin-bottom: 20px;
  width: ${() => (isMobile ? 'auto' : '40vw')};
`

export const ButtonWrapper = styled.div`
  display: inline-block;
`
