import { FC, MouseEventHandler } from 'react'
import DownloadButton from '@organisms/DownloadButton'
import { useLazyGetRiskMapReportQuery } from '@services/riskMaps'

type Props = {
  index: number
  id: number
}

const DownloadReportRisk: FC<Props> = ({ index, id }) => {
  const [getReport, { isLoading }] = useLazyGetRiskMapReportQuery()

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    void getReport(id)
  }

  return (
    <DownloadButton
      isLoading={isLoading}
      onClick={handleClick}
      id={`risk-map-report-${index}`}
    >
      Скачать отчет "Карта рисков"
    </DownloadButton>
  )
}

export default DownloadReportRisk
