import { dateField } from '@templates/AddNewItemModal/templates'
import { Fields } from '@templates/AddNewItemModal/Content'
import { isBeforeTodayValidation } from '@utils/validation'

const useApproveModalFields = (): Fields => {
  return [
    {
      fields: [
        dateField({
          name: 'date',
          label: 'Дата утверждения',
          validate: isBeforeTodayValidation,
        }),
      ],
    },
  ]
}

export default useApproveModalFields
