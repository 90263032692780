import { ReactNode } from 'react'
import { FilialLimitHistoryOption } from '@models/filialLimitHistories'
import { formatServerDate } from '@utils/date'

type TableItem = {
  title: ReactNode
  count: ReactNode
  startAt: ReactNode
  endAt: ReactNode
  key: string
}

export const getTableData = (
  items: FilialLimitHistoryOption[],
  filialTitle: string
) => {
  const tableData: TableItem[] = []
  for (const {
    oldLimit = 0,
    newLimit = 0,
    newEndAt,
    oldEndAt,
    newStartAt,
    oldStartAt,
  } of items) {
    const newLimitCalc = newLimit || 0
    const oldLimitCalc = oldLimit || 0
    const endAtCalc = newEndAt === oldEndAt ? oldEndAt : newEndAt
    const startAtCalc = newStartAt === oldStartAt ? oldStartAt : newStartAt

    const data: Omit<TableItem, 'key'> = {
      title: filialTitle,
      count:
        newLimitCalc - oldLimitCalc === 0
          ? oldLimitCalc
          : newLimitCalc - oldLimitCalc,
      endAt: endAtCalc ? formatServerDate(endAtCalc) : 'NULL',
      startAt: startAtCalc ? formatServerDate(startAtCalc) : 'NULL',
    }

    tableData.push({
      ...data,
      key: JSON.stringify(data),
    })
  }

  return tableData
}
