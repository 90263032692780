import { FC, ReactNode } from 'react'
import Prompt from '@organisms/Prompt'
import {
  FieldWrapper,
  InlineLabelWrapper,
  Label,
  LabelWrapper,
  PromptWrapper,
  RightLabel,
  Wrapper,
} from './styled'

type Props = {
  label: string
  field: ReactNode
  style?: Record<string, unknown>
  widthMultiplier?: number
  withoutFixedHeight?: boolean
  promptText?: ReactNode
  fixedLabelHeight?: boolean
  id?: string
  withRightLabel?: boolean
}

const FormField: FC<Props> = ({
  label,
  field,
  style,
  widthMultiplier = 1,
  withoutFixedHeight = false,
  promptText,
  fixedLabelHeight = false,
  withRightLabel = false,
  id,
}) => {
  if (withRightLabel) {
    return (
      <InlineLabelWrapper id={id}>
        <div>{field}</div>
        {label && (
          <div>
            <RightLabel>{label}</RightLabel>
            {promptText && (
              <PromptWrapper>
                <Prompt promptText={promptText} />
              </PromptWrapper>
            )}
          </div>
        )}
      </InlineLabelWrapper>
    )
  }

  return (
    <Wrapper
      id={id}
      style={style}
      widthMultiplier={widthMultiplier}
      withoutFixedHeight={withoutFixedHeight}
    >
      {label && (
        <LabelWrapper fixedLabelHeight={fixedLabelHeight}>
          <Label>{label}</Label>
          {promptText && (
            <PromptWrapper>
              <Prompt promptText={promptText} />
            </PromptWrapper>
          )}
        </LabelWrapper>
      )}
      <FieldWrapper>{field}</FieldWrapper>
    </Wrapper>
  )
}

export default FormField
