import { FC } from 'react'
import { FooterProps } from '@templates/AddNewItemModalLayout/Footer'
import { Routes } from '@enums/Routes'
import JobsFooter from '@templates/JobsFooter'
import { EmployeeDetailed } from '@models/employee'
import { EmployeeEducationDetailed } from '@models/employeeEducation'
import { WhomAssignee } from '@enums/WhomAssignee'

const Footer: FC<FooterProps> = (props) => {
  return (
    <JobsFooter
      {...props}
      additionalProps={{
        createOptions: [
          {
            label: 'Компанию',
            value: Routes.COMPANY_MODAL,
          },
          {
            label: 'Филиал',
            value: Routes.FILIAL_MODAL,
          },
          {
            label: 'Подразделение',
            value: Routes.DIVISION_MODAL,
          },
          {
            label: 'Должность',
            value: Routes.POSITION_MODAL,
          },
        ],
        nextRoute: Routes.EDUCATION_MODAL,
        nextRouteDescription: 'Переход к созданию обучения',
        getNextState: (
          data: EmployeeDetailed
        ): Partial<EmployeeEducationDetailed> => ({
          whomAssign: WhomAssignee.EMPLOYEE,
          employees: [
            {
              ...data,
              id: data['@id'] as unknown as number,
            },
          ],
        }),
      }}
    />
  )
}

export default Footer
