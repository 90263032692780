import { FC, useEffect } from 'react'
import { useMount } from 'react-use'
import useCourseThemes from '@hooks/useCourseThemes'
import { createInitFilter } from '@utils/filters'
import { AccordionBodyProps } from '@organisms/Accordion'
import Onboarding from '@templates/Onboarding'
import OnboardingCodes from '@enums/OnboardingCodes'
import Theme from './Theme'
import { ONBOARDING_STEPS, ONBOARDING_TITLE } from './onboarding'

type Props = {
  id: number
  themeNumber: number
  courseIri: string
  educationId: number
} & AccordionBodyProps

const Body: FC<Props> = ({
  id,
  onChangeSize,
  toggleLoading,
  themeNumber,
  courseIri,
  educationId,
}) => {
  const { data, refetch } = useCourseThemes({
    ...createInitFilter('courseChapter', id),
    ...(+educationId
      ? createInitFilter(
          'forEmployeeEducation',
          '/api/employee_education_destinations/' + educationId
        )
      : {}),
  })
  const { items = [] } = data || {}

  useMount(() => {
    toggleLoading(true)
    refetch()
  })

  useEffect(() => {
    if (data) {
      toggleLoading(false)
      onChangeSize()
    }
  }, [data])

  return (
    <>
      <Onboarding
        firstStepTitle={ONBOARDING_TITLE}
        steps={ONBOARDING_STEPS}
        code={OnboardingCodes.my_education_chapter}
      />
      <div id="chapter">
        {items.map((item, index) => (
          <Theme
            number={`${themeNumber}.${index + 1}`}
            {...item}
            key={item.id}
            courseIri={courseIri}
            educationId={educationId}
          />
        ))}
      </div>
    </>
  )
}

export default Body
