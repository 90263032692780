import { FC, ReactNode } from 'react'
import { HeaderWrapper, StyledTitle } from './styled'

type Props = {
  isEdit?: boolean
  titlePostfix?: string
  customAddText?: string
  additionalTitleContent?: ReactNode
  customFullTitle?: string
}

const Header: FC<Props> = ({
  isEdit,
  customAddText,
  titlePostfix,
  additionalTitleContent,
  customFullTitle,
}) => {
  return (
    <HeaderWrapper hasAdditionalTitleContent={!!additionalTitleContent}>
      {customFullTitle ? (
        <StyledTitle>{customFullTitle}</StyledTitle>
      ) : (
        <StyledTitle>
          {isEdit ? 'Редактировать ' : customAddText || 'Добавить '}
          {titlePostfix}
        </StyledTitle>
      )}
      {additionalTitleContent && <>{additionalTitleContent}</>}
    </HeaderWrapper>
  )
}

export type { Props as HeaderProps }
export default Header
