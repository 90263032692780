import { FC } from 'react'
import { Wrapper } from './styled'

type Props = {
  length: number
}

const Counter: FC<Props> = ({ length }) => {
  return <Wrapper>{length > 99 ? '99+' : length}</Wrapper>
}

export default Counter
