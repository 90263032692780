import styled from '@emotion/styled'
import { isMobile } from '@const/device'

export const Wrapper = styled.div`
  cursor: pointer;
  border: 1px solid #c4c4c4;
  padding: 32px;
  gap: 32px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  width: ${() => (isMobile ? '100%' : '320px')};
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  .circle {
    border-radius: 50%;
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f6fa;
  }

  :hover {
    background-color: #f2f6fa;
    border: 1px solid transparent;

    .circle {
      background-color: white;
    }
  }
`
