import * as React from 'react'
import { FC } from 'react'
import Table, { Column } from '@organisms/Table'
import { ListPageLayoutListProps } from '@templates/ListPageLayout'
import { TableWrapper } from './styled'

const COLUMNS: Column[] = [
  {
    title: 'Действия',
    dataIndex: 'actions',
  },
  {
    title: '№ РМ',
    dataIndex: 'number',
  },
  {
    title: 'Наименование профессии (должности) работника',
    dataIndex: 'title',
  },
  {
    title: '№ карты СОУТ',
    dataIndex: 'soutNumber',
  },
  {
    title: 'Дата карты',
    dataIndex: 'date',
  },
  {
    title: 'Подразделение',
    dataIndex: 'division',
  },
  {
    title: 'Должность',
    dataIndex: 'position',
  },
  {
    title: 'Итоговый класс УТ',
    dataIndex: 'classUt',
  },
  {
    title: 'Химический',
    dataIndex: 'chemical',
  },
  {
    title: 'Биологический',
    dataIndex: 'biological',
  },
  {
    title: 'Аэрозоли преимущественно фиброгенного действия',
    dataIndex: 'aero',
  },
  {
    title: 'ШУМ',
    dataIndex: 'shum',
  },
  {
    title: 'Инфразвук',
    dataIndex: 'infraSound',
  },
  {
    title: 'Ультразвук воздушный',
    dataIndex: 'ultraSoundAero',
  },
  {
    title: 'Вибрация общая',
    dataIndex: 'commonVibration',
  },
  {
    title: 'Вибрация локальная',
    dataIndex: 'localVibration',
  },
  {
    title: 'Неионизирующие излучения',
    dataIndex: 'nonIon',
  },
  {
    title: 'Ионизирующие излучения',
    dataIndex: 'ion',
  },
  {
    title: 'Параметры микроклимата',
    dataIndex: 'microClimateParams',
  },
  {
    title: 'Параметры световой среды',
    dataIndex: 'lightParams',
  },
  {
    title: 'Тяжесть трудового процесса',
    dataIndex: 'workProcessHeaving',
  },
  {
    title: 'Напряженность трудового процесса',
    dataIndex: 'workProcessStrangeness',
  },
]

type DataItem = {
  key: string
  actions: string
  number: string
  title: string
  soutNumber: string
  date: string
  division: string
  position: string
  classUt: string
  chemical: string
  biological: string
  aero: string
  shum: string
  infraSound: string
  ultraSoundAero: string
  commonVibration: string
  localVibration: string
  nonIon: string
  ion: string
  microClimateParams: string
  lightParams: string
  workProcessHeaving: string
  workProcessStrangeness: string
}

const WorkPlacesTable: FC<ListPageLayoutListProps> = ({ list }) => {
  const tableData: DataItem[] = []
  for (const item of list) {
    console.log(item)
    tableData.push({
      key: JSON.stringify(item),
      actions: '1',
      number: '1',
      title: '1',
      soutNumber: '1',
      date: '1',
      division: '1',
      position: '1',
      classUt: '1',
      chemical: '1',
      biological: '1',
      aero: '1',
      shum: '1',
      infraSound: '1',
      ultraSoundAero: '1',
      commonVibration: '1',
      localVibration: '1',
      nonIon: '1',
      ion: '1',
      microClimateParams: '1',
      lightParams: '1',
      workProcessHeaving: '1',
      workProcessStrangeness: '1',
    })
  }

  return (
    <TableWrapper>
      <Table columns={COLUMNS} data={tableData} />
    </TableWrapper>
  )
}

export default WorkPlacesTable
