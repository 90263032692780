import { createSlice } from '@reduxjs/toolkit'
import OnboardingCodes from '@enums/OnboardingCodes'

export type State = {
  started: OnboardingCodes | null
}

const initialState: State = {
  started: null,
}

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    startOnboarding: (state, { payload }) => {
      state.started = payload
    },
    finishOnboarding: (state) => {
      state.started = null
    },
  },
})

export const { startOnboarding, finishOnboarding } = onboardingSlice.actions

export default onboardingSlice.reducer
