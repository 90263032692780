import { useUpdateEmployeeMutation } from '@services/employees'
import { UpdateEmployeeBody } from '@models/employee'
import useMe from './useMe'

type ReturnType = [(partialMe: Partial<UpdateEmployeeBody>) => void, any]

const useUpdateMe = (): ReturnType => {
  const { id, meUser: data } = useMe()

  const [updateEmployee, updateData] = useUpdateEmployeeMutation()

  const updateMe = (partialMe: Partial<UpdateEmployeeBody>) => {
    if (!partialMe) return

    void updateEmployee({
      companyGroup: data.companyGroup?.['@id'],
      companyBranch: data.companyBranch?.['@id'],
      fullName: data.fullName,
      phone: data.phone,
      email: data.email || undefined,
      birthDate: data.birthDate || undefined,
      employmentDate: data.employmentDate || undefined,
      education: data.education || undefined,
      nextBodyCheckDate: data.nextBodyCheckDate || undefined,
      comment: data.comment || undefined,
      inn: data.inn || undefined,
      insuranceNumber: data.insuranceNumber || undefined,
      subdivisionJobPositionRels:
        data.subdivisionJobPositionRels?.map((item) => ({
          subdivision: item.subdivision['@id'],
          jobPosition: item.jobPosition['@id'],
        })) || undefined,
      employeeProbationSheets:
        data.employeeProbationSheets?.map((item) => ({
          title: item.title,
          issueDate: item.issueDate,
          responsibleOfProbation: item.responsibleOfProbation?.['@id'],
          probationShiftCount: item.probationShiftCount,
          probationStartAt: item.probationStartAt,
          probationEndAt: item.probationEndAt,
          probationSender: item.probationSender?.['@id'],
          probationReason: item.probationReason,
          probationConclusion: item.probationConclusion,
          workPermittedAt: item.workPermittedAt,
          probationSheetQuestions:
            item.probationSheetQuestions?.map((it) => ({
              question: it.question,
              date: it.date,
            })) || undefined,
        })) || undefined,
      curatorStatus: data.curatorStatus,
      id,
      userConfig: data.userConfig,
      ...partialMe,
    })
  }

  return [updateMe, updateData]
}

export default useUpdateMe
