import { isEqual, pick } from 'lodash'
import { log } from '@utils/console'

export const memoEqual = (prevProps: any, nextProps: any): boolean => {
  const fieldsToEqual: string[] = []

  for (const key in prevProps) {
    if (typeof prevProps[key] !== 'function') {
      fieldsToEqual.push(key)
    }
  }

  const equal = isEqual(
    pick(prevProps, fieldsToEqual),
    pick(nextProps, fieldsToEqual)
  )

  if (window.ENABLE_LOGS_MEMO && !equal) {
    log('=========')
    log('prevProps', pick(prevProps, fieldsToEqual))
    log('nextProps', pick(nextProps, fieldsToEqual))
  }

  return equal
}
