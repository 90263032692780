import styled from '@emotion/styled'
import { isMobile } from '@const/device'

export const HeaderWrapper = styled.div<{ hovered: boolean }>`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  gap: 8px;
  align-items: center;

  color: ${({ hovered }) => (hovered ? '#1D4E89' : '#7b7b7b')};
`

export const ExpandedContent = styled.div`
  padding: 24px 22px;
  border-radius: ${() => (isMobile ? '16px' : '32px')};
  background-color: #f2f6fa;
  margin-left: -15px;
`
