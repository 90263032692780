import { FC } from 'react'
import useFormProcessor from '@hooks/useFormProcessor'
import {
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
} from '@services/companies'
import { CommonCompanyBody, CompanyDetailed } from '@models/company'
import AddNewItemModal from '@templates/AddNewItemModal'
import CuratorsMultilineFields from '@templates/CuratorsMultilineFields'
import { Option } from '@organisms/Select'
import { getMultilineValue } from '@utils/form'
import { File } from '@models/file'
import getDefaultValues from './getDefaultValues'
import useCompanyFields from './useCompanyFields'
import Footer from './Footer'

export type FormValues = {
  title: string
  fullTitle: string
  subscriptionsCount?: string
  logo?: File
  curators: Option[]
  forcePasswordChange?: boolean
  description?: string
}

type Props = {
  isEdit?: boolean
  handleCloseModal: () => void
  company?: CompanyDetailed
}

const CompanyModal: FC<Props> = ({ isEdit, company, handleCloseModal }) => {
  const [create, createData] = useCreateCompanyMutation()
  const [update, updateData] = useUpdateCompanyMutation()
  const additionalProps = isEdit ? updateData : createData

  const fields = useCompanyFields()
  const methods = useFormProcessor({
    apiErrors: additionalProps.error,
    defaultValues: getDefaultValues(company),
  })

  const forcePasswordChange = methods.watch('forcePasswordChange')

  const handleSubmitForm = (form: FormValues) => {
    const commonForm: CommonCompanyBody = {
      title: form.title,
      fullTitle: form.fullTitle,
      curators: getMultilineValue(form.curators),
      subscriptionsCount: form.subscriptionsCount
        ? +form.subscriptionsCount
        : undefined,
      logo: form.logo?.['@id'],
      forcePasswordChange: !!forcePasswordChange,
      description: form.description,
    }

    if (isEdit) {
      if (company) {
        update({
          ...commonForm,
          id: company.id,
        })
      }
    } else {
      create(commonForm)
    }
  }

  const companyValue = methods.watch('company')

  return (
    <AddNewItemModal
      {...additionalProps}
      CustomFooter={isEdit ? undefined : Footer}
      titlePostfix="компанию"
      fields={fields}
      onSubmitForm={handleSubmitForm}
      methods={methods}
      contentAfterFields={
        <CuratorsMultilineFields
          curatorFieldLabel="Куратор компании"
          initValues={{ company: companyValue }}
        />
      }
      handleCloseModal={handleCloseModal}
      isEdit={isEdit}
      withoutAddMore
    />
  )
}

export default CompanyModal
