import styled from '@emotion/styled'
import { isMobile } from '@const/device'
import Title from '@organisms/Title'

const DesktopWrapper = styled.div`
  margin-top: -200px;
  width: 100%;
  padding: 0 20px 30px;
`
const MobileWrapper = styled(DesktopWrapper)`
  padding: 0;
`
export const Wrapper = isMobile ? MobileWrapper : DesktopWrapper

const DesktopSubWrapper = styled.div`
  background: ${({ theme }) => theme.colors.background.surface};
  border-radius: 30px;
  padding: 50px 20px 50px 220px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 150px;
  overflow-y: hidden;
`
const MobileSubWrapper = styled(DesktopSubWrapper)`
  padding: 10px 20px;
  flex-direction: column;
  height: 350px;
`
export const SubWrapper = isMobile ? MobileSubWrapper : DesktopSubWrapper

const DesktopBearWrapper = styled.div`
  position: relative;
  z-index: 200;
  top: 155px;
  padding-left: 50px;
  max-width: 300px;
`
const MobileBearWrapper = styled(DesktopBearWrapper)`
  top: 350px;
  padding-left: 10px;
`
export const BearWrapper = isMobile ? MobileBearWrapper : DesktopBearWrapper

export const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-top: 12px;
`

export const StyledTitle = styled(Title)`
  margin-bottom: 0;
`

export const BlueTitle = styled(StyledTitle)`
  color: ${({ theme }) => theme.colors.main};
  margin-left: 5px;
`

const DesktopTitleWrapper = styled.div`
  display: flex;
`
const MobileTitleWrapper = styled(DesktopTitleWrapper)`
  margin-top: -45px;
`
export const TitleWrapper = isMobile ? MobileTitleWrapper : DesktopTitleWrapper

const DesktopContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-left: 40px;
`
const MobileContentWrapper = styled(DesktopContentWrapper)`
  flex-direction: column;
  margin-top: -150px;
`
export const ContentWrapper = isMobile
  ? MobileContentWrapper
  : DesktopContentWrapper

const DesktopButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
const MobileButtonsWrapper = styled(DesktopButtonsWrapper)`
  margin-top: 15px;
`
export const ButtonsWrapper = isMobile
  ? MobileButtonsWrapper
  : DesktopButtonsWrapper

const DesktopMedButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 26px;
  border: 1px dashed #c4c4c4;
  border-radius: 30px;
`
const MobileMedButtonWrapper = styled(DesktopMedButtonWrapper)`
  padding: 10px 15px;
`
export const MedButtonWrapper = isMobile
  ? MobileMedButtonWrapper
  : DesktopMedButtonWrapper
