import styled from '@emotion/styled'
import Title from '@organisms/Title'

export const FooterWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 25px;
`

export const StyledTitle = styled(Title)`
  margin: 20px 0;
`
