import WHOM_ASSIGNEE_ITEMS from '@const/whomAssignee'
import ASSIGN_TYPES from '@const/assignTypes'
import { EmployeeEducationDetailed } from '@models/employeeEducation'
import { formatServerDate } from '@utils/date'
import setDefaultCommissionMembers from '@templates/CommissionMemberMultilineFields/setDefaultCommissionMembers'
import { FormValues } from './index'

const getDefaultValues = (
  employeeEducation?: EmployeeEducationDetailed
  // eslint-disable-next-line sonarjs/cognitive-complexity
): Partial<FormValues> | void => {
  if (!employeeEducation) return

  const result: Partial<FormValues> = {
    title: employeeEducation.title,
    localActName: employeeEducation.localActName,
    protocolNumber: employeeEducation.protocolNumber,
    trainingReason: employeeEducation.trainingReason,
    workPlace: employeeEducation.workPlace || undefined,
    employerInn: employeeEducation.employerInn || undefined,
    educationOrganization: employeeEducation.educationOrganization || undefined,
    educationOrganizationInn:
      employeeEducation.educationOrganizationInn || undefined,
    repeatEducationAtMonth: employeeEducation.repeatEducationAtMonth
      ? employeeEducation.repeatEducationAtMonth.toString()
      : undefined,
  }

  if (employeeEducation.deadlineAt) {
    result.deadlineAt = formatServerDate(employeeEducation.deadlineAt)
  }

  if (employeeEducation.protocolDate) {
    result.protocolDate = formatServerDate(employeeEducation.protocolDate)
  }

  if (employeeEducation.whomAssign) {
    const findedAssign = WHOM_ASSIGNEE_ITEMS.find(
      (whom) => whom.value === employeeEducation.whomAssign
    )
    if (findedAssign) {
      result.whomAssign = findedAssign
    }
  }

  if (employeeEducation.type) {
    const findedType = ASSIGN_TYPES.find(
      (assignType) => assignType.value === employeeEducation.type
    )
    if (findedType) {
      result.type = findedType
    }
  }

  if (employeeEducation.course) {
    result.course = {
      label: employeeEducation.course.title,
      value: employeeEducation.course['@id'],
    }
  }

  if (employeeEducation.conductsBriefing) {
    result.conductsBriefing = {
      ...employeeEducation.conductsBriefing,
      value: employeeEducation.conductsBriefing['@id'],
      label: employeeEducation.conductsBriefing.fullName,
    }
  }

  if (employeeEducation.company) {
    result.company = {
      ...employeeEducation.company,
      label: employeeEducation.company.title,
      value: employeeEducation.company['@id'],
    }
  }

  if (employeeEducation.companyBranch) {
    result.filial = {
      ...employeeEducation.companyBranch,
      label: employeeEducation.companyBranch.title,
      value: employeeEducation.companyBranch['@id'],
    }
  }

  if (employeeEducation.subdivision) {
    result.division = {
      ...employeeEducation.subdivision,
      label: employeeEducation.subdivision.title,
      value: employeeEducation.subdivision['@id'],
    }
  }

  if (employeeEducation.jobPosition) {
    result.position = {
      ...employeeEducation.jobPosition,
      label: employeeEducation.jobPosition.title,
      value: employeeEducation.jobPosition['@id'],
    }
  }

  if (employeeEducation.employees) {
    result.employees = employeeEducation.employees?.map((item) => ({
      label: item.fullName,
      value: item['@id'],
    }))
  }

  return setDefaultCommissionMembers(
    result,
    employeeEducation.commissionHead,
    employeeEducation.commissionMembers
  )
}

export default getDefaultValues
