import { JoyStep } from '@templates/Onboarding'
import { InfoCircleTwoTone } from '@ant-design/icons'

const TITLE = 'Создание карты рисков'

export const ONBOARDING_TITLE = 'Расскажем как создать карту рисков'

export const getOnboardingSteps = (onTabOpen: () => void): JoyStep[] => [
  {
    target: '#risk-map-for',
    title: TITLE,
    content: (
      <div>
        <div>Выберете для какой должности будет создана карта рисков.</div>
        <p>
          <div>
            <InfoCircleTwoTone style={{ color: 'white' }} /> Для должности
            должна быть только одна активная карта рисков.
          </div>
          <div>
            <InfoCircleTwoTone style={{ color: 'white' }} /> Карта рисков станет
            доступной для сотрудников после её отправки на ознакомление.
          </div>
        </p>
      </div>
    ),
  },
  {
    target: '#risk-map-info',
    title: TITLE,
    content: 'Заполните данные о карте рисков',
  },
  {
    target: '#risk-map-file',
    title: TITLE,
    content: (
      <div>
        <div>Вы сможете скачать карту рисков после её заполнения.</div>
        <div>После утверждения, загрузите подписанный скан в это поле.</div>
        <div>
          <InfoCircleTwoTone style={{ color: 'white' }} /> Карту рисков можно
          отправить на ознакомление сотрудникам только после загрузки файла
          карты рисков и её утверждения.
        </div>
        <div>
          <InfoCircleTwoTone style={{ color: 'white' }} /> Сотрудники увидят в
          личном кабинете только номер карты рисков, должность и загруженный
          сюда файл.
        </div>
        <div>
          <InfoCircleTwoTone style={{ color: 'white' }} /> Если карта рисков
          изменится, то вы сможете заменить файл карты рисков через функционал
          "Пересмотреть" в раскрытой карточке.
        </div>
      </div>
    ),
  },
  {
    target: '#dangers-tab',
    title: TITLE,
    content: 'Перейдите к заполнению опасностей и рисков.',
    onOpen: onTabOpen,
  },
  // ======
  {
    target: '#select-dangers',
    title: TITLE,
    content: (
      <div>
        <div>
          Вы можете выбрать опасности из общего списка, указав должность, вид
          опасности, объект или Место/Аварию/Работу.
        </div>
        <div>
          <InfoCircleTwoTone style={{ color: 'white' }} /> Вы сможете выбрать
          должности похожие со своей. Тогда будут добавлены рекомендуемые для
          них опасности.
        </div>
      </div>
    ),
  },
  {
    target: '#risk-map-danger-fields-0',
    title: TITLE,
    content: 'Заполните данные об опасности.',
  },
  {
    target: '#risk-map-significance',
    title: TITLE,
    content:
      'Значимость риска считается автоматически на основании введённых данных и матрицы оценки риска.',
  },
  {
    target: '#risk-map-danger-add',
    title: TITLE,
    content:
      'Вы можете создавать неограниченное число опасностей и удалять лишние.',
  },
  {
    target: '#risk-map-danger-delete',
    title: TITLE,
    content:
      'Вы можете создавать неограниченное число опасностей и удалять лишние.',
  },
  {
    target: '#risk-map-danger-fill-meres',
    title: TITLE,
    content: (
      <div>
        <div>
          После сохранения карты к опасностям будут автоматически добавлены
          рекомендуемые меры.
        </div>
        <div>
          Вы сможете отредактировать меры, назначить ответственных и сроки.
        </div>
        <div>
          <InfoCircleTwoTone style={{ color: 'white' }} />
          При необходимости, вы можете ввести собственные значения в мерах.
        </div>
      </div>
    ),
  },
  {
    target: '#risk-map-danger-fill-protectionMean',
    title: TITLE,
    content: (
      <div>
        <div>Заполните средства индивидуальной защиты для опасностей.</div>
        <div>
          Чтобы были доступны средства индивидуальной защиты, заполняйте
          опасности с типом наименования “Только по ЕТН”.
        </div>
        <div>
          При необходимости, вы можете ввести собственные значения в СИЗ.
        </div>
      </div>
    ),
  },
  {
    target: '#modal-save',
    title: 'Сохраните карту рисков',
    content: '',
  },
]
