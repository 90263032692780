import * as React from 'react'
import { FC } from 'react'
import AddNewItemModal from '@templates/AddNewItemModal'
import { useUpdateDivisionMutation } from '@services/divisions'
import useFormProcessor from '@hooks/useFormProcessor'
import { DivisionDetailed, UpdateDivisionBody } from '@models/division'
import RiskMemberMultilineFields from '@templates/RiskMemberMultilineFields'
import { User } from '@models/user'
import getDefaultValues from '@modals/DivisionModal/getDefaultValues'
import { getMultilineValue } from '@utils/form'

export type FormValues = {
  riskMapCommissionMembers?: User[]
}

type Props = {
  divisionInfo: DivisionDetailed
}

const EditDivisionBlock: FC<Props> = ({ divisionInfo }) => {
  const [update, updateData] = useUpdateDivisionMutation()

  const methods = useFormProcessor({
    apiErrors: updateData.error,
    defaultValues: getDefaultValues(divisionInfo),
  })

  const handleSubmitForm = (form: FormValues) => {
    if (!divisionInfo) {
      return
    }

    const formBody: UpdateDivisionBody = {
      companyBranch: divisionInfo.companyBranch['@id'],
      curators: getMultilineValue(divisionInfo.curators),
      title: divisionInfo.title,
      id: divisionInfo.id,
      initialBriefing: divisionInfo.initialBriefing?.['@id'],
      initiatoryBriefing: divisionInfo.initiatoryBriefing?.['@id'],
      safeProgram: divisionInfo.safeProgram?.['@id'],
      responsibleOfProbation: divisionInfo.responsibleOfProbation?.['@id'],
      responsibleOfSafeProgram: divisionInfo.responsibleOfSafeProgram?.['@id'],
      riskMapCommissionMembers: getMultilineValue(
        form.riskMapCommissionMembers
      ),
    }

    update(formBody)
  }

  return (
    <AddNewItemModal
      {...updateData}
      onSubmitForm={handleSubmitForm}
      methods={methods}
      fields={[]}
      customFullTitle=""
      successContentAddTitle="Настройки подразделения успешно обновлены"
      withNotificationBanner
      contentAfterFields={
        <>
          <RiskMemberMultilineFields />
        </>
      }
      withoutReset
    />
  )
}

export default EditDivisionBlock
