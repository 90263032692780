import styled from '@emotion/styled'

export const Container = styled.div`
  position: absolute;
  z-index: 500;
  background-color: white;
  padding: 25px 10px;
  width: 100%;
  height: 65px;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
`

export const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  & > * {
    background-color: #f2f6fa;
    border-radius: 24px;
    padding: 12px;
    height: 48px;
    align-items: center;
    display: flex;
  }
`
