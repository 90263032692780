import { fileField } from '@templates/AddNewItemModal/templates'

const FILE_FORMATS = ['png', 'svg', 'jpg']

const companyLogoField = fileField({
  name: 'logo',
  label: 'Логотип',
  fileName: FILE_FORMATS.join(' '),
  formats: FILE_FORMATS,
  maxSizeMegaBytes: 10,
})

export default companyLogoField
