import { FC } from 'react'
import EditPasswordBlock from './EditPasswordBlock'
import { Wrapper } from './styled'
import EditProfileBlock from './EditProfileBlock'

const Profile: FC = () => {
  return (
    <Wrapper>
      <EditProfileBlock />
      <EditPasswordBlock />
    </Wrapper>
  )
}

export default Profile
