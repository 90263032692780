import { Dispatch, MouseEventHandler, SetStateAction } from 'react'
import { useToggle } from 'react-use'

const useMouseOverOut = (params?: {
  onMouseOver?: MouseEventHandler
  onMouseDown?: MouseEventHandler
  isLoading?: boolean
}): [
  boolean,
  Dispatch<SetStateAction<boolean>>,
  MouseEventHandler,
  MouseEventHandler
] => {
  const { onMouseOver, onMouseDown, isLoading } = params || {}
  const [hovered, toggleHovered] = useToggle(false)

  const handleMouseEnter: MouseEventHandler = (e) => {
    if (!isLoading) {
      toggleHovered(true)
      onMouseOver?.(e)
    }
  }

  const handleMouseLeave: MouseEventHandler = (e) => {
    if (!isLoading) {
      toggleHovered(false)
      onMouseDown?.(e)
    }
  }

  return [hovered, toggleHovered, handleMouseEnter, handleMouseLeave]
}

export default useMouseOverOut
