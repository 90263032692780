import { JoyStep } from '@templates/Onboarding'

export const ONBOARDING_TITLE = 'Расскажем про заполнение стажировочных листов'

export const ONBOARDING_STEPS: JoyStep[] = [
  {
    target: '#content',
    title: 'Заполнение стажировочных листов',
    content: 'Заполните данные по стажировочным листам сотрудника',
  },
  {
    target: '#modal-footer',
    title: 'Дальнейшие действия',
    content: 'Перейдите к одному из следующих шагов',
  },
  {
    target: '#modal-save-and-close',
    title: 'Сохраните и вернитесь к списку сотрудников',
    content: '',
  },
  {
    target: '#modal-save-and-add-more',
    title: 'Сохраните этого сотрудника и добавьте ещё одного',
    content: '',
  },
  {
    target: '#modal-next',
    title: 'Перейдите к созданию обучения',
    content: '',
  },
]
