import { FC, MouseEventHandler } from 'react'
import { Skeleton } from 'antd'
import useMouseOverOut from '@hooks/useMouseOverOut'
import { Routes } from '@enums/Routes'
import { Icon } from '@app-types/common'
import { StyledReactSVG, Text, Wrapper } from './styled'

const ICON_SIZE = 18

export enum LinkStyle {
  DEFAULT,
  GRAY,
  GRAY_UPPERCASE,
}

type Props = {
  isActive?: boolean
  icon?: {
    ActiveIcon: Icon
    InactiveIcon: Icon
  }
  onClick?: MouseEventHandler
  text: string
  linkStyle?: LinkStyle
  fontSize?: number
  route?: Routes
  isLogout?: boolean
  isLoading?: boolean
}

const Link: FC<Props> = ({
  isActive = false,
  icon,
  onClick,
  text,
  linkStyle = LinkStyle.GRAY,
  fontSize,
  isLoading = false,
}) => {
  const { ActiveIcon, InactiveIcon } = icon || {}

  const [hovered, , handleMouseEnter, handleMouseLeave] = useMouseOverOut()

  const iconProps = {
    width: ICON_SIZE,
    height: ICON_SIZE,
  }

  if (isLoading) {
    return (
      <Skeleton.Button
        active
        style={{
          height: 15,
          width: 200,
          borderRadius: 50,
        }}
      />
    )
  }

  return (
    <Wrapper
      key={text}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {!!ActiveIcon && !!InactiveIcon && (
        <StyledReactSVG>
          {isActive || hovered ? (
            <ActiveIcon {...iconProps} />
          ) : (
            <InactiveIcon {...iconProps} />
          )}
        </StyledReactSVG>
      )}
      <Text linkStyle={linkStyle} isMatch={isActive} fontSize={fontSize}>
        {text}
      </Text>
    </Wrapper>
  )
}

export default Link
