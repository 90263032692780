import { FC } from 'react'
import { Tab, TabsItem } from '@organisms/Tabs'
import EditModal from '../../EditModal'

export type EditFieldTabItemProps = TabsItem & {
  editModal?: {
    Component: FC<any> // correct
    props: any // correct
    onOpen?: () => void | Promise<void>
  }
  onClick?: () => void
}

type Props = {
  item: EditFieldTabItemProps
}

const FieldTab: FC<Props> = ({ item }) => {
  if (!item.editModal) {
    return <Tab {...item} tabSize="small" />
  }

  return (
    <EditModal
      CustomEditComponent={({ onClick }) => (
        <Tab {...item} onClick={onClick} tabSize="small" />
      )}
      ModalContent={item.editModal.Component}
      modalProps={item.editModal.props}
      onOpen={item.editModal.onOpen}
    />
  )
}

export default FieldTab
