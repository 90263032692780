export enum ReviewStatuses {
  REVIEWED = 'REVIEWED',
  NOT_REVIEWED = 'NOT_REVIEWED',
  ALL = 'ALL',
}

export const REVIEW_STATUS_OPTIONS = [
  {
    label: 'Ознакомлен',
    value: ReviewStatuses.REVIEWED,
  },
  {
    label: 'Не ознакомлен',
    value: ReviewStatuses.NOT_REVIEWED,
  },
  {
    label: 'Все',
    value: ReviewStatuses.ALL,
  },
]
