import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import useFormProcessor from '@hooks/useFormProcessor'
import {
  useCreateFilialMutation,
  useUpdateFilialMutation,
} from '@services/filials'
import useMe from '@hooks/useMe'
import { CommonFilialBody, FilialDetailed } from '@models/filial'
import AddNewItemModal from '@templates/AddNewItemModal'
import { Option } from '@organisms/Select'
import CommissionMemberMultilineFields from '@templates/CommissionMemberMultilineFields'
import CuratorsMultilineFields from '@templates/CuratorsMultilineFields'
import RiskMemberMultilineFields from '@templates/RiskMemberMultilineFields'
import OnboardingCodes from '@enums/OnboardingCodes'
import { User } from '@models/user'
import { getMultilineValue } from '@utils/form'
import getDefaultValues from './getDefaultValues'
import useFilialFields from './useFilialFields'
import Footer from './Footer'
import { ONBOARDING_STEPS, ONBOARDING_TITLE } from './onboarding'

export type FormValues = {
  curators: Option[]
  company: Option
  commissionHead: Option
  commissionMembers: Option[]
  riskMapCommissionMembers?: User[]
  title: string
  fullTitle: string
  subscriptionStartAt: string
  subscriptionEndAt: string
  subscriptionsCount: string
  inn: string
  protocolOrderDate?: string
  protocolOrderNumber?: string
}

type Props = {
  isEdit?: boolean
  handleCloseModal: () => void
  filial?: FilialDetailed
}

const FilialModal: FC<Props> = ({ isEdit, filial, handleCloseModal }) => {
  const { state } = useLocation()
  const { isAdmin, isCompanyGroupCurator, company: meCompany } = useMe()
  const [create, createData] = useCreateFilialMutation()
  const [update, updateData] = useUpdateFilialMutation()
  const additionalProps = isEdit ? updateData : createData

  const methods = useFormProcessor({
    apiErrors: additionalProps.error,
    defaultValues: getDefaultValues(state || filial),
  })
  const fields = useFilialFields(methods)

  const handleSubmitForm = (form: FormValues) => {
    const commonForm: CommonFilialBody = {
      title: form.title,
      fullTitle: form.fullTitle,
      company:
        isAdmin || isCompanyGroupCurator
          ? form.company?.value
          : meCompany['@id'],
      subscriptionsCount: form.subscriptionsCount
        ? +form.subscriptionsCount
        : null,
      subscriptionStartAt: form.subscriptionStartAt || null,
      subscriptionEndAt: form.subscriptionEndAt || null,
      curators: getMultilineValue(form.curators),
      inn: form.inn || null,
      commissionMembers: getMultilineValue(form.commissionMembers),
      commissionHead: form.commissionHead?.value || null,
      riskMapCommissionMembers: getMultilineValue(
        form.riskMapCommissionMembers
      ),
      protocolOrderDate: form.protocolOrderDate,
      protocolOrderNumber: form.protocolOrderNumber,
    }

    if (isEdit) {
      if (filial) {
        update({
          ...commonForm,
          id: filial.id,
        })
      }
    } else {
      create(commonForm)
    }
  }

  const company = isAdmin ? methods.watch('company') : meCompany

  return (
    <AddNewItemModal
      {...additionalProps}
      CustomFooter={isEdit ? undefined : Footer}
      titlePostfix="филиал"
      fields={fields}
      onSubmitForm={handleSubmitForm}
      methods={methods}
      withSaveAndAddMore
      contentAfterFields={
        <>
          <CuratorsMultilineFields
            curatorFieldLabel="Куратор филиала"
            initValues={{ company }}
          />
          <CommissionMemberMultilineFields />
          <RiskMemberMultilineFields />
        </>
      }
      handleCloseModal={handleCloseModal}
      isEdit={isEdit}
      withoutAddMore
      onboarding={{
        steps: ONBOARDING_STEPS,
        firstStepTitle: ONBOARDING_TITLE,
        code: OnboardingCodes.branch_create,
        withScroll: true,
        withFeedback: true,
      }}
    />
  )
}

export default FilialModal
