import styled from '@emotion/styled'
import { isMobile } from '@const/device'

export const Wrapper = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 36px;
  margin-left: 10px;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`

const DesktopNumber = styled.div`
  margin-right: 30px;
  width: 20px;
`

const MobileNumber = styled(DesktopNumber)`
  margin-right: 20px;
  width: 20px;
`
export const Number = isMobile ? MobileNumber : DesktopNumber
