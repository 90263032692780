import { JoyStep } from '@templates/Onboarding'

export const ONBOARDING_TITLE = 'Расскажем про раздел моё обучение'

const TITLE = 'Моё обучение'

export const ONBOARDING_STEPS: JoyStep[] = [
  {
    target: '#list',
    title: TITLE,
    content: 'Здесь вы будете видеть все назначенные вам обучения',
  },
  {
    target: '#deadlineBlock',
    title: TITLE,
    content:
      'Здесь вы будете видеть, когда вам назначили обучение и есть ли рекомендуемая дата завершения',
  },
  {
    target: '#myEducation0',
    title: TITLE,
    content: 'Чтобы перейти к обучению, нажмите на него',
  },
]
