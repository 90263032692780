import { Option } from '@organisms/Select'

const COURSE_FREE_FILTERS: Option[] = [
  {
    value: 'true',
    label: 'Бесплатный',
  },
  {
    value: 'false',
    label: 'Платный',
  },
]

export default COURSE_FREE_FILTERS
