import { FC } from 'react'
import useTariffEnrollmentTabs from '@hooks/useTariffEnrollmentTabs'
import ListPage from '@templates/ListPage'
import EnrollmentItem from './EnrollmentItem'

const EnrollmentsHistory: FC = () => {
  const titleTabs = useTariffEnrollmentTabs()

  return (
    <ListPage
      ItemComponent={EnrollmentItem}
      selectedTab={1}
      titleTabs={titleTabs}
      list={[{}]}
      length={1}
    />
  )
}

export default EnrollmentsHistory
