import StoreTags from '@enums/StoreTags'
import { CommonQueryParams, ItemsFromResponse } from '@models/common'
import { Notification, NotificationOption } from '@models/notification'
import { Me } from '@models/me'
import emptySplitApi from '../api'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'

const BASE_URL = '/api/user_notifications'

export const notificationsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getUnreadNotificationsCount: builder.query<number, void>({
      query: () => ({
        url: '/api/users/me',
      }),
      transformResponse: (response: Me) => response.unreadNotificationsCount,
    }),
    getNotifications: builder.query<
      {
        items: NotificationOption[]
        length: number
      },
      CommonQueryParams
    >({
      providesTags: [StoreTags.NOTIFICATIONS],
      async queryFn(params, _queryApi, _extraOptions, fetchWithBQ) {
        const res = await fetchWithBQ({
          url: BASE_URL,
          params,
        })
        const response = res.data as ItemsFromResponse<Notification>

        const items: NotificationOption[] = []
        for (const notification of response[HYDRA_MEMBER]) {
          items.push({
            ...notification,
            value: notification['@id'],
            label: notification.title,
          })
          if (!notification.readAt) {
            fetchWithBQ({
              url: `${BASE_URL}/${notification.id}`,
              method: 'PUT',
            })
          }
        }

        return {
          data: {
            items,
            length: response[HYDRA_TOTAL_ITEMS],
          },
        }
      },
    }),
  }),
})

export const { useGetNotificationsQuery, useGetUnreadNotificationsCountQuery } =
  notificationsApi
