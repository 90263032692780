import { FC } from 'react'
import { ReactComponent as ArrowRightBlack } from '@interface-images/arrow-right-gray.svg'
import { ReactComponent as ArrowRightWhite } from '@interface-images/arrow-right-white.svg'
import { ArrowWrapper, Square } from './styled'

type Props = {
  hovered: boolean
}

const ArrowRight: FC<Props> = ({ hovered }) => {
  const ArrowRight = hovered ? ArrowRightWhite : ArrowRightBlack

  return (
    <ArrowWrapper>
      <Square hovered={hovered}>
        <ArrowRight width={15} height={15} />
      </Square>
    </ArrowWrapper>
  )
}

export default ArrowRight
