import Input, { InputProps } from '@organisms/Input'
import { FieldForRender, FieldParams } from '..'

type Params = {
  isLoading?: boolean
  componentProps?: InputProps
} & FieldParams

const linkField = (params?: Params): FieldForRender => {
  const paramsObj = params || ({} as Params)
  const {
    name,
    label,
    isLoading,
    widthMultiplier = 1,
    componentProps: props,
  } = paramsObj

  const componentProps: InputProps = {
    ...props,
    placeholder: 'Ссылка',
    isLoading,
    withoutDefaultSizes: widthMultiplier > 1,
  }

  return {
    ...paramsObj,
    name: name || 'link',
    label: label || 'Ссылка',
    rules: {
      pattern: {
        value:
          /(http|ftp|https):\/\/([\w-]+(?:\.[\w-]+)+)([\w#%&+,./:=?@^~-]*[\w#%&+/=?@^~-])/,
        message: 'Некорректная ссылка',
      },
    },
    Component: Input,
    componentProps,
    widthMultiplier,
  }
}

export default linkField
