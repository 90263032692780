import StoreTags from '@enums/StoreTags'
import { CommonQueryParams, ItemsFromResponse } from '@models/common'
import {
  Instruction,
  InstructionDetailed,
  InstructionOption,
} from '@models/instruction'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'
import emptySplitApi from '../api'

const BASE_URL = '/api/instructions'

export const instructionsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getInstructions: builder.query<
      { items: InstructionOption[]; length: number },
      CommonQueryParams
    >({
      query: (params) => ({
        url: BASE_URL,
        params,
      }),
      transformResponse: (response: ItemsFromResponse<Instruction>) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          value: item['@id'],
          label: item.title,
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
      providesTags: [StoreTags.INSTRUCTIONS],
    }),
    getInstruction: builder.query<InstructionDetailed, string>({
      providesTags: [StoreTags.INSTRUCTION],
      query: (code) => `${BASE_URL}/${code}`,
    }),
  }),
})

export const { useGetInstructionsQuery, useGetInstructionQuery } =
  instructionsApi
