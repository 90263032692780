import { FC } from 'react'
import usePositions from '@hooks/usePositions'
import useFormProcessor from '@hooks/useFormProcessor'
import ListPage from '@templates/ListPage'
import { ALPHABETICAL_SORTER } from '@const/sorters'
import PositionModal from '@modals/PositionModal'
import Onboarding from '@templates/Onboarding'
import OnboardingCodes from '@enums/OnboardingCodes'
import Position from './Position'
import usePositionFilters from './usePositionsFilters'
import { ONBOARDING_STEPS, ONBOARDING_TITLE } from './onboarding'

const Positions: FC = () => {
  const methods = useFormProcessor()
  const filters = usePositionFilters(methods)
  const { data, ...rest } = usePositions()
  const { items = [], length = 0 } = data || {}

  return (
    <>
      <Onboarding
        firstStepTitle={ONBOARDING_TITLE}
        steps={ONBOARDING_STEPS}
        code={OnboardingCodes.job_position_section}
      />
      <ListPage
        {...rest}
        methods={methods}
        ModalContent={PositionModal}
        titleTabs={[
          {
            title: 'Должности',
            counter: length,
            index: 0,
          },
        ]}
        ItemComponent={Position}
        sorters={[ALPHABETICAL_SORTER]}
        filters={filters}
        list={items}
        length={length}
        withSearch
        resetOnboardingCodes={[
          OnboardingCodes.job_position_section,
          OnboardingCodes.job_position_create,
        ]}
      />
    </>
  )
}

export default Positions
