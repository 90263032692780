import { Spin } from 'antd'
import { FC, ReactNode } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { useTheme } from '@emotion/react'

type Props = {
  isLoading: boolean
  color?: string
  fontSize?: number
  children: ReactNode
}

const Loader: FC<Props> = ({ children, isLoading, color, fontSize }) => {
  const {
    colors: { main },
  } = useTheme()

  return (
    <Spin
      indicator={
        <LoadingOutlined
          style={{
            color: color || main,
            fontSize,
          }}
          spin
        />
      }
      spinning={isLoading}
    >
      {children}
    </Spin>
  )
}

export default Loader
