import { FC } from 'react'
import useMe from '@hooks/useMe'
import useFormProcessor from '@hooks/useFormProcessor'
import AddNewItemModal from '@templates/AddNewItemModal'
import ContentBlock from '@organisms/ContentBlock'
import getDefaultValues from '@modals/EmployeeModal/getDefaultValues'
import { User } from '@models/user'
import useUpdateMe from '@hooks/useUpdateMe'
import useProfileFields from './useProfileFields'

export type FormValues = {
  email: string
  fullName: string
  phone: string
}

const EditProfileBlock: FC = () => {
  const me = useMe()
  const fields = useProfileFields()

  const [updateMe, updateData] = useUpdateMe()

  const methods = useFormProcessor({
    apiErrors: updateData.error,
    defaultValues: getDefaultValues(me as unknown as User),
  })

  const handleSubmitForm = (form: FormValues) => {
    updateMe({
      email: form.email || null,
      fullName: form.fullName,
      phone: form.phone || null,
    })
  }

  return (
    <ContentBlock>
      <AddNewItemModal
        {...updateData}
        onSubmitForm={handleSubmitForm}
        methods={methods}
        fields={fields}
        isEdit
        titlePostfix="профиль"
        successContentAddTitle=""
        successContentEditTitle=""
        withSaveAndAddMore
        withNotificationBanner
        withoutReset
        minHeightVh={15}
      />
    </ContentBlock>
  )
}

export default EditProfileBlock
