import * as React from 'react'
import { FC, useEffect } from 'react'
import { FieldValues, FormProvider } from 'react-hook-form'
import { SubmitHandler } from 'react-hook-form/dist/types/form'
import useFormProcessor from '@hooks/useFormProcessor'
import { usePostValidationCodeMutation } from '@services/auth/api'
import BackButton from '@organisms/BackButton'
import Field from '@templates/Field'
import { maskedField } from '@templates/AddNewItemModal/templates'
import { StyledSubtitle } from '../../../styled'
import { BackButtonContainer } from './styled'

const CODE_FIELD_NAME = 'code'

type Props = {
  handleBack: () => void
  submittedNumber?: string
}

const CodeForm: FC<Props> = ({ submittedNumber, handleBack }) => {
  const [postValidationCode, { error, isLoading }] =
    usePostValidationCodeMutation()

  const methods = useFormProcessor({ apiErrors: error })
  const { clearErrors, handleSubmit, watch } = methods

  const onSubmit: SubmitHandler<FieldValues> = (form) => {
    if (submittedNumber) {
      postValidationCode({
        phone: submittedNumber,
        code: form[CODE_FIELD_NAME],
      })
    }
  }

  const code = watch(CODE_FIELD_NAME)

  const codeField = maskedField({
    isRequired: true,
    label: 'Ваш пароль',
    mask: '0000',
    placeholder: '____',
    name: CODE_FIELD_NAME,
    isLoading,
    widthMultiplier: 1.2,
  })

  useEffect(() => {
    if (code?.length === 4) {
      clearErrors()
      handleSubmit(onSubmit)()
    }
  }, [code])

  if (!submittedNumber) return null

  return (
    <FormProvider {...methods}>
      <BackButtonContainer>
        <BackButton onClick={handleBack} type="button" />
      </BackButtonContainer>
      <StyledSubtitle isLast>Пароль получите у Вашего куратора</StyledSubtitle>
      <Field {...codeField} />
    </FormProvider>
  )
}

export default CodeForm
