import { FC } from 'react'
import useFormProcessor from '@hooks/useFormProcessor'
import StaffingModal from '@templates/StaffingModal'
import { useUploadEmployeesStateTableMutation } from '@services/employees'
import { UploadEmployeeStateTable } from '@models/employee'
import { Option } from '@organisms/Select'
import OnboardingCodes from '@enums/OnboardingCodes'
import { File } from '@models/file'
import useManyEmployeesFields from './useManyEmployeesFields'
import { ONBOARDING_STEPS, ONBOARDING_TITLE } from './onboarding'

type FormValues = {
  company: Option
  file: File
  filial: Option
}

type Props = {
  handleCloseModal: () => void
}

const AddManyEmployees: FC<Props> = ({ handleCloseModal }) => {
  const methods = useFormProcessor()
  const fields = useManyEmployeesFields(methods)
  const [uploadStateTable, uploadInfo] = useUploadEmployeesStateTableMutation()

  const handleSubmit = (form: FormValues) => {
    const submitData: UploadEmployeeStateTable = {
      file: form.file?.['@id'],
      company: form.company?.value,
      companyBranch: form.filial?.value,
    }

    void uploadStateTable(submitData)
  }

  return (
    <StaffingModal
      {...uploadInfo}
      onSuccess={handleCloseModal}
      fields={fields}
      methods={methods}
      handleSubmit={handleSubmit}
      onboarding={{
        firstStepTitle: ONBOARDING_TITLE,
        steps: ONBOARDING_STEPS,
        code: OnboardingCodes.employee_state_table_upload,
      }}
    />
  )
}

export default AddManyEmployees
