import { FC } from 'react'
import useFilials from '@hooks/useFilials'
import ListPage from '@templates/ListPage'
import { CREATED_AT_SORTER } from '@const/sorters'
import FilialModal from '@modals/FilialModal'
import Onboarding from '@templates/Onboarding'
import { ONBOARDING_STEPS, ONBOARDING_TITLE } from '@pages/Filials/onboarding'
import OnboardingCodes from '@enums/OnboardingCodes'
import Filial from './Filial'
import useFilialsFilters from './useFilialsFilters'

const Filials: FC = () => {
  const filters = useFilialsFilters()
  const { data, ...rest } = useFilials()
  const { items = [], length = 0 } = data || {}

  return (
    <>
      <Onboarding
        firstStepTitle={ONBOARDING_TITLE}
        steps={ONBOARDING_STEPS}
        code={OnboardingCodes.branch_section}
      />
      <ListPage
        {...rest}
        titleTabs={[
          {
            title: 'Филиалы',
            counter: length,
            index: 0,
          },
        ]}
        ModalContent={FilialModal}
        ItemComponent={Filial}
        list={items}
        length={length}
        filters={filters}
        sorters={[CREATED_AT_SORTER]}
        withSearch
        resetOnboardingCodes={[
          OnboardingCodes.branch_section,
          OnboardingCodes.branch_create,
        ]}
      />
    </>
  )
}

export default Filials
