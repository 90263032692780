import { FC } from 'react'
import Prompt from '@organisms/Prompt'
import Badge from '@organisms/Badge'
import { Wrapper } from './styled'

const Reviewed: FC = () => {
  return (
    <Wrapper>
      <Badge badgeColor="green" />
      <span>Ознакомлен</span>
      <Prompt promptText="Вы ознакомились с картой рисков" width={300} />
    </Wrapper>
  )
}

export default Reviewed
