import { FC, memo } from 'react'
import StyledButton, { StyledButtonProps } from '@organs/StyledButton'
import ButtonStyle from '@enums/ButtonStyle'

type Props = Omit<StyledButtonProps, 'buttonStyle'>

const GrayButton: FC<Props> = (props) => (
  <StyledButton {...props} buttonStyle={ButtonStyle.GRAY} />
)

export type { Props as GrayButtonButtonProps }
export default memo(GrayButton)
