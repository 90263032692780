import { DetailedHTMLProps, FC, InputHTMLAttributes, ReactNode } from 'react'
import { Default, Description, Error, Tag, Warning } from './styled'

const COMPONENTS_MAP = new Map([
  ['default', Default],
  ['tag', Tag],
  ['error', Error],
  ['warning', Warning],
  ['description', Description],
])

type Props = {
  children: ReactNode
  type?: 'default' | 'tag' | 'error' | 'warning' | 'description'
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

const Text: FC<Props> = ({ type = 'default', children, ...rest }) => {
  const Component = COMPONENTS_MAP.get(type) || Default

  return <Component {...rest}>{children}</Component>
}

export default Text
