import {
  EMAIL_VALIDATION_RULE,
  REQUIRED_VALIDATION_RULE,
} from '@const/validation'
import Input, { InputProps } from '@organisms/Input'
import { FieldForRender, FieldParams } from '..'

type Params = {
  name?: string
  big?: boolean
  componentProps?: InputProps
  placeholder?: string
} & Partial<Omit<FieldParams, 'name'>>

const emailField = (params?: Params): FieldForRender => {
  const paramsObj = params || ({} as Params)
  const {
    name,
    componentProps: props,
    isRequired,
    label,
    big,
    rules,
    placeholder = 'Адрес электронной почты',
  } = paramsObj

  const calcLabel = label || 'Электронная почта'

  const componentProps: InputProps = {
    ...props,
    placeholder,
    big,
  }

  return {
    ...paramsObj,
    name: name || 'email',
    label: isRequired ? calcLabel + '*' : calcLabel,
    rules: {
      ...EMAIL_VALIDATION_RULE,
      ...(isRequired ? REQUIRED_VALIDATION_RULE : {}),
      ...rules,
    },
    Component: Input,
    componentProps,
    emptyValue: '',
  }
}

export default emailField
