import { FC } from 'react'
import StyledButton, { StyledButtonProps } from '@organs/StyledButton'
import ButtonStyle from '@enums/ButtonStyle'

type Props = Omit<StyledButtonProps, 'buttonStyle'>

const WarningButton: FC<Props> = (props) => (
  <StyledButton {...props} buttonStyle={ButtonStyle.WARNING} />
)

export default WarningButton
