import { FC, useState } from 'react'
import { useLocation } from 'react-router-dom'
import InitForm from './InitForm'
import PhoneAuthForm from './PhoneAuthForm'
import EmailAuthForm from './EmailAuthForm'
import NewPasswordForm from './NewPasswordForm'

export enum AuthMethod {
  PHONE,
  EMAIL,
}

const AuthBlock: FC = () => {
  const { search } = useLocation()
  const initialMethod =
    search && search.includes('loginWithEmailRecovery')
      ? AuthMethod.EMAIL
      : null
  const [selectedMethod, setSelectedMethod] = useState<AuthMethod | null>(
    initialMethod
  )

  const handleBack = () => {
    setSelectedMethod(null)
  }

  if (selectedMethod === AuthMethod.PHONE) {
    return <PhoneAuthForm onBack={handleBack} />
  } else if (selectedMethod === AuthMethod.EMAIL) {
    return <EmailAuthForm onBack={handleBack} />
  }

  if (search && search.includes('passwordRecoveryChange')) {
    return <NewPasswordForm setSelectedMethod={setSelectedMethod} />
  }

  return <InitForm setSelectedMethod={setSelectedMethod} />
}

export default AuthBlock
