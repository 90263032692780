import styled from '@emotion/styled'

export const Wrapper = styled.div`
  width: 100%;
`

export const EnterButtonWrapper = styled.span`
  cursor: pointer;
  padding-right: 6px;
  padding-top: 6px;
`
