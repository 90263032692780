import styled from '@emotion/styled'

export const TabsWrapper = styled.div`
  padding-top: 15px;
  display: flex;
  flex-wrap: wrap;
`

export const Wrapper = styled.div`
  margin-top: 22px;
`
