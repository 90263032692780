import { UseFormReturn } from 'react-hook-form'
import useFilials from '@hooks/useFilials'
import { createInitFilter } from '@utils/filters'
import { Company } from '@models/company'
import useJobCleaner from './useJobCleaner'
import useHasJobs from './useHasJobs'
import { JobData } from './useJobDataForFields'

const useFilialsJobData = (
  methods: UseFormReturn,
  companiesJobData: JobData | null
): JobData | null => {
  const { watch } = methods
  const company: Company | undefined = watch('company')

  const { hasFilial, hasCompany } = useHasJobs()

  useJobCleaner(methods)

  const filialsResponse = useFilials({
    skip: hasCompany && !company,
    ...(company?.id
      ? { initFilters: createInitFilter('company', company?.id) }
      : {}),
  })
  const { items = [], length = 0 } = filialsResponse.data || {}
  const filialsIsLoading = filialsResponse.isLoading

  if (!hasFilial) {
    return null
  }

  return {
    isDisabled: hasCompany && (!company || !!companiesJobData?.isLoading),
    options: items,
    length,
    isLoading: filialsIsLoading,
    setPage: filialsResponse.setPage,
    setFilters: filialsResponse.setFilters,
  }
}

export default useFilialsJobData
