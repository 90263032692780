import { isEmpty } from 'lodash'
import {
  EmployeeProbationSheet,
  ProbationSheetQuestion,
} from '@models/employee'
import { Division } from '@models/division'
import { BaseApiResponse } from '@models/common'
import {
  EMPLOYEE_PROBATION_INDEX_SEPARATOR,
  EMPLOYEE_PROBATION_PREFIX,
} from './useStaffingFields'
import {
  MULTILINE_PREFIX,
  MULTILINE_SEPARATOR,
} from './IntershipProgramTable/const'
import { FormValues } from './index'

const probationSheetQuestionPrefix =
  EMPLOYEE_PROBATION_PREFIX + MULTILINE_PREFIX + 'probationSheetQuestions'
const getNameWithoutListActivePrefixes = (formKey: string) =>
  formKey.slice(
    probationSheetQuestionPrefix.length + 1,
    formKey.length - EMPLOYEE_PROBATION_INDEX_SEPARATOR.length - 1
  )

const getProbationSheetField = (name: string, postfix: string) =>
  EMPLOYEE_PROBATION_PREFIX +
  name +
  EMPLOYEE_PROBATION_INDEX_SEPARATOR +
  postfix

const getProbationSheetQuestions = (
  form: FormValues,
  postfix: string
): ProbationSheetQuestion[] => {
  const probationSheetQuestions = [] as ProbationSheetQuestion[]

  const probationSheetQuestionsFilledPostfixes = ['']
  for (const formKey in form) {
    if (
      formKey.startsWith(probationSheetQuestionPrefix) &&
      formKey.endsWith(postfix)
    ) {
      const withoutPrefix = getNameWithoutListActivePrefixes(formKey)
      const questionPostfix = withoutPrefix.split(MULTILINE_SEPARATOR)[1]

      if (probationSheetQuestionsFilledPostfixes.includes(questionPostfix)) {
        continue
      }

      probationSheetQuestionsFilledPostfixes.push(questionPostfix)

      const question =
        form[
          probationSheetQuestionPrefix +
            '_question' +
            MULTILINE_SEPARATOR +
            questionPostfix +
            EMPLOYEE_PROBATION_INDEX_SEPARATOR +
            postfix
        ]
      const date =
        form[
          probationSheetQuestionPrefix +
            '_date' +
            MULTILINE_SEPARATOR +
            questionPostfix +
            EMPLOYEE_PROBATION_INDEX_SEPARATOR +
            postfix
        ]

      const newQuestion: ProbationSheetQuestion = {
        question,
        date,
      }

      if (question && date) {
        probationSheetQuestions.push(newQuestion)
      }
    }
  }

  return probationSheetQuestions
}

const getProbationSheetFieldString = (
  form: FormValues,
  postfix: string,
  key: string
): string | undefined => {
  return form[getProbationSheetField(key, postfix)]
}

export const getEmployeeProbationSheetsFromForm = (
  form: FormValues
): EmployeeProbationSheet[] => {
  const employeeProbationSheets: EmployeeProbationSheet[] = []
  const handledEmployeeProbationSheetIndexes = ['']
  for (const item in form) {
    if (item.startsWith(EMPLOYEE_PROBATION_PREFIX)) {
      const postfix = item.split(EMPLOYEE_PROBATION_INDEX_SEPARATOR)[1]
      if (handledEmployeeProbationSheetIndexes.includes(postfix)) {
        continue
      }

      handledEmployeeProbationSheetIndexes.push(postfix)

      const responsibleOfProbation: string | undefined =
        form[getProbationSheetField('responsibleOfProbation', postfix)]?.value
      const probationSender: string | undefined =
        form[getProbationSheetField('probationSender', postfix)]?.value
      const probationShiftCount: number | undefined =
        form[getProbationSheetField('probationShiftCount', postfix)]

      const probationSheetQuestions = getProbationSheetQuestions(form, postfix)

      employeeProbationSheets.push({
        title: getProbationSheetFieldString(form, postfix, 'title'),
        issueDate: getProbationSheetFieldString(form, postfix, 'issueDate'),
        probationStartAt: getProbationSheetFieldString(
          form,
          postfix,
          'probationStartAt'
        ),
        probationEndAt: getProbationSheetFieldString(
          form,
          postfix,
          'probationEndAt'
        ),
        probationReason: getProbationSheetFieldString(
          form,
          postfix,
          'probationReason'
        ),
        probationConclusion: getProbationSheetFieldString(
          form,
          postfix,
          'probationConclusion'
        ),
        workPermittedAt: getProbationSheetFieldString(
          form,
          postfix,
          'workPermittedAt'
        ),
        responsibleOfProbation,
        probationSender,
        probationShiftCount: probationShiftCount
          ? +probationShiftCount
          : undefined,
        probationSheetQuestions,
      })
    }
  }

  return employeeProbationSheets
}

export const getSubdivisionJobPositionRels = (
  form: FormValues,
  divisionCuratorWithOneDivision: Division | null
) => {
  if (!form.subdivisionJobPositionRels) {
    return []
  }

  if (
    form.subdivisionJobPositionRels.length === 1 &&
    isEmpty(form.subdivisionJobPositionRels[0].position)
  ) {
    return []
  }

  // если элемент один, то вложенного объект внутри subdivisionJobPositionRels не будет
  return form.subdivisionJobPositionRels
    ? form.subdivisionJobPositionRels.map((item) =>
        divisionCuratorWithOneDivision
          ? {
              subdivision: divisionCuratorWithOneDivision['@id'],
              jobPosition: (item as unknown as BaseApiResponse)['@id'],
            }
          : {
              subdivision: item.division?.value,
              jobPosition: item.position?.value,
            }
      )
    : []
}
