import { JoyStep } from '@templates/Onboarding'

export const ONBOARDING_TITLE = 'Расскажем как создать подразделения'

export const ONBOARDING_STEPS: JoyStep[] = [
  {
    target: '#firstLine',
    title: 'Создание подразделения',
    content: 'Заполните данные о подразделении',
  },
  {
    target: '#add-button-multiline',
    title: 'Добавление куратора',
    content: (
      <div>
        <div>
          Куратор — это ответственное лицо за охрану труда в компании, который
          будет представлять подразделение в системе.
        </div>
        <div>Он имеет право:</div>
        <div>- изменять должности сотрудников своего подразделения;</div>
        <div>- назначать обучения на сотрудников своего подразделения;</div>
        <div>- создавать отчёты по своему подразделению.</div>
        <br />
        <div>У подразделения может быть несколько кураторов.</div>
      </div>
    ),
  },
  {
    target: '#add-button-multiline',
    title: 'Добавление куратора',
    content: (
      <div>
        <div>Выберете куратора подразделения.</div>
        <div>
          Куратор должен входить в тот же филиал, что и создаваемое
          подразделение.
        </div>
      </div>
    ),
  },
  {
    target: '#modal-footer',
    title: 'Дальнейшие действия',
    content: (
      <div>
        <div>Перейдите к одному из следующих шагов.</div>
        <div>
          Если вы хотите добавить сотрудников через загрузку штатного
          расписания, перейдите в раздел “Сотрудники”.
        </div>
      </div>
    ),
  },
  {
    target: '#modal-save-and-close',
    title: 'Сохраните и вернитесь к списку подразделений',
    content: '',
  },
  {
    target: '#modal-save-and-add-more',
    title: 'Сохраните это подразделение и добавьте ещё одно',
    content: '',
  },
  {
    target: '#modal-next',
    title: 'Перейдите к созданию должностей',
    content: '',
  },
]
