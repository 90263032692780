import styled from '@emotion/styled'
import Title from '@organisms/Title'

export const StyledTitle = styled(Title)`
  margin: 0;
  padding: 0;
`

export const TitleWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin: 10px 20px 40px;
  align-items: center;
`
