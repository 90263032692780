import { FC, ReactNode } from 'react'
import { Table } from 'antd'
import { TableProps } from 'antd/lib/table/Table'

type Column = {
  title: string
  dataIndex: string
  key?: string
  width?: number
}

type TableData = Record<string, ReactNode> & {
  key: string
}

type Props = {
  columns: Column[]
  data: TableData[]
  isLoading?: boolean
} & TableProps<any>

const TableComponent: FC<Props> = ({
  columns,
  data,
  isLoading,
  pagination = false,
  ...rest
}) => {
  return (
    <div style={{ overflowX: 'auto' }}>
      <Table
        {...rest}
        dataSource={data}
        columns={columns}
        pagination={pagination}
        bordered={false}
        loading={isLoading}
      />
    </div>
  )
}

export type { TableData, Column }
export default TableComponent
