import { useState } from 'react'
import { SelectSearchFieldParams } from '@templates/AddNewItemModal/templates/selectSearchField'
import { ReturnType } from '@app-types/riskMapFields'
import { ProtectionMeanConstructionOption } from '@models/protectionMeans'
import dataSelectField from '@templates/AddNewItemModal/templates/dataSelectField'
import { useGetProtectionMeanConstructionsQuery } from '@services/protectionMeans'
import { FilterState } from '@hooks/useItemsFilters'

const useProtectionMeanConstructionsField = (
  params: Partial<SelectSearchFieldParams>,
  onSelectConstruction: (
    construction: ProtectionMeanConstructionOption | null
  ) => void
): ReturnType => {
  const [filters, setFilters] = useState<FilterState | undefined>()

  return {
    setFilters,
    field: () =>
      dataSelectField({
        filters,
        query: useGetProtectionMeanConstructionsQuery,
        setFilters,
        dropdownWidth: 500,
        label: 'Конструкция СИЗ',
        name: 'construction',
        withManualEntryText: true,
        withShowLabelInDescription: true,
        componentProps: {
          onChange: (newValue: ProtectionMeanConstructionOption | null) => {
            onSelectConstruction(newValue)
          },
        },
        ...params,
      }),
  }
}

export default useProtectionMeanConstructionsField
