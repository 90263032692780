const ACTIVE_FILTERS = [
  {
    label: 'Показывать активных',
    value: 'true',
  },
  {
    label: 'Показывать деактивированных',
    value: 'false',
  },
]

export default ACTIVE_FILTERS
