import { FC } from 'react'
import StyledButton, { StyledButtonProps } from '@organs/StyledButton'
import ButtonStyle from '@enums/ButtonStyle'
import { EditOutlined } from '@ant-design/icons'

const EditButton: FC<StyledButtonProps> = ({
  buttonStyle = ButtonStyle.GRAY,
  children = 'Изменить',
  ...restProps
}) => {
  return (
    <StyledButton
      {...restProps}
      buttonStyle={buttonStyle}
      leftIcon={<EditOutlined />}
    >
      {children}
    </StyledButton>
  )
}

export default EditButton
