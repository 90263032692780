import { FC } from 'react'
import { Skeleton } from 'antd'
import { useTheme } from '@emotion/react'
import useMouseOverOut from '@hooks/useMouseOverOut'
import useMe from '@hooks/useMe'
import { isMobile } from '@const/device'
import { useLazyGetFileQuery } from '@services/files'
import { Document } from '@models/document'
import EditModal from '@templates/EditModal'
import { ListItemProps } from '@templates/List'
import Loader from '@organisms/Loader'
import ModalContent from '@modals/LibraryModal'
import { BottomText, ButtonsWrapper, TopText, Wrapper } from './styled'
import DeleteButton from './DeleteButton'

const LibraryItem: FC<ListItemProps<Document>> = ({
  isLoading: isLoadingProp,
  item,
  item: { title, documentType, id, file },
}) => {
  const { isAdmin } = useMe()
  const [hovered, , handleMouseEnter, handleMouseLeave] = useMouseOverOut()
  const {
    sizes: { twoBase },
  } = useTheme()

  const [getFile, { isLoading }] = useLazyGetFileQuery()

  const handleClick = () => {
    if (!isLoading) {
      void getFile({
        path: file.filePath,
        name: title,
      })
    }
  }

  if (isLoadingProp) {
    return (
      <Skeleton.Button
        active
        style={{
          marginBottom: twoBase,
          marginRight: twoBase,
          borderRadius: 30,
          height: isAdmin ? 180 : 140,
          width: isMobile ? '100%' : '23vw',
        }}
      />
    )
  }

  return (
    <Wrapper
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      key={id}
      isAdmin={isAdmin}
    >
      <Loader isLoading={isLoading}>
        <TopText hovered={hovered}>{title}</TopText>
        <BottomText>{documentType?.title}</BottomText>
      </Loader>
      {isAdmin && (
        <ButtonsWrapper>
          <EditModal
            ModalContent={ModalContent}
            modalProps={{
              document: item,
            }}
          />
          <DeleteButton documentId={id} />
        </ButtonsWrapper>
      )}
    </Wrapper>
  )
}

export default LibraryItem
