import { CommonQueryParams, ItemsFromResponse } from '@models/common'
import { Certificate, CertificateOption } from '@models/certificates'
import StoreTags from '@enums/StoreTags'
import emptySplitApi from '../api'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'

const BASE_URL = '/api/listener_course_certificates'

export const certificatesApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCertificates: builder.query<
      { items: CertificateOption[]; length: number },
      CommonQueryParams
    >({
      providesTags: [StoreTags.CERTIFICATES],
      query: (params) => ({
        url: BASE_URL,
        params,
      }),
      transformResponse: (response: ItemsFromResponse<Certificate>) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          value: item['@id'],
          label: item.createdAt as unknown as string,
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
    }),
  }),
})

export const { useGetCertificatesQuery } = certificatesApi
