import { useEffect } from 'react'
import { useLazyDownloadFromApiFilesQuery } from '@services/files'
import { BACKEND_URL } from '@const/env'

const useDownloadFile = (fileIri: string | null | undefined) => {
  const [downloadFileFromApi, { data, isLoading }] =
    useLazyDownloadFromApiFilesQuery()

  useEffect(() => {
    if (data) {
      window.open(`${BACKEND_URL}/uploads/${data.filePath}`, '_blank')
    }
  }, [data])

  const download = () => {
    if (!fileIri) {
      console.warn('useDownloadFile fileIri not found', { fileIri })
      return
    }

    void downloadFileFromApi(fileIri.split('/api/files/')[1]!)
  }

  return {
    download,
    isLoading,
  }
}

export default useDownloadFile
