import { useEffect } from 'react'
import { useLazyGetCertificateQuery } from '@services/courses'
import { useLazyGetFileQuery } from '@services/files'

const useDownloadCertificate = () => {
  const [getCert, { data, isSuccess, isLoading: getCertIsLoading }] =
    useLazyGetCertificateQuery()
  const [getFile, { isLoading: getFileLoading }] = useLazyGetFileQuery()

  useEffect(() => {
    if (isSuccess && data) {
      void getFile({
        path: data.file.filePath,
        name: 'Сертификат',
      })
    }
  }, [isSuccess, data])

  return {
    getCert,
    isLoading: getCertIsLoading || getFileLoading,
  }
}

export default useDownloadCertificate
