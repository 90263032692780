import * as React from 'react'
import { Dispatch, FC, ReactNode, SetStateAction, useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { notification } from 'antd'
import useFormProcessor from '@hooks/useFormProcessor'
import { useSetNewPasswordMutation } from '@services/auth/api'
import { UpdateAuthBody } from '@models/auth'
import { textField } from '@templates/AddNewItemModal/templates'
import Field from '@templates/Field'
import PrimaryButton from '@organisms/PrimaryButton'
import NewPasswordPrompt from '@templates/NewPasswordPrompt'
import { getPasswordValidationSchema } from '@const/validation'
import { ButtonWrapper, StyledSubtitle } from '../../styled'
import { EmailWrapper } from '../EmailAuthForm/EmailPasswordForm/styled'
import { AuthMethod } from '../index'

const getField = (name: string, placeholder: string, promptText?: ReactNode) =>
  textField({
    placeholder,
    name,
    label: placeholder,
    isRequired: true,
    componentProps: {
      type: 'password',
    },
    promptText,
  })

const passwordFieldProps = getField(
  'password',
  'Введите новый пароль',
  <NewPasswordPrompt />
)
const repeatPasswordFieldProps = getField(
  'repeatPassword',
  'Повторите новый пароль'
)

type FormValues = {
  repeatPassword: string
  password: string
}

type Props = {
  setSelectedMethod: Dispatch<SetStateAction<AuthMethod | null>>
}

const NewPasswordForm: FC<Props> = ({ setSelectedMethod }) => {
  const [searchParams] = useSearchParams()
  const [setNewPassword, { error, isLoading, isSuccess }] =
    useSetNewPasswordMutation()
  const methods = useFormProcessor({
    apiErrors: error,
    fieldsForToastNotification: ['code'],
    validationSchema: getPasswordValidationSchema('password'),
  })
  const { handleSubmit, watch, setError, clearErrors } = methods

  const password = watch('password')
  const repeatPassword = watch('repeatPassword')

  useEffect(() => {
    if (password !== repeatPassword) {
      setError('repeatPassword', { message: 'Пароли не совпадают' })
    } else {
      clearErrors()
    }
  }, [password, repeatPassword])

  useEffect(() => {
    if (isSuccess) {
      setSelectedMethod(AuthMethod.EMAIL)
      notification.success({
        message:
          'Новый пароль успешно установлен, используйте его для входа в систему',
      })
    }
  }, [isSuccess])

  const onSubmit = (form: FormValues) => {
    const resultForm: UpdateAuthBody = {
      password: form.password,
      code: undefined,
      username: undefined,
    }
    for (const [key, value] of searchParams.entries()) {
      if (key === 'code') {
        resultForm.code = value
      }
      if (key === 'login' || key === 'email') {
        resultForm.username = value
      }
    }
    setNewPassword(resultForm)
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledSubtitle isLast>Введите новый пароль</StyledSubtitle>
        <EmailWrapper>
          <Field {...passwordFieldProps} />
        </EmailWrapper>
        <Field {...repeatPasswordFieldProps} />
        <ButtonWrapper>
          <PrimaryButton type="submit" isLoading={isLoading} big>
            Сохранить
          </PrimaryButton>
        </ButtonWrapper>
      </form>
    </FormProvider>
  )
}

export default NewPasswordForm
