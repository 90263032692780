import * as React from 'react'
import { FC } from 'react'
import { employeeField } from '@templates/AddNewItemModal/templates'
import { EmployeeSelectFormValues } from '@templates/EmployeeSelect'
import MultilineFieldNew from '@templates/MultilineFieldNew'

const CURATOR_FIELD_NAME = 'curators'

type Props = {
  curatorFieldLabel: string
  initValues?: EmployeeSelectFormValues
}

const CuratorsMultilineFields: FC<Props> = ({
  curatorFieldLabel,
  initValues,
}) => {
  const curatorField = employeeField({
    label: curatorFieldLabel,
    name: CURATOR_FIELD_NAME,
    initValues,
    isClearable: false,
  })

  return (
    <MultilineFieldNew
      fieldsSrc={[curatorField]}
      fieldsName={CURATOR_FIELD_NAME}
      amendValue={{}}
      addLabel="Добавить куратора"
    />
  )
}

export default CuratorsMultilineFields
