import { Option } from '@organisms/Select'
import { WhomAssignee } from '@enums/WhomAssignee'

const WHOM_ASSIGNEE_ITEMS: Option[] = [
  {
    label: 'Компания',
    value: WhomAssignee.COMPANY,
  },
  {
    label: 'Филиал',
    value: WhomAssignee.FILIAL,
  },
  {
    label: 'Подразделение',
    value: WhomAssignee.DIVISION,
  },
  {
    label: 'Должность',
    value: WhomAssignee.POSITION,
  },
  {
    label: 'Сотрудники',
    value: WhomAssignee.EMPLOYEE,
  },
]

export default WHOM_ASSIGNEE_ITEMS
