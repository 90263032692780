import { FC } from 'react'
import useFormProcessor from '@hooks/useFormProcessor'
import {
  usePostDocumentMutation,
  usePutDocumentMutation,
} from '@services/documents'
import { CommonDocumentBody, Document } from '@models/document'
import AddNewItemModal from '@templates/AddNewItemModal'
import { Option } from '@organisms/Select'
import { File } from '@models/file'
import useLibraryFields from './useLibraryFields'
import getDefaultValues from './getDefaultValues'

export type FormValues = {
  documentType: Option
  file: File
  title: string
}

type Props = {
  isEdit?: boolean
  handleCloseModal: () => void
  document?: Document
}

const LibraryModal: FC<Props> = ({
  isEdit = false,
  handleCloseModal,
  document,
}) => {
  const fields = useLibraryFields(isEdit)
  const [createDocument, createData] = usePostDocumentMutation()
  const [updateDocument, updateData] = usePutDocumentMutation()
  const additionalProps = isEdit ? updateData : createData

  const methods = useFormProcessor({
    apiErrors: additionalProps.error,
    defaultValues: getDefaultValues(document),
  })

  const handleSubmitForm = (form: FormValues) => {
    const commonForm: CommonDocumentBody = {
      title: form.title,
      documentType: form.documentType.value?.toString(),
    }

    if (isEdit) {
      if (document) {
        updateDocument({
          ...commonForm,
          id: document.id,
        })
      }
    } else {
      createDocument({
        ...commonForm,
        file: form.file?.['@id'],
      })
    }
  }

  return (
    <AddNewItemModal
      {...additionalProps}
      handleCloseModal={handleCloseModal}
      onSubmitForm={handleSubmitForm}
      methods={methods}
      isEdit={isEdit}
      fields={fields}
      titlePostfix="документ"
      successContentAddTitle="Документ успешно добавлен"
      successContentEditTitle="Документ успешно изменен"
      withSaveAndAddMore
      withStopPropagation
    />
  )
}

export default LibraryModal
