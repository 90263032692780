import 'moment/locale/ru'
import locale from 'antd/es/date-picker/locale/ru_RU'
import { DatePickerProps as AntdDatePickerProps } from 'antd'
import { FC, forwardRef } from 'react'
import IMask from 'imask'
import moment from 'moment'
import { StyledDatePicker } from './styled'

const DATE_FORMAT = 'DD.MM.YYYY'
const MASKED = IMask.createMask({
  mask: Date,
  lazy: true,
  pattern: 'd{.}`m{.}`Y', // паттерн по умолчанию для библиотеки imask
  autofix: true,
})

type Props = {
  onChange?: (dateStr: string | null) => void
} & Omit<AntdDatePickerProps, 'onChange'>

const DatePicker: FC<Props> = forwardRef((props, ref) => {
  const resultProps: AntdDatePickerProps = {
    ...props,
    onChange: (_moment, str) => {
      props.onChange?.(str === '' ? null : str)
    },
    value: props.value ? moment(props.value, DATE_FORMAT) : undefined,
    format: 'DD.MM.YYYY',
    locale,
    style: {
      width: '100%',
    },
    onKeyDown: (event) => {
      const input = event.target as HTMLInputElement
      MASKED.value = input.value
      input.value = MASKED.unmaskedValue
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ref,
  }

  return <StyledDatePicker {...resultProps} />
})

export type { Props as DatePickerProps }
export default DatePicker
