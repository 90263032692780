import { FC, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useToggle } from 'react-use'
import { useAppDispatch } from '@hooks/useAppDispatch'
import { acceptCookie, getAcceptedCookie } from '@services/auth/slice'
import PrimaryButton from '@organisms/PrimaryButton'
import { useAppSelector } from '@hooks/useAppSelector'
import { BackgroundWrapper, Footer, Text, Wrapper } from './styled'

const CookieModal: FC = () => {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const onboardingStarted = useAppSelector((state) => state.onboarding.started)

  const [visible, toggleVisible] = useToggle(!getAcceptedCookie())

  const handleOkClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation()
    dispatch(acceptCookie())
    toggleVisible(false)
  }

  const handleBackgroundClick = () => {
    toggleVisible(false)
  }

  useEffect(() => {
    if (!getAcceptedCookie()) {
      toggleVisible(true)
    }
  }, [pathname])

  if (!visible || onboardingStarted) return null

  return (
    <BackgroundWrapper onClick={handleBackgroundClick}>
      <Wrapper>
        <Text>
          Мы используем файлы cookie, чтобы улучшить работу и повысить
          эффективность системы.
        </Text>
        <Text>
          Продолжая пользование системой, Вы соглашаетесь с использованием
          файлов cookie.
        </Text>
        <Footer>
          <PrimaryButton onClick={handleOkClick}>Ok</PrimaryButton>
        </Footer>
      </Wrapper>
    </BackgroundWrapper>
  )
}

export default CookieModal
