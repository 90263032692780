import { FC } from 'react'
import { useDeleteCourseThemeMutation } from '@services/courseThemes'
import DeleteButton from '@templates/DeleteButton'

type Props = {
  themeId: number
}

const DeleteThemeLink: FC<Props> = ({ themeId }) => {
  const [deleteCourseTheme, data] = useDeleteCourseThemeMutation()

  const handleClick = async () => {
    await deleteCourseTheme(themeId)
  }

  return (
    <DeleteButton
      {...data}
      onClick={handleClick}
      deleteDescription="Вы точно хотите удалить тему?"
      isLink
    />
  )
}

export default DeleteThemeLink
