import styled from '@emotion/styled'
import { isMobile } from '@const/device'

const DesktopHeaderContainer = styled.div<{
  borderRadius: number
  withoutDefaultHeight: boolean
}>`
  padding: ${({
    theme: {
      sizes: { base, threeBase },
    },
  }) => `${base} ${threeBase}`};
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.background.surface};
  box-shadow: 5px 5px 20px rgba(29, 78, 137, 0.1);
  border-radius: ${({ borderRadius }) => borderRadius}px;

  ${({ withoutDefaultHeight }) => (!withoutDefaultHeight ? 'height: 55px' : '')}
`

const MobileHeaderContainer = styled(DesktopHeaderContainer)<{
  borderRadius: number
  withoutDefaultHeight: boolean
}>`
  height: inherit;
`

export const HeaderContainer = isMobile
  ? MobileHeaderContainer
  : DesktopHeaderContainer

export const MainWrapper = styled.div`
  border-radius: 30px;
  margin-bottom: 12px;
  background-color: ${({ theme }) => theme.colors.background.surface};
  box-shadow: 5px 5px 20px rgba(29, 78, 137, 0.1);
  width: 100%;
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const BodyWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background.surface};
  justify-content: space-between;
  padding: ${({
    theme: {
      sizes: { base, threeBase },
    },
  }) => `${base} ${threeBase}`};
  border-radius: 0 0 30px 30px;
  margin-top: 20px;
`
