import { FC, useState } from 'react'
import useFormProcessor from '@hooks/useFormProcessor'
import {
  useCreateCompanyGroupMutation,
  useUpdateCompanyGroupMutation,
} from '@services/companyGroups'
import {
  CommonCompanyGroupBody,
  CompanyGroupDetailed,
} from '@models/companyGroup'
import { Option } from '@organisms/Select'
import { getMultilineValue } from '@utils/form'
import AddNewItemModalLayout from '@templates/AddNewItemModalLayout'
import LineFields from '@templates/AddNewItemModal/Content/LineFields'
import { numberField, textField } from '@templates/AddNewItemModal/templates'
import { ExternalLineWrapper } from '@templates/AddNewItemModal/Content/LineFields/styled'
import { EmployeeDetailed } from '@models/employee'
import getDefaultValues from './getDefaultValues'
import CuratorsTable from './CuratorsTable'
import MultilineCompanies from './MultilineCompanies'

export type FormValues = {
  title: string
  subscriptionsCount?: string
  curators: Option[]
  companies: Option[]
}

type Props = {
  isEdit?: boolean
  handleCloseModal: () => void
  companyGroup?: CompanyGroupDetailed
}

const CompanyGroupModal: FC<Props> = ({
  isEdit = false,
  companyGroup,
  handleCloseModal,
}) => {
  const [selectedCurators, setSelectedCurators] = useState(
    (companyGroup?.curators || []) as unknown as EmployeeDetailed[]
  )

  const [create, createData] = useCreateCompanyGroupMutation()
  const [update, updateData] = useUpdateCompanyGroupMutation()
  const additionalProps = isEdit ? updateData : createData

  const methods = useFormProcessor({
    apiErrors: additionalProps.error,
    defaultValues: getDefaultValues(companyGroup),
  })

  const handleSubmitForm = (form: FormValues) => {
    const commonForm: CommonCompanyGroupBody = {
      title: form.title,
      companies: getMultilineValue(form.companies),
      curators: selectedCurators.map((item) => item['@id']),
      subscriptionsCount: form.subscriptionsCount
        ? +form.subscriptionsCount
        : undefined,
    }

    if (isEdit) {
      if (companyGroup) {
        update({
          ...commonForm,
          id: companyGroup.id,
        })
      }
    } else {
      create(commonForm)
    }
  }

  return (
    <AddNewItemModalLayout
      {...additionalProps}
      titlePostfix="группу компаний"
      onSubmitForm={handleSubmitForm}
      methods={methods}
      handleCloseModal={handleCloseModal}
      isEdit={isEdit}
      withSaveAndAddMore
    >
      <ExternalLineWrapper>
        <LineFields
          fields={[
            textField({
              name: 'title',
              label: 'Наименование',
              placeholder: 'Наименование группы компаний',
              isRequired: true,
            }),
            numberField({
              label: 'Лицензии',
              placeholder: 'Количество лицензий',
              name: 'subscriptionsCount',
              isRequired: true,
            }),
          ]}
        />
      </ExternalLineWrapper>
      <CuratorsTable
        isEdit={isEdit}
        companyGroup={companyGroup}
        selectedCurators={selectedCurators}
        setSelectedCurators={setSelectedCurators}
      />
      <MultilineCompanies />
    </AddNewItemModalLayout>
  )
}

export default CompanyGroupModal
