import { createContext, Dispatch, FC, SetStateAction } from 'react'
import { useToggle } from 'react-use'
import { noop } from 'lodash'
import { useFormContext } from 'react-hook-form'
import { AUTO_SIZ_NAME } from '@modals/RiskMapModalFromFile/useRiskMapFromFileFields'
import SelectDangers from './SelectDangers'
import DangersFields from './DangersFields'
import TopControllers from './TopControllers'

type TopControllersContextValue = {
  enabledEtn: boolean
  toggleEnabledEtn: Dispatch<SetStateAction<boolean>>
  enabledAutoAddSiz: boolean
  toggleEnabledAutoAddSiz: (nextValue: boolean) => void
}

export const TopControllersContext = createContext<TopControllersContextValue>({
  enabledEtn: true,
  enabledAutoAddSiz: true,
  toggleEnabledEtn: noop,
  toggleEnabledAutoAddSiz: noop,
})

const Dangers: FC = () => {
  const { watch, setValue } = useFormContext()

  const [enabledEtn, toggleEnabledEtn] = useToggle(true)

  const enabledAutoAddSiz: boolean = watch(AUTO_SIZ_NAME)

  const toggleEnabledAutoAddSiz = (value: boolean) => {
    setValue(AUTO_SIZ_NAME, value)
  }

  return (
    <TopControllersContext.Provider
      value={{
        enabledEtn,
        enabledAutoAddSiz,
        toggleEnabledEtn,
        toggleEnabledAutoAddSiz,
      }}
    >
      <TopControllers />
      <SelectDangers />
      <DangersFields />
    </TopControllersContext.Provider>
  )
}

export default Dangers
