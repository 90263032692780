import { EmployeeDetailed } from '@models/employee'
import { User } from '@models/user'

const setDefaultRiskCommissionMembers = (
  result: any,
  riskCommissionMembers: User[] | EmployeeDetailed[] | null
) => {
  if (riskCommissionMembers) {
    result.riskMapCommissionMembers = riskCommissionMembers.map((item) => ({
      ...item,
      label: item.fullName,
      value: item['@id'],
    }))
  }

  return result
}

export default setDefaultRiskCommissionMembers
