import * as React from 'react'
import { FC } from 'react'
import { useToggle } from 'react-use'
import { SettingsButton } from '@models/settings'
import Modal from '@organisms/Modal'
import SecondaryButton from '@organisms/SecondaryButton'
import { Wrapper } from './styled'

const BearButton: FC<SettingsButton> = ({ title, link, text }) => {
  const [visible, toggleVisible] = useToggle(false)

  const handleClick = () => {
    if (link) {
      window.open(link, '_blank')
    } else if (text) {
      toggleVisible()
    }
  }

  if (!title) {
    return null
  }

  return (
    <>
      <SecondaryButton onClick={handleClick}>{title}</SecondaryButton>
      <Modal visible={visible} onModalClose={toggleVisible}>
        <Wrapper>
          <div
            dangerouslySetInnerHTML={{
              __html: text?.replaceAll('\n', '<br/>'),
            }}
          ></div>
        </Wrapper>
      </Modal>
    </>
  )
}

export default BearButton
