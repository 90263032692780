/* eslint-disable no-unused-expressions, no-sequences */
import { isEmpty } from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import {
  MereFormItem,
  MeresFieldsFormValues,
} from '@modals/RiskMapModal/Dangers/DangersFields/DangersFieldsBlock/MeresFields'
import {
  ProtectionMeanItem,
  ProtectionMeansFieldsFormValues,
} from '@modals/RiskMapModal/Dangers/DangersFields/DangersFieldsBlock/ProtectionMeansFields'
import { RiskMapFilteredDanger } from '@models/riskMap'
import { DangersApi } from '@services/dangers'
import { protectionMeansApi } from '@services/protectionMeans'
import { convertMeanToForm } from '@utils/riskMap'
import { PositionOption } from '@models/position'

export type State = {
  selectedDangers: RiskMapFilteredDanger[]
  selectedMeresForms: Record<number, MeresFieldsFormValues>
  selectedProtectionMeansForms: Record<number, ProtectionMeansFieldsFormValues>
  meresIsVisibleIndex: number | null
  protectionMeansIsVisibleIndex: number | null
  selectDangersModalIsVisible: boolean
  selectedMainPosition: PositionOption | null
}

const initialState: State = {
  selectedDangers: [],
  selectedMeresForms: { 0: { prefilled: false, meres: [{} as MereFormItem] } },
  selectedProtectionMeansForms: {
    0: { prefilled: false, protectionMeans: [{} as ProtectionMeanItem] },
  },
  meresIsVisibleIndex: null,
  protectionMeansIsVisibleIndex: null,
  selectDangersModalIsVisible: false,
  selectedMainPosition: null,
}

export const riskMapModalSlice = createSlice({
  name: 'riskMapModal',
  initialState,
  reducers: {
    setSelectedDangers: (state, { payload }) => {
      state.selectedDangers.push(...payload)
    },
    setSelectDangersModalIsVisible: (state, { payload }) => {
      state.selectDangersModalIsVisible = payload
    },
    setSelectedMeresForms: (state, { payload }) => {
      state.selectedMeresForms = {
        ...state.selectedMeresForms,
        ...payload,
      }
    },
    setSelectedProtectionMeansForms: (state, { payload }) => {
      state.selectedProtectionMeansForms = {
        ...state.selectedProtectionMeansForms,
        ...payload,
      }
    },
    setMeresIsVisibleIndex: (state, { payload }) => {
      state.meresIsVisibleIndex = payload
    },
    setProtectionMeansIsVisibleIndex: (state, { payload }) => {
      state.protectionMeansIsVisibleIndex = payload
    },
    cleanAllForms: () => {
      return initialState
    },
    deleteSelectedProtectionMeansForms: (state, { payload }) => {
      const typedPayload = payload as number
      state.selectedProtectionMeansForms[typedPayload] =
        initialState.selectedProtectionMeansForms[0]
    },
    deleteSelectedMeresForms: (state, { payload }) => {
      const typedPayload = payload as number
      state.selectedMeresForms[typedPayload] =
        initialState.selectedMeresForms[0]
    },
    cleanSavedSelectedForms: (state) => {
      state.selectedMeresForms = initialState.selectedMeresForms
      state.selectedProtectionMeansForms =
        initialState.selectedProtectionMeansForms
    },
    setSelectedMainPosition: (state, { payload }) => {
      state.selectedMainPosition = payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      DangersApi.endpoints.getDangersSearchedMeres.matchFulfilled,
      (state, { payload: { items, modalIndex = 0 } }) => {
        if (isEmpty(items)) return

        state.selectedMeresForms[modalIndex] = {
          prefilled: true,
          meres: items.map((mere) => ({
            mereKind: {
              ...mere.kind,
              label: mere.kind.value,
              value: mere.kind.value,
            },
            content: {
              ...mere.content,
              label: mere.content.value,
              value: mere.content.value,
            },
            source: undefined,
            deadline: undefined,
            responsible: undefined,
          })),
        }
      }
    ),
      builder.addMatcher(
        protectionMeansApi.endpoints.getDangersSearchedProtectionMeans
          .matchFulfilled,
        (state, { payload: { items, modalIndex = 0 } }) => {
          if (isEmpty(items)) return

          state.selectedProtectionMeansForms[modalIndex] = {
            prefilled: true,
            protectionMeans: items.map((mean) => convertMeanToForm(mean)),
          }
        }
      )
  },
})

export const {
  setSelectedMeresForms,
  setSelectedProtectionMeansForms,
  cleanAllForms,
  setMeresIsVisibleIndex,
  setProtectionMeansIsVisibleIndex,
  setSelectDangersModalIsVisible,
  setSelectedDangers,
  deleteSelectedProtectionMeansForms,
  deleteSelectedMeresForms,
  cleanSavedSelectedForms,
  setSelectedMainPosition,
} = riskMapModalSlice.actions

export default riskMapModalSlice.reducer
