import * as React from 'react'
import { FC } from 'react'
import { FormProvider } from 'react-hook-form'
import useFormProcessor from '@hooks/useFormProcessor'
import { usePostLoginAuthMutation } from '@services/auth/api'
import Link, { LinkStyle } from '@organisms/Link'
import BackButton from '@organisms/BackButton'
import Field from '@templates/Field'
import { textField } from '@templates/AddNewItemModal/templates'
import PrimaryButton from '@organisms/PrimaryButton'
import { ButtonWrapper, StyledSubtitle } from '../../../styled'
import { EmailWrapper, LinkWrapper } from './styled'

type FormValues = {
  email: string
  password: string
}

const emailFieldProps = textField({
  name: 'login',
  label: 'логин / электронная почта',
  placeholder: 'Логин / Адрес электронной почты',
  isRequired: true,
  widthMultiplier: 1.2,
})

const passwordFieldProps = textField({
  placeholder: 'Пароль',
  name: 'password',
  label: 'Пароль',
  isRequired: true,
  widthMultiplier: 1.2,
  componentProps: {
    type: 'password',
  },
})

type Props = {
  handleBack: () => void
  handleRestorePassword: () => void
}

const EmailPasswordForm: FC<Props> = ({
  handleBack,
  handleRestorePassword,
}) => {
  const [postLoginAuth, { error, isLoading }] = usePostLoginAuthMutation()
  const methods = useFormProcessor({ apiErrors: error })

  const onSubmit = (form: FormValues) => {
    postLoginAuth(form)
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <StyledSubtitle>Войти по логину / электронной</StyledSubtitle>
        <StyledSubtitle isLast>почте и паролю</StyledSubtitle>
        <EmailWrapper>
          <Field {...emailFieldProps} />
        </EmailWrapper>
        <Field {...passwordFieldProps} />
        <ButtonWrapper>
          <BackButton onClick={handleBack} big />
          <PrimaryButton type="submit" isLoading={isLoading} big>
            Далее
          </PrimaryButton>
        </ButtonWrapper>
        <LinkWrapper>
          <Link
            text="ВОССТАНОВИТЬ ПАРОЛЬ"
            linkStyle={LinkStyle.GRAY_UPPERCASE}
            onClick={handleRestorePassword}
            fontSize={14}
          />
        </LinkWrapper>
      </form>
    </FormProvider>
  )
}

export default EmailPasswordForm
