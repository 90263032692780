import styled from '@emotion/styled'
import Title from '@organisms/Title'

export const Wrapper = styled.div`
  margin-top: 10px;
`

export const StyledTitle = styled(Title)`
  font-weight: 700;
`
