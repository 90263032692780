import { useState } from 'react'
import { SelectSearchFieldParams } from '@templates/AddNewItemModal/templates/selectSearchField'
import { ReturnType } from '@app-types/riskMapFields'
import dataSelectField from '@templates/AddNewItemModal/templates/dataSelectField'
import { useGetMeasuresKindsQuery } from '@services/measuresKinds'
import { FilterState } from '@hooks/useItemsFilters'

const useMeasuresKindsField = (
  params: Partial<SelectSearchFieldParams>
): ReturnType => {
  const [filters, setFilters] = useState<FilterState | undefined>()

  return {
    setFilters,
    field: () =>
      dataSelectField({
        filters,
        query: useGetMeasuresKindsQuery,
        setFilters,
        length,
        dropdownWidth: 500,
        label: 'Вид меры',
        name: 'measuereKind',
        withShowLabelInDescription: true,
        withManualEntryText: true,
        ...params,
      }),
  }
}

export default useMeasuresKindsField
