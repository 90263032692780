import { User } from '@models/user'

const setDefaultCurators = (result: any, curators: User[]) => {
  if (curators) {
    result.curators = curators.map((item) => ({
      ...item,
      label: item.fullName,
      value: item['@id'],
    }))
  }

  return result
}

export default setDefaultCurators
