import { FC, ReactNode } from 'react'
import useMe from '@hooks/useMe'
import { BACKEND_URL } from '@const/env'
import {
  BearWrapper,
  BlueTitle,
  ButtonsWrapper,
  ContentWrapper,
  Description,
  StyledTitle,
  SubWrapper,
  TitleWrapper,
  Wrapper,
} from './styled'

const BEAR_SIZE = 200

type Props = {
  bearPath?: string
  description?: string | null
  buttons?: ReactNode[]
}

const BearBannerComponent: FC<Props> = ({
  bearPath = 'hello-bear-new.svg',
  description = 'Отличный день для изучения курсов',
  buttons = [],
}) => {
  const { fullName } = useMe()

  const nowHours = new Date().getHours()

  const isMorning = nowHours > 6 && nowHours <= 12
  const isAfternoon = nowHours > 12 && nowHours <= 20
  const isEvening = nowHours > 20 && nowHours <= 6

  let text = 'Добрый день'
  if (isMorning) {
    text = 'Доброе утро'
  } else if (isAfternoon) {
    text = 'Добрый день'
  } else if (isEvening) {
    text = 'Добрый вечер'
  }

  return (
    <Wrapper>
      <BearWrapper>
        <img
          alt="bear"
          src={`${BACKEND_URL}/images/${bearPath}`}
          width={BEAR_SIZE}
          height={BEAR_SIZE}
        />
      </BearWrapper>
      <SubWrapper>
        <ContentWrapper>
          <div>
            <TitleWrapper>
              <StyledTitle>{text}, </StyledTitle>
              <BlueTitle> {fullName?.split(' ')?.[1] || fullName}</BlueTitle>
            </TitleWrapper>
            <Description>{description}</Description>
          </div>
          <ButtonsWrapper>{buttons}</ButtonsWrapper>
        </ContentWrapper>
      </SubWrapper>
    </Wrapper>
  )
}

export default BearBannerComponent
