import { DatePickerProps } from 'antd'
import { Validate } from 'react-hook-form/dist/types/validator'
import { REQUIRED_VALIDATION_RULE } from '@const/validation'
import { DATE_PLACEHOLDER } from '@const/placeholders'
import { MaskedInputProps } from '@organisms/MaskedInput'
import DatePicker from '@organisms/DatePicker'
import { FieldForRender, FieldParams } from '..'

type Params = {
  componentProps?: MaskedInputProps
  validate?: Validate<any, any>
} & FieldParams

const dateField = (params: Params): FieldForRender => {
  const {
    label,
    isRequired,
    componentProps: props,
    validate,
    name,
  } = params || {}

  const componentProps: DatePickerProps = {
    ...props,
    placeholder: DATE_PLACEHOLDER,
    small: true,
  }

  return {
    ...params,
    label: isRequired ? `${label}*` : label,
    rules: {
      ...(isRequired ? REQUIRED_VALIDATION_RULE : {}),
      ...(validate ? { validate } : {}),
    },
    Component: DatePicker,
    componentProps,
    id: name,
  }
}

export default dateField
