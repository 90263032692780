import { UseFormReturn } from 'react-hook-form'
import { compact } from 'lodash'
import useJobFields from '@hooks/useJobFields'
import WHOM_ASSIGNEE_ITEMS from '@const/whomAssignee'
import ASSIGN_TYPES from '@const/assignTypes'
import useCoursesSelectField from '@hooks/useCoursesSelectField'
import useHasJobs from '@hooks/useHasJobs'
import { WhomAssignee } from '@enums/WhomAssignee'
import { EmployeeEducationDetailed } from '@models/employeeEducation'
import { Fields } from '@templates/AddNewItemModal/Content'
import {
  dateField,
  employeeField,
  modalField,
  selectField,
  textField,
} from '@templates/AddNewItemModal/templates'
import UploadEmployeesFile from '@modals/EmployeeEducationModal/UploadEmployeesFile'

const useEmployeeEducationFields = (
  methods: UseFormReturn,
  isEdit: boolean,
  employeeEducation?: EmployeeEducationDetailed
): Fields => {
  const { hasFilial, hasCompany, hasDivision } = useHasJobs()
  const { company, filial, division, position } = useJobFields(methods)
  const selectCourseField = useCoursesSelectField()

  const hasEmployeesListFile = methods.watch('employeesList')
  const whoAssigneeCode: WhomAssignee | undefined =
    methods.watch('whomAssign')?.value
  const hasWhoAssigneeCode = whoAssigneeCode !== undefined
  const forFilial = whoAssigneeCode === WhomAssignee.FILIAL
  const forDivision = whoAssigneeCode === WhomAssignee.DIVISION
  const forPosition = whoAssigneeCode === WhomAssignee.POSITION
  const forEmployee = whoAssigneeCode === WhomAssignee.EMPLOYEE

  const whomAssigneeItems = WHOM_ASSIGNEE_ITEMS.filter(({ value }) => {
    switch (value) {
      case WhomAssignee.COMPANY:
        return hasCompany
      case WhomAssignee.DIVISION:
        return hasDivision
      case WhomAssignee.FILIAL:
        return hasFilial
      case WhomAssignee.POSITION:
      case WhomAssignee.EMPLOYEE:
      default:
        return true
    }
  })

  const assignTypes = ASSIGN_TYPES

  return [
    {
      fields: compact([
        selectField({
          isDisabled: isEdit,
          withoutFetchOptions: true,
          options: whomAssigneeItems,
          label: 'Кому назначить',
          name: 'whomAssign',
          isRequired: true,
        }),
        forEmployee &&
          modalField({
            Component: UploadEmployeesFile,
            name: 'file',
            label: 'Загрузить файл',
            componentProps: {
              children: 'Загрузить файл',
              disabled: isEdit,
            },
          }),
      ]),
    },
    {
      fields: compact([
        hasWhoAssigneeCode && !forEmployee && company?.({ isDisabled: isEdit }),
        (forFilial || forDivision || forPosition) &&
          filial?.({ isDisabled: isEdit }),
        (forDivision || forPosition) && division?.({ isDisabled: isEdit }),
        forPosition && position?.({ isDisabled: isEdit }),
        forEmployee &&
          employeeField({
            isDisabled: isEdit || hasEmployeesListFile,
            label: 'Сотрудник',
            name: 'employee',
            isClearable: false,
          }),
      ]),
      isMultiline: forEmployee,
      preselectedItems: employeeEducation?.employees,
      isDisabledMultiline: isEdit,
    },
    {
      lineId: 'secondLine',
      fields: [
        textField({
          label: 'Наименование',
          placeholder: 'Наименование',
          name: 'title',
          isRequired: true,
        }),
        selectField({
          isDisabled: isEdit,
          withoutFetchOptions: true,
          options: assignTypes,
          label: 'Тип назначения',
          name: 'type',
        }),
        selectCourseField({
          isDisabled: isEdit,
          label: 'Курс',
          name: 'course',
          isRequired: true,
        }),
        dateField({
          label: 'рекомендуемая дата окончания',
          name: 'deadlineAt',
        }),
      ],
    },
    {
      lineId: 'thirdLine',
      fields: [
        employeeField({
          name: 'conductsBriefing',
          label: 'проводит инструктаж',
        }),
        textField({
          label: 'Причина проведения',
          placeholder: 'Причина проведения',
          name: 'trainingReason',
        }),
        textField({
          label: 'наименование локального акта',
          placeholder: 'Наименование локального акта',
          name: 'localActName',
          widthMultiplier: 2,
        }),
      ],
    },
    {
      lineId: 'fourthLine',
      fields: [
        textField({
          label: 'Номер протокола',
          placeholder: 'Номер протокола',
          name: 'protocolNumber',
        }),
        dateField({
          label: 'Дата протокола',
          name: 'protocolDate',
        }),
        textField({
          label: 'Место работы',
          placeholder: 'Место работы',
          name: 'workPlace',
        }),
        textField({
          label: 'ИНН организации работодателя',
          placeholder: 'ИНН организации',
          name: 'employerInn',
        }),
      ],
    },
    {
      lineId: 'fifthLine',
      fields: [
        textField({
          label: 'Обучающая организация',
          placeholder: 'Обучающая организация',
          name: 'educationOrganization',
        }),
        textField({
          label: 'ИНН обучающей организации',
          placeholder: 'ИНН обучающей организации',
          name: 'educationOrganizationInn',
        }),
      ],
    },
  ]
}

export default useEmployeeEducationFields
