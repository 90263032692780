import { FC } from 'react'
import { ClearIndicatorProps, components } from 'react-select'
import { isEmpty } from 'lodash'

const ClearIndicator: FC<ClearIndicatorProps> = (props) => {
  const notSelected = isEmpty(props.selectProps.value)
  return (
    <components.ClearIndicator
      {...props}
      innerProps={{
        ...props.innerProps,
        style: {
          ...props.innerProps.style,
          color: notSelected ? 'lightgray' : 'white',
        },
      }}
    />
  )
}

export default ClearIndicator
