import { useGetCourseChaptersQuery } from '@services/courseChapters'
import { PER_PAGE } from '@const/pagination'
import useItemsFilters, { FilterState } from './useItemsFilters'

const useCourseChapters = (
  initFilters: FilterState = {},
  pagination = true,
  limit = PER_PAGE
) => {
  const { filters, setFilters, setPage, page } = useItemsFilters(
    initFilters,
    pagination,
    limit
  )

  const state = useGetCourseChaptersQuery(filters)

  return {
    ...state,
    page,
    setPage,
    setFilters,
  }
}

export default useCourseChapters
