import { compact } from 'lodash'
import { UseFormReturn } from 'react-hook-form'
import { useEffect } from 'react'
import { Fields } from '@templates/AddNewItemModal/Content'
import useJobFields from '@hooks/useJobFields'
import {
  dateField,
  selectField,
  selectSearchField,
  textField,
} from '@templates/AddNewItemModal/templates'
import useRiskMaps from '@hooks/useRiskMaps'
import { RiskMapReports } from './enums'
import { REVIEW_STATUS_OPTIONS } from './const'

const NOT_REQUIRED_FILIAL_REPORTS = new Set([
  RiskMapReports.DANGEROUS_REGISTRY,
  RiskMapReports.ACK_WITH_RISK_MAPS,
  RiskMapReports.POSITIONS_WITHOUT_RISK_MAPS,
  RiskMapReports.RISK_MANAGEMENT_PLAN,
  RiskMapReports.SIZ,
])

const REPORTS_WITHOUT_POSITION = new Set([
  RiskMapReports.POSITIONS_WITHOUT_RISK_MAPS,
  RiskMapReports.RISK_MANAGEMENT_SYSTEM,
])

const useRiskMapsFiltersModalFields = (
  methods: UseFormReturn,
  selectedReport: RiskMapReports
): Fields => {
  const { company, filial, division, position } = useJobFields(methods)

  const { data, isLoading, setPage, setFilters } = useRiskMaps()
  const { items = [], length = 0 } = data || {}

  const selectedCompany: string | undefined = methods.watch('company')?.['@id']
  const selectedFilial: string | undefined = methods.watch('filial')?.['@id']
  const selectedDivision: string | undefined =
    methods.watch('division')?.['@id']
  const selectedPosition: string | undefined =
    methods.watch('position')?.['@id']

  useEffect(() => {
    setFilters({
      'jobPosition.subdivision.companyBranch.company': selectedCompany,
      'jobPosition.subdivision.companyBranch': selectedFilial,
      'jobPosition.subdivision': selectedDivision,
      jobPosition: selectedPosition,
    })
  }, [selectedCompany, selectedFilial, selectedDivision, selectedPosition])

  return compact([
    {
      fields: compact([
        company?.({
          isRequired: true,
        }),
        filial?.({
          isRequired: !NOT_REQUIRED_FILIAL_REPORTS.has(selectedReport),
        }),
        selectedReport !== RiskMapReports.RISK_MANAGEMENT_SYSTEM &&
          division?.({
            isRequired: false,
          }),
        !REPORTS_WITHOUT_POSITION.has(selectedReport) &&
          position?.({
            isRequired:
              selectedReport !== RiskMapReports.ACK_WITH_RISK_MAPS &&
              !NOT_REQUIRED_FILIAL_REPORTS.has(selectedReport),
          }),
        selectedReport === RiskMapReports.RISK_MANAGEMENT_SYSTEM &&
          textField({
            name: 'headPosition',
            label: 'Должность возглавляющего работу по оценке рисков',
            placeholder: 'Введите значение',
            isRequired: true,
          }),
        selectedReport === RiskMapReports.RISK_MANAGEMENT_SYSTEM &&
          textField({
            name: 'safetyDivision',
            label: 'Служба (специалист) по охране труда',
            placeholder: 'Введите значение',
            isRequired: true,
          }),
      ]),
    },
    (selectedReport === RiskMapReports.ACK_WITH_RISK_MAPS ||
      selectedReport === RiskMapReports.SIZ) && {
      fields: compact([
        selectSearchField({
          name: 'riskMap',
          label: 'Карта риска',
          options: items,
          isLoading,
          setFilters,
          setPage,
          length,
          dropdownWidth: 500,
        }),
        selectedReport === RiskMapReports.ACK_WITH_RISK_MAPS &&
          dateField({
            label: 'Ознакомлен до',
            name: 'reviewedBeforeAt',
          }),
        selectedReport === RiskMapReports.ACK_WITH_RISK_MAPS &&
          selectField({
            name: 'reviewStatus',
            label: 'Статус ознакомления',
            options: REVIEW_STATUS_OPTIONS,
            dropdownWidth: 350,
            withoutFetchOptions: true,
          }),
      ]),
    },
  ])
}

export default useRiskMapsFiltersModalFields
