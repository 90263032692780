import { JoyStep } from '@templates/Onboarding'

export const ONBOARDING_TITLE = 'Расскажем, как добавить или найти филиал'

export const ONBOARDING_STEPS: JoyStep[] = [
  {
    target: '#add-button',
    title: 'Как добавить филиал',
    content: 'Чтобы добавить филиал, нажмите кнопку “Добавить”',
  },
  {
    target: '#search-block',
    title: 'Как найти филиал',
    content: 'Вы можете найти нужный филиал по наименованию',
  },
  {
    target: '#filter-panel',
    title: 'Как найти филиал',
    content: 'Также можно найти филиал с помощью фильтров',
  },
]
