import StoreTags from '@enums/StoreTags'
import { CommonQueryParams, ItemsFromResponse } from '@models/common'
import {
  CreateDangerEventBody,
  CreateDangerEventResponse,
  DangerEvent,
  DangerEventOption,
} from '@models/dangerEvent'
import emptySplitApi from '../api'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'

const BASE_URL = '/api/risk_map_danger_events'
const JOB_TAGS = [StoreTags.DANGER_EVENTS, StoreTags.DANGER_EVENT]

export const DangerEventsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDangerEvents: builder.query<
      { items: DangerEventOption[]; length: number },
      CommonQueryParams
    >({
      query: (params) => ({
        url: BASE_URL,
        params,
      }),
      transformResponse: (response: ItemsFromResponse<DangerEvent>) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          label: item.value,
          value: item['@id'],
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
      providesTags: [StoreTags.DANGER_EVENTS],
    }),
    createDangerEvent: builder.mutation<
      CreateDangerEventResponse,
      CreateDangerEventBody
    >({
      invalidatesTags: (result) => (result ? JOB_TAGS : []),
      query: (body) => ({
        url: BASE_URL,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useGetDangerEventsQuery, useCreateDangerEventMutation } =
  DangerEventsApi
