import { ItemsFromResponse } from '@models/common'
import {
  CourseCategory,
  CourseCategoryServerResponse,
} from '@models/courseCategory'
import StoreTags from '@enums/StoreTags'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'
import emptySplitApi from '../api'

const BASE_URL = '/api/course_categories'

export const courseCategoriesApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCourseCategories: builder.query<
      { items: CourseCategory[]; length: number },
      void
    >({
      providesTags: [StoreTags.COURSE_CATEGORIES],
      query: () => BASE_URL,
      transformResponse: (
        response: ItemsFromResponse<CourseCategoryServerResponse>
      ) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          value: item['@id'],
          label: item.title,
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
    }),
  }),
})

export const { useGetCourseCategoriesQuery } = courseCategoriesApi
