import {
  emailField,
  phoneField,
  textField,
} from '@templates/AddNewItemModal/templates'
import { Fields } from '@templates/AddNewItemModal/Content'

const useProfileFields = (): Fields => {
  return [
    {
      fields: [
        textField({
          name: 'fullName',
          label: 'ФИО',
          isRequired: true,
          placeholder: 'ФИО',
        }),
        emailField(),
        phoneField(),
      ],
    },
  ]
}

export default useProfileFields
