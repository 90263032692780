import styled from '@emotion/styled'

export const Container = styled.div`
  display: flex;
  padding: 32px 24px;
  border: 1px solid ${({ theme }) => theme.colors.disable};
  border-radius: 24px;
  margin: 10px;
  cursor: pointer;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.main};
    color: ${({ theme }) => theme.colors.main};
    background-color: #f2f6fa;
  }
`

export const Wrapper = styled.div<{ width?: number }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  ${({ width }) => (width ? `width: ${width}px;` : '')}
`
