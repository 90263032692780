import styled from '@emotion/styled'
import { isMobile } from '@const/device'
import Title from '@atoms/Title'

const DesktopFrame = styled.iframe<{ inModal: boolean }>`
  width: ${({ inModal }) => (inModal ? 100 : 78)}vw;
  height: ${({ inModal }) => (inModal ? '88vh' : '70vh')};
  background: ${({ theme }) => theme.colors.background.surface};
  box-shadow: ${({ inModal }) =>
    inModal ? 'none' : '5px 5px 20px rgba(29, 78, 137, 0.1)'};
  border-radius: 15px;
  padding: 10px;
  margin-bottom: ${({ inModal, theme }) => (inModal ? 0 : theme.sizes.twoBase)};
  margin-top: ${({ inModal }) => (inModal ? '-5px' : 0)};
`
const MobileFrame = styled(DesktopFrame)<{ inModal: boolean }>`
  height: ${({ inModal }) => (inModal ? '80vh' : '50vh')};
  width: ${({ inModal }) => (inModal ? 100 : 95)}vw;
`
export const Frame = isMobile ? MobileFrame : DesktopFrame

export const Wrapper = styled.div<{ inModal: boolean }>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  background: ${({ inModal }) => (inModal ? 'white' : 'none')};
  padding-bottom: 15px;
  gap: 10px;
  height: 100vh;
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
`

export const NavButtonsWrapperDesktop = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`

export const NavButtonsWrapperMobile = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
`

export const NavButtonsWrapper = isMobile
  ? NavButtonsWrapperMobile
  : NavButtonsWrapperDesktop

export const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
`

export const TopWrapper = styled.div`
  width: 100%;
`

export const StyledTitle = styled(Title)`
  margin: 0 8px 8px;
  width: 100%;
  white-space: break-spaces;
  text-align: left;
`

export const MobileTopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
`

export const MobileContentWrapper = styled.div<{ inModal: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;

  ${({ inModal }) => (inModal ? 'padding-top: 15px;' : 'margin-top: -10px;')}
`

export const BottomMobileContentWrapper = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;

  & > div {
    display: flex;
    border-radius: 32px;
    padding: 16px 24px;
    background-color: white;
  }
`

export const MobileNavBlockWrapper = styled.div`
  display: flex;
  gap: 10px;

  & button {
    background-color: #f2f6fa;
  }
`
