import { FC } from 'react'
import { ReactComponent as Sad } from '@interface-images/sad-smile.svg'
import { ReactComponent as SadGray } from '@interface-images/sad-smile-gray.svg'
import { ReactComponent as Happy } from '@interface-images/happy-smile.svg'
import { ReactComponent as HappyGray } from '@interface-images/happy-smile-gray.svg'
import { ReactComponent as Lovely } from '@interface-images/lovely-smile.svg'
import { ReactComponent as LovelyGray } from '@interface-images/lovely-smile-gray.svg'
import { ScormVotes } from '@models/scorm'
import { Wrapper } from './styled'

type Props = {
  onClickSad?: () => void
  sadDescription?: string | number
  onClickHappy?: () => void
  happyDescription?: string | number
  onClickLovely?: () => void
  lovelyDescription?: string | number
  activeSmile?: ScormVotes
}

const ThemeSmiles: FC<Props> = ({
  onClickSad,
  onClickHappy,
  onClickLovely,
  sadDescription,
  happyDescription,
  lovelyDescription,
  activeSmile,
}) => {
  const LovelyComponent =
    activeSmile && activeSmile !== ScormVotes.EXCELLENT ? LovelyGray : Lovely

  const HappyComponent =
    activeSmile && activeSmile !== ScormVotes.GOOD ? HappyGray : Happy

  const SadComponent =
    activeSmile && activeSmile !== ScormVotes.BAD ? SadGray : Sad

  return (
    <Wrapper>
      <LovelyComponent onClick={onClickLovely} />
      {lovelyDescription}
      <HappyComponent onClick={onClickHappy} />
      {happyDescription}
      <SadComponent onClick={onClickSad} />
      {sadDescription}
    </Wrapper>
  )
}

export default ThemeSmiles
