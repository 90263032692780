import RiskMapStatuses from '@enums/RiskMapStatuses'
import { TabProps } from '@organisms/Tabs/Tab'

const TAB_SIZE = 'small'

const RISK_MAP_STATUSES_CONFIG: Record<RiskMapStatuses, TabProps> = {
  [RiskMapStatuses.ARCHIVED]: {
    title: 'Архив',
    tabSize: TAB_SIZE,
    badgeColor: 'darkgray',
    id: '1',
  },
  [RiskMapStatuses.APPROVED]: {
    title: 'Утверждён',
    tabSize: TAB_SIZE,
    badgeColor: 'green',
    id: '2',
  },
  [RiskMapStatuses.CHANGED]: {
    title: 'Изменён',
    tabSize: TAB_SIZE,
    badgeColor: 'orange',
    id: '3',
  },
  [RiskMapStatuses.DRAFT]: {
    title: 'Черновик',
    tabSize: TAB_SIZE,
    badgeColor: 'lightgray',
    id: '4',
  },
  [RiskMapStatuses.CANCELED]: {
    title: 'Отменён',
    tabSize: TAB_SIZE,
    badgeColor: 'red',
    id: '5',
  },
  [RiskMapStatuses.EXTENDED]: {
    title: 'Продлён',
    tabSize: TAB_SIZE,
    badgeColor: 'darkblue',
    id: '6',
  },
}

export default RISK_MAP_STATUSES_CONFIG
