import useCourses from '@hooks/useCourses'
import { SelectSearchFieldParams } from '@templates/AddNewItemModal/templates/selectSearchField'
import { Field } from '@templates/AddNewItemModal/Content'
import { selectSearchField } from '@templates/AddNewItemModal/templates'

const useCoursesSelectField = (): ((
  params: Partial<SelectSearchFieldParams> & { name: string; label: string }
) => Field) => {
  const {
    data: coursesData,
    isLoading: coursesIsLoading,
    setPage,
    setFilters,
  } = useCourses()
  const { items: courses = [], length = 0 } = coursesData || {}

  return (params) =>
    selectSearchField({
      ...params,
      options: courses,
      isLoading: coursesIsLoading,
      setFilters,
      setPage,
      length,
      dropdownWidth: 500,
    })
}

export default useCoursesSelectField
