import * as React from 'react'
import { FC, useEffect } from 'react'
import CompanyModal from '@modals/CompanyModal'
import { useGetCompanyQuery } from '@services/companies'
import Loader from '@organisms/Loader'
import AccordionBodyFieldWithTabs from '@templates/AccordionBodyFieldWithTabs'
import AccordionBodyFieldsEmployees from '@templates/AccordionBodyFieldsEmployees'
import EmployeesCountField from '@templates/EmployeesCountField'

type Props = {
  id: number
  onSuccess?: () => void
}

const CompanyListItem: FC<Props> = ({ id, onSuccess }) => {
  const { data, isLoading, isSuccess } = useGetCompanyQuery(id)
  const { title = '', countEmployees = 0, curators } = data || {}

  useEffect(() => {
    if (isSuccess) {
      onSuccess?.()
    }
  }, [isSuccess])

  return (
    <Loader isLoading={isLoading}>
      <AccordionBodyFieldWithTabs
        label="Наименование"
        items={[
          {
            id: '1',
            title,
            editModal: {
              Component: CompanyModal,
              props: {
                company: data,
              },
            },
          },
        ]}
      />
      <AccordionBodyFieldsEmployees
        label="Кураторы компании"
        users={curators}
      />
      <EmployeesCountField
        countEmployees={countEmployees}
        company={
          data
            ? {
                label: data.title,
                value: data['@id'],
              }
            : undefined
        }
        isAccordionField
      />
    </Loader>
  )
}

export default CompanyListItem
