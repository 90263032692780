import * as React from 'react'
import { FC, useEffect } from 'react'
import { notification } from 'antd'
import DoneButton from '@organisms/DoneButton'
import { useMarkEducationAsDoneMutation } from '@services/employeeEducationDestination'
import { EmployeeEducationDestination } from '@models/employeeEducationDestination'
import useMe from '@hooks/useMe'
import ButtonWithConfirm from '@templates/ButtonWithConfirm'

type Props = {
  employeeEducationDestination: EmployeeEducationDestination
  onSuccess?: () => void
}

const MarkEducationAsDoneButton: FC<Props> = ({
  employeeEducationDestination,
  onSuccess,
}) => {
  const { isAdmin, isCompanyCurator } = useMe()

  const [markAsDone, { isSuccess }] = useMarkEducationAsDoneMutation()

  useEffect(() => {
    if (isSuccess) {
      notification.success({ message: 'Прохождение успешно зачтено' })
      setTimeout(() => onSuccess?.(), 400)
    }
  }, [isSuccess])

  const handleMarkAsDone = () => {
    void markAsDone(employeeEducationDestination.id)
  }

  if (
    (isAdmin || isCompanyCurator) &&
    employeeEducationDestination.progress < 100
  ) {
    return (
      <ButtonWithConfirm
        ButtonComponent={({ onClick }) => (
          <DoneButton onClick={onClick}>Зачесть прохождение</DoneButton>
        )}
        onConfirm={handleMarkAsDone}
        confirmText="Вы точно хотите зачесть прохождение курса?"
        confirmButtonText="Подтвердить"
        cancelText="Назад"
      />
    )
  }

  return null
}

export default MarkEducationAsDoneButton
