import { FC, useRef } from 'react'
import { useToggle } from 'react-use'
import AddNewItemModal from '@templates/AddNewItemModal'
import useFormProcessor from '@hooks/useFormProcessor'
import { useReviseRiskMapMutation } from '@services/riskMapVersions'
import RiskMapStatuses from '@enums/RiskMapStatuses'
import { Option } from '@organisms/Select'
import RiskMapFileField from '@templates/RiskMapFileField'
import { RiskMapDetailed } from '@models/riskMap'
import ConfirmModalContent from '@organisms/ConfirmModalContent'
import { ButtonsWrapper, TextWrapper } from '@templates/DeleteButton/styled'
import Text from '@organisms/Text'
import PrimaryButton from '@organisms/PrimaryButton'
import SecondaryButton from '@organisms/SecondaryButton'
import Modal from '@organisms/Modal'
import { ReviseRiskMapBody } from '@models/riskMapVersions'
import { File } from '@models/file'
import { StyledTitle } from './styled'
import useApproveModalFields from './useReviseFields'

type FormValues = {
  status: Option<RiskMapStatuses>
  number: string | undefined
  date: string | undefined
  reviseBeforeAt: string | undefined
  file: File | undefined
}

type Props = {
  data: RiskMapDetailed
}

const ReviseModal: FC<Props> = ({ data: riskMap }) => {
  const formStateRef = useRef<ReviseRiskMapBody | null>(null)

  const [confirmModalIsVisible, toggleConfirmModalIsVisible] = useToggle(false)

  const [reviseRiskMap, data] = useReviseRiskMapMutation()

  const methods = useFormProcessor({
    apiErrors: data.error,
  })
  const { watch } = methods

  const fields = useApproveModalFields(methods, riskMap.status)

  const status: Option<RiskMapStatuses> | undefined = watch('status')

  const onSubmitForm = (form: FormValues) => {
    const formState: ReviseRiskMapBody = {
      riskMap: riskMap['@id'],
      status: form.status?.value,
      number: form.number,
      date: form.date,
      reviseBeforeAt: form.reviseBeforeAt,
      file: form.file?.['@id'],
    }

    if (form.status?.value === RiskMapStatuses.CANCELED) {
      formStateRef.current = formState
      toggleConfirmModalIsVisible(true)
    } else {
      void reviseRiskMap(formState)
    }
  }

  const handleConfirm = () => {
    if (formStateRef.current) {
      void reviseRiskMap(formStateRef.current)
    }

    toggleConfirmModalIsVisible(false)
  }

  return (
    <AddNewItemModal
      {...data}
      customFullTitle="Пересмотреть карту рисков"
      methods={methods}
      onSubmitForm={onSubmitForm}
      fields={fields}
      contentAfterFields={
        <>
          {status?.value === RiskMapStatuses.CHANGED && (
            <>
              <StyledTitle>Загрузить карту рисков</StyledTitle>
              <RiskMapFileField />
            </>
          )}
          <Modal visible={confirmModalIsVisible}>
            <ConfirmModalContent
              title="Подтвердите действие"
              leftIcon="sad-bear-new.svg"
              rightContent={
                <>
                  <TextWrapper>
                    <Text>
                      После отмены карты рисков, она станет недоступна для
                      дальнейших изменений, и не будет показана сотрудникам.
                    </Text>
                    <Text>Продолжить?</Text>
                  </TextWrapper>
                  <ButtonsWrapper>
                    <PrimaryButton onClick={handleConfirm} big>
                      Продолжить
                    </PrimaryButton>
                    <SecondaryButton onClick={toggleConfirmModalIsVisible} big>
                      Назад
                    </SecondaryButton>
                  </ButtonsWrapper>
                </>
              }
            />
          </Modal>
        </>
      }
    />
  )
}

export default ReviseModal
