import * as React from 'react'
import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import useMe from '@hooks/useMe'
import MultilineFieldNew from '@templates/MultilineFieldNew'
import usePositionDivisionFields from './usePositionDivisionFields'

const FIELDS_NAME = 'subdivisionJobPositionRels'

const DivisionPositionMultilineFields: FC = () => {
  const { divisionCuratorWithOneDivision } = useMe()
  const methods = useFormContext()

  const fieldsSrc = usePositionDivisionFields(methods)

  return (
    <MultilineFieldNew
      fieldsSrc={fieldsSrc}
      fieldsName={FIELDS_NAME}
      amendValue={
        divisionCuratorWithOneDivision
          ? {}
          : {
              division: {},
              position: {},
            }
      }
      addLabel={
        divisionCuratorWithOneDivision
          ? 'добавить должность'
          : 'добавить подразделение и должность'
      }
      fieldId="position-division"
    />
  )
}

export default DivisionPositionMultilineFields
