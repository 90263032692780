import { isAfter, isBefore, sub, format, parse } from 'date-fns'

export const formatServerDate = (
  serverDate: string,
  withHours = false
): string => {
  if (!serverDate) return ''

  const year = serverDate.slice(0, 4)
  const month = serverDate.slice(5, 7)
  const day = serverDate.slice(8, 10)
  const hours = serverDate.slice(11, 13)
  const minutes = serverDate.slice(14, 16)

  return withHours
    ? `${day}.${month}.${year} ${hours}:${minutes}`
    : `${day}.${month}.${year}`
}

export const inRange = (
  date: Date,
  yearsStart: number,
  yearsEnd: number
): boolean => {
  const now = new Date()

  return (
    isBefore(date, sub(now, { years: yearsStart })) &&
    isAfter(date, sub(now, { years: yearsEnd }))
  )
}

export const toClientDate = (date: string | Date): string => {
  return format(typeof date === 'string' ? new Date(date) : date, 'dd.MM.yyyy')
}

export const toServerDate = (date: string | Date): string => {
  return (
    format(
      typeof date === 'string'
        ? new Date(parse(date, 'dd.MM.yyyy', new Date()))
        : date,
      'yyyy-MM-dd'
    ) + 'T00:00:00.000Z'
  )
}
