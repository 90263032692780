import styled from '@emotion/styled'

export const DescriptionInfoWrapper = styled.div`
  display: flex;
  padding: 16px;
  gap: 8px;
  border-radius: 24px;
  background-color: #f2f6fa;
  align-items: center;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
`
