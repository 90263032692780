import { FC } from 'react'
import { components, MenuProps } from 'react-select'
import { isMobile } from '@const/device'

const Menu: FC<MenuProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { dropdownWidth } = props.selectProps || {}

  return (
    <components.Menu
      {...props}
      innerProps={{
        ...props.innerProps,
        style: {
          ...props.innerProps.style,
          borderRadius: 25,
          padding: 10,
          width: isMobile ? undefined : dropdownWidth,
        },
      }}
    />
  )
}

export default Menu
