import { FC, MouseEventHandler } from 'react'
import DownloadButton from '@organisms/DownloadButton'
import ButtonStyle from '@enums/ButtonStyle'
import { File } from '@models/file'
import { BACKEND_URL } from '@const/env'
import { useMarkRiskMapAsDownloadedMutation } from '@services/riskMapVersions'

type Props = {
  file: File
  isWhiteBackground?: boolean
  isDisabled?: boolean
  revision?: string
  index?: number
}

const DownloadRiskMap: FC<Props> = ({
  file,
  isWhiteBackground = false,
  isDisabled = false,
  revision,
  index,
}) => {
  const [markAsDownloaded] = useMarkRiskMapAsDownloadedMutation()

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    if (revision) {
      void markAsDownloaded(revision)
    }
    window.open(`${BACKEND_URL}/uploads/${file.filePath}`, '_blank')
  }

  return (
    <>
      <DownloadButton
        buttonStyle={isWhiteBackground ? ButtonStyle.WHITE : undefined}
        onClick={handleClick}
        disabled={isDisabled}
        id={`download-risk-map-${index}`}
      >
        Скачать карту рисков
      </DownloadButton>
    </>
  )
}

export default DownloadRiskMap
