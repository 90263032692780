import { useEffect, useRef } from 'react'
import { isEqual } from 'lodash'
import { log } from '@utils/console'

const useEffectByValue = (cb: () => void, deps = [] as any[]) => {
  const prevDepsRef = useRef<any[]>([])

  if (window.ENABLE_LOGS_EQUAL) {
    log('====================')
    log('deps', JSON.stringify(deps))
    log('prevDepsRef.current', JSON.stringify(prevDepsRef.current))
    log(
      'isEqual(deps, prevDepsRef.current)',
      isEqual(deps, prevDepsRef.current)
    )
  }

  useEffect(() => {
    if (!isEqual(deps, prevDepsRef.current)) {
      cb()
      prevDepsRef.current = deps
    }
  }, [deps])
}

export default useEffectByValue
